import graphql from 'babel-plugin-relay/macro';
import { FC, Suspense, useEffect } from 'react';
import { useQueryLoader } from 'react-relay';

import { InternalDocumentComponent } from 'src/components/internalDocuments/InternalDocumentComponent';
import { InternalDocumentSkeleton } from 'src/skeletons/internalDocuments/InternalDocumentSkeleton';

const InternalDocumentComponentParentQuery = graphql`
    query InternalDocumentComponentParentQuery($internalDocumentSetId: String!) {
        internalDocuments(internalDocumentSetId: $internalDocumentSetId) {
            edges {
                node {
                    id
                    fileName
                    fileUrl
                    created
                    user {
                        id
                        firstName
                        lastName
                        email
                        company {
                            id
                            companyName
                            logo
                        }
                    }
                }
            }
        }
    }
`;

interface Props {
    internalDocumentSetId: string;
    viewIsReadOnly: boolean;
}

export const InternalDocumentComponentParent: FC<Props> = ({
    internalDocumentSetId,
    viewIsReadOnly,
}: Props) => {
    const [internalDocumentQueryRef, loadQuery, disposeQuery] = useQueryLoader(
        InternalDocumentComponentParentQuery,
        null,
    );

    const refreshInternalDocumentQuery = () => {
        loadQuery({ internalDocumentSetId }, { fetchPolicy: 'store-and-network' });
    };

    useEffect(() => {
        refreshInternalDocumentQuery();
        return () => {
            disposeQuery();
        };
    }, [internalDocumentSetId]);

    return (
        <>
            {internalDocumentQueryRef ? (
                <>
                    <Suspense fallback={<InternalDocumentSkeleton />}>
                        <InternalDocumentComponent
                            internalDocumentQueryRef={internalDocumentQueryRef}
                            refreshInternalDocumentQuery={refreshInternalDocumentQuery}
                            internalDocumentSetId={internalDocumentSetId}
                            viewIsReadOnly={viewIsReadOnly}
                        />
                    </Suspense>
                </>
            ) : (
                <InternalDocumentSkeleton />
            )}
        </>
    );
};
