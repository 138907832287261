import * as React from 'react';

import { Trans } from '@lingui/macro';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Button, Grid, Typography } from '@mui/material';

import { Modal } from 'src/components/common/modal';
import usePersistToLocalStorage from 'src/hooks/usePersistToLocalStorage';

export const AI_DISCLAIMER_LOCAL_STORAGE_KEY = 'dislaimerAISeen';

interface Props {
    onConfirm: any;
    rest?: any;
    triggerButtonComponent: any;
}

const DisclaimerAIModal = ({ onConfirm, triggerButtonComponent, ...rest }: Props) => {
    const [savedValue, saveToLocalStorage] = usePersistToLocalStorage(
        AI_DISCLAIMER_LOCAL_STORAGE_KEY,
        false,
    );
    const [open, setOpen] = React.useState(false);
    const setDisclaimerSeenCookie = () => {
        saveToLocalStorage(true);
    };

    const handleClose = () => setOpen(false);
    const handleOpenClick = React.useCallback(() => {
        if (savedValue) {
            onConfirm();
        } else {
            setOpen(true);
        }
    }, [onConfirm, savedValue]);

    const handleConfirm = React.useCallback(() => {
        setDisclaimerSeenCookie();
        handleClose();
        onConfirm();
    }, [onConfirm, handleClose, setDisclaimerSeenCookie]);

    return (
        <Modal
            onClose={handleClose}
            externalControl
            isOpen={open}
            triggerButtonComponent={() => triggerButtonComponent(handleOpenClick)}
            style={{
                height: '85%',
                overflow: 'scroll',
                width: 'auto',
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                zIndex: 1501,
            }}
            {...rest}
        >
            <Grid sx={{ pl: 4, pr: 4, pt: 0, pb: 2 }}>
                <Trans>
                    <Typography sx={{ mb: 1 }} variant="h6" component="div">
                        This is an apadua AI feature - Data Security and Privacy Disclaimer
                    </Typography>
                    <Typography sx={{ mb: 1 }} textAlign="justify" variant="body1">
                        At apadua we embrace new technology with excitement and diligence.
                        apadua&apos;s software solutions and AI features are committed to the
                        highest standards of data security and privacy. In line with the General
                        Data Protection Regulation (GDPR), we ensure stringent compliance with data
                        protection and privacy laws. Our AI implementation employs Microsoft&apos;s
                        Azure/OpenAI capabilities. For detailed information on our data handling
                        practices, please refer to Microsoft&apos;s guidelines on data privacy for
                        cognitive services, accessible at{' '}
                        <a
                            href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            &ldquo;Data, privacy, and security for Azure OpenAI Service - Azure AI
                            services&rdquo;
                        </a>{' '}
                    </Typography>
                    <Typography sx={{ mb: 1 }} textAlign="justify" variant="body1">
                        Your use of this AI feature signifies your agreement to these terms.
                    </Typography>
                    <Typography sx={{ mb: 1 }} textAlign="justify" variant="body1">
                        This is how Microsoft handles data send and received from their AI. Your
                        data (inputs & outputs):
                    </Typography>
                    <Typography sx={{ mb: 1, pl: 2 }} textAlign="justify" variant="body1">
                        <ul>
                            <li>are NOT available to other users</li>
                            <li>are NOT available to OpenAI</li>
                            <li>are NOT used to improve OpenAI models</li>
                            <li>
                                are NOT used to improve any Microsoft or 3rd party products or
                                services
                            </li>
                            <li>
                                are NOT used for automatically improving Azure OpenAI models for
                                your use in your resource
                            </li>
                        </ul>
                    </Typography>
                    <Typography sx={{ mb: 1 }} variant="body1" textAlign="justify">
                        The Azure OpenAI Service is fully controlled by Microsoft. Microsoft hosts
                        the OpenAI models in Microsoft’s Azure environment and the Service does NOT
                        interact with any services operated by OpenAI (e.g. ChatGPT, or the OpenAI
                        API). Servers are hosted in Europe. By using this service, you acknowledge
                        that your interactions with ChatGPT remain on the Azure servers of apadua
                        and are not shared with third parties.
                    </Typography>
                    <Typography sx={{ mb: 1 }} variant="body1" textAlign="justify">
                        All AI features of apadua are indicated with a magic wand{' '}
                        <AutoFixHighIcon /> icon. Please be aware that neither Microsoft nor any of
                        its affiliates will be liable for any direct, indirect, incidental,
                        consequential, or exemplary damages resulting from the use of this service,
                        even if advised of the possibility of such damages. AI suggestions are
                        efficiency features. Check the created content thoroughly and adjust it to
                        your needs.
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="body1" textAlign="justify">
                        We are happy to receive your feedback via the feedback form or directly to{' '}
                        <a href="mailto:support@apadua.com">support@apadua.com</a>.
                    </Typography>

                    <Grid sx={{ mb: 3 }} item xs={12} justifyContent="flex-end" display="flex">
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={handleClose}
                            sx={{ mr: 2 }}
                        >
                            Continue without AI
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={handleConfirm}
                        >
                            Continue with AI
                        </Button>
                    </Grid>
                </Trans>
            </Grid>
        </Modal>
    );
};

export default DisclaimerAIModal;
