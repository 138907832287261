import { ApaduaAPI } from './ApaduaAPI';

export interface Tag {
    key: string;
    label: Tag;
}

export interface TagRelation {
    accessRight: string;
    tag: Tag;
}

export interface ListData {
    id: string;
    name: string;
    note: string;
    tags_relations: TagRelation[];
}

export interface ListItem {
    id: string;
    modelName: string;
    objectId: string;
    note?: string;
}

export interface NewListItem {
    content_type: string;
    object_id: string;
    note?: string;
}

export type ListResponse = Array<ListData>;

export class ListsAPI extends ApaduaAPI {
    resource: string = 'api/lists/';

    getLists({ includeItems = false, tags = '' }): Promise<ListResponse> {
        const params = {
            include_items: includeItems,
            tags,
        };

        return this.get('', { params });
    }

    getList(id: string, { includeItems = false }): Promise<ListData> {
        const params = {
            include_items: includeItems,
        };

        return this.get(`${id}/`, { params });
    }

    createList(data: any): Promise<ListData> {
        return this.post('', data);
    }

    createOrganisationList(data: any): Promise<ListData> {
        return this.post('organisation/', data);
    }

    updateList(id: string, data: any): Promise<Response> {
        return this.put(`${id}/`, data);
    }

    deleteList(id: string): Promise<Response> {
        return this.delete(`${id}/`);
    }

    getListItems(id: string): Promise<Response> {
        return this.get(`${id}/listitem/`);
    }

    getListItem(id: string, itemId: string): Promise<Response> {
        return this.get(`${id}/listitem/${itemId}`);
    }

    createListItems(id: string, data: Array<ListItem>): Promise<Response> {
        return this.post(`${id}/listitem/bulk_create/`, data);
    }

    updateListItem(id: string, itemId: string, data: any): Promise<Response> {
        return this.patch(`${id}/listitem/${itemId}/`, data);
    }

    deleteListItem(id: string, itemId: string): Promise<Response> {
        return this.delete(`${id}/listitem/${itemId}/`);
    }
}
