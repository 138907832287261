import { FC } from 'react';

import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import TranslatedText from 'src/translations/translatedText';

interface SummaryCardItemProps extends CardProps {
    header?: string;
    content?: string;
    onFlyTranslate?: boolean;
}

const SummaryCardItem: FC<SummaryCardItemProps> = ({
    header,
    content,
    onFlyTranslate = false,
    ...rest
}: SummaryCardItemProps) => (
    <Card {...rest}>
        {header && (
            <CardHeader
                title={
                    <Typography
                        color="primary"
                        variant="subtitle1"
                        style={{
                            fontWeight: 700,
                        }}
                    >
                        {onFlyTranslate ? <TranslatedText>{header}</TranslatedText> : header}
                    </Typography>
                }
            />
        )}
        {content && (
            <CardContent>
                <Typography
                    color="primary"
                    variant="body2"
                    style={{
                        textAlign: 'justify',
                    }}
                >
                    {onFlyTranslate ? <TranslatedText>{content}</TranslatedText> : content}
                </Typography>
            </CardContent>
        )}
    </Card>
);

export default SummaryCardItem;
export type { SummaryCardItemProps };
