import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/styles';

import { StepIcon } from 'src/components/StepIcon';
import { StepWithLink } from 'src/components/StepWithLink';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';

interface Props {
    orientation: 'horizontal' | 'vertical';
    editMode: boolean;
}

const StyledStep = styled(Step)({
    '& a': {
        textDecoration: 'none !important',
    },
});

export const RatecardProjectStepper: FC<Props> = ({ orientation, editMode }: Props) => {
    const { i18n } = useLingui();
    const location = useLocation();
    const { ratecardProjectQuery } = useRatecardProjectQuery();

    const ratecard = useFragment(
        graphql`
            fragment RatecardProjectStepper_ratecardProject on RatecardProjectNode {
                id
                projectName
                editMode
                validation {
                    tenderPlanning
                    supplierSelection
                    ratecard
                    otherInformation
                    allSuccessful
                    errorMessage
                }
            }
        `,
        ratecardProjectQuery.ratecardProject,
    );

    const part = editMode ? 'evaluation/edit' : 'setup';

    const steps = [
        {
            step: 1,
            label: i18n._('Tender Planning'),
            status: ratecard?.validation?.tenderPlanning,
            path: `/dashboard/ratecards/${ratecard?.id}/${part}/tender-planning`,
        },
        {
            step: 2,
            label: i18n._('Supplier Selection'),
            status: ratecard?.validation?.supplierSelection,
            path: `/dashboard/ratecards/${ratecard?.id}/${part}/supplier-selection`,
        },
        {
            step: 3,
            label: i18n._('Rate Card'),
            status: ratecard?.validation?.ratecard,
            path: `/dashboard/ratecards/${ratecard?.id}/${part}/ratecard`,
        },
        {
            step: 4,
            label: i18n._('Other Information'),
            status: ratecard?.validation?.otherInformation,
            path: `/dashboard/ratecards/${ratecard?.id}/${part}/other-information`,
        },
    ];

    const activeStep = steps.findIndex((step) => matchPath(step.path, location.pathname));

    return (
        <Stepper nonLinear activeStep={activeStep} orientation={orientation}>
            {steps.map((step, i) => (
                <StyledStep key={step.step}>
                    <StepWithLink to={step.path} isCurrent={i === activeStep}>
                        <StepLabel
                            style={{ cursor: 'pointer' }}
                            error={step.status === 'error'}
                            icon={<StepIcon type={step.status} step={step.step} />}
                        >
                            {step.label}
                        </StepLabel>
                    </StepWithLink>
                </StyledStep>
            ))}
        </Stepper>
    );
};
