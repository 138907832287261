import { FC, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';
import { SearchContext } from 'src/explore/contexts/SearchContext';
import { CompanyExploreContextProvider } from 'src/explore/providers/CompanyExploreContextProvider';

interface LinkTabProps {
    label?: string;
    href?: string;
}

function LinkTab(props: LinkTabProps) {
    return (
        <Tab
            component="a"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

interface CompanyProfileTabsProps {
    company: any;
}

const CompanyProfileTabs: FC<CompanyProfileTabsProps> = ({ company }: CompanyProfileTabsProps) => {
    const { i18n } = useLingui();
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useContext(CompanyExploreContext);
    const { currentSearch } = useContext(SearchContext);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let searchQuery = encodeURIComponent(urlParams.get('search'));
        if (searchQuery === 'null') {
            searchQuery = null;
        }

        if (search || searchQuery) {
            navigate(
                newValue === 0
                    ? `about?search=${search || searchQuery}`
                    : `explore?search=${search || searchQuery}`,
            );
        } else {
            navigate(newValue === 0 ? 'about' : 'explore');
        }
    };

    useEffect(() => {
        if (location.pathname.includes('/about')) {
            setValue(0);
        } else {
            setValue(1);
        }
    }, [location]);

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Card>
                <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                    <LinkTab label={i18n._('About')} />
                    <LinkTab label={i18n._('Explore')} />
                    {currentSearch && (
                        <Button
                            variant="contained"
                            style={{
                                margin: '8px',
                                position: 'absolute',
                                right: 0,
                            }}
                            onClick={() => navigate(currentSearch)}
                        >
                            {i18n._('Back to search')}
                        </Button>
                    )}
                </Tabs>
                <Box>
                    <CompanyProfileContext.Provider
                        value={{
                            company,
                        }}
                    >
                        <CompanyExploreContextProvider>
                            <Outlet />
                        </CompanyExploreContextProvider>
                    </CompanyProfileContext.Provider>
                </Box>
                <Typography
                    variant="subtitle2"
                    color="#c7c7c7"
                    sx={{
                        p: 2,
                    }}
                >
                    {company?.id ? `#${atob(company.id).split(':')[1]}` : ''}
                </Typography>
            </Card>
        </Box>
    );
};

export default CompanyProfileTabs;
