/**
 * @generated SignedSource<<c81529152dbb6986c3cbd9be0604e944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompanyExploreContextProviderQuery$variables = {
  companyId?: string | null;
  domain?: string | null;
  first?: number | null;
  keyword?: string | null;
  last?: number | null;
};
export type CompanyExploreContextProviderQuery$data = {
  readonly companyEsResults: any | null;
};
export type CompanyExploreContextProviderQuery = {
  response: CompanyExploreContextProviderQuery$data;
  variables: CompanyExploreContextProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "keyword"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "domain",
        "variableName": "domain"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "keyword",
        "variableName": "keyword"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      }
    ],
    "kind": "ScalarField",
    "name": "companyEsResults",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyExploreContextProviderQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompanyExploreContextProviderQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "807ea0a199518f058f82d93018e65aff",
    "id": null,
    "metadata": {},
    "name": "CompanyExploreContextProviderQuery",
    "operationKind": "query",
    "text": "query CompanyExploreContextProviderQuery(\n  $first: Int\n  $last: Int\n  $keyword: String\n  $domain: String\n  $companyId: ID\n) {\n  companyEsResults(first: $first, last: $last, keyword: $keyword, domain: $domain, companyId: $companyId)\n}\n"
  }
};
})();

(node as any).hash = "58c1260d2e141cc4a439fb133ccc5bbb";

export default node;
