import { createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface QuoteContextType {
    quoteQueryRef: PreloadedQuery | null;
    refreshQuoteQuery: () => void;
}

const initialValues: QuoteContextType = {
    quoteQueryRef: null,
    refreshQuoteQuery: () => {},
};

export const QuoteContext = createContext(initialValues);
