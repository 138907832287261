import { ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import OptionsSection from './optionsSection';

interface SelectSectionProps<T> {
    values: T[];
    selected: T[];
    onSelect: (selected: T[], value: T) => void;
    equalityFn?: (a: T, b: T) => boolean;
    renderLabel?: (a: T) => ReactNode;
    sectionTitle?: ReactNode;
    sectionHeader?: ReactNode;
}

const SelectSection = ({
    values,
    selected,
    onSelect,
    equalityFn = (a, b) => a === b,
    renderLabel = (a) => a,
    sectionTitle,
    sectionHeader,
}: SelectSectionProps<any>) => {
    const select = (value: any) => {
        onSelect([...selected, value], value);
    };

    const deselect = (value: any) => {
        const deselectIndex = selected.findIndex((element) => equalityFn(element, value));
        const newSelected = [...selected];
        newSelected.splice(deselectIndex, 1);
        onSelect(newSelected, value);
    };

    const handleSelection = (value: any) =>
        !selected.some((item) => equalityFn(item, value)) ? select(value) : deselect(value);

    const handleToggleSelection = (selectAll: boolean) =>
        selectAll ? onSelect([...values], null) : onSelect([], null);

    const sectionHeaderWithSelect = (
        <Grid container alignItems="center" gap={2}>
            {sectionHeader}
            <FormControlLabel
                key="toggle-selected"
                label={
                    selected.length === values.length ? (
                        <Trans>Deselect all</Trans>
                    ) : (
                        <Trans>Select all</Trans>
                    )
                }
                data-testid="checkboxItem-toggle-selected"
                control={
                    <Checkbox
                        checked={selected.length === values.length}
                        indeterminate={selected.length && selected.length < values.length}
                        onChange={() => handleToggleSelection(selected.length !== values.length)}
                    />
                }
            />
        </Grid>
    );

    return (
        <OptionsSection
            data-testid="companiesOption"
            title={sectionTitle}
            header={sectionHeaderWithSelect}
        >
            {values?.map((value, idx) => (
                <FormControlLabel
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    label={renderLabel(value)}
                    data-testid={`checkboxItem-${idx}`}
                    control={
                        <Checkbox
                            checked={selected.some((item) => equalityFn(item, value))}
                            onChange={(e) => handleSelection(value)}
                        />
                    }
                />
            ))}
        </OptionsSection>
    );
};

export default SelectSection;
