/**
 * @generated SignedSource<<241cfc7d199a89759eb1cc56142f3389>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserTrackingConsentInput = {
  clientMutationId?: string | null;
  trackingConsent: boolean;
};
export type CookieConsentBannerUpdateUserTrackingConsentMutation$variables = {
  input: UpdateUserTrackingConsentInput;
};
export type CookieConsentBannerUpdateUserTrackingConsentMutation$data = {
  readonly updateUserTrackingConsent: {
    readonly user: {
      readonly id: string;
      readonly trackingConsent: boolean | null;
    } | null;
  } | null;
};
export type CookieConsentBannerUpdateUserTrackingConsentMutation = {
  response: CookieConsentBannerUpdateUserTrackingConsentMutation$data;
  variables: CookieConsentBannerUpdateUserTrackingConsentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserTrackingConsentPayload",
    "kind": "LinkedField",
    "name": "updateUserTrackingConsent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trackingConsent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CookieConsentBannerUpdateUserTrackingConsentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CookieConsentBannerUpdateUserTrackingConsentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1570accc410758f26ab6674909b95cce",
    "id": null,
    "metadata": {},
    "name": "CookieConsentBannerUpdateUserTrackingConsentMutation",
    "operationKind": "mutation",
    "text": "mutation CookieConsentBannerUpdateUserTrackingConsentMutation(\n  $input: UpdateUserTrackingConsentInput!\n) {\n  updateUserTrackingConsent(input: $input) {\n    user {\n      trackingConsent\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd8899215c60fd52db58543d2350c327";

export default node;
