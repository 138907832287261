import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { Tooltip } from '@mui/material';

export const TooltipRenderer: React.FunctionComponent<ICellRendererParams> = ({
    value,
}: ICellRendererParams) => (
    <Tooltip title={value} placement="top">
        <div>{value}</div>
    </Tooltip>
);
