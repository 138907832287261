import { EMPTY_OBJECT } from 'src/utils/empty';

export const addSearchParams = (
    url: string,
    params: Record<string, string> = EMPTY_OBJECT,
): string => {
    const urlObject = new URL(url);
    const searchParams = new URLSearchParams([
        ...Array.from(urlObject.searchParams.entries()),
        ...Object.entries(params),
    ] as string[][]);
    const targetUrl = searchParams.toString().length
        ? `${urlObject.origin}${urlObject.pathname}${urlObject.hash}?${searchParams.toString()}`
        : `${urlObject.origin}${urlObject.pathname}${urlObject.hash}`;
    return new URL(targetUrl).href;
};
