import graphql from 'babel-plugin-relay/macro';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-relay/hooks';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';

import { FormikFormErrors } from 'src/components/FormikFormErrors';
import { TextField } from 'src/components/formik-material-ui/TextField';
import useErrorMessage from 'src/translations/hooks/useErrorMessage';
import DismissibleToast from 'src/utils/DismissibleToast';

import { useRatecardProjectQuery } from '../RatecardProjectProvider';

interface Props {
    editModeNextUrl?: string;
}

export const EditModeLeaveMessageDialog: FC<Props> = ({ editModeNextUrl }: Props) => {
    const { i18n } = useLingui();
    const navigate = useNavigate();
    const { ratecardProjectQuery, refreshRatecardProjectQuery } = useRatecardProjectQuery();
    const ratecardId = ratecardProjectQuery?.ratecardProject?.id;
    const extractErrorMessage = useErrorMessage();

    const defaultMessage =
        i18n._("Changes have been made to the rate card tender '") +
        ratecardProjectQuery?.ratecardProject?.projectName +
        i18n._("'. Please enter the tender and check the changes.");

    const [exitEditModeMutation, exitInFlight] = useMutation(graphql`
        mutation EditModeLeaveMessageDialogExitEditModeMutation(
            $input: RatecardProjectLeaveEditModeMutationInput!
        ) {
            ratecardProjectLeaveEditMode(input: $input) {
                ratecardProject {
                    id
                }
            }
        }
    `);

    const ValidationSchema = Yup.object().shape({
        editModeMessage: Yup.string().required(i18n._('This field is required')),
    });

    const exitEditMode = (message: string) => {
        exitEditModeMutation({
            variables: {
                input: {
                    ratecardProjectId: ratecardId,
                    message,
                },
            },
            onCompleted(completedData, errors) {
                if (errors) {
                    toast.error(DismissibleToast(extractErrorMessage(errors, 'exitEditModeError')));
                } else {
                    refreshRatecardProjectQuery();
                    if (editModeNextUrl && !editModeNextUrl?.includes('/evaluation/edit')) {
                        navigate(editModeNextUrl);
                    } else {
                        navigate(`/dashboard/ratecards/${ratecardId}/evaluation/overview`);
                    }
                    toast.success(DismissibleToast(i18n._('You left edit mode')));
                }
            },
        });
    };

    const onSubmit = (values) => {
        exitEditMode(values.editModeMessage);
    };

    const leaveWithoutMessage = () => {
        exitEditMode(null);
    };

    const form = ({ status, submitForm }): JSX.Element => (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: 500,
                },
            }}
            maxWidth="xs"
            aria-labelledby="dialog-title"
            open
        >
            <DialogTitle id="dialog-title">
                {i18n._('Leave a message for the consultants')}
            </DialogTitle>
            <DialogContent dividers>
                <Form>
                    <Typography variant="subtitle2">
                        {i18n._(
                            'Please edit or extend the following message to reflect the important changes made in edit mode',
                        )}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Field
                            fullWidth
                            multiline
                            rows={8}
                            component={TextField}
                            name="editModeMessage"
                            label={i18n._('Changes made in edit mode')}
                            data-testid="editModeMessage"
                        />
                    </Box>
                    <FormikFormErrors status={status} />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={leaveWithoutMessage}
                    disabled={exitInFlight}
                    data-testid="messageCancelButton"
                >
                    {i18n._('Leave without providing a message')}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={submitForm}
                    disabled={exitInFlight}
                    data-testid="messageSubmitButton"
                >
                    {i18n._('Submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Formik
            initialValues={{ editModeMessage: defaultMessage }}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
        >
            {form}
        </Formik>
    );
};
