import toast from 'react-hot-toast';

import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, Typography } from '@mui/material';

const DissmissibleToast =
    (message) =>
    ({ id }: { id: string }) => (
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item>
                <Typography variant="subtitle2">{message}</Typography>
            </Grid>
            <Grid item>
                <IconButton
                    sx={{ mr: -2, ml: 2 }}
                    type="button"
                    onClick={() => toast.dismiss(id)}
                    data-testid="toastCloseButton"
                >
                    <ClearIcon />
                </IconButton>
            </Grid>
        </Grid>
    );

export default DissmissibleToast;
