import { FC } from 'react';

import QuillEditor from 'src/components/QuillEditor';

export const FormikQuillEditor: FC<any> = (props: any) => {
    const { field, height, readOnly } = props;
    const { name, value, onChange } = field;

    const onChangeCallback = onChange(name);

    const onChangeWrapped = (newValue: string) => {
        onChangeCallback(newValue);
    };

    const { 'data-testid': dataTest } = props;

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
        ],
    };

    return (
        <div {...(dataTest ? { 'data-testid': dataTest } : {})}>
            <QuillEditor
                value={value}
                onChange={onChangeWrapped}
                sx={{ height: height || 400 }}
                height={height || 400}
                modules={modules}
                readOnly={readOnly}
            />
        </div>
    );
};
