import { Grid, TableCell, TableRow, Typography, tableRowClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTheme } from 'src/theme';

import { ProjectRequestedVendor } from '../types';

interface ServiceTableRowProps {
    row: ProjectRequestedVendor;
}

const ROW_HEIGHT = 32;

const StyledTableRow = styled(TableRow)(() => ({
    [`&.${tableRowClasses.root}`]: {
        height: `${ROW_HEIGHT}px`,
        maxHeight: `${ROW_HEIGHT}px`,
        overflow: 'auto',
    },
}));

const StyledTableCell = styled(TableCell)(() => ({
    padding: '8px 16px',
}));

const ServiceTableRow = ({ row }: ServiceTableRowProps) => {
    const theme = useTheme();
    const { providerName, providerWebsite, email, phoneNumber, contactPersonName } = row;

    return (
        <StyledTableRow>
            <StyledTableCell>
                <Grid container flexDirection="column" justifyContent="center" height="100%">
                    <Typography>{providerName}</Typography>
                </Grid>
            </StyledTableCell>
            <StyledTableCell>
                <Grid container flexDirection="column" justifyContent="center" height="100%">
                    <Typography>
                        <a
                            style={{ color: theme.palette.primary.main }}
                            href={providerWebsite}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {providerWebsite}
                        </a>
                    </Typography>
                </Grid>
            </StyledTableCell>
            <StyledTableCell>
                <Grid container flexDirection="column" justifyContent="center" height="100%">
                    <Typography>{contactPersonName}</Typography>
                </Grid>
            </StyledTableCell>
            <StyledTableCell>
                <Grid container flexDirection="column" justifyContent="center" height="100%">
                    <Typography>{email}</Typography>
                </Grid>
            </StyledTableCell>
            <StyledTableCell>
                <Grid container flexDirection="column" justifyContent="center" height="100%">
                    <Typography>{phoneNumber}</Typography>
                </Grid>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default ServiceTableRow;
