/**
 * @generated SignedSource<<92662ea70b92b818cac6c29579722169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InternalDocumentComponentQuery$variables = {
  internalDocumentSetId: string;
};
export type InternalDocumentComponentQuery$data = {
  readonly internalDocuments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created: any;
        readonly fileName: string | null;
        readonly fileUrl: string | null;
        readonly id: string;
        readonly user: {
          readonly company: {
            readonly companyName: string;
            readonly id: string;
            readonly logo: string | null;
          } | null;
          readonly email: string;
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
        };
      } | null;
    } | null>;
  } | null;
};
export type InternalDocumentComponentQuery = {
  response: InternalDocumentComponentQuery$data;
  variables: InternalDocumentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "internalDocumentSetId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "internalDocumentSetId",
        "variableName": "internalDocumentSetId"
      }
    ],
    "concreteType": "InternalDocumentNodeConnection",
    "kind": "LinkedField",
    "name": "internalDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InternalDocumentNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InternalDocumentNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyNode",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalDocumentComponentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternalDocumentComponentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3b7ef8912d6f659a39a8d9c781faff8b",
    "id": null,
    "metadata": {},
    "name": "InternalDocumentComponentQuery",
    "operationKind": "query",
    "text": "query InternalDocumentComponentQuery(\n  $internalDocumentSetId: String!\n) {\n  internalDocuments(internalDocumentSetId: $internalDocumentSetId) {\n    edges {\n      node {\n        id\n        fileName\n        fileUrl\n        created\n        user {\n          id\n          firstName\n          lastName\n          email\n          company {\n            id\n            companyName\n            logo\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbf9b143c16771401f8a4d1ec0e14580";

export default node;
