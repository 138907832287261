/**
 * @generated SignedSource<<7ae33ad492e081cd1d5cb1c0e2d9b1ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationContextProviderUnreadNotificationsCountQuery$variables = {};
export type NotificationContextProviderUnreadNotificationsCountQuery$data = {
  readonly unreadNotificationsCount: number | null;
};
export type NotificationContextProviderUnreadNotificationsCountQuery = {
  response: NotificationContextProviderUnreadNotificationsCountQuery$data;
  variables: NotificationContextProviderUnreadNotificationsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unreadNotificationsCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationContextProviderUnreadNotificationsCountQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationContextProviderUnreadNotificationsCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d4ebff22a09c5df470c134cf4c338168",
    "id": null,
    "metadata": {},
    "name": "NotificationContextProviderUnreadNotificationsCountQuery",
    "operationKind": "query",
    "text": "query NotificationContextProviderUnreadNotificationsCountQuery {\n  unreadNotificationsCount\n}\n"
  }
};
})();

(node as any).hash = "eaf8eb6fc4592f1007a4f139e30dc6f3";

export default node;
