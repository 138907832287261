import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import MuiTextField from '@mui/material/TextField';

import { Autocomplete } from 'src/components/formik-material-ui/Autocomplete';
import { useAppContext } from 'src/contexts/AppContext';
import getOptionKey from 'src/utils/language/getOptionKey';
import { sortByOptionLabelPair } from 'src/utils/sorting';

interface ServiceStandard {
    id: string;
    serviceEn: string;
    serviceDe: string;
}

export const ServiceStandardSelectAllServiceStandardsQuery = graphql`
    query ServiceStandardSelectAllServiceStandardsQuery {
        allPublishedServiceStandards {
            edges {
                node {
                    id
                    isLeaf
                    parentServiceEn
                    parentServiceDe
                    serviceEn
                    serviceDe
                }
            }
        }
    }
`;

export const ServiceStandardSelect: FC<any> = (props: any) => {
    const { globalQueryRef } = useAppContext();
    const data = usePreloadedQuery(ServiceStandardSelectAllServiceStandardsQuery, globalQueryRef);

    const allPublishedServiceStandards = data.allPublishedServiceStandards || {
        edges: [],
    };
    const options = allPublishedServiceStandards.edges
        .map((edge) => edge.node)
        .sort(sortByOptionLabelPair('parentService', 'service'))
        .filter((x) => x.isLeaf === 'true');

    const { required } = props;

    return (
        <Autocomplete
            {...props}
            isOptionEqualToValue={(option: ServiceStandard, value?: ServiceStandard) =>
                option?.id === value?.id
            }
            getOptionLabel={(option: ServiceStandard): string => getOptionKey(option, 'service')}
            groupBy={(option: ServiceStandard): string => getOptionKey(option, 'parentService')}
            options={options}
            renderInput={(params: any): JSX.Element => (
                <MuiTextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    error={!!props.form.errors[props.field.name]}
                    helperText={props.form.errors[props.field.name]}
                    label={props.label || 'Service Standard'}
                    required={!!required}
                />
            )}
        />
    );
};
