import { Trans } from '@lingui/macro';
import Typography from '@mui/material/Typography';

import BucketSection, { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import { InternalSection } from 'src/components/sections/section';
import { Information } from 'src/components/typography';
import EditAction from 'src/project/components/editAction';
import { useProjectDetails } from 'src/project/state/hook';
import { useSalutations } from 'src/redux/salutations/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

const ContactInformation = () => {
    const projectDetails = useProjectDetails();
    const salutations = useSalutations();
    const { noPersonalContactInformation, quoteContacts } = projectDetails;

    return (
        <BucketSection
            title={<Trans>Contact Information</Trans>}
            actions={<EditAction editRoute="rfp-details#contact-information" />}
        >
            <Bucket>
                <InternalSection>
                    <BucketContainer isVertical={false}>
                        {noPersonalContactInformation && (
                            <Bucket xs={12}>
                                <Typography sx={{ mb: 1 }} variant="body1">
                                    <Trans>Not relevant</Trans>
                                </Typography>
                            </Bucket>
                        )}
                        {!noPersonalContactInformation &&
                            quoteContacts.length &&
                            quoteContacts.map((item, index) => (
                                <Bucket key={item.id} xs={12} md={6}>
                                    <Information
                                        title={<Trans>Contact #{index + 1}</Trans>}
                                        value=""
                                        defaultValue=""
                                    />
                                    <Information
                                        title={<Trans>Salutation</Trans>}
                                        value={getOptionKey(
                                            salutations?.find((s) => s.id === item.salutation),
                                            'salutation',
                                        )}
                                    />
                                    <Information
                                        title={<Trans>First Name</Trans>}
                                        value={item.firstName}
                                    />
                                    <Information
                                        title={<Trans>Last Name</Trans>}
                                        value={item.lastName}
                                    />
                                    <Information
                                        title={<Trans>Job Title</Trans>}
                                        value={item.jobTitle}
                                    />
                                </Bucket>
                            ))}
                    </BucketContainer>
                </InternalSection>
            </Bucket>
        </BucketSection>
    );
};

export default ContactInformation;

/*

*/
