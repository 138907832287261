import { createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface CompanyUserContextType {
    companyUserQueryRef: PreloadedQuery | null;
    refreshCompanyUserQuery: () => void;
}

const initialValues: CompanyUserContextType = {
    companyUserQueryRef: null,
    refreshCompanyUserQuery: () => {},
};

export const CompanyUserContext = createContext(initialValues);
