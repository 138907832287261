import { useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

import { FileDropzone } from 'src/components/fileUpload/FileDropzone';
import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import {
    useFullProject,
    usePatchProjectDetailsAsync,
    useProjectDetails,
} from 'src/project/state/hook';
import { useDocumentTypes } from 'src/redux/documentTypes/hook';

const mapProjectDocumentsToFileDropzoneData = (documents: [any]) =>
    documents.map((item) => ({
        data: {
            data: {
                attributes: item,
                type: 'quote-documents',
            },
        },
    }));

const ProjectAccompanyingDocuments = () => {
    const fullProject = useFullProject();
    const projectDetails = useProjectDetails();
    const { setFieldValue } = useFormikContext();

    const patchProjectDetails = usePatchProjectDetailsAsync();

    const [fileIds, setFileIds] = React.useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = React.useState(null);

    const documentTypes = useDocumentTypes();
    const uploadApiUrl = `api/project/${fullProject?.id}/upload-documents/`;

    React.useEffect(() => {
        setSelectedDocumentType(documentTypes[0]?.id);
    }, [documentTypes.length]);

    React.useEffect(() => {
        if (projectDetails?.documents) {
            setFileIds(mapProjectDocumentsToFileDropzoneData(projectDetails?.documents || []));
        }
    }, [projectDetails?.documents?.length]);

    const onUpdate = React.useCallback(
        (documentIds) => {
            const documentsPayload = documentIds.map((item) => item.data.data.attributes);
            const payload = {
                documents: documentsPayload,
            };
            patchProjectDetails(payload);
        },
        [projectDetails?.documents, projectDetails?.documents?.length],
    );

    const onUpload = React.useCallback(
        (newValue: any) => {
            const existingDocuments = projectDetails?.documents;
            const newDocument = {
                ...(newValue?.data?.data?.attributes || {}),
                type: selectedDocumentType,
                id: newValue?.data?.data?.id,
            };
            const updatedDocuments = [...existingDocuments, newDocument];
            const payload = {
                documents: updatedDocuments,
            };
            setFieldValue('documents', updatedDocuments);
            patchProjectDetails(payload);
        },
        [projectDetails?.documents, projectDetails?.documents?.length, selectedDocumentType],
    );
    return (
        <BucketSection
            isVertical
            title={<Trans>Accompanying documents</Trans>}
            id="accompanying-documents"
        >
            <Bucket>
                <Grid sx={{ mb: 2 }}>
                    <Trans>
                        <Typography color="textSecondary">
                            Please provide any documents you would like to submit with this project
                            request
                        </Typography>
                    </Trans>
                </Grid>
                <Grid>
                    <Typography color="textSecondary" variant="subtitle2">
                        <FileDropzone
                            uploadApiUrl={uploadApiUrl}
                            fileIds={fileIds}
                            setFileIds={setFileIds}
                            viewIsReadOnly={false}
                            parent={selectedDocumentType}
                            onDeleteCallback={onUpdate}
                            onUploadCallback={onUpload}
                            onFileTypeChangeCallback={onUpdate}
                        />
                    </Typography>
                </Grid>
            </Bucket>
        </BucketSection>
    );
};

export default ProjectAccompanyingDocuments;
