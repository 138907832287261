import React, { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';

import { Information } from 'src/components/typography';
import useCancelTourOnUnmount from 'src/tours/cancelTourOnUnmount';
import { useUserTourPolicy } from 'src/tours/tourPolicy';

import TourContentWithDecline from './tourContentWithDecline';

export const useProjectInformationTour = () => {
    useCancelTourOnUnmount();
    const { shouldDisplay, onTourShown, onTourDeclined, onTourFinished } =
        useUserTourPolicy('ProjectInformationTour');
    const { setIsOpen, setSteps, setCurrentStep } = useTour();
    const computedSteps = useMemo(() => {
        const handleClose = () => {
            setIsOpen(false);
            setCurrentStep(0);
        };
        const tourSteps = [
            {
                selector: '[data-testid="projectStepper"]',
                content: (
                    <Information
                        title={<Trans>Project progress</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        In the newly created RFP creation process, you will find
                                        your progress in the left-hand sidebar. You can also use
                                        this sidebar to move to different steps of the RFP creation
                                        process.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="projectActions"]',
                content: (
                    <Information
                        title={<Trans>Project actions</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        At each step of the RFP creation process you can save &
                                        validate your progress to check whether you filled out all
                                        the necessary field correctly. You can also just use “save
                                        as draft” if you want to save without validating the
                                        contents. By clicking on “next” you will move to the next
                                        step of the RFP creation process.
                                    </Trans>
                                </Typography>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        <Trans>Done</Trans>
                                    </Button>
                                </Grid>
                            </TourContentWithDecline>
                        }
                    />
                ),
                actionAfter: onTourFinished,
                stepInteraction: false,
            },
        ];

        return tourSteps;
    }, []);

    useEffect(() => {
        setSteps(computedSteps);
        if (shouldDisplay) {
            onTourShown();

            setTimeout(() => setIsOpen(true), 1000);
        }
    }, [
        shouldDisplay,
        onTourShown,
        onTourDeclined,
        onTourFinished,
        setSteps,
        setIsOpen,
        computedSteps,
    ]);
};
