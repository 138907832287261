import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FC, KeyboardEvent } from 'react';
import { SyntheticEvent } from 'react-draft-wysiwyg';

import { withSearch } from '@elastic/react-search-ui';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import ExploreWalkthroughModal from '../../videoModals/exploreWalkthroughModal';
import SearchSuggestions from './SearchSuggestions';

type ElasticAutocompleteProps = {
    onChange: (value: string, flag?: boolean) => void;
    onSuggestionClick: (label: string) => void;
    searchTerm?: string;
    label?: string;
    autocompleteResults: string[];
    showSearchSuggestions?: boolean;
};

/**
 * This component is used to display the autocomplete search box.
 * It is used in the SearchBoxComponent.tsx file.
 * The searchTerm provided is a comma separated string of the query terms.
 * This component splits the string into chips and displays them as tags.
 * The last chip is the one that is being typed in.
 * The autocomplete works based on the last query term, the SearchConfig in
 * ExplorePageLayout.tsx has an onAutocomplete middleware to extrct only the last
 * query term which fields to search on.
 *
 * @param onChange
 * @param onSuggestionClick
 * @param searchTerm
 * @param autocompleteResults
 * @param label
 * @param showSearchSuggestions
 * @constructor
 */
const ElasticAutocomplete: FC<ElasticAutocompleteProps> = ({
    onChange,
    onSuggestionClick,
    searchTerm,
    label,
    autocompleteResults,
    showSearchSuggestions,
}: ElasticAutocompleteProps) => {
    const searchChips = searchTerm.split(',');
    const inputValue = searchChips.pop();

    const deleteChip = (itemIndex: number) => {
        const parts = [...searchChips];
        parts.splice(itemIndex, 1);
        onChange(parts.length ? `${parts.join(',')},` : '', true);
    };

    const handleKeyPressed = (event: SyntheticEvent) => {
        const keyboardEvent = event as KeyboardEvent;
        if (keyboardEvent?.key === 'Backspace' || keyboardEvent?.code === 'Backspace') {
            if (inputValue === '') {
                deleteChip(-1);
            }
        }
    };
    const clearAllInput = () => onChange('', true);
    const handleInputChange = (_: SyntheticEvent, newValue: string) =>
        onChange([...searchChips, newValue].join(','));
    const handleChipCreation = (event: SyntheticEvent, newValues: string[]) => {
        const keyboardEvent = event as KeyboardEvent;
        if (
            (keyboardEvent?.key === 'Enter' ||
                keyboardEvent?.code === 'Enter' ||
                event.type === 'click') &&
            newValues
        ) {
            onChange(`${newValues.join(',')},`, true);
        }
    };

    const handleSuggestionClick = (newValue: string) => {
        const newValues = [...searchChips, newValue, inputValue];
        onSuggestionClick(newValues.join(','));
    };

    return (
        <Box
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Autocomplete
                fullWidth
                freeSolo
                multiple
                value={searchChips}
                onChange={handleChipCreation}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onKeyDown={(event) => handleKeyPressed(event)}
                options={autocompleteResults}
                getOptionLabel={(option) => option || ''}
                renderTags={(chipValue: readonly string[]) =>
                    chipValue.map((option: string, index: number) => (
                        <Chip
                            key={option}
                            tabIndex={-1}
                            label={option}
                            sx={{
                                mr: 0.5,
                            }}
                            onDelete={() => deleteChip(index)}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <IconButton size="small" onClick={clearAllInput}>
                                        <ClearIcon />
                                    </IconButton>
                                    <ExploreWalkthroughModal />
                                </>
                            ),
                        }}
                        label={label}
                        variant="outlined"
                        style={{
                            backgroundColor: 'white',
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    if (!option) {
                        return null;
                    }
                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part) => (
                                    <span
                                        key={part.text}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
            {showSearchSuggestions && (
                <SearchSuggestions
                    onSuggestionClick={handleSuggestionClick}
                    filterOut={searchChips.map((p) => p.toLowerCase())}
                />
            )}
        </Box>
    );
};

export default withSearch(({ searchTerm }) => ({ searchTerm }))(
    ElasticAutocomplete,
) as React.FC<ElasticAutocompleteProps>;
