import React, { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

import { SaveBeforeLeaveDialog } from 'src/components/ratecards/dialogs/SaveBeforeLeaveDialog';

interface Props {
    condition: boolean;
    onCancel?: () => void;
    onDiscard?: () => void;
    onSave?: () => Promise<void>;
}

const useConfirmUnsavedChangesModal = ({ condition, onCancel, onDiscard, onSave }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);

    const blocker = useBlocker(condition);
    useEffect(() => {
        if (blocker.state === 'blocked') {
            setModalOpen(true);
        }
    }, [blocker.state]);

    const modalContent = modalOpen && (
        <SaveBeforeLeaveDialog
            cancel={() => {
                setModalOpen(false);
                onCancel?.();
                blocker.reset?.();
            }}
            discardAndProceed={() => {
                setModalOpen(false);
                onDiscard?.();
                blocker.proceed();
            }}
            saveAndProceed={async () => {
                setModalOpen(false);
                await onSave?.();
                blocker.proceed();
            }}
        />
    );

    return modalContent;
};

export default useConfirmUnsavedChangesModal;
