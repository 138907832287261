import { AgGridReact } from 'ag-grid-react';
import { AgGridReactProps, AgReactUiProps } from 'ag-grid-react/lib/shared/interfaces';
import { FC } from 'react';

import { useLingui } from '@lingui/react';

import { agGridLocaleProps } from 'src/utils/locale';

type Props = AgGridReactProps | AgReactUiProps;

// Wrapper component for AgGridReact with localization
export const AgGrid: FC<Props> = (props: Props) => {
    const { i18n } = useLingui();
    return (
        <AgGridReact
            // reactUi={!!true}
            suppressFieldDotNotation={!!true}
            {...agGridLocaleProps(i18n)}
            {...props}
        />
    );
};
