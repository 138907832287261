import { all } from 'redux-saga/effects';

import { watchLoginAction } from './loginSaga';
import { watchNavigateAction } from './navigateSaga';
import { watchResendOTPAction } from './resendOTPSaga';
import { watchSubmitOTPAction } from './submitOTPSaga';
import { watchUpdateVendorsAction } from './updateVendorsContactsSaga';

// confisder using fork for non blocking sagas
// https://redux-saga.js.org/docs/advanced/RootSaga/
export default function* rootSaga() {
    yield all([
        watchNavigateAction(),
        watchSubmitOTPAction(),
        watchResendOTPAction(),
        watchLoginAction(),
        watchUpdateVendorsAction(),
    ]);
}
