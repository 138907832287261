import { I18n } from '@lingui/core';

export const getNotificationLongDescription = (type, i18n: I18n) => {
    switch (type) {
        case 'INVITETORFQ':
            return i18n._(`Proposal due: `);
        case 'REMINDERFORINVITETORFQ':
            return i18n._(`Proposal due: `);
        case 'REMINDERFORCONFIRMATIONOFRFQ':
            return i18n._(`Proposal due: `);
        default:
            return '';
    }
};

export const getNotificationDescription = (type, i18n: I18n) => {
    switch (type) {
        case 'SUPPLIERDISMISSEDFROMRFP':
            return i18n._(`This is the client's comment for this activity:`);
        case 'PROPOSALLOSTMESSAGETOSUPPLIER':
            return i18n._(`The client has left the following feedback:`);
        case 'CONDITIONSACCEPTED':
            return i18n._(`The following conditions apply:`);
        case 'RATESREJECTED':
            return i18n._(`This is the client's comment for this activity:`);
        case 'PROPOSALREVISIONREQUESTED':
            return i18n._(`This is the client's comment for this activity:`);
        default:
            return '';
    }
};
