import { FC, ReactNode } from 'react';

import { ButtonBase, SxProps, Theme } from '@mui/material';
import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';

export interface ListItemProps extends Omit<GridProps, 'item' & 'container'> {
    onClick?: (element: ReactNode) => void;
    buttonSx?: SxProps<Theme>;
}

const defaultButtonSx = Object.freeze({
    width: '100%',
    height: '100%',
    alignItems: 'baseline',
});

const ListItem: FC<ListItemProps> = ({
    onClick,
    children,
    buttonSx,
    ...gridProps
}: ListItemProps) => (
    <Grid item {...gridProps}>
        <ButtonBase sx={{ ...defaultButtonSx, ...buttonSx }} onClick={onClick}>
            {children}
        </ButtonBase>
    </Grid>
);

export default ListItem;
