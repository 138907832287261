import { fetchQuery } from 'react-relay';

import { CurrencySelectAllCurrencies2Query } from 'src/components/common/select/CurrencySelect';
import Fetcher from 'src/redux/fetcher';
import { environment } from 'src/relay/environment';

import { sanitizeCurrency } from './sanitizeCurrency';

export const CurrenciesFetcher = new Fetcher(
    'currencies/fetchCurrencies',
    async () => {
        const data = await fetchQuery(
            environment,
            CurrencySelectAllCurrencies2Query,
            {},
        ).toPromise();
        return data;
    },
    sanitizeCurrency,
);
