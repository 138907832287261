import { FC } from 'react';

import { withSearch } from '@elastic/react-search-ui';
import { Box } from '@mui/material';

import { useFilteredArray } from 'src/hooks/useFilteredArray';

import SuggestionChip from './SuggestionChip';

const NUMBER_OF_SUGGESTIONS = 10;

type SearchSuggestionsProps = {
    facets?: any;
    wasSearched?: boolean;
    onSuggestionClick: (label: string) => void;
    filterOut: string[];
};

const SearchSuggestions: FC<SearchSuggestionsProps> = ({
    facets,
    wasSearched,
    onSuggestionClick: handleChipClick,
    filterOut,
}: SearchSuggestionsProps) => {
    /**
     * suggestions as array of strings
     * computed from specialities facet (focus topics)
     * filter out every value in the filterOut array, case insensitive
     * filter out empty values
     * only returns the first 10 entries
     */
    const suggestionEntries = useFilteredArray(
        facets['ln_company_specialities_en.keyword']?.[0]?.data?.map((entry) => entry.value),
        filterOut,
        NUMBER_OF_SUGGESTIONS,
    );

    return (
        <Box
            style={{
                padding: '16px 0px 0px',
            }}
        >
            {wasSearched &&
                suggestionEntries &&
                suggestionEntries.map((entry) => (
                    <SuggestionChip key={entry} label={entry} onClick={handleChipClick} />
                ))}
        </Box>
    );
};

export default withSearch(({ facets, wasSearched }) => ({ facets, wasSearched }))(
    SearchSuggestions,
) as FC<SearchSuggestionsProps>;
