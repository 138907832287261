import { Field } from 'formik';
import React from 'react';

import { CheckboxProps, FormControlLabel } from '@mui/material';

import { Checkbox } from 'src/components/formik-material-ui/Checkbox';

interface CheckboxFieldProps extends CheckboxProps {
    label: React.ReactNode;
    name: string;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, name, checked, ...rest }) => (
    <FormControlLabel
        label={label}
        control={
            <Field
                checked={checked}
                component={(fieldProps: any) => <Checkbox {...fieldProps} type="checkbox" />}
                name={name}
                data-testid={name}
                {...rest}
            />
        }
    />
);

export default CheckboxField;
