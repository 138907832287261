/**
 * @generated SignedSource<<5ac65489796d2c36859a0f50c1bb01aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RatecardProposalProviderRatecardProposalClientQuery$variables = {
  id: string;
};
export type RatecardProposalProviderRatecardProposalClientQuery$data = {
  readonly ratecardProposal: {
    readonly company: {
      readonly companyName: string;
      readonly id: string;
    };
    readonly hasAcceptedBids: boolean | null;
    readonly hasOpenBids: boolean | null;
    readonly id: string;
    readonly projectId: string | null;
    readonly state: string;
    readonly " $fragmentSpreads": FragmentRefs<"InformationRatecardProposalFragment_ratecardProposal" | "RebatesRatecardProposalFragment_ratecardProposal" | "SupplierDetailsRatecardProposalFragment_ratecardProposal" | "UploadedDocumentsRatecardProposalFragment_ratecardProposal">;
  } | null;
};
export type RatecardProposalProviderRatecardProposalClientQuery = {
  response: RatecardProposalProviderRatecardProposalClientQuery$data;
  variables: RatecardProposalProviderRatecardProposalClientQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasOpenBids",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAcceptedBids",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RatecardProposalProviderRatecardProposalClientQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RatecardProposalNode",
        "kind": "LinkedField",
        "name": "ratecardProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNode",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RebatesRatecardProposalFragment_ratecardProposal"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InformationRatecardProposalFragment_ratecardProposal"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UploadedDocumentsRatecardProposalFragment_ratecardProposal"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SupplierDetailsRatecardProposalFragment_ratecardProposal"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RatecardProposalProviderRatecardProposalClientQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RatecardProposalNode",
        "kind": "LinkedField",
        "name": "ratecardProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNode",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressNodeConnection",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddressNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine3",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CountryNode",
                            "kind": "LinkedField",
                            "name": "country",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "iso2letter",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commonNameEn",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commonNameDe",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RatecardProposalRebateNodeConnection",
            "kind": "LinkedField",
            "name": "rebates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RatecardProposalRebateNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RatecardProposalRebateNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rebate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "condition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comment",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalInformation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RatecardProposalFileNodeConnection",
            "kind": "LinkedField",
            "name": "proposalAttachments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RatecardProposalFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RatecardProposalFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "file",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "consultantUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mobilePhone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AcademicTitleNode",
                "kind": "LinkedField",
                "name": "academicTitle",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "titleEn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "titleDe",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalutationNode",
                "kind": "LinkedField",
                "name": "salutation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salutationDe",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salutationEn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7477e14d81fc673336301d07d6926860",
    "id": null,
    "metadata": {},
    "name": "RatecardProposalProviderRatecardProposalClientQuery",
    "operationKind": "query",
    "text": "query RatecardProposalProviderRatecardProposalClientQuery(\n  $id: ID!\n) {\n  ratecardProposal(id: $id) {\n    id\n    state\n    projectId\n    company {\n      id\n      companyName\n    }\n    hasOpenBids\n    hasAcceptedBids\n    ...RebatesRatecardProposalFragment_ratecardProposal\n    ...InformationRatecardProposalFragment_ratecardProposal\n    ...UploadedDocumentsRatecardProposalFragment_ratecardProposal\n    ...SupplierDetailsRatecardProposalFragment_ratecardProposal\n  }\n}\n\nfragment InformationRatecardProposalFragment_ratecardProposal on RatecardProposalNode {\n  id\n  additionalInformation\n}\n\nfragment RebatesRatecardProposalFragment_ratecardProposal on RatecardProposalNode {\n  id\n  rebates {\n    edges {\n      node {\n        id\n        rebate\n        condition\n        comment\n      }\n    }\n  }\n}\n\nfragment SupplierDetailsRatecardProposalFragment_ratecardProposal on RatecardProposalNode {\n  id\n  state\n  modified\n  company {\n    id\n    companyName\n    addresses {\n      edges {\n        node {\n          addressLine1\n          addressLine2\n          addressLine3\n          postalCode\n          city\n          country {\n            id\n            iso2letter\n            commonNameEn\n            commonNameDe\n          }\n          id\n        }\n      }\n    }\n  }\n  consultantUser {\n    id\n    firstName\n    lastName\n    jobTitle\n    email\n    mobilePhone\n    academicTitle {\n      id\n      titleEn\n      titleDe\n    }\n    salutation {\n      id\n      salutationDe\n      salutationEn\n    }\n  }\n}\n\nfragment UploadedDocumentsRatecardProposalFragment_ratecardProposal on RatecardProposalNode {\n  id\n  proposalAttachments {\n    edges {\n      node {\n        id\n        createdAt\n        file\n        fileUrl\n        fileName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ee342dfa4a5d812655b1656b48a669d";

export default node;
