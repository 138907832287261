import { fetchQuery } from 'react-relay';

import { ServiceStandardSelectAllServiceStandardsQuery } from 'src/components/common/select/ServiceStandardSelect';
import relayEnvironment from 'src/relayEnvironment';
import { mapEdgesToList } from 'src/selectors/useGenericQuery';

import Fetcher from '../fetcher';

const fetchServiceStandards = () =>
    new Promise((resolve, reject) => {
        const serviceStandardsObservable = fetchQuery(
            relayEnvironment,
            ServiceStandardSelectAllServiceStandardsQuery,
            {},
        );

        const subscription = serviceStandardsObservable.subscribe({
            next: (responseData) => {
                resolve(mapEdgesToList(responseData.allPublishedServiceStandards));
                subscription?.unsubscribe();
            },
            complete: () => {},
            error: (error) => {
                reject(error);
            },
        });
    });

export const ServiceStandardsFetcher = new Fetcher('serviceStandards/list', async () =>
    fetchServiceStandards(),
);
