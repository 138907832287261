import { ReactNodeLike } from 'prop-types';
import { FC, Suspense } from 'react';

import { RatecardProjectProvider } from 'src/components/ratecards/RatecardProjectProvider';

interface Props {
    children: ReactNodeLike;
}

const RatecardProjectRoute: FC<Props> = ({ children }: Props) => (
    <Suspense fallback="">
        <RatecardProjectProvider>{children}</RatecardProjectProvider>
    </Suspense>
);

export default RatecardProjectRoute;
