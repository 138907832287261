import React from 'react';

import useAccessToken from 'src/hooks/useAccessToken';

const ApalyticsFrame = () => {
    const token = useAccessToken();

    return (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
            <iframe
                title="Apalytics"
                src={`${window.CANONICAL_DOMAIN_APALYTICS}?token=${token}`}
                width="100%"
                height="100%"
                frameBorder="0"
            />
        </div>
    );
};

export default React.memo(ApalyticsFrame);
