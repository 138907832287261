import { FC, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import { useLingui } from '@lingui/react';

import { AnnouncementComponent } from 'src/components/announcements/AnnouncementComponent';
import { useQuoteQuery } from 'src/components/rfp/QuoteProvider';
import { useProjectProposals } from 'src/project/hooks';
import { useCreateProjectAnnouncement, useFullProject } from 'src/project/state/hook';
import AnnouncementsSkeletonWithAdd from 'src/skeletons/ratecards/AnnouncementSkeletonWithAdd';
import useErrorMessage from 'src/translations/hooks/useErrorMessage';
import DismissibleToast from 'src/utils/DismissibleToast';

const QuoteAnnouncements: FC = () => {
    const { i18n } = useLingui();

    const { quoteQuery } = useQuoteQuery();
    const announcementSetId = quoteQuery?.sourceQuote?.announcementSetId;
    const extractErrorMessage = useErrorMessage();

    const project = useFullProject();
    const createAnnouncement = useCreateProjectAnnouncement();
    const projectProposals = useProjectProposals();

    const createNewAnnouncement = (
        values,
        proposals,
        fileIds,
        { setFileIds, setSubmitting, setStatus, setErrors, resetForm },
    ) => {
        const files = fileIds.map((element) => ({
            id: element.data.data.id,
        }));
        createAnnouncement({
            content: values.content,
            proposals,
            announcementFiles: files,
        })
            .then(() => {
                toast.success(DismissibleToast(i18n._('Announcement saved')));
                resetForm({
                    values: { content: '<p><br></p>' },
                });
                setFileIds([]);
            })
            .catch((errors) => {
                toast.error(
                    DismissibleToast(extractErrorMessage(errors, 'cannotAddAnnouncementError')),
                );
                setStatus({ errors });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>{i18n._('Announcements')}</title>
            </Helmet>
            <Suspense fallback={<AnnouncementsSkeletonWithAdd />}>
                <AnnouncementComponent
                    proposals={projectProposals}
                    addNewAnnouncement
                    viewIsReadOnly={false}
                    announcements={project.announcements}
                    createNewAnnouncement={createNewAnnouncement}
                    announcementSetId={announcementSetId}
                />
            </Suspense>
        </>
    );
};

export default QuoteAnnouncements;
