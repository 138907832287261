import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { ApaduaAnnouncementsFetcher } from './thunk';

const initialState = fetcherInitialState;

export const apaduaAnnouncementsSlice = createSlice({
    name: 'apaduaAnnouncements',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ApaduaAnnouncementsFetcher.reducers(builder);
    },
});

export default apaduaAnnouncementsSlice.reducer;
