import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {
    Box,
    Button,
    Divider,
    Skeleton,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';

import { DelayedRender } from 'src/components/DelayedRender';
import NavSection from 'src/components/NavSection';
import { StepIcon } from 'src/components/StepIcon';

const RatecardProjectEditModeSidebarSkeleton: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();

    const steps = [
        {
            step: 1,
            label: i18n._('Tender Planning'),
            status: 'warning',
            path: '',
        },
        {
            step: 2,
            label: i18n._('Supplier Selection'),
            status: 'warning',
            path: '',
        },
        {
            step: 3,
            label: i18n._('Rate Card'),
            status: 'warning',
            path: '',
        },
        {
            step: 4,
            label: i18n._('Other Information'),
            status: 'warning',
            path: '',
        },
    ];

    const section = {
        title: '',
        items: [
            {
                title: i18n._('Edit project'),
                path: '/',
                icon: <NoteAltIcon />,
            },
        ],
    };

    return (
        <DelayedRender>
            <Box sx={{ my: 2, px: 2, pb: 2 }}>
                <Typography color="textPrimary" variant="h6" align="center" noWrap>
                    <Skeleton />
                </Typography>
                <Typography color="textPrimary" variant="subtitle1" align="center" noWrap>
                    {i18n._('Edit mode')}
                </Typography>
                <Divider sx={{ mb: 2, mt: 1 }} />
                <NavSection
                    key="info"
                    pathname={location.pathname}
                    sx={{
                        '& a': {
                            pl: 1,
                        },
                    }}
                    {...section}
                />
                <Stepper nonLinear activeStep={-1} orientation="vertical">
                    {steps.map((step) => (
                        <Step key={step.step}>
                            <StepLabel
                                style={{ cursor: 'pointer' }}
                                icon={<StepIcon type="warning" step={step.step} />}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Divider sx={{ mt: 2 }} />
                <Box sx={{ my: 2 }}>
                    <Button variant="contained" color="primary" startIcon={<ExitToAppIcon />}>
                        {i18n._('Leave edit mode')}
                    </Button>
                </Box>
            </Box>
        </DelayedRender>
    );
};

export default RatecardProjectEditModeSidebarSkeleton;
