import { ProjectPhasesAPI } from 'src/apis/projectPhasesAPI';

import Fetcher from '../fetcher';
import { sanitizeProjectPhases } from './sanitizers';

const api = new ProjectPhasesAPI();

export const ProjectPhasesFetcher = new Fetcher(
    'projectPhases/list',
    async () => api.getProjectPhases(),
    sanitizeProjectPhases,
);
