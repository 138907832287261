/**
 * @generated SignedSource<<df17d6fbc650a3a1d5e3e66140fbb444>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationContextProviderNotificationChangesQuery$variables = {};
export type NotificationContextProviderNotificationChangesQuery$data = {
  readonly lastNotificationCreatedAt: any | null;
};
export type NotificationContextProviderNotificationChangesQuery = {
  response: NotificationContextProviderNotificationChangesQuery$data;
  variables: NotificationContextProviderNotificationChangesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastNotificationCreatedAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationContextProviderNotificationChangesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationContextProviderNotificationChangesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ad05e0f1d0bce8f8a643d7eec4c2d5c7",
    "id": null,
    "metadata": {},
    "name": "NotificationContextProviderNotificationChangesQuery",
    "operationKind": "query",
    "text": "query NotificationContextProviderNotificationChangesQuery {\n  lastNotificationCreatedAt\n}\n"
  }
};
})();

(node as any).hash = "7da0697e4f24890c3c7b2a379f93c511";

export default node;
