import type { FC } from 'react';

import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Skeleton,
    useTheme,
} from '@mui/material';

import { DelayedRender } from 'src/components/DelayedRender';
import useWindowSize from 'src/utils/useWindowSize';

const AnnouncementSkeletonWithAdd: FC = () => {
    const { i18n } = useLingui();

    const rows = [];

    const windowSize = useWindowSize();
    const theme = useTheme();

    for (let index = 0; index < 4; index++) {
        rows.push(
            <Box key={index}>
                <Grid container style={{ margin: '16px' }}>
                    <Grid item md={3} xs={12}>
                        <Skeleton sx={{ my: 2 }} variant="rectangular" height="160px" />
                    </Grid>
                    <Grid
                        item
                        md={9}
                        xs={12}
                        style={{
                            paddingLeft:
                                windowSize.width >= theme.breakpoints.values.lg ? '16px' : '0px',
                            paddingTop:
                                windowSize.width >= theme.breakpoints.values.lg ? '0px' : '16px',
                        }}
                    >
                        <Box>
                            <Skeleton sx={{ my: 2 }} variant="rectangular" height="160px" />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
            </Box>,
        );
    }

    return (
        <>
            <DelayedRender>
                <Grid container justifyContent="left" sx={{ mb: 3 }}>
                    <Button color="primary" variant="contained">
                        {i18n._('Add new announcement')}
                    </Button>
                </Grid>
                <Card variant="outlined" sx={{ mb: 8 }}>
                    <CardHeader title={i18n._('Announcements')} />
                    <Divider />
                    <CardContent>{rows}</CardContent>
                </Card>
            </DelayedRender>
        </>
    );
};

export default AnnouncementSkeletonWithAdd;
