/**
 * @generated SignedSource<<b541d2086d546988f55b45ce97943be8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConsultingCompanyAutocompleteConsultingCompanySearchQuery$variables = {
  text: string;
};
export type ConsultingCompanyAutocompleteConsultingCompanySearchQuery$data = {
  readonly consultingCompaniesSearch: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly companyName: string;
        readonly generalInfo: {
          readonly website: string | null;
        } | null;
        readonly id: string;
      } | null;
    } | null>;
  } | null;
};
export type ConsultingCompanyAutocompleteConsultingCompanySearchQuery = {
  response: ConsultingCompanyAutocompleteConsultingCompanySearchQuery$data;
  variables: ConsultingCompanyAutocompleteConsultingCompanySearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsultingCompanyAutocompleteConsultingCompanySearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyNodeConnection",
        "kind": "LinkedField",
        "name": "consultingCompaniesSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GeneralInfoNode",
                    "kind": "LinkedField",
                    "name": "generalInfo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConsultingCompanyAutocompleteConsultingCompanySearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompanyNodeConnection",
        "kind": "LinkedField",
        "name": "consultingCompaniesSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GeneralInfoNode",
                    "kind": "LinkedField",
                    "name": "generalInfo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71f830a450905a6fad8138754e5fb1a2",
    "id": null,
    "metadata": {},
    "name": "ConsultingCompanyAutocompleteConsultingCompanySearchQuery",
    "operationKind": "query",
    "text": "query ConsultingCompanyAutocompleteConsultingCompanySearchQuery(\n  $text: String!\n) {\n  consultingCompaniesSearch(text: $text) {\n    edges {\n      node {\n        id\n        companyName\n        generalInfo {\n          website\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebd5cb348331ef617ac136979bb9d508";

export default node;
