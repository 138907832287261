import { AxiosError } from 'axios';

import { ApaduaAPI } from './ApaduaAPI';

interface ResendOTPPayload {
    current_otp_token: string;
}

export const serializeResendOTPPayload = (data): ResendOTPPayload => ({
    current_otp_token: data.otpToken,
});

export const sanitizeResendOTPResponse = (data) => ({
    otpToken: data.otp_required_token,
    details: data.details,
});

export class LoginAPI extends ApaduaAPI {
    validateOTP = (payload): Promise<Array<any>> =>
        this.post('jwt/login/otp-validate', payload) as any;

    resendOTP = (payload): Promise<Array<any>> => this.post('jwt/login/resend-otp', payload) as any;

    // eslint-disable-next-line
    handleError(error: AxiosError): any {
        throw error;
    }

    getSSOProvider = (companyAlias: string): Promise<any> =>
        this.get(`sso/company/${companyAlias}/`);
}
