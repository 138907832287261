import { useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

const LOCALSTORAGE_ANNOUCEMENT_KEY = 'apaduaAnnouncementsShown';

const setDeclined = (announcementId: number) => {
    const apaduaAnnouncementsShown = localStorage.getItem(LOCALSTORAGE_ANNOUCEMENT_KEY);
    let parsedApaduaAnnouncementsShown = {};
    try {
        parsedApaduaAnnouncementsShown = JSON.parse(apaduaAnnouncementsShown) ?? {};
    } catch {
        localStorage.removeItem(LOCALSTORAGE_ANNOUCEMENT_KEY);
    }
    parsedApaduaAnnouncementsShown[announcementId] = { declined: true };
    localStorage.setItem(
        LOCALSTORAGE_ANNOUCEMENT_KEY,
        JSON.stringify(parsedApaduaAnnouncementsShown),
    );
};

export const apaduaAnnouncementsSelector = (state: GlobalState) => state.apaduaAnnouncements;

export const apaduaAnnouncementsSelectorData = (state: GlobalState) => {
    const apaduaAnnouncements = apaduaAnnouncementsSelector(state).data;
    const apaduaAnnouncementsShown = localStorage.getItem(LOCALSTORAGE_ANNOUCEMENT_KEY);
    let parsedApaduaAnnouncementsShown = {};
    try {
        parsedApaduaAnnouncementsShown = JSON.parse(apaduaAnnouncementsShown) ?? {};
    } catch {
        localStorage.removeItem(LOCALSTORAGE_ANNOUCEMENT_KEY);
    }
    const declinedAnnouncements = Object.keys(parsedApaduaAnnouncementsShown).filter(
        (id) => parsedApaduaAnnouncementsShown[id]?.declined,
    );
    const candidateAnnouncements = apaduaAnnouncements.filter(
        (announcement) => !declinedAnnouncements.includes(announcement?.id?.toString()),
    );

    return {
        announcement: candidateAnnouncements[0] ?? {},
        handleDoNotShow: setDeclined,
    };
};

export const useApaduaAnnouncements = () => useAppSelector(apaduaAnnouncementsSelectorData);
