import { ApaduaAnnouncementsAPI } from 'src/apis/apaduaAnnouncementsAPI';

import Fetcher from '../fetcher';
import { sanitizeApaduaAnnouncements } from './sanitizers';

const api = new ApaduaAnnouncementsAPI();

export const ApaduaAnnouncementsFetcher = new Fetcher(
    'apaduaAnnouncements/list',
    async () => api.getApaduaAnnouncements(),
    sanitizeApaduaAnnouncements,
);
