import { Trans } from '@lingui/macro';
import { TableCell, TableHead, TableRow } from '@mui/material';

const ServiceTableHead = () => (
    <TableHead>
        <TableRow>
            <TableCell>
                <Trans>Name</Trans>
            </TableCell>
            <TableCell>
                <Trans>Website</Trans>
            </TableCell>
            <TableCell>
                <Trans>Name of the contact person</Trans>
            </TableCell>
            <TableCell>
                <Trans>Email of the contact person</Trans>
            </TableCell>
            <TableCell>
                <Trans>Phone number of the contact person</Trans>
            </TableCell>
        </TableRow>
    </TableHead>
);

export default ServiceTableHead;
