import { createContext } from 'react';

interface CompanyProfileContextType {
    company: any;
}

const initialValues: CompanyProfileContextType = {
    company: null,
};

export const CompanyProfileContext = createContext(initialValues);
