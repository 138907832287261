import { useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import OptionsSection from 'src/components/sections/optionsSection';
import { InternalSection } from 'src/components/sections/section';
import { useCurrencies } from 'src/currencies/hook';
import AutocompleteField from 'src/forms/autocompleteField';
import CheckboxField from 'src/forms/checkboxField';
import RadioGroupField from 'src/forms/radioGroupField';

const ProposalContentsForm = () => {
    const currencies = useCurrencies();
    const timeMaterialRadios = [
        { value: true, label: <Trans>Time & material</Trans> },
        { value: false, label: <Trans>Fixed fee</Trans> },
    ];

    return (
        <BucketSection
            isVertical={false}
            title={<Trans>Proposal Contents</Trans>}
            id="proposal-contents"
        >
            <Bucket xs={6}>
                <Typography variant="h6">
                    <Trans>Which remuneration model do you require?</Trans>
                </Typography>
                <RadioGroupField name="timeMaterial" radios={timeMaterialRadios} required />
                <InternalSection
                    title={<Trans>Which currency should be used for invoicing?</Trans>}
                >
                    <AutocompleteField
                        options={currencies}
                        name="currency"
                        required
                        label={<Trans>Currency</Trans>}
                        getOptionValue={(option: any) => option?.rawID}
                        getOptionLabel={(option): any => option?.isoCurrencyCode || ''}
                    />
                </InternalSection>
            </Bucket>
            <Bucket xs={6} isVertical>
                <TechnicalProposalSection />
                <TeamMemberSection />
            </Bucket>
        </BucketSection>
    );
};

const TechnicalProposalSection = () => {
    const { values } = useFormikContext() as any;
    return (
        <OptionsSection
            title={<Trans>Which contents do you require in a technical proposal?</Trans>}
        >
            <CheckboxField
                disabled
                checked={values.approachPresentation}
                name="approachPresentation"
                label={<Trans>Project approach as PDF</Trans>}
                required
            />
            <CheckboxField
                checked={values.projectReferences}
                name="projectReferences"
                label={<Trans>References of completed projects</Trans>}
                required
            />
            <CheckboxField
                label={<Trans>Draft of project plan (milestones, timing and deliverables)</Trans>}
                checked={values.outlineProjectPhases}
                name="outlineProjectPhases"
                required
            />
            <CheckboxField
                label={<Trans>Company profiles</Trans>}
                checked={values.companyPortrait}
                name="companyPortrait"
                required
            />
        </OptionsSection>
    );
};

const TeamMemberSection = () => {
    const { values } = useFormikContext() as any;
    return (
        <OptionsSection
            title={<Trans>Which information do you require about each team member?</Trans>}
        >
            <CheckboxField
                label={<Trans>Standard consultant profile</Trans>}
                disabled
                checked={values.standardProfile}
                name="standardProfile"
                required
            />
            <CheckboxField
                label={<Trans>List of individual project references</Trans>}
                checked={values.projectsWorkedOn}
                name="projectsWorkedOn"
                required
            />
        </OptionsSection>
    );
};

export default ProposalContentsForm;
