import React from 'react';

import { useLingui } from '@lingui/react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';

interface ExploreTableHeadProps {
    onSelectAll: () => void;
    isChecked: boolean;
}

export const descriptionDisplayStyle = {
    xs: 'none',
    md: 'none',
    lg: 'table-cell',
};

const ExploreTableHead = ({ onSelectAll, isChecked }: ExploreTableHeadProps) => {
    const { i18n } = useLingui();

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox onClick={onSelectAll} checked={isChecked} />
                </TableCell>
                <TableCell>
                    {/* This cell is intentionally left empty for aligning logos */}
                </TableCell>
                <TableCell>{i18n._('Name')}</TableCell>
                <TableCell>{i18n._('Size')}</TableCell>
                <TableCell>{i18n._('Focus Topics')}</TableCell>
                <TableCell sx={{ display: descriptionDisplayStyle }}>
                    {i18n._('Description')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default ExploreTableHead;
