import { FC, useState } from 'react';

import { useLingui } from '@lingui/react';
import BookIcon from '@mui/icons-material/Book';
import SupportIcon from '@mui/icons-material/Support';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
    Button,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/styles';

import { useChatWootContext } from 'src/contexts/ChatWootContext';
import useWindowSize from 'src/utils/useWindowSize';

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
}));

export const SupportMenu: FC = () => {
    const { i18n } = useLingui();
    const windowSize = useWindowSize();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const { chatReady, toggleChat } = useChatWootContext();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openChat = () => {
        toggleChat();
        handleClose();
    };

    if (!chatReady) {
        return null;
    }

    return (
        <>
            <Button
                id="support-menu-button"
                aria-controls="support-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<SupportIcon />}
                style={{
                    marginLeft: windowSize.width <= theme.breakpoints.values.sm ? '16px' : 0,
                    minWidth: windowSize.width <= theme.breakpoints.values.sm ? '20px' : 'auto',
                    padding: 1,
                }}
            >
                {windowSize.width > theme.breakpoints.values.sm && (
                    <Typography color="textPrimary" variant="subtitle1">
                        {i18n._('Help')}
                    </Typography>
                )}
            </Button>
            <Menu
                id="support-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'support-menu-button',
                }}
            >
                <MenuItem onClick={openChat}>
                    <ListItemIcon sx={{ m: 0 }}>
                        <SupportAgentIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography color="textPrimary" variant="subtitle1">
                                {i18n._('Support')}
                            </Typography>
                        }
                    />
                </MenuItem>
                <StyledLink href="https://apadua.com/help/" target="_blank" rel="noreferrer">
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon sx={{ m: 0 }}>
                            <BookIcon />
                        </ListItemIcon>

                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="subtitle1">
                                    {i18n._('Tutorials')}
                                </Typography>
                            }
                        />
                    </MenuItem>
                </StyledLink>
            </Menu>
        </>
    );
};
