export interface JWTUser {
    id: string;
    email: string;
    emailValidated?: boolean;
    profileCompleted?: boolean;
    firstName: string;
    lastName: string;
    language: string;
    formattingLocale: string;
    isStaff: boolean;
    hasPassword: boolean;
    isClient: boolean;
    isConsultant: boolean;
    isAgent: boolean;
    isReferencesEmailUser: boolean;
    briefRole: string | null;
    apaduaRole: string | null;
    monitorRole: string | null;
    exploreRole: string | null;
    apalyticsRole: string | null;
    chatwootSession: string | null;
    chatwootToken: string | null;
    companyId: string;
    companyName: string;
    company: any;
    jobTitle: string;
    mobilePhone: string;
    salutation: any;
    academicTitle: any;
    apps: string[];
    sessionExpired?: boolean;
    activeRfps?: number;
    activeRfqs?: number;

    [key: string]: any;
}

enum AppRole {
    ADMIN = 'admin',
    USER = 'user',
}

export const ApaduaRole = { ...AppRole };
export const MonitorRole = { ...AppRole };
export const ExploreRole = { ...AppRole };
