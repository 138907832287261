import { RouteObject } from 'react-router';

import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import { QuoteProvider } from 'src/components/rfp/QuoteProvider';
import CompanyAbout from 'src/explore/CompanyAbout';
import CompanyExplore from 'src/explore/CompanyExplore';
import CompanyProfilePage from 'src/explore/CompanyProfilePage';
import SessionManager from 'src/login/sessionManager';
import {
    ProjectBriefPage,
    ProjectRFPDetailsPage,
    ProjectReviewAndSubmitPage,
    ProjectSupplierSelectionPage,
} from 'src/pages/project';
import ProjectAnnouncementsPage from 'src/pages/project/projectAnnouncementsPage';
import ProjectCoverLetterPage from 'src/pages/project/projectCoverLetterPage';
import ProjectCriteriaEvaluationPage from 'src/pages/project/projectCriteriaEvaluationPage';
import ProjectInternalDocumentsPage from 'src/pages/project/projectInternalDocumentsPage';
import ProjectPage from 'src/pages/project/projectPage';
import ProjectUserManagementPage from 'src/pages/project/projectUserManagementPage';
import ProjectSideBar from 'src/pages/project/sidebar';
import {
    ProjectAnnouncementsFetcher,
    ProjectVendorsFetcher,
    fetchProjectData,
} from 'src/project/state/thunk';
import { ProjectPhasesFetcher } from 'src/redux/projectPhases/thunk';
import store from 'src/redux/store';
import { routeURLs } from 'src/router/routeURLs';

export const projectLoader = async ({ params }) => {
    const { id, quoteId } = params;
    let currentId = id;

    if (quoteId) {
        [, currentId] = atob(quoteId).split('QuoteNode:');
    }

    const projectPhasesPromise = store.dispatch(ProjectPhasesFetcher.action());
    const projectAnnouncementsPromise = store.dispatch(
        ProjectAnnouncementsFetcher.action(currentId),
    );
    const projectPromise = store.dispatch(fetchProjectData(currentId));
    await Promise.all([projectPhasesPromise, projectPromise, projectAnnouncementsPromise]);
    return null;
};

const projectVendorsLoader = async ({ params }) => {
    const { id } = params;
    const { loading } = store.getState().project.projectVendors;
    if (loading !== 'loading') {
        await store.dispatch(ProjectVendorsFetcher.action(id));
    }
    const { project } = store.getState();
    return project;
};

const routes: RouteObject[] = [
    {
        path: routeURLs.PROJECT_BASE,
        element: <DashboardLayout />,
        sidebar: <ProjectSideBar />,
        requiresTenant: true,
        children: [
            {
                path: routeURLs.PROJECT,
                element: <ProjectPage />,
                loader: SessionManager.createLoader(projectLoader),
                children: [
                    {
                        element: <ProjectBriefPage />,
                        sidebar: <ProjectSideBar />,
                        index: true,
                    },
                    {
                        path: routeURLs.PROJECT_VENDORS,
                        loader: SessionManager.createLoader(projectVendorsLoader),
                        children: [
                            {
                                index: true,
                                sidebar: <ProjectSideBar />,
                                element: <ProjectSupplierSelectionPage />,
                            },
                            {
                                path: routeURLs.PROJECT_VENDORS_PROFILE,
                                element: <CompanyProfilePage />,
                                children: [
                                    {
                                        path: routeURLs.PROJECT_VENDORS_DETAILS,
                                        sidebar: <ProjectSideBar />,
                                        element: <CompanyAbout />,
                                    },
                                    {
                                        path: routeURLs.PROJECT_VENDORS_EXPLORE,
                                        sidebar: <ProjectSideBar />,
                                        element: <CompanyExplore />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: routeURLs.PROJECT_RFP_DETAILS,
                        sidebar: <ProjectSideBar />,
                        element: <ProjectRFPDetailsPage />,
                    },
                    {
                        path: routeURLs.PROJECT_CRITERIA_EVALUATION,
                        sidebar: <ProjectSideBar />,
                        element: <ProjectCriteriaEvaluationPage />,
                    },
                    {
                        path: routeURLs.PROJECT_COVER_LETTER,
                        element: <ProjectCoverLetterPage />,
                    },
                    {
                        path: routeURLs.PROJECT_REVIEW_AND_SUBMIT,
                        element: <ProjectReviewAndSubmitPage />,
                        sidebar: <ProjectSideBar />,
                    },
                    {
                        path: routeURLs.PROJECT_INTERNAL_DOCUMENTS,
                        sidebar: <ProjectSideBar />,
                        element: (
                            <QuoteProvider>
                                <ProjectInternalDocumentsPage />
                            </QuoteProvider>
                        ),
                    },
                    {
                        path: routeURLs.PROJECT_USER_MANAGEMENT,
                        sidebar: <ProjectSideBar />,
                        element: (
                            <QuoteProvider>
                                <ProjectUserManagementPage />
                            </QuoteProvider>
                        ),
                    },
                    {
                        path: routeURLs.PROJECT_ANNOUNCEMENTS,
                        sidebar: <ProjectSideBar />,
                        element: (
                            <QuoteProvider>
                                <ProjectAnnouncementsPage />
                            </QuoteProvider>
                        ),
                    },
                ],
            },
        ],
    },
];

export default routes;
