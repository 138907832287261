import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField as MuiTextField, Typography } from '@mui/material';

import { ClientQuestionsLegacyAPI } from 'src/apis/clientQuestionsLegacyAPI';
import { FormikFormErrors } from 'src/components/FormikFormErrors';
import { Modal } from 'src/components/common/modal';
import StyledTextarea from 'src/components/common/styledTextArea';
import { TextField } from 'src/components/formik-material-ui/TextField';
import useAuth from 'src/hooks/useAuth';
import DismissibleToast from 'src/utils/DismissibleToast';

interface Props {
    triggerButtonComponent?: React.ComponentType<{
        handleOpen: () => void;
        triggerButtonText: string;
    }>;
    projectID: number;
    projectTitle: string;
}

const api = new ClientQuestionsLegacyAPI();

const RequestCallbackModal = ({ triggerButtonComponent, projectID, projectTitle }: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const { user } = useAuth();
    const { i18n } = useLingui();

    const params = useParams();
    const onSubmit = (values) => {
        const { quoteProposalId } = params;
        const [, proposalID] = atob(`${quoteProposalId}`).split('QuoteProposalNode:');
        const payload = {
            proposalID,
            ...values,
        };
        api.create(payload)
            .then((response) => {
                if (response) {
                    toast.success(DismissibleToast(i18n._('Your request has been send.')));
                }
            })
            .catch(() => {
                toast.error(DismissibleToast(i18n._('An error occurred. Please try again later.')));
            })
            .finally(() => {
                handleClose();
            });
    };
    return (
        <Modal
            isOpen={isOpen}
            externalControl
            onClose={handleClose}
            onOpen={handleOpen}
            withCloseIcon={false}
            triggerButtonComponent={triggerButtonComponent}
            style={{
                width: 'auto',
                maxWidth: '900px',
                paddingTop: 2,
            }}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    email: user?.email,
                    company: user?.company?.companyName,
                    fullName: `${user?.firstName} ${user?.lastName}`,
                    phoneNumber: user?.mobilePhone,
                    question: '',
                }}
                onSubmit={onSubmit}
            >
                {({ status, isSubmitting, values }): JSX.Element => (
                    <Form>
                        <Grid container flexDirection="column">
                            <Grid item sx={{ mb: 1 }}>
                                <Typography variant="h6" color="gray">
                                    <Trans>Request a call back from the client</Trans>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ mb: 1 }}>
                                <Typography variant="body2" color="gray">
                                    <Grid>
                                        <Trans>
                                            With this form, you request a call-back from the client.
                                        </Trans>
                                    </Grid>
                                    <Grid>
                                        <Trans>
                                            The manager of this RfP will receive a notification
                                            about your request.
                                        </Trans>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Project ID:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <MuiTextField
                                        fullWidth
                                        disabled
                                        size="small"
                                        id="outlined-disabled"
                                        value={projectID}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Project name:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <MuiTextField
                                        disabled
                                        fullWidth
                                        size="small"
                                        id="outlined-disabled"
                                        value={projectTitle}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Your company:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <Field
                                        name="company"
                                        type="text"
                                        size="small"
                                        component={TextField}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Your full name:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <Field
                                        fullWidth
                                        required
                                        component={TextField}
                                        size="small"
                                        name="fullName"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Your email address:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <Field
                                        type="email"
                                        name="email"
                                        required
                                        fullWidth
                                        component={TextField}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: 2 }} display="flex" justifyContent="space-between">
                                <Grid>
                                    <Typography variant="body2" color="gray">
                                        <Trans>Your full number:</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <Field
                                        required
                                        type="number"
                                        name="phoneNumber"
                                        component={TextField}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 2 }}>
                                <Grid sx={{ mb: 1 }}>
                                    <Typography variant="body2" color="gray">
                                        Your request. Please be as specific as possible about the
                                        nature of your request.
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Field name="question" required component={StyledTextarea}>
                                        {({ field }) => (
                                            <StyledTextarea
                                                {...field}
                                                value={values.question}
                                                minRows={4}
                                                maxRows={4}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Grid>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    color="primary"
                                    loading={isSubmitting}
                                >
                                    <Typography>
                                        <Trans>Send request</Trans>
                                    </Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <FormikFormErrors status={status} />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default RequestCallbackModal;
