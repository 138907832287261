import remove from 'lodash/remove';
import union from 'lodash/union';
import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { SummaryCardItemProps } from 'src/components/common/lists/SummaryCardItem';

export const useCompanyFocusTopics = (company) => {
    const theme = useTheme();

    const focusTopicsWithSummaries = useMemo(
        () =>
            company?.allKeywordSummaries?.map(
                (speciality) =>
                    ({
                        header: speciality.keyword.keywordEn,
                        content: speciality.summary,
                        onFlyTranslate: true,
                        key: speciality.keyword.keywordEn,

                        sx: {
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'auto',
                            backgroundColor: theme.palette.background.paper,
                        },
                    }) as SummaryCardItemProps & { key?: string },
            ) || [],
        [],
    );

    const focusTopicsWithoutSummaries = useMemo(
        () =>
            remove(
                [...company?.lnCompanySpecialities?.edges],
                (n: any) =>
                    !company?.allKeywordSummaries
                        ?.map((speciality) => speciality.keyword.id)
                        .includes(n.node.id),
            )?.map(
                (speciality) =>
                    ({
                        header: speciality.node.keywordEn,
                        content: null,
                        onFlyTranslate: false,
                        key: speciality.node.keywordEn,

                        sx: {
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'auto',
                            backgroundColor: theme.palette.background.paper,
                        },
                    }) as SummaryCardItemProps & { key?: string },
            ) || [],
        [],
    );

    const legacyFocusTopics = useMemo(
        () =>
            company?.services?.edges?.map(
                (service) =>
                    ({
                        header: service.node.serviceEn,
                        content: null,
                        onFlyTranslate: false,
                        key: service.node.serviceEn,

                        sx: {
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'auto',
                            backgroundColor: theme.palette.background.paper,
                        },
                    }) as SummaryCardItemProps & { key?: string },
            ) || [],
        [],
    );

    return union(focusTopicsWithSummaries, focusTopicsWithoutSummaries, legacyFocusTopics);
};
