import posthog from 'posthog-js';
import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const GaTracker: FC = () => {
    const location = useLocation();
    const { user } = useAuth();
    const [trackingInitialized, setTrackingInitialized] = useState(false);

    useEffect(() => {
        if (
            (window.ENVIRONMENT === 'production' || window.ENVIRONMENT === 'staging') &&
            user?.trackingConsent
        ) {
            ReactGA.initialize('G-BPDYRNLZFH', {
                gaOptions: {
                    cookieFlags: 'SameSite=None; Secure',
                    userId: user.email,
                },
            });
            setTrackingInitialized(true);

            posthog.init('phc_rmJMJH55AlG7LSw2E1tV5xUvEIYaDokyFHkmE70b2tk', {
                api_host: 'https://eu.posthog.com',
            });

            posthog.identify(
                user.id, // distinct_id, required
                { user_email: user.email }, // $set, optional
            );
        }
    }, [user?.trackingConsent]);

    useEffect(() => {
        if (trackingInitialized) {
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search,
            });
        }
    }, [location, trackingInitialized]);

    return null;
};

export default GaTracker;
