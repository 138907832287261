import { useNavigate } from 'react-router';

import { Trans } from '@lingui/macro';
import { Button } from '@mui/material';

import { ProjectStatus } from 'src/project/types';

import { useFullProject } from '../state/hook';

interface EditActionProps {
    editRoute?: string;
}

const EditAction = ({ editRoute }: EditActionProps) => {
    const project = useFullProject();
    const navigate = useNavigate();
    const shouldShow = project?.status === ProjectStatus.draft;

    const handleEdit = () => {
        if (!editRoute) {
            navigate(`/project/${project.id}`);
            return;
        }
        if (editRoute.startsWith('http')) {
            window.location.href = editRoute;
            return;
        }
        if (editRoute.startsWith('#')) {
            navigate(`/project/${project.id}${editRoute}`);
            return;
        }
        navigate(`/project/${project.id}/${editRoute}`);
    };

    return shouldShow ? (
        <Button onClick={handleEdit}>
            <Trans>Edit</Trans>
        </Button>
    ) : null;
};

export default EditAction;
