import graphql from 'babel-plugin-relay/macro';
import { debounce } from 'lodash';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
    fetchQuery,
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
} from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';

import DismissibleToast from 'src/utils/DismissibleToast';

export const ConsultingCompanyAutocompleteConsultingCompanySearchQuery = graphql`
    query ConsultingCompanyAutocompleteConsultingCompanySearchQuery($text: String!) {
        consultingCompaniesSearch(text: $text) {
            edges {
                node {
                    id
                    companyName
                    generalInfo {
                        website
                    }
                }
            }
        }
    }
`;

export const ConsultingCompanyAutocompleteChildComponent: FC<any> = (props: any) => {
    const { i18n } = useLingui();
    const { companiesPreloadedQuery, refetch, onChange, consultingCompanies } = props;
    const data = usePreloadedQuery(
        ConsultingCompanyAutocompleteConsultingCompanySearchQuery,
        companiesPreloadedQuery,
    );

    const options = [
        {
            id: null,
            companyName: 'new',
        },
        ...data.consultingCompaniesSearch.edges.map((edge) => edge.node),
    ];
    const [searchText, setSearchText] = useState('');

    const changeHandler = (event) => {
        refetch(event.target.value);
        setSearchText(event.target.value);
    };
    const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), []);

    return (
        <Autocomplete
            options={options}
            onChange={(event, value) => {
                if (value === null) {
                    return;
                }
                if (typeof value === 'string') {
                    onChange({
                        id: null,
                        companyName: value,
                    });
                } else {
                    let found = false;
                    consultingCompanies.forEach((company) => {
                        if (company.company.id === value.id) {
                            found = true;
                        }
                    });
                    if (!found) {
                        onChange(value);
                    } else {
                        toast.error(
                            DismissibleToast(i18n._('You already selected this consultant')),
                        );
                    }
                }
            }}
            getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.companyName;
            }}
            filterOptions={(filterOptions) => filterOptions}
            renderInput={(params): JSX.Element => (
                <TextField
                    fullWidth
                    variant="outlined"
                    label={i18n._('Company Name')}
                    onChange={debouncedChangeHandler}
                    {...params}
                />
            )}
            renderOption={(optionProps, option) => {
                if (option.id === null) {
                    return (
                        <li key="createNewCompany">
                            {options.length > 1 && (
                                <Typography color="textPrimary" variant="h6" sx={{ ml: 2 }}>
                                    {i18n._('Add new company')}
                                </Typography>
                            )}
                            {options.length === 1 && (
                                <Typography color="textPrimary" variant="subtitle1" sx={{ ml: 2 }}>
                                    {i18n._('No companies found')}
                                    <Button
                                        onClick={() => {
                                            onChange({
                                                id: null,
                                                companyName: searchText,
                                            });
                                        }}
                                        color="primary"
                                        variant="contained"
                                        sx={{ mt: 2, mr: 0, mb: 2, ml: 4 }}
                                        data-testid="addNewConsultingCompanyButton"
                                    >
                                        {i18n._('Add new company')}
                                    </Button>
                                </Typography>
                            )}
                            {options.length > 1 && (
                                <Button
                                    onClick={() => {
                                        onChange({
                                            id: null,
                                            companyName: searchText,
                                        });
                                    }}
                                    color="primary"
                                    variant="contained"
                                    sx={{ mt: 2, mr: 0, mb: 2, ml: 4 }}
                                    data-testid="addNewConsultingCompanyButton"
                                >
                                    {i18n._('Add new company')}
                                </Button>
                            )}
                            {options.length > 1 && (
                                <Typography color="textPrimary" variant="h6" sx={{ ml: 2, mt: 2 }}>
                                    {i18n._('Or select an existing one')}
                                </Typography>
                            )}
                        </li>
                    );
                }
                return (
                    <li {...optionProps} style={{ marginLeft: '20px' }} key={option.id}>
                        {option.companyName}
                        {option.generalInfo?.website && (
                            <>
                                {' ('}
                                {option.generalInfo.website}
                                {') '}
                            </>
                        )}
                    </li>
                );
            }}
            data-testid="consultingCompanyAutocomplete"
        />
    );
};

interface ConsultingCompanyAutocompleteProps {
    onChange: CallableFunction;
    consultingCompanies: any;
}

export const ConsultingCompanyAutocomplete: FC<ConsultingCompanyAutocompleteProps> = ({
    onChange,
    consultingCompanies,
}: ConsultingCompanyAutocompleteProps) => {
    const { i18n } = useLingui();
    const environment = useRelayEnvironment();
    const [queryRef, loadQuery] = useQueryLoader(
        ConsultingCompanyAutocompleteConsultingCompanySearchQuery,
    );
    const [activeSubscription, setActiveSubscription] = useState(null);

    const refetch = useCallback((text) => {
        if (activeSubscription) {
            // if there is an active subscription, we drop it;
            activeSubscription.unsubscribe();
        }

        const variables = { text };

        // fetchQuery will fetch the query and write
        // the data to the Relay store. This will ensure
        // that when we re-render, the data is already
        // cached and we don't suspend
        // get the subscription object and set it as state
        setActiveSubscription(
            fetchQuery(
                environment,
                ConsultingCompanyAutocompleteConsultingCompanySearchQuery,
                variables,
            ).subscribe({
                complete: () => {
                    setActiveSubscription(null);

                    // *After* the query has been fetched, we call
                    // loadQuery again to re-render with a new
                    // queryRef.
                    loadQuery({ text }, { fetchPolicy: 'network-only' });
                },
                error: () => {
                    setActiveSubscription(null);
                },
            }),
        );
    }, []);

    useEffect(() => {
        loadQuery({ text: '' }, { fetchPolicy: 'network-only' });
    }, []);

    if (queryRef === null) {
        return <div>Loading...</div>;
    }

    return (
        <Suspense fallback="Loading...">
            <Typography color="textPrimary" variant="subtitle1" sx={{ mb: 1 }}>
                {i18n._('Select a company from our database')}
            </Typography>
            <ConsultingCompanyAutocompleteChildComponent
                companiesPreloadedQuery={queryRef}
                consultingCompanies={consultingCompanies}
                onChange={onChange}
                refetch={refetch}
            />
        </Suspense>
    );
};
