import { FC, FormEvent, MouseEvent as ME } from 'react';

import { useLingui } from '@lingui/react';
import { Box } from '@mui/material';

import ElasticAutocomplete from './ElasticAutocomplete';
import SearchSubmitButton from './SearchSubmitButton';
import SearchToggleButtons from './SearchToggleButtons';

type SearchBoxViewProps = {
    onChange: (value: string, updateQuery: boolean) => void;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    onToggleChange: (event: ME<HTMLButtonElement, MouseEvent>, value: any) => void;
    onSearchButtonClick: (event: ME<HTMLButtonElement, MouseEvent>) => void;
    onSuggestionClick: (label: string) => void;
    autocompleteResults: string[];
    contentSearch: boolean;
};

const SearchBoxView: FC<SearchBoxViewProps> = ({
    onChange: handleQueryChange,
    onSubmit: handleSubmit,
    onToggleChange: handleToggleChange,
    onSearchButtonClick: handleSearchButtonClick,
    onSuggestionClick: handleSuggestionClick,
    autocompleteResults,
    contentSearch,
}: SearchBoxViewProps) => {
    const { i18n } = useLingui();

    return (
        <form onSubmit={handleSubmit}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <SearchToggleButtons
                    contentSearch={contentSearch}
                    onToggleChange={handleToggleChange}
                />
                <ElasticAutocomplete
                    onChange={handleQueryChange}
                    onSuggestionClick={handleSuggestionClick}
                    autocompleteResults={autocompleteResults}
                    label={
                        contentSearch
                            ? i18n._('Search companies by their content')
                            : i18n._('Search companies by name')
                    }
                    showSearchSuggestions={contentSearch}
                />
                <SearchSubmitButton handleSearchButtonClick={handleSearchButtonClick} />
            </Box>
        </form>
    );
};

export default SearchBoxView;
