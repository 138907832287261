import { useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';

import CheckBoxWithSection from 'src/components/formik-material-ui/checkboxWithSection';
import BucketSection, { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import { InternalSection } from 'src/components/sections/section';
import SpacingContainer from 'src/components/sections/spacingContainer';
import AutocompleteField from 'src/forms/autocompleteField';
import RadioGroupField from 'src/forms/radioGroupField';
import TextareaField from 'src/forms/textareaField';
import useAuth from 'src/hooks/useAuth';
import { useLanguages } from 'src/redux/languages/hook';
import { EMPTY_ARRAY } from 'src/utils/empty';
import getOptionKey from 'src/utils/language/getOptionKey';
import { getYupFieldMaxValue } from 'src/yup/getYupFieldMaxValue';

import ProjectAffiliateCompanyForm from './projectAffiliateCompanyForm';
import { validationSchema } from './validation';

const ProjectSetupForm = () => {
    const languages = useLanguages();
    const { user } = useAuth();
    const defaultCompanyRadios = [
        {
            value: true,
            label: (
                <Trans>
                    Use company from user profile - <strong>{user.company.companyName}</strong>{' '}
                </Trans>
            ),
        },
        { value: false, label: <Trans>Use an affiliate company</Trans> },
    ];

    return (
        <BucketSection isVertical title={<Trans>Project Setup</Trans>} id="project-setup">
            <Bucket xs={12} withDivider={false}>
                <InternalSection
                    title={<Trans>What is the name of your company or organization?</Trans>}
                >
                    <SpacingContainer flexDirection="column">
                        <RadioGroupField
                            name="useDefaultCompany"
                            radios={defaultCompanyRadios}
                            required
                        />
                        <ProjectAffiliateCompanyForm />
                    </SpacingContainer>
                </InternalSection>
            </Bucket>
            <Bucket xs={12} withDivider={false}>
                <InternalSection title={<Trans>Write a client introduction message</Trans>}>
                    <ProjectClientIntroduction />
                </InternalSection>
            </Bucket>
            <Bucket>
                <ProjectPhases />
            </Bucket>
            <Bucket>
                <InternalSection
                    title={<Trans>What languages are relevant for the project?</Trans>}
                >
                    <BucketContainer isVertical={false}>
                        <Bucket xs={12} sm={6} lg={4} xl withDivider={false}>
                            <AutocompleteField
                                options={languages}
                                name="mainLanguage"
                                required
                                label={<Trans>Project Language</Trans>}
                                getOptionLabel={(option) => getOptionKey(option, 'language') || ''}
                            />
                        </Bucket>
                        <Bucket xs={12} sm={6} lg={4} withDivider={false}>
                            <AutocompleteField
                                options={languages}
                                name="proposalLanguage"
                                required
                                label={<Trans>Proposal Language</Trans>}
                                getOptionLabel={(option) => getOptionKey(option, 'language') || ''}
                            />
                        </Bucket>
                        <Bucket xs={12} sm={6} lg={4} withDivider={false}>
                            <AutocompleteField
                                multiple
                                id="secondaryLanguages"
                                label={<Trans>Secondary languages</Trans>}
                                name="secondaryLanguages"
                                options={languages || EMPTY_ARRAY}
                                getOptionLabel={(option: any) => getOptionKey(option, 'language')}
                                limitTags={3}
                            />
                        </Bucket>
                    </BucketContainer>
                </InternalSection>
            </Bucket>
        </BucketSection>
    );
};

const ProjectClientIntroduction = () => {
    const { values } = useFormikContext() as any;

    const maxClientIntroductionValue = getYupFieldMaxValue(validationSchema, 'clientIntroduction');
    return (
        <TextareaField
            name="clientIntroduction"
            minRows={4}
            label={<Trans>Client Introduction</Trans>}
            inputProps={{ maxLength: maxClientIntroductionValue }}
            helperText={`Your remaining characters are ${
                maxClientIntroductionValue - (values.clientIntroduction || '')?.length
            }`}
            required
        />
    );
};

const ProjectPhases = () => {
    const { values } = useFormikContext() as any;
    return (
        <CheckBoxWithSection
            name="projectPhases"
            labelKey="projectPhase"
            values={values.projectPhases}
            sectionTitle={<Trans>Which project phases should be covered by the request?</Trans>}
        />
    );
};

export default ProjectSetupForm;
