import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { QuoteProposalContext } from 'src/contexts/rfp/QuoteProposalContext';
import useAuth from 'src/hooks/useAuth';

const QuoteProposalProviderQuery = graphql`
    query QuoteProposalProviderQuery($quoteProposalId: ID!) {
        sourceProposal(quoteProposalId: $quoteProposalId) {
            id
            integerId
            announcementSetId
            internalDocumentSetId
            status
            participationConfirmed
            quote {
                id
                integerId
                projectName
            }
            ...QuoteProposalUserManagementComponent_sourceProposal
            ...Questions_sourceProposal
        }
    }
`;

const QuoteProposalProviderClientQuery = graphql`
    query QuoteProposalProviderClientQuery($quoteProposalId: ID!) {
        sourceProposal(quoteProposalId: $quoteProposalId) {
            id
            quote {
                id
                projectName
            }
        }
    }
`;

const useQuery = () => {
    const { user } = useAuth();
    return user.isConsultant ? QuoteProposalProviderQuery : QuoteProposalProviderClientQuery;
};

export const useQuoteProposalQuery = () => {
    const query = useQuery();
    const { quoteProposalQueryRef, refreshQuoteProposalQuery } = useContext(QuoteProposalContext);
    const quoteProposalQuery = usePreloadedQuery(query, quoteProposalQueryRef);
    return {
        quoteProposalQuery,
        refreshQuoteProposalQuery,
    };
};

interface Props {
    children: any;
}

export const QuoteProposalProvider: FC<Props> = ({ children }: Props) => {
    const query = useQuery();
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(query);
    const location = useLocation();
    const matchProject = matchPath(
        '/dashboard/rfp/:quoteId/proposals/:quoteProposalId/*',
        location.pathname,
    );

    const quoteProposalId = matchProject?.params?.quoteProposalId;

    const refresh = () => {
        loadQuery({ quoteProposalId }, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refresh();
        return () => {
            disposeQuery();
        };
    }, [quoteProposalId]);

    if (queryRef === null) {
        return null;
    }

    return (
        <QuoteProposalContext.Provider
            value={{
                quoteProposalQueryRef: queryRef,
                refreshQuoteProposalQuery: refresh,
            }}
        >
            {children}
        </QuoteProposalContext.Provider>
    );
};
