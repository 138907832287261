import React, { forwardRef } from 'react';

import { TextareaAutosize, TextareaAutosizeProps } from '@mui/base';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export interface StyledTextareaProps extends TextareaAutosizeProps {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    error?: boolean;
}

const UnstyledTextarea = forwardRef<HTMLTextAreaElement, StyledTextareaProps>(
    ({ error, ...props }, ref) => <TextareaAutosize ref={ref} {...props} />,
);

const StyledTextarea = styled(UnstyledTextarea)<StyledTextareaProps>(
    ({ theme, error = false }) => ({
        maxWidth: '100%',
        maxHeight: '60vh',
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.5,
        padding: 12,
        backgroundColor: theme.palette.baseColors.color5,
        color: grey[900],
        border: `1px solid ${error ? theme.palette.error.main : grey[200]}`,
        boxShadow: `0px 2px 2px ${grey[50]}`,
        borderRadius: 4,
        '&:hover': {
            borderColor: error ? theme.palette.error : theme.palette.text.primary,
        },
        '&:focus': {
            borderColor: error ? theme.palette.error : theme.palette.text.primary,
        },
        '&:focus-visible': {
            outline: 0,
        },
    }),
);

export default StyledTextarea;

export { UnstyledTextarea };
