import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

import BucketSection, { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import { InternalSection } from 'src/components/sections/section';
import ErrorMessage from 'src/components/typography/errorMessage';
import EditAction from 'src/project/components/editAction';
import { useProjectDetails } from 'src/project/state/hook';

const AdditionalInformation = () => {
    const projectDetails = useProjectDetails();
    const { otherMentions } = projectDetails;

    return (
        <BucketSection
            title={<Trans>Additional Information</Trans>}
            actions={<EditAction editRoute="rfp-details#additional-information" />}
        >
            <Bucket>
                <InternalSection title="">
                    <BucketContainer isVertical>
                        <Bucket xs={12} md={6}>
                            <Grid container sx={{ pl: 2 }}>
                                {otherMentions ? (
                                    <Typography
                                        dangerouslySetInnerHTML={{ __html: otherMentions }}
                                    />
                                ) : (
                                    <ErrorMessage
                                        errorMessage={<Trans>No information available</Trans>}
                                    />
                                )}
                            </Grid>
                        </Bucket>
                    </BucketContainer>
                </InternalSection>
            </Bucket>
        </BucketSection>
    );
};

export default AdditionalInformation;
