import reduceReducers from 'reduce-reducers';

import { combineReducers } from '@reduxjs/toolkit';

/**
 * Combines a slice with any number of child slices.
 *
 * This solution is inspired by [this GitHub
 * comment](https://github.com/reduxjs/redux-toolkit/issues/259#issuecomment-604496169).
 *
 * @param sliceReducer - The reducer of the parent slice. For example, if your
 * slice is called `mySlice`, you should pass in `mySlice.reducer`.
 * @param {object} sliceInitialState - The initial state object passed to
 * `createSlice`. This is needed so that we know what keys are in the initial
 * state.
 * @param {object} childSliceReducers - An object of child slice reducers, keyed
 * by the name you want them to have in the Redux state namespace. For example,
 * if you've imported child slices called `childSlice1` and `childSlice2`, you
 * should pass in this argument as `{ childSlice1: childSlice1.reducer,
 * childSlice2: childSlice2.reducer }`. NOTE: The name of each child slice
 * should reflect its place in the namespace hierarchy to ensure that action
 * names are properly namespaced. For example, the `name` property of the
 * `childSlice1` slice should be `mySlice/childSlice1`, not just `childSlice1`.
 */
export const combineSlices = (sliceReducer, sliceInitialState, childSliceReducers) => {
    const noopReducersFromInitialState = Object.keys(sliceInitialState).reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: (s = null) => s,
        }),
        {},
    );

    const childReducers = combineReducers({
        ...childSliceReducers,
        ...noopReducersFromInitialState,
    });

    return reduceReducers(sliceReducer, childReducers);
};
