export const sanitizeCurrency = (data) => {
    if (
        data &&
        data.allCurrencies &&
        Array.isArray(data.allCurrencies.edges) &&
        data.allCurrencies.edges.length > 0
    ) {
        return data.allCurrencies.edges.map((item) => {
            // TODO extract the below functionality to a separate util
            const decodedID = atob(item.node.id);
            const [, rawDatabaseId] = decodedID.split(':');
            const numericDatabaseId = parseInt(rawDatabaseId, 10);
            return {
                rawID: numericDatabaseId,
                id: item.node.id,
                isoCurrencyCode: item.node.isoCurrencyCode,
            };
        });
    }

    return [];
};
