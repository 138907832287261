export const fieldValidationErros = (errors: any) => {
    let res: Record<string, string> = {};

    errors?.forEach((error) => {
        if ('validation' in error) {
            res = {
                ...res,
                ...error.validation.fieldErrors,
            };
        }
    });

    return res;
};

export const formValidationErros = (errors: any) => {
    const res: string[] = [];

    errors?.forEach((error) => {
        if ('validation' in error) {
            res.push(...error.validation.formErrors);
        } else {
            res.push(error.message);
        }
    });

    return res;
};
