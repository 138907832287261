import React from 'react';

import { InsertDriveFile } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Grid, IconButton, Typography } from '@mui/material';

interface Props {
    file: string;
    filePath: string;
    type?: string;
}

const FileRenderer = ({ file, filePath, type }: Props) => {
    const handleDownload = () => {
        window.open(file, '_blank');
    };

    return (
        <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
                <IconButton onClick={handleDownload}>
                    <DownloadIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <InsertDriveFile />
            </Grid>
            <Grid item>
                <Typography>{filePath}</Typography>
            </Grid>
            {type && (
                <Grid item>
                    <Typography>
                        <b>-</b> {type}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default FileRenderer;
