import React, { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';

import { Information } from 'src/components/typography';
import useCancelTourOnUnmount from 'src/tours/cancelTourOnUnmount';
import { useUserTourPolicy } from 'src/tours/tourPolicy';

import TourContentWithDecline from './tourContentWithDecline';

export const useProjectReviewSubmitTour = () => {
    useCancelTourOnUnmount();
    const { shouldDisplay, onTourShown, onTourDeclined, onTourFinished } =
        useUserTourPolicy('ProjectReviewSubmitTour');
    const { setIsOpen, setSteps, setCurrentStep } = useTour();
    const computedSteps = useMemo(() => {
        const handleClose = () => {
            setIsOpen(false);
            setCurrentStep(0);
        };
        const tourSteps = [
            {
                selector: '[data-testid="ProjectReviewSubmit"]',
                content: (
                    <Information
                        title={<Trans>Review and submit</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        In this last step you will be able to check the contents of
                                        your RFP. This view is what the vendors will see once they
                                        accept to participate in the RFP.
                                    </Trans>
                                </Typography>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        <Trans>Done</Trans>
                                    </Button>
                                </Grid>
                            </TourContentWithDecline>
                        }
                    />
                ),
                actionAfter: onTourFinished,
                stepInteraction: false,
            },
        ];

        return tourSteps;
    }, []);

    useEffect(() => {
        setSteps(computedSteps);
        if (shouldDisplay) {
            onTourShown();

            setTimeout(() => setIsOpen(true), 1000);
        }
    }, [
        shouldDisplay,
        onTourShown,
        onTourDeclined,
        onTourFinished,
        setSteps,
        setIsOpen,
        computedSteps,
    ]);
};
