import { withSearch } from '@elastic/react-search-ui';
import CheckIcon from '@mui/icons-material/Check';
import { Chip, Grid } from '@mui/material';

import RichTooltip from 'src/components/common/tooltip/richTooltip';
import { useListsData } from 'src/pages/listManager/state/hooks';
import { SearchFilters } from 'src/search';

const ListChip = ({
    list,
    onDelete,
    onClick,
}: {
    list: any;
    onDelete(): void;
    onClick(ev: any): void;
}) => (
    <Chip
        key={list.id}
        label={list?.name}
        size="small"
        icon={list.locked ? <CheckIcon /> : undefined}
        sx={{
            ml: 1,
            mb: 1,
            backgroundColor: list.locked ? 'chips.green' : undefined,
            '&:hover': {
                backgroundColor: list.locked ? 'chips.green' : undefined,
            },
            flexShrink: list.locked ? 0 : 1,
            minWidth: 0,
        }}
        onDelete={onDelete}
        onClick={onClick}
    />
);

interface ExploreTableRowChipsProps {
    row?: any;
    filters?: any;
    addFilter?: (name: string, value: any, type?: any) => void;
    removeFilter?: (name: string, value?: any, type?: any) => void;
}

const ExploreTableRowChips = ({
    row,
    filters,
    addFilter,
    removeFilter,
}: ExploreTableRowChipsProps) => {
    const lists = useListsData();
    const listsFilter = filters.find((el) => el.field === SearchFilters.LISTS);

    const selectedLists = listsFilter?.values || [];

    const onDeleteChipFn = (list: any) =>
        selectedLists.includes(list.id)
            ? () => removeFilter(SearchFilters.LISTS, list.id)
            : undefined;

    const handleChipClick = (ev: React.MouseEvent, list: any) => {
        ev.stopPropagation();
        addFilter(SearchFilters.LISTS, list.id, 'any');
    };

    const chipLists = lists
        .filter((list) =>
            list.list_items?.some((item) => `${item.object_id}` === row?.id?.raw) ? list : false,
        )
        .sort((a, b) => selectedLists.includes(b.id) - selectedLists.includes(a.id))
        // show preferred vendors list first (locked)
        .sort((a, b) => b.locked - a.locked);

    const MAX_CHIPS = 2;

    const extraChips = chipLists.slice(MAX_CHIPS).map((list) => (
        <Grid item p={0.5} key={list.id}>
            <ListChip
                list={list}
                onDelete={onDeleteChipFn(list)}
                onClick={(ev) => handleChipClick(ev, list)}
            />
        </Grid>
    ));

    const moreChips =
        chipLists.length > MAX_CHIPS ? (
            <RichTooltip
                content={
                    <Grid container p={0.5}>
                        {extraChips}
                    </Grid>
                }
            >
                <Chip
                    label={`+ ${chipLists.length - MAX_CHIPS}`}
                    size="small"
                    sx={{ ml: 1, mb: 1 }}
                />
            </RichTooltip>
        ) : null;
    return (
        <>
            {chipLists.slice(0, MAX_CHIPS).map((list) => (
                <ListChip
                    onDelete={onDeleteChipFn(list)}
                    onClick={(ev) => handleChipClick(ev, list)}
                    list={list}
                />
            ))}
            {moreChips}
        </>
    );
};

export default withSearch(({ filters, addFilter, removeFilter }) => ({
    filters,
    addFilter,
    removeFilter,
}))(ExploreTableRowChips) as React.FC<ExploreTableRowChipsProps>;
