import { useFormikContext } from 'formik';

import { Trans } from '@lingui/macro';
import { Box } from '@mui/material';

import AutocompleteField from 'src/forms/autocompleteField';
import CountryField from 'src/forms/countryField';
import TextField from 'src/forms/textField';
import { useCompanySizeEmployees } from 'src/redux/companySizeEmployees/hook';
import { useCompanySizeTurnover } from 'src/redux/companySizeTurnover/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

const ProjectAffiliateCompanyForm = () => {
    const { values } = useFormikContext() as any;
    const companySizeEmployees = useCompanySizeEmployees();
    const companySizeTurnover = useCompanySizeTurnover();

    return (
        !values.useDefaultCompany && (
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField name="companyName" label={<Trans>Company Name</Trans>} required />
                <TextField
                    name="companyAddressLineOne"
                    label={<Trans>Address line one</Trans>}
                    required
                />
                <TextField name="companyAddressLineTwo" label={<Trans>Address line two</Trans>} />
                <TextField
                    name="companyAddressLineThree"
                    label={<Trans>Address line three</Trans>}
                />
                <TextField name="companyPostalCode" label={<Trans>Postal code</Trans>} required />
                <TextField name="companyCity" label={<Trans>City</Trans>} required />
                <CountryField name="companyCountry" required label={<Trans>Country</Trans>} />
                <TextField name="companyWebsite" label={<Trans>Website</Trans>} required />
                <AutocompleteField
                    key="companySizeEmployees"
                    options={companySizeEmployees}
                    name="companySizeEmployees"
                    required
                    label={<Trans>Company size</Trans>}
                    getOptionLabel={(option): string => `${getOptionKey(option, 'employeesRange')}`}
                />
                <AutocompleteField
                    key="companySizeTurnover"
                    options={companySizeTurnover}
                    name="companySizeTurnover"
                    required
                    label={<Trans>Company turnover</Trans>}
                    getOptionLabel={(option): string => `${getOptionKey(option, 'turnoverRange')}`}
                />
            </Box>
        )
    );
};

export default ProjectAffiliateCompanyForm;
