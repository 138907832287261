import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, Typography } from '@mui/material';

import { DateTimePicker } from 'src/components/formik-material-ui/DateTimePicker';
import { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import TextField from 'src/forms/textField';
import { getUserTimezone, transformTimezone } from 'src/utils/time';

interface Props {
    closeDrawer: () => void;
    timelineEvent: {
        id?: string;
        key: string;
        name: string;
        eventType: string;
        date: Date;
    };
    timelineEvents: any[];
    setTimelineEvents: CallableFunction;
    timezone: string;
}

const TimelineItemForm: FC<Props> = ({
    closeDrawer,
    timelineEvent,
    timelineEvents,
    setTimelineEvents,
    timezone,
}: Props) => {
    const { i18n } = useLingui();
    const minDate = new Date(); // today as min date

    const ValidationSchema = Yup.object().shape({
        name: Yup.string()
            .max(100, i18n._('Event name must be at most 100 characters'))
            .required(i18n._('This field is required')),
        eventType: Yup.string().max(64).required(i18n._('This field is required')),
        date: Yup.date()
            .required(i18n._('This field is required'))
            .nullable()
            .typeError(i18n._('Enter a valid date'))
            .min(minDate, i18n._('Enter a valid date')),
    });

    const defaultTime = new Date();
    defaultTime.setHours(18, 0, 0, 0);

    const onSubmit = async (values): Promise<void> => {
        const date = transformTimezone(values.date, getUserTimezone(), timezone);
        setTimelineEvents(
            timelineEvents
                .filter((tlEvent) => tlEvent.key !== timelineEvent.key)
                .concat([
                    {
                        id: timelineEvent.id,
                        name: values.name,
                        key: timelineEvent.key,
                        date,
                        eventType: values.eventType,
                    },
                ]),
        );

        closeDrawer();
    };

    return (
        <Formik
            initialValues={{
                id: timelineEvent.id,
                name: timelineEvent.name,
                eventType: timelineEvent.eventType || 'GENERAL',
                date: timelineEvent.date || defaultTime,
            }}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
        >
            {({ submitForm }): JSX.Element => (
                <Form>
                    <Box padding={2}>
                        <BucketContainer isVertical>
                            <Bucket xs={12} mt={8} withDivider={false}>
                                <Typography color="textPrimary" variant="h6">
                                    <Trans>Timeline Event</Trans>
                                </Typography>
                            </Bucket>
                            <Bucket item xs={12} withDivider={false}>
                                <TextField name="name" label={<Trans>Event name</Trans>} required />
                            </Bucket>
                            <Bucket item xs={12} withDivider={false}>
                                <Field
                                    fullWidth
                                    component={DateTimePicker}
                                    name="date"
                                    data-testid="timelineEventDate"
                                    ampm={false}
                                    timezone={timezone}
                                    label={<Trans>Date</Trans>}
                                    required
                                />
                            </Bucket>
                            <Bucket item xs={12} textAlign="right">
                                <Button
                                    sx={{ mr: 2 }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    data-testid="timelineEventSaveButton"
                                    onClick={submitForm}
                                >
                                    <Trans>Save</Trans>
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    data-testid="timelineEventCancelButton"
                                    onClick={closeDrawer}
                                >
                                    <Trans>Cancel</Trans>
                                </Button>
                            </Bucket>
                        </BucketContainer>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default TimelineItemForm;
