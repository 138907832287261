import type { FC } from 'react';

import { styled } from '@mui/material/styles';

const Logo: FC<any> = (props) => (
    <svg height="39.996" version="1.1" viewBox="0 0 140.375 39.996" width="140.375" {...props}>
        <title>Apadua Logo</title>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="140.375"
            height="39.996"
            viewBox="0 0 140.375 39.996"
        >
            <g id="Group_13534" data-name="Group 13534" transform="translate(-32 -15.996)">
                <g id="Group_4" data-name="Group 4" transform="translate(81.3 23.148)">
                    <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                        <g id="Group_2" data-name="Group 2">
                            <g id="Group_1" data-name="Group 1">
                                <path
                                    id="Path_1"
                                    data-name="Path 1"
                                    d="M1116.809,456.506c0-3.107,2.633-4.713,8.663-5.371-.026-1.685-.632-3.16-2.765-3.16a7.963,7.963,0,0,0-4.186,1.475l-1.132-2.054a11.093,11.093,0,0,1,5.845-1.9c3.607,0,5.266,2.291,5.266,6.135v8.715h-2.475l-.263-1.659h-.079a7.2,7.2,0,0,1-4.608,2A4,4,0,0,1,1116.809,456.506Zm8.663.105v-3.555c-4.292.527-5.74,1.606-5.74,3.239,0,1.422.974,2,2.264,2A5.1,5.1,0,0,0,1125.472,456.611Z"
                                    transform="translate(-1116.809 -439.365)"
                                    fill="#0d3c5a"
                                />
                                <path
                                    id="Path_2"
                                    data-name="Path 2"
                                    d="M1420.383,445.869h2.475l.237,1.58h.079a7.342,7.342,0,0,1,4.45-1.948c3.686,0,5.74,2.923,5.74,7.373,0,4.976-2.975,7.82-6.24,7.82a5.971,5.971,0,0,1-3.818-1.632l.079,2.475v3.682h-3Zm9.847,7.057c0-3.028-.974-4.9-3.4-4.9a5.088,5.088,0,0,0-3.449,1.764v7.083a4.908,4.908,0,0,0,3.16,1.317C1428.651,458.191,1430.231,456.322,1430.231,452.925Z"
                                    transform="translate(-1404.401 -439.365)"
                                    fill="#0d3c5a"
                                />
                                <path
                                    id="Path_3"
                                    data-name="Path 3"
                                    d="M1711.95,456.506c0-3.107,2.633-4.713,8.663-5.371-.026-1.685-.632-3.16-2.765-3.16a7.963,7.963,0,0,0-4.187,1.475l-1.132-2.054a11.092,11.092,0,0,1,5.845-1.9c3.607,0,5.266,2.291,5.266,6.135v8.715h-2.475l-.263-1.659h-.079a7.2,7.2,0,0,1-4.608,2A4,4,0,0,1,1711.95,456.506Zm8.663.105v-3.555c-4.292.527-5.74,1.606-5.74,3.239,0,1.422.974,2,2.264,2A5.1,5.1,0,0,0,1720.613,456.611Z"
                                    transform="translate(-1680.617 -439.365)"
                                    fill="#0d3c5a"
                                />
                                <path
                                    id="Path_4"
                                    data-name="Path 4"
                                    d="M1997.019,342.742c0-4.713,2.976-7.636,6.214-7.636a5.586,5.586,0,0,1,3.9,1.633l-.131-2.4v-5.371h3.028v20.985h-2.475l-.263-1.632h-.079A6.07,6.07,0,0,1,2003,350.3C1999.363,350.3,1997.019,347.534,1997.019,342.742Zm9.979,3.291V338.95a4.542,4.542,0,0,0-3.186-1.316c-2.027,0-3.66,1.922-3.66,5.055,0,3.291,1.29,5.108,3.554,5.108A4.256,4.256,0,0,0,2007,346.033Z"
                                    transform="translate(-1950.678 -328.971)"
                                    fill="#0d3c5a"
                                />
                                <path
                                    id="Path_5"
                                    data-name="Path 5"
                                    d="M2327.1,461.612V452.5h3.028v8.742c0,2.5.711,3.476,2.423,3.476,1.342,0,2.264-.632,3.476-2.159V452.5h3.028v14.482h-2.475l-.237-2.185h-.079a6.071,6.071,0,0,1-4.739,2.528C2328.439,467.326,2327.1,465.3,2327.1,461.612Z"
                                    transform="translate(-2263.377 -445.998)"
                                    fill="#0d3c5a"
                                />
                                <path
                                    id="Path_6"
                                    data-name="Path 6"
                                    d="M2624.668,456.506c0-3.107,2.633-4.713,8.663-5.371-.027-1.685-.632-3.16-2.765-3.16a7.965,7.965,0,0,0-4.187,1.475l-1.132-2.054a11.092,11.092,0,0,1,5.845-1.9c3.607,0,5.266,2.291,5.266,6.135v8.715h-2.475l-.263-1.659h-.079a7.2,7.2,0,0,1-4.608,2A4,4,0,0,1,2624.668,456.506Zm8.663.105v-3.555c-4.292.527-5.74,1.606-5.74,3.239,0,1.422.974,2,2.264,2A5.107,5.107,0,0,0,2633.331,456.611Z"
                                    transform="translate(-2545.283 -439.365)"
                                    fill="#0d3c5a"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Group_13377" data-name="Group 13377" transform="translate(32 15.996)">
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M480.754,188.418s21.061,14.01,28.968,27.038c0,0-16.848-.936-28.271.14a77.413,77.413,0,0,0-.687-26.822C480.606,187.989,480.754,188.418,480.754,188.418Z"
                        transform="translate(-468.481 -188.301)"
                        fill="#ffc000"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M248.775,715.466s30.572.938,41.315-.288c0,0-2.6,8.941-9.878,12.257H259.59A18.668,18.668,0,0,1,248.775,715.466Z"
                        transform="translate(-248.775 -687.439)"
                        fill="#0d3c5a"
                    />
                </g>
            </g>
        </svg>
    </svg>
);

export const MenuLogo = styled(Logo)(() => ({
    width: 140,
    height: 40,
}));

export const MobileMenuLogo = styled(Logo)(() => ({
    width: 100,
    height: 40,
}));

export default Logo;
