import { FC, ReactElement } from 'react';

import { renderDelay } from 'src/constants';
import useDelayedRender from 'src/hooks/useDelayedRender';

interface DelayedRenderProps {
    delay?: number;
    children: ReactElement | ReactElement[];
}

export const DelayedRender: FC<DelayedRenderProps> = ({ delay, children }) =>
    useDelayedRender(delay || renderDelay)(() => children);
