import getOptionKey from 'src/utils/language/getOptionKey';

export const sortByOptionLabel = (label: string) => (a, b) => {
    const x = getOptionKey(a, label);
    const y = getOptionKey(b, label);
    // eslint-disable-next-line no-nested-ternary
    return x < y ? -1 : x > y ? 1 : 0;
};

export const sortByOptionLabelPair = (label1: string, label2: string) => (a, b) => {
    const x = getOptionKey(a, label1);
    const y = getOptionKey(b, label1);
    if (x === y) {
        const u = getOptionKey(a, label2);
        const v = getOptionKey(b, label2);
        // eslint-disable-next-line no-nested-ternary
        return u < v ? -1 : u > v ? 1 : 0;
    }
    // eslint-disable-next-line no-nested-ternary
    return x < y ? -1 : x > y ? 1 : 0;
};
