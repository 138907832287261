import ChecklistDrawer from 'src/project/checklist';
import { useNavigate } from 'src/sagas';

import { NextAction, PreviousAction, SaveDraftAction, ValidateAction } from './components';
import SubmitRFP from './projectSummary/submitRFP';
import { useFullProject, useProjectLoading, useProjectStep } from './state/hook';

interface ProjectPageHeaderProps {
    onValidate?: () => Promise<void>;
    onSave?: () => void;
    submitProject?: boolean;
    withNavigation?: boolean;
    withChecklist?: boolean;
}

const useProjectActions = ({
    onValidate,
    onSave,
    submitProject = false,
    withNavigation = true,
    withChecklist = false,
}: ProjectPageHeaderProps) => {
    const { nextStep, previousStep } = useProjectStep();
    const fullProject = useFullProject();

    const navigate = useNavigate();
    const handleNext = () => {
        navigate(nextStep);
    };

    const handlePrevious = () => {
        navigate(previousStep);
    };

    const isLoading = useProjectLoading();

    return [
        withChecklist && <ChecklistDrawer />,
        onValidate && <ValidateAction onClick={onValidate} />,
        onSave && <SaveDraftAction disabled={isLoading} loading={isLoading} onClick={onSave} />,
        withNavigation && previousStep && (
            <PreviousAction disabled={isLoading} onClick={handlePrevious} />
        ),
        withNavigation && nextStep && <NextAction disabled={isLoading} onClick={handleNext} />,
        submitProject && <SubmitRFP project={fullProject} />,
    ];
};

export default useProjectActions;
