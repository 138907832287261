import { fetchQuery } from 'react-relay';

import { IndustryStandardSelectAllIndustryStandardsQuery } from 'src/components/common/select/IndustryStandardSelect';
import relayEnvironment from 'src/relayEnvironment';
import { mapEdgesToList } from 'src/selectors/useGenericQuery';

import Fetcher from '../fetcher';

const fetchIndustryStandards = () =>
    new Promise((resolve, reject) => {
        const industryStandardsObservable = fetchQuery(
            relayEnvironment,
            IndustryStandardSelectAllIndustryStandardsQuery,
            {},
        );

        const subscription = industryStandardsObservable.subscribe({
            next: (responseData) => {
                resolve(mapEdgesToList(responseData.allPublishedIndustryStandards));
                subscription?.unsubscribe();
            },
            complete: () => {},
            error: (error) => {
                reject(error);
            },
        });
    });

export const IndustryStandardsFetcher = new Fetcher(
    'industryStandards/list',
    fetchIndustryStandards,
);
