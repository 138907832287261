import { t } from '@lingui/macro';

import useFormatting from 'src/hooks/useFormatting';

export const getNotificationTitle = (type, notificationContext, dateFormatterByUserLocale) => {
    let proposalDueDate = new Date(notificationContext).getTime();
    if (proposalDueDate) {
        const formattedproposalDueDate = dateFormatterByUserLocale(proposalDueDate);
        proposalDueDate = formattedproposalDueDate;
    }

    const {
        clientCompanyAlias,
        projectDueDate,
        description,
        userFirstName,
        userLastName,
        projectOwnerFirstName,
        projectName,
        projectOwnerLastName,
        supplierUserFirstName,
        supplierFirstName,
        supplierLastName,
        newStatus,
        supplierUserLastName,
        supplierUserFullName,
        requesterFirstName,
        requesterLastName,
        supplierCompanyAlias,
        supplierCompany,
        projectId,
        clientUserLastName,
        clientUserFirstName,
        assigningUserFirstName,
        assigningUserLastName,
    } = notificationContext;
    let titlePart1;
    let titlePart2;
    let title;
    switch (type) {
        case 'NEWQUESTIONS':
            return t`New questions have been posted`;
        case 'NEWANSWERSTOQUESTIONS':
            return t`New answers have been posted`;
        case 'NEWANNOUNCEMENT':
            return t`New announcement`;
        case 'NEWCLIENTCALLBACKWASPOSTED':
            return t`A supplier requests a call-back`;
        case 'NEWORGANIZATIONACCOUNT':
            return t`A new account was registered`;
        case 'NEWUSERACCOUNTACTIVATED':
            return t`A new account was activated`;
        case 'NEWSUPPORTQUESTION':
            return t`A new support request was posted`;
        case 'SUCCESSFULPROPOSALSUBMISSION':
        case 'SUCCESSFULRATECARDPROPOSALSUBMISSION':
            return t`Your proposal was submitted successfully`;
        case 'PARTICIPATIONCANCELLEDBYVENDOR':
            title = t`has rejected participation`;
            return `${supplierCompanyAlias} - ${supplierUserFullName} ${title}`;
        case 'PROPOSALINVITATION':
            title = t`has invited you to a project RFP - Proposal due:`;
            return `${clientCompanyAlias} ${title} ${proposalDueDate}`;
        case 'REMINDERFORINVITETORFP':
            titlePart1 = t`Reminder:`;
            titlePart2 = t`has invited you to a project RFP - Proposal due:`;
            return `${titlePart1} ${clientCompanyAlias} ${titlePart2} ${proposalDueDate}`;
        case 'REMINDERFORCONFIRMATIONOFRRFP':
            title = t`is awaiting your confirmation`;
            return `${clientCompanyAlias} ${title}`;
        case 'USERASSIGNEDTORFP':
            title = t`has added you to a project RFP`;
            return `${userFirstName} ${userLastName} ${title}`;
        case 'INVITETOCONCEPTEVALUATION':
            title = t`has invited you to evaluate concepts`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'INVITETOPITCHEVALUATION':
            title = t`has invited you to evaluate pitches`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'EVALUATIONREMINDER':
            titlePart1 = t`Reminder:`;
            titlePart2 = t`has invited you to evaluate proposals`;
            return `${titlePart1} ${projectOwnerFirstName} ${projectOwnerLastName} ${titlePart2}`;
        case 'PITCHEVALUATIONREMINDER':
            titlePart1 = t`Reminder:`;
            titlePart2 = t`has invited you to evaluate pitches`;
            return `${titlePart1} ${projectOwnerFirstName} ${projectOwnerLastName} ${titlePart2}`;
        case 'PITCHINVITATION':
            title = t`has invited you to a pitch presentation`;
            return `${userFirstName} ${userLastName} ${t`has invited you to a pitch presentation`}`;
        case 'NEWPROJECTREQUEST':
            title = t`has posted a new project via the request form`;
            return `${requesterFirstName} ${requesterLastName} ${t`has posted a new project via the request form`}`;
        case 'NEWPROPOSALSUBMITTEDRFP':
            title = t`has submitted a proposal`;
            return `${supplierUserFirstName} ${supplierUserLastName} ${t`from`} ${supplierCompanyAlias} ${title}`;
        case 'PROPOSALAWARDINGTOSUPPLIER':
            title = t`has awarded a project to you`;
            return `${userFirstName} ${userLastName} ${t`of`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALAWARDINGCLIENTINTERNAL':
            title = t`Has been awarded with the project`;
            return `${projectId} | ${supplierCompanyAlias} | ${title}`;
        case 'PROPOSALCANCELLATIONBYSUPPLIER':
            title = t`Cancelled Participation`;
            return `${supplierCompanyAlias} ${'| Cancelled Participation'}`;
        case 'REQUESTNEWPROPOSAL':
            title = t`has requested a new proposal from you`;
            return `${clientUserFirstName} ${clientUserLastName} ${title}`;
        case 'NEWPROJECTSTATUS':
            title = t`has changed the status to`;
            return `${clientUserFirstName} ${clientUserLastName} ${title} ${newStatus}`;
        case 'PROJECTAWARDINGDUE':
            titlePart1 = t`The awarding of project`;
            titlePart2 = t`is due today`;
            return `${titlePart1} ${projectId} ${projectName} ${titlePart2}`;
        case 'PROJECTAWARDINGDUEDATEREMINDER':
            titlePart1 = t`The awarding of project`;
            titlePart2 = t`is 2 weeks overdue`;
            return `${titlePart1} ${projectId} ${projectName} ${titlePart2}`;
        case 'SUPPLIERDISMISSEDFROMRFP':
            title = t`has dismissed you from the project`;
            return `${clientCompanyAlias} ${title}`;
        case 'PROPOSALLOSTMESSAGETOSUPPLIER':
            title = t`has awarded the project to someone else`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`of`} ${clientCompanyAlias} ${title}`;
        case 'NEWPROJECTCREATEDRFP':
            title = t`has created a new project`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'NEWPROJECTSUBMITTEDRFP':
            title = t`has submitted a new project as a RFP`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'SUPPLIERREJECTSTNCS':
            title = t`has rejected to participate in project RFP`;
            return `${supplierCompany} ${title} ${projectId} ${projectName}`;
        case 'SUPPLIERCONFIRMSTNCS':
            title = t`has accepted the terms for project`;
            return `${supplierCompany} ${title} ${projectId} ${projectName}`;
        case 'PITCHINVITATIONACCEPTED':
            title = t`has accepted your pitch invitation for project`;
            return `${supplierCompany} ${title} ${projectId} ${projectName}`;
        case 'PITCHINVITATIONDECLINED':
            title = t`has declined your pitch invitation for project`;
            return `${supplierCompany} ${title} ${projectId} ${projectName}`;
        case 'TIMELINECHANGE':
            title = t`has changed the timeline`;
            return `${clientUserFirstName} ${clientUserLastName} ${title}`;
        case 'CONDITIONSACCEPTED':
            title = t`of your organization has accepted the conditions for this project`;
            return `${supplierFirstName} ${supplierLastName} ${title}`;
        case 'REMINDERFORPROPOSALDUEDATE':
            title = t`is expecting your proposal until`;
            return `${clientCompanyAlias} ${title} ${projectDueDate}`;
        case 'PROPOSALDUEDATEREMINDER':
            return t`Reminder: Proposal Due in 2 Days`;
        case 'NEWPROJECTCREATEDRFQ':
            title = t`has created a new project`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'NEWPROJECTSUBMITTEDRFQ':
            title = t`has submitted a new rate card tender`;
            return `${projectOwnerFirstName} ${projectOwnerLastName} ${title}`;
        case 'PROJECTINVITATIONACCEPTEDRFQ':
            title = t`has confirmed to participate in your RFQ`;
            return `${userFirstName} ${userLastName} ${t`from`} ${supplierCompanyAlias} ${title}`;
        case 'NEWPROJECTSTATUSRFQ':
            title = t`has changed the status to`;
            return `${clientUserFirstName} ${clientUserLastName} ${title} ${newStatus}`;
        case 'EDITMODEREMINDER':
            titlePart1 = t`Your RFQ`;
            titlePart2 = t`is still in "Edit Mode`;
            return `${titlePart1} ${projectName} ${titlePart2}`;
        case 'PROPOSALPARTICIPATIONCONFIRMATION':
            title = t`has confirmed their participation`;
            return `${supplierCompanyAlias} ${title}`;
        case 'INVITETORFQ':
            title = t`has invited you to a rate card RFQ`;
            return `${clientCompanyAlias} ${title}`;
        case 'REMINDERFORINVITETORFQ':
            title = t`has invited you to a rate card RFQ`;
            return `${t`Reminder`}: ${clientCompanyAlias} ${title}`;
        case 'REMINDERFORCONFIRMATIONOFRFQ':
            title = t`has invited you to a rate card RFQ - Reminder: Intent to submit a proposal`;
            return `${clientCompanyAlias} ${title}`;
        case 'USERASSIGNEDTORFQ':
            title = t`has added you to a rate card RFQ`;
            return `${assigningUserFirstName} ${assigningUserLastName} ${title}`;
        case 'NEWPROPOSALSUBMITTEDRFQ':
            title = t`has submitted a new proposal to a rate card RFQ`;
            return `${userFirstName} ${userLastName} ${t`from`} ${supplierCompanyAlias} ${title}`;
        case 'NEWRATESSUBMITTED':
            title = t`has submitted new rates to a rate card RFQ`;
            return `${userFirstName} ${userLastName} ${t`from`} ${supplierCompanyAlias} ${title}`;
        case 'RATESCONFIRMED':
            title = t`has confirmed your rates`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'RATESREJECTED':
            title = t`has rejected your rates`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'RATEREVISIONREQUESTED':
            title = t`has requested you to revise your rates`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALREVISIONREQUESTED':
            title = t`has requested you to revise your proposal`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALACCEPTED':
            title = t`has accepted your proposal`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALDELETED':
            title = t`has deleted your proposal`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALDISMISSED':
            title = t`has dismissed your proposal`;
            return `${clientUserFirstName} ${clientUserLastName} ${t`from`} ${clientCompanyAlias} ${title}`;
        case 'PROPOSALAWARDINGDUE':
            titlePart1 = t`The awarding of project`;
            titlePart2 = t`is due today`;
            return `${titlePart1} ${projectName} ${titlePart1}`;
        case 'PROPOSALAWARDINGDUEDATEREMINDER':
            titlePart1 = t`The awarding of project`;
            titlePart2 = t`is 2 weeks overdue`;
            return `${titlePart1} ${projectName} ${titlePart1}`;
        case 'DEPRECATED':
            return description;
        case 'DEPRECATED_72':
            return description;
        default:
            return '';
    }
};

export const getNotificationTitleWithoutDate = (type, notificationContext) => {
    const { clientCompanyAlias } = notificationContext;
    let titlePart1;
    let titlePart2;
    switch (type) {
        case 'PROPOSALINVITATION':
            titlePart1 = t``;
            titlePart2 = t`has invited you to a project RFP`;
            return `${titlePart1} ${clientCompanyAlias} ${titlePart2}`;

        case 'REMINDERFORINVITETORFP':
            titlePart1 = t`Reminder:`;
            titlePart2 = t`has invited you to a project RFP`;
            return `${titlePart1} ${clientCompanyAlias} ${titlePart2}`;
        default:
            return '';
    }
};

export const formatNotificationTitle = (notification) => {
    const { dateFormatterByUserLocale } = useFormatting();

    let title = getNotificationTitle(
        notification.notificationType,
        notification.context,
        dateFormatterByUserLocale,
    );

    if (
        notification.notificationType === 'PROPOSALINVITATION' ||
        notification.notificationType === 'REMINDERFORINVITETORFP'
    ) {
        if (!notification.context.proposalDueDate || notification.context.proposalDueDate === '') {
            return getNotificationTitleWithoutDate(
                notification.notificationType,
                notification.context,
            );
        }
        const proposalDueDate = new Date(notification.context.proposalDueDate).getTime();
        const formattedproposalDueDate = dateFormatterByUserLocale(proposalDueDate);
        const context = {
            ...notification.context,
            proposalDueDate: formattedproposalDueDate,
        };
        title = getNotificationTitle(
            notification.notificationType,
            context,
            dateFormatterByUserLocale,
        );
    }

    return title;
};
