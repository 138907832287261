export const environmentIsDemo = () => {
    if (window.location.host === 'demo-next.apadua.com') {
        return true;
    }
    return false;
};

export const environmentIsProduction = () => {
    if (window.location.host === 'next.apadua.com') {
        return true;
    }
    return false;
};
