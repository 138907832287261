import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';

import { FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import { NumberParser } from 'src/utils/formattingUtils';

export const FormikNumberTextField: FC<any> = (props: any) => {
    const { currencySymbol, integer, form, field, ...otherProps } = props;
    const { name, value } = field;
    const { setFieldTouched, setFieldValue } = useFormikContext();
    const { user } = useAuth();

    const [displayValue, setDisplayValue] = useState(value);

    // Parse and format for display, handling locale-specific settings
    const parseAndFormatForDisplay = (val: string | number) => {
        const parsed = new NumberParser(user?.formattingLocale).parse(val);
        return !Number.isNaN(parsed)
            ? new Intl.NumberFormat(user?.formattingLocale, { maximumFractionDigits: 20 }).format(
                  parsed,
              )
            : '';
    };

    // Effect to update display value if locale or main value changes
    useEffect(() => {
        setDisplayValue(parseAndFormatForDisplay(value));
    }, [user?.formattingLocale, value]);

    const error = form.errors[field.name];
    const { 'data-testid': dataTest } = props;

    return (
        <FormControl fullWidth {...(dataTest ? { 'data-testid': dataTest } : {})}>
            <TextField
                fullWidth
                value={displayValue}
                {...otherProps}
                error={!!error}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                }}
                onChange={(event) => {
                    const rawInput = event.target.value.replace(/[.,\s]/g, '');
                    const newParsedValue = new NumberParser(user?.formattingLocale).parse(rawInput);

                    // eslint-disable-next-line
                    if (isNaN(newParsedValue)) return;

                    // Format the display value with locale settings for feedback
                    setDisplayValue(parseAndFormatForDisplay(rawInput));

                    // Set form field value to the raw integer value
                    setFieldTouched(name);
                    setFieldValue(name, newParsedValue);
                }}
            />
            {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
    );
};
