import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { CurrenciesFetcher } from './thunk';

export type CurrenciesState = {
    data: Array<any>;
    error: any;
    status: 'idle' | 'loading' | 'failed' | 'succeeded';
};

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        CurrenciesFetcher.reducers(builder);
    },
});

export default currenciesSlice.reducer;
