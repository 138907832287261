import { FC } from 'react';

import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardContent, CardHeader, Divider, Skeleton } from '@mui/material';

import { DelayedRender } from 'src/components/DelayedRender';

export const RatecardUserManagmentSkeleton: FC = () => {
    const { i18n } = useLingui();

    return (
        <DelayedRender>
            <Card variant="outlined">
                <CardHeader
                    title={i18n._('User management')}
                    action={
                        <Box>
                            <Button sx={{ mr: 1 }} variant="contained" color="primary" size="small">
                                <AddIcon fontSize="small" sx={{ mr: 1 }} />
                                {i18n._('Add existing user')}
                            </Button>
                            <Button sx={{ mr: 1 }} variant="contained" color="primary" size="small">
                                <AddIcon fontSize="small" sx={{ mr: 1 }} />
                                {i18n._('Invite new user')}
                            </Button>
                        </Box>
                    }
                />
                <Divider />
                <CardContent>
                    <Skeleton sx={{ my: 2 }} />
                    <Divider />
                    <Skeleton sx={{ my: 2 }} />
                    <Divider />
                    <Skeleton sx={{ my: 2 }} />
                    <Divider />
                    <Skeleton sx={{ my: 2 }} />
                    <Divider />
                    <Skeleton sx={{ my: 2 }} />
                    <Divider />
                </CardContent>
            </Card>
        </DelayedRender>
    );
};
