import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import HighlightedFeeAmount from 'src/components/ratecardproposals/highlightedFeeAmount';
import useAuth from 'src/hooks/useAuth';
import useFormatting from 'src/hooks/useFormatting';
import { NumberParser } from 'src/utils/formattingUtils';

import {
    getNotificationDescription,
    getNotificationLongDescription,
} from './getNotificationDescription';

const TextAlignStartBox = styled('div')(() => ({
    textAlign: 'start',
}));

interface Props {
    notification: any;
    variant?:
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'button'
        | 'overline'
        | 'inherit';
    color?: string;
}

export const NotificationLongContentFormatter: FC<Props> = ({
    notification,
    variant = 'body1',
    color = 'textPrimary',
}: Props) => {
    const { i18n } = useLingui();
    const { user } = useAuth();
    const { dateFormatterByUserLocale, dateFormatterShortByUserLocale } = useFormatting();

    const feeFlatForParticipation = new NumberParser(user.formattingLocale).format(
        notification?.context?.feeFlatForParticipation,
        'EUR',
    );
    const {
        userFirstName,
        userLastName,
        userCompanyName,
        clientRejectionReason,
        cancellationReasonText,
        feedbackText,
        textMessageOfRequest,
        pitchInvitationText,
        userEmailAddress,
        userPhoneNumber,
        phoneNumberFromRequestForm,
    } = notification.context;
    // X007 - New client call-back was posted
    if (notification.notificationType === 'NEWCLIENTCALLBACKWASPOSTED') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('User email: ')}</b>
                    {notification.context.userEmailAddress}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('User phone: ')}</b>
                    {notification.context.userPhoneNumber
                        ? notification.context.userPhoneNumber
                        : '-'}
                </Typography>
                {variant === 'body1' && <br />}
                {/* Request */}
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Submitted request:')}</b>
                </Typography>
                <Typography variant={variant} color={color} display="block" sx={{ mx: 2 }}>
                    <i>{notification.context.requestMessage}</i>
                </Typography>
            </TextAlignStartBox>
        );
    }

    // X009 - New organization account
    if (notification.notificationType === 'NEWORGANIZATIONACCOUNT') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('Account information')}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User first name: ')}
                    {userFirstName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User last name: ')}
                    {userLastName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User email address: ')}
                    {userEmailAddress}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User company name: ')}
                    {userCompanyName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User phone number: ')}
                    {userPhoneNumber}
                </Typography>
            </TextAlignStartBox>
        );
    }

    // X010 - New user account activated
    if (notification.notificationType === 'NEWUSERACCOUNTACTIVATED') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('Account information')}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User first name: ')}
                    {userFirstName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User last name: ')}
                    {userLastName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User email address: ')}
                    {userEmailAddress}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User company name: ')}
                    {userCompanyName}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User phone number: ')}
                    {userPhoneNumber}
                </Typography>
            </TextAlignStartBox>
        );
    }

    // X011 - New support question
    if (notification.notificationType === 'NEWSUPPORTQUESTION') {
        const { requestUserFirstName, requestUserLastName, requestUserCompanyName, date } =
            notification.context;
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('Request information')}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('A new support question was sent by user ')}
                    {requestUserFirstName} {requestUserLastName}
                    {i18n._(', from company')}
                    {requestUserCompanyName}
                    {i18n._('submitted on ')}
                    {date}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('User phone: ')}
                    {phoneNumberFromRequestForm}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('Submitted request:')}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {textMessageOfRequest}
                </Typography>
            </TextAlignStartBox>
        );
    }

    // P009 - Pitch invitation
    if (notification.notificationType === 'PITCHINVITATION') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Pitch date/time: ')}</b>
                    {notification.context.pitchDate}
                </Typography>
                {variant === 'body1' && <br />}
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Invitation text:')}</b>
                </Typography>
                <Typography variant={variant} color={color} display="block" sx={{ mx: 2 }}>
                    <i>{pitchInvitationText}</i>
                </Typography>
            </TextAlignStartBox>
        );
    }

    // P014 - Proposal awarding client internal
    if (notification.notificationType === 'PROPOSALAWARDINGCLIENTINTERNAL') {
        // awarding date format: DD. MONTH YYYY HH:MM:SS Timezone of project
        // planned start date format: DD. MONTH YYYY
        const plannedStartDate = new Date(notification.context.plannedStartDate).getTime();
        const awardingDate = new Date(notification.context.awardingTimestamp).getTime();

        const formattedPlanedStartDate = dateFormatterShortByUserLocale(plannedStartDate);
        const formattedAwardingDate = dateFormatterByUserLocale(awardingDate);

        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Awarding User: ')}</b>
                    {`${notification.context.awardingUserFirstName} ${notification.context.awardingUserLastName} (${notification.context.awardingUserEmail})`}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {formattedAwardingDate && (
                        <>
                            <b>{i18n._('Awarding Date/Timestamp: ')}</b>
                            {formattedAwardingDate}
                        </>
                    )}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {/* supplierCompanyAlias */}
                    <b>{i18n._('Winning Service Provider: ')}</b>
                    {notification.context.supplierCompanyAlias}
                </Typography>
                <br />
                {/* Address */}
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Supplier Address: ')}</b>
                </Typography>
                <Box sx={{ ml: 2 }}>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierCompanyName}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierAddressLine1}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierAddressLine2}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierAddressLine3}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierZip}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierCity}
                    </Typography>
                    <Typography variant={variant} color={color} display="block">
                        {notification.context.supplierVat}
                    </Typography>
                </Box>
                <br />
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('Awarded Project Volume: ')}</b>
                    {`${notification.context.awardedProjectVolume} ${notification.context.projectCurrency}`}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {notification.context.plannedStartDate && (
                        <>
                            <b>{i18n._('Planned Start Date: ')}</b>
                            {formattedPlanedStartDate}
                        </>
                    )}
                </Typography>
            </TextAlignStartBox>
        );
    }

    if (notification.notificationType === 'PROPOSALCANCELLATIONBYSUPPLIER') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    {i18n._('Cancellation Reason:')}
                </Typography>
                <Typography variant={variant} color={color} display="block">
                    {cancellationReasonText}
                </Typography>
            </TextAlignStartBox>
        );
    }

    // P021 - Proposal lost message to supplier
    if (notification.notificationType === 'PROPOSALLOSTMESSAGETOSUPPLIER') {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    <b>{i18n._('The client has left the following feedback:')}</b>
                </Typography>
                <Typography variant={variant} color={color} display="block" sx={{ mx: 2 }}>
                    <i>{feedbackText}</i>
                </Typography>
            </TextAlignStartBox>
        );
    }

    // P029 - Conditions accepted
    if (notification.notificationType === 'CONDITIONSACCEPTED') {
        return (
            <TextAlignStartBox>
                {((notification?.context?.feeCap !== 0 &&
                    (notification?.context?.feePercentageUponWinning !== 0 ||
                        notification?.context?.feePercentageUponParticipation !== 0)) ||
                    notification?.context?.feeFlatForParticipation !== 0) && (
                    <Typography variant={variant} color={color} display="block">
                        <b>{i18n._('The following conditions apply:')}</b>
                    </Typography>
                )}
                {notification?.context?.feePercentageUponWinning !== 0 && (
                    <Typography color="textPrimary" variant="subtitle1">
                        {i18n._(
                            'Success fee: The use of the software, viewing of the request and interaction with the customer are free of charge until a successful project award. This consent does not commit you to submitting an offer. In case you are awarded with the project by the client after submitting your proposal, a success fee will be charged. The fee is calculated proportionally to the bid volume submitted by you and confirmed by the client. The fee amounts to',
                        )}
                        {': '}
                        {notification?.context?.feePercentageUponWinning && (
                            <HighlightedFeeAmount
                                amount={`${notification?.context?.feePercentageUponWinning}%`}
                            />
                        )}
                    </Typography>
                )}
                {notification?.context?.feePercentageUponParticipation !== 0 && (
                    <Typography color="textPrimary" variant="subtitle1">
                        {i18n._(
                            'Participation fee: The use of the software, viewing of the request and interaction with the client are free of charge until an offer is submitted. This consent does not commit you to submitting an offer. In case you submit a proposal in response to this request, you will be charged a percentage participation fee. The fee is calculated proportionally on the proposal volume you submit. The fee amounts to',
                        )}
                        {': '}
                        {notification?.context?.feePercentageUponParticipation && (
                            <HighlightedFeeAmount
                                amount={`${notification?.context?.feePercentageUponParticipation}%`}
                            />
                        )}
                    </Typography>
                )}
                {notification?.context?.feeFlatForParticipation !== 0 && (
                    <Typography color="textPrimary" variant="subtitle1">
                        {i18n._(
                            'Fixed fee: There is a flat fee for this request. The fee amounts to',
                        )}
                        {': '}
                        <b>
                            {feeFlatForParticipation && (
                                <HighlightedFeeAmount amount={feeFlatForParticipation} />
                            )}
                        </b>
                    </Typography>
                )}
                {notification?.context?.feeCap !== 0 &&
                    (notification?.context?.feePercentageUponWinning !== 0 ||
                        notification?.context?.feePercentageUponParticipation !== 0) && (
                        <Typography color="textPrimary" variant="subtitle1">
                            {i18n._('The fees incurred for this request are capped at')}
                            {': '}
                            <b>
                                {new NumberParser(user.formattingLocale).format(
                                    Number.parseInt(
                                        notification?.context?.feeCap.substring(
                                            0,
                                            notification?.context?.feeCap.length - 4,
                                        ),
                                        10,
                                    ),
                                    'EUR',
                                )}
                            </b>{' '}
                            {i18n._('')}
                        </Typography>
                    )}
                {(notification?.context?.feePercentageUponWinning !== 0 ||
                    notification?.context?.feePercentageUponParticipation !== 0 ||
                    notification?.context?.feeFlatForParticipation !== 0) && (
                    <Typography color="textPrimary" variant="subtitle1">
                        {i18n._(
                            'VAT for intra-community products and services will be charged accordingly.',
                        )}
                    </Typography>
                )}
            </TextAlignStartBox>
        );
    }

    // P020 - Supplier dismissed from RFP
    // Q008 - Rates rejected
    // Q009 - Rate revision requested
    // Q010 - Proposal revision requested
    if (
        [
            'SUPPLIERDISMISSEDFROMRFP',
            'RATESREJECTED',
            'RATEREVISIONREQUESTED',
            'PROPOSALREVISIONREQUESTED',
        ].includes(notification.notificationType)
    ) {
        return (
            <TextAlignStartBox>
                <Typography variant={variant} color={color} display="block">
                    <b>{getNotificationDescription(notification.notificationType, i18n)}</b>
                </Typography>
                <Typography variant={variant} color={color} display="block" sx={{ mx: 2 }}>
                    <i>{clientRejectionReason}</i>
                </Typography>
            </TextAlignStartBox>
        );
    }

    // Q001 - Invite to RFQ
    // Q002 - Reminder for Invite to RFQ
    // Q003 - Reminder for confirmation of RFQ
    if (
        ['INVITETORFQ', 'REMINDERFORINVITETORFQ', 'REMINDERFORCONFIRMATIONOFRFQ'].includes(
            notification.notificationType,
        )
    ) {
        // proposal due date format: DD. Month_EN YYYY / DD. Month_DE YYYY
        const date = notification.context.proposalDueDate
            ? new Date(notification.context.proposalDueDate).getTime()
            : null;

        return (
            <>
                {date && (
                    <Box display="flex">
                        <Typography variant={variant} color={color} display="block">
                            <b>
                                {getNotificationLongDescription(
                                    notification.notificationType,
                                    i18n,
                                )}
                            </b>
                            {dateFormatterShortByUserLocale(date)}
                        </Typography>
                    </Box>
                )}
            </>
        );
    }

    return (
        <Typography variant={variant} color={color} display="block">
            {getNotificationLongDescription(notification.notificationType, i18n)}
        </Typography>
    );
};
