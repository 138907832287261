import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';

import useWindowSize from 'src/utils/useWindowSize';

const Drawer = ({ children, ...rest }: DrawerProps) => {
    const windowSize = useWindowSize();

    return (
        <MuiDrawer
            PaperProps={{
                sx: {
                    width: windowSize.width >= 520 ? 520 : '100%',
                },
            }}
            {...rest}
        >
            {children}
        </MuiDrawer>
    );
};

export default Drawer;
