import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { api } from 'src/services/apiService';
import { logoutUserSessionExpired } from 'src/utils/login';

async function fetchRelay(params, variables) {
    try {
        const { data } = await api.post('/graphql', {
            query: params.text,
            variables,
        });
        return data;
    } catch (error) {
        if (error?.response?.status === 401) {
            return logoutUserSessionExpired();
        }
        throw error;
    }
}

// used in redux-thunk only right now // unrelated with RelayProvider.ts
export const environment = new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});
