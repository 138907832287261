import { FC } from 'react';

import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Button, Divider, Drawer, IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NotificationIcon } from 'src/components/notifications/NotificationIcon';
import { NotificationLink } from 'src/components/notifications/NotificationLinkFormatter';
import { NotificationLongContentFormatter } from 'src/components/notifications/NotificationLongContentFormatter';
import {
    NotificationDateBox,
    NotificationFlexBoxColumn,
    NotificationFlexGrowBox,
    NotificationHeaderBox,
    NotificationHeadingBox,
} from 'src/components/notifications/ReusableStyledComponents';
import { formatNotificationTitle } from 'src/components/notifications/formatNotificationTitle';
import { notificationOptions } from 'src/components/notifications/notificationTypeProps';
import { ElapsedTimeWithTooltip } from 'src/utils/time';
import useWindowSize from 'src/utils/useWindowSize';

import { getNotificationHeading } from './getNotificationHeading';

const NotificationBox = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
}));

interface Props {
    notification: any;
    closeDrawer: () => void;
}

export const NotificationDrawer: FC<Props> = ({ notification, closeDrawer }: Props) => {
    const { i18n } = useLingui();
    const windowSize = useWindowSize();
    const theme = useTheme();

    const smallScreen = windowSize.width <= theme.breakpoints.values.sm;

    const notificationProps = notificationOptions[notification.notificationType];
    const longFormat = notificationProps?.longFormat;
    const notificationLink = notificationProps?.link;

    return (
        <Drawer
            anchor="right"
            open
            style={{ zIndex: 1450 }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: windowSize.width >= 520 ? 520 : '100%',
                },
            }}
        >
            <Box sx={{ mx: 2, mb: 2, mt: 0 }}>
                <Box
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 16,
                    }}
                >
                    <IconButton aria-label="close" onClick={closeDrawer} data-testid="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {notification && (
                    <NotificationBox>
                        <NotificationHeaderBox>
                            <NotificationIcon notificationType={notification.notificationType} />
                            <NotificationFlexBoxColumn>
                                <NotificationHeadingBox>
                                    <NotificationFlexGrowBox>
                                        <Typography variant="body2" color="primary" align="justify">
                                            <b>{getNotificationHeading(notification)}</b>
                                        </Typography>
                                    </NotificationFlexGrowBox>
                                    {/* desktop - notification date */}
                                    {!smallScreen && (
                                        <NotificationDateBox>
                                            <ElapsedTimeWithTooltip
                                                start={new Date(notification.created)}
                                            />
                                        </NotificationDateBox>
                                    )}
                                </NotificationHeadingBox>
                                <Typography variant="body2" color="primary" align="justify">
                                    {formatNotificationTitle(notification)}
                                </Typography>
                                {/* mobile - notification date and icon */}
                                {smallScreen && (
                                    <NotificationDateBox>
                                        <ElapsedTimeWithTooltip
                                            start={new Date(notification.created)}
                                        />
                                    </NotificationDateBox>
                                )}
                            </NotificationFlexBoxColumn>
                        </NotificationHeaderBox>
                        <Divider sx={{ my: 2 }} />
                        {notificationLink && (
                            <NotificationLink notification={notification}>
                                <Button
                                    sx={{ width: '100px', mb: 2 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={closeDrawer}
                                    data-testid="openNotificationDetailsLink"
                                    startIcon={<LaunchIcon />}
                                >
                                    {i18n._('Open')}
                                </Button>
                            </NotificationLink>
                        )}
                        {longFormat && (
                            <>
                                <NotificationLongContentFormatter notification={notification} />
                            </>
                        )}
                    </NotificationBox>
                )}
            </Box>
        </Drawer>
    );
};
