/* tslint:disable */

/* eslint-disable */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { FileError } from 'react-dropzone';
import { ErrorCode } from 'react-dropzone';
import toast from 'react-hot-toast';

import { t } from '@lingui/macro';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

import { FileHeader } from 'src/components/announcements/FileHeader';
import { api } from 'src/services/apiService';
import DismissibleToast from 'src/utils/DismissibleToast';

export interface FileUploadWithProgressProps {
    setFileIds: CallableFunction;
    uploadApiUrl: string;
    parent?: string;
    file: File;
    onUpload: CallableFunction;
    errors: FileError[];
}

const ErrorLinearProgress = withStyles((theme) =>
    createStyles({
        bar: {
            backgroundColor: theme.palette.error.main,
        },
    }),
)(LinearProgress);

export function FileUploadWithProgress({
    setFileIds,
    uploadApiUrl,
    parent,
    file,
    onUpload,
    errors,
}: FileUploadWithProgressProps) {
    const [progress, setProgress] = useState(0);

    const uploadFile = async (
        currfile: File,
        fileErrors: FileError[],
        onProgress: (percentage: number) => void,
    ) => {
        if (fileErrors.length) {
            throw new Error(fileErrors);
        }
        const data = new FormData();
        data.append('file', currfile);
        data.append('parent', parent);
        return api.post(uploadApiUrl, data, {
            onUploadProgress: (progressEvent) => {
                onProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
        });
    };

    useEffect(() => {
        function upload() {
            uploadFile(file, errors, setProgress)
                .then((resp) => {
                    onUpload(file, [], resp);
                    setFileIds((current) => [...current, resp]);
                })
                .catch(() => {
                    onUpload(file, errors);
                    const errorCode = errors[0].code;
                    let errorMessage = '';

                    switch (errorCode) {
                        case ErrorCode.FileInvalidType:
                            errorMessage = t`Invalid file type.`;
                            break;

                        default:
                            errorMessage = t`Something went wrong while uploading your file.`;
                    }

                    toast.error(DismissibleToast(errorMessage));
                });
        }

        upload();
    }, []);

    return (
        <Grid item>
            <FileHeader file={file} />
            {errors.length === 0 ? (
                <LinearProgress variant="determinate" value={progress} />
            ) : (
                <ErrorLinearProgress variant="determinate" value={progress} />
            )}
            {errors.map((error) => (
                <div key={error.code}>
                    <Typography color="error">{error.message}</Typography>
                </div>
            ))}
        </Grid>
    );
}
