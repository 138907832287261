import { ReactNode, useContext, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import { Trans } from '@lingui/macro';

import { useQuery } from 'src/components/rfp/QuoteProvider';
import { QuoteContext } from 'src/contexts/rfp/QuoteContext';
import { BudgetApprovalStatus, EngagementMode, ProposalStatus } from 'src/project/types';
import { fromRelayId } from 'src/relay/utils';

const useEngangementModeTranslation = (engagementMode: string): ReactNode => {
    const translatedEngagementMode = useMemo(() => {
        if (engagementMode === EngagementMode.on_site) {
            return <Trans>On-Site</Trans>;
        }
        if (engagementMode === EngagementMode.hybrid) {
            return <Trans>Hybrid</Trans>;
        }
        if (engagementMode === EngagementMode.remote) {
            return <Trans>Remote</Trans>;
        }
        return '';
    }, [engagementMode]);

    return translatedEngagementMode;
};

const useBudgetApprovalStatusTranslation = (budgetApprovalStatus: string): ReactNode => {
    const translatedBudgetApprovalStatus = useMemo(() => {
        if (budgetApprovalStatus === BudgetApprovalStatus.unknown) {
            return <Trans>Not relevant</Trans>;
        }
        if (budgetApprovalStatus === BudgetApprovalStatus.pending) {
            return <Trans>Pending</Trans>;
        }
        if (budgetApprovalStatus === BudgetApprovalStatus.approved) {
            return <Trans>Approved</Trans>;
        }
        return '';
    }, [budgetApprovalStatus]);

    return translatedBudgetApprovalStatus;
};

const useProjectProposals = (activeStatesOnly: boolean = true) => {
    const query = useQuery();
    const { quoteQueryRef } = useContext(QuoteContext);
    const quoteQuery = usePreloadedQuery(query, quoteQueryRef);
    const proposals = quoteQuery.sourceQuote?.proposals?.edges?.map((e) => ({
        id: fromRelayId(e.node.id),
        companyName: e.node.company?.companyName,
        isActive: e.node.isActive,
        status: e.node.status,
    }));
    if (activeStatesOnly) {
        return proposals?.filter(
            (proposal) =>
                proposal.isActive &&
                ![
                    ProposalStatus.lost,
                    ProposalStatus.dismissed,
                    ProposalStatus.cancelled,
                    ProposalStatus.declined,
                    ProposalStatus.inactive,
                ].includes(proposal.status),
        );
    }
    return proposals;
};

export { useEngangementModeTranslation, useBudgetApprovalStatusTranslation, useProjectProposals };
