import { FC, useContext, useEffect } from 'react';

import { useNotificationsQuery } from 'src/components/notifications/providers/NotificationContextProvider';
import { NotificationContext } from 'src/contexts/NotificationContext';
import useAuth from 'src/hooks/useAuth';

interface Props {
    children: any;
}

export const NotificationContextInitializer: FC<Props> = ({ children }: Props) => {
    const { setNotifications, setUnreadNotifications, setUnreadNotificationsCount } =
        useContext(NotificationContext);
    const { notificationsQuery, unreadNotificationsCountQuery } = useNotificationsQuery();

    const { user } = useAuth();

    useEffect(() => {
        const notifications = notificationsQuery?.userNotifications?.edges?.map(
            (edge) => edge.node,
        );
        setNotifications(notifications);
        setUnreadNotifications(
            notificationsQuery?.unreadNotifications?.edges?.map((edge) => edge.node),
        );
    }, [notificationsQuery, user]);

    useEffect(() => {
        setUnreadNotificationsCount(unreadNotificationsCountQuery?.unreadNotificationsCount);
    }, [unreadNotificationsCountQuery, user]);

    return <>{children}</>;
};
