import { FC, useLayoutEffect, useRef, useState } from 'react';

import { useLingui } from '@lingui/react';
import { Box, Button, Chip, Divider, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AnnouncementFiles } from 'src/components/announcements/AnnouncementFilesList';
import { UserAvatarWithPopup } from 'src/components/dashboard/UserAvatarWithPopup';
import ChevronDownIcon from 'src/icons/ChevronDown';
import ChevronUpIcon from 'src/icons/ChevronUp';
import 'src/styles/utils.css';
import { ProjectAnnouncement } from 'src/types';
import { DateWithTooltip } from 'src/utils/time';
import useWindowSize from 'src/utils/useWindowSize';

interface Props {
    announcementNode: ProjectAnnouncement;
    timezone: string;
    openFile: any;
}

const maxHeight = 196;
const StyledTypography = styled(Typography)({
    paddingRight: '8px',
    '&.multilineEllipsis': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight,
    },
});

const StyledAuthorBox = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
}));

const StyledAuthorNameBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    overflow: 'auto',
}));

export const AnnouncementHistoryItem: FC<Props> = ({
    announcementNode,
    timezone,
    openFile,
}: Props) => {
    const { i18n } = useLingui();
    const windowSize = useWindowSize();
    const theme = useTheme();

    const announcement = announcementNode;
    const date = new Date(announcement.createdAt);

    const announcementRef = useRef(null);
    const [readMoreOpen, setReadMoreOpen] = useState(false);
    const [overflow, setOverflow] = useState(false);

    useLayoutEffect(() => {
        if (
            announcementRef?.current?.clientHeight !== announcementRef?.current?.scrollHeight ||
            announcementRef?.current?.clientHeight > maxHeight
        ) {
            setOverflow(true);
        } else {
            setOverflow(false);
        }
    }, [announcementRef, windowSize.width, announcement]);

    return (
        <Box data-testid={`announcement-${announcement.id}`} key={announcement.id}>
            <Grid container style={{ margin: '16px' }}>
                <Grid item md={3} xs={12}>
                    <StyledAuthorBox>
                        <UserAvatarWithPopup user={announcement.user} />
                        <StyledAuthorNameBox>
                            <Typography
                                data-testid="userName"
                                color="textSecondary"
                                variant="subtitle1"
                                noWrap
                            >
                                {`${announcement.user.firstName} ${announcement.user.lastName}`}
                            </Typography>
                        </StyledAuthorNameBox>
                    </StyledAuthorBox>
                    <Typography color="textPrimary" variant="subtitle2" sx={{ ml: 5 }}>
                        <DateWithTooltip date={date} timezone={timezone} />
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={9}
                    xs={12}
                    style={{
                        paddingLeft:
                            windowSize.width >= theme.breakpoints.values.lg ? '16px' : '0px',
                        paddingTop:
                            windowSize.width >= theme.breakpoints.values.lg ? '0px' : '16px',
                    }}
                >
                    <Box>
                        <StyledTypography
                            ref={announcementRef}
                            className={
                                readMoreOpen
                                    ? 'formattedTextContainer'
                                    : 'multilineEllipsis formattedTextContainer'
                            }
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                                __html: announcement.content,
                            }}
                            data-testid="historyContent"
                        />
                        {overflow && (
                            <Button
                                sx={{ p: 0 }}
                                endIcon={readMoreOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                onClick={() => {
                                    setReadMoreOpen(!readMoreOpen);
                                }}
                            >
                                {readMoreOpen ? i18n._('Show less') : i18n._('Show more')}
                            </Button>
                        )}
                    </Box>
                    <Box overflow="auto">
                        <AnnouncementFiles
                            announcementFiles={announcement.announcementFiles}
                            openFile={openFile}
                        />
                    </Box>
                </Grid>
                <Grid container xs={12} pt={2} gap={1}>
                    {announcement.proposals.map((proposal) => (
                        <Chip
                            data-testid={`chip-${proposal.companyName}`}
                            key={proposal.id}
                            label={proposal.companyName}
                        />
                    ))}
                </Grid>
            </Grid>
            <Divider />
        </Box>
    );
};
