import { useCallback, useRef } from 'react';

import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

import { CancellationModal } from 'src/components/common/modal/cancellationModal';
import { ModalProps } from 'src/components/common/modal/modal';
import { usePatchProposalAsync } from 'src/proposal/state/hook';

const CancelParticipationModal = (modalProps: ModalProps) => {
    const patchProposal = usePatchProposalAsync();
    const formRef = useRef(null);

    const handleCancellation = useCallback(() => {
        patchProposal(formRef?.current?.values);
    }, [formRef.current]);

    const triggerButtonComponent = ({ handleOpen }) => (
        <LoadingButton variant="outlined" color="primary" onClick={handleOpen}>
            <Typography>
                <Trans>Cancel participation</Trans>
            </Typography>
        </LoadingButton>
    );

    return (
        <CancellationModal
            formRef={formRef}
            onSubmit={handleCancellation}
            triggerButtonComponent={triggerButtonComponent}
            buttonLabel={<Trans>Cancel participation</Trans>}
            modalTitle={<Trans>Please select a cancellation reason</Trans>}
            {...modalProps}
        />
    );
};

export default CancelParticipationModal;
