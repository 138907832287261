import { FC, useState } from 'react';

import '@elastic/react-search-ui-views/lib/styles/styles.css';
import type { FieldValue } from '@elastic/search-ui';
import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Checkbox,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import 'src/explore/styles/searchUi.css';
import useAuth from 'src/hooks/useAuth';

const StyledToggleButton = styled(ToggleButton)({
    paddingTop: 0,
    paddingBottom: 0,
});

const getFilterValueDisplay = (
    filterValue: any,
    locale: any,
    translationKey: any,
    facets: any,
): string => {
    if (filterValue === undefined || filterValue === null) {
        return 'None';
    }
    if (!facets) {
        return String(filterValue) || 'None';
    }
    if (Object.prototype.hasOwnProperty.call(filterValue, 'name')) {
        return filterValue.name || 'None';
    }
    if (Object.keys(facets).length) {
        let index = -1;
        if (facets[`${translationKey}_en.keyword`]) {
            index = facets[`${translationKey}_en.keyword`][0].data
                .map((entry) => entry.value)
                .indexOf(filterValue);
        } else {
            index = facets[`${translationKey}_de.keyword`][0].data
                .map((entry) => entry.value)
                .indexOf(filterValue);
        }
        if (index !== -1 && facets[`${translationKey}_${locale}.keyword`]) {
            return (
                facets[`${translationKey}_${locale}.keyword`][0].data[index]?.value ||
                String(filterValue) ||
                'None'
            );
        }
        return String(filterValue) || 'None';
    }
    return String(filterValue) || 'None';
};

type CustomFacetViewProps = {
    label: any;
    onMoreClick: any;
    onRemove: any;
    onSelect: any;
    options: any;
    showMore: any;
    showSearch: any;
    onSearch: any;
    facets: any;
    translationKey: any;
    disjunctiveFacets: any;
    setDisjunctiveFacets: any;
    addFilter: any;
    removeFilter: any;
    filters: any;
    facetId: string;
};

const CustomFacetView: FC<CustomFacetViewProps> = ({
    label,
    onMoreClick,
    onRemove,
    onSelect,
    options,
    showMore,
    showSearch,
    onSearch,
    facets,
    translationKey,
    disjunctiveFacets,
    setDisjunctiveFacets,
    addFilter,
    removeFilter,
    filters,
    facetId,
}: CustomFacetViewProps) => {
    const { i18n } = useLingui();
    const { locale: language } = i18n;
    const { user } = useAuth();
    const [alignment, setAlignment] = useState(
        filters[filters.findIndex((el) => el.field === facetId)]?.type ||
            (disjunctiveFacets.includes(facetId) ? 'any' : 'all'),
    );

    return (
        <fieldset className="sui-facet" style={{ padding: '0 8px' }}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography>
                    <legend className="sui-facet__title">{label}</legend>
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    value={
                        filters[filters.findIndex((el) => el.field === facetId)]?.type || alignment
                    }
                    exclusive
                    onChange={(e, newAlignment) => {
                        if (newAlignment) {
                            const facetIdx = disjunctiveFacets.indexOf(facetId);
                            const filterIdx = filters.findIndex((el) => el.field === facetId);
                            const newFilter = { ...filters[filterIdx] };
                            newFilter.type = newAlignment;
                            const newDisjunctive = [...disjunctiveFacets];
                            if (facetIdx !== -1) {
                                newDisjunctive.splice(facetIdx, 1);
                            } else {
                                newDisjunctive.push(facetId);
                            }
                            setDisjunctiveFacets(newDisjunctive);
                            if (filters.length > 0 && newFilter.field !== undefined) {
                                removeFilter(newFilter.field);
                                addFilter(newFilter.field, [], newFilter.type);
                            }
                            setAlignment(newAlignment);
                        }
                    }}
                    aria-label="Platform"
                >
                    <StyledToggleButton value="all">{i18n._('AND')}</StyledToggleButton>
                    <StyledToggleButton value="any">{i18n._('OR')}</StyledToggleButton>
                </ToggleButtonGroup>
            </Box>
            {showSearch && (
                <div className="sui-facet-search">
                    <TextField
                        placeholder={
                            language === 'en'
                                ? `${i18n._('Filter')} ${label}`
                                : `${label} ${i18n._('Filter')}`
                        }
                        variant="outlined"
                        style={{
                            backgroundColor: 'white',
                        }}
                        inputProps={{
                            style: {
                                padding: '4px 7px',
                            },
                        }}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }}
                    />
                </div>
            )}
            <div className="sui-multi-checkbox-facet">
                {options.length < 1 && <div>No matching options</div>}
                {options.map((option) => {
                    const checked = option.selected;
                    const value = option.value as FieldValue;
                    return (
                        <label
                            key={`${getFilterValueDisplay(
                                option.value,
                                language,
                                translationKey,
                                facets,
                            )}`}
                            htmlFor={`example_facet_${label}${getFilterValueDisplay(
                                option.value,
                                language,
                                translationKey,
                                facets,
                            )}`}
                            className="sui-multi-checkbox-facet__option-label"
                        >
                            <div className="sui-multi-checkbox-facet__option-input-wrapper">
                                <Checkbox
                                    data-transaction-name={`facet - ${label}`}
                                    id={`example_facet_${label}${getFilterValueDisplay(
                                        option.value,
                                        language,
                                        translationKey,
                                        facets,
                                    )}`}
                                    checked={checked}
                                    onChange={() => (checked ? onRemove(value) : onSelect(value))}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    size="small"
                                    sx={{
                                        p: 0,
                                    }}
                                />
                                <span className="sui-multi-checkbox-facet__input-text">
                                    <Typography variant="subtitle2">
                                        {getFilterValueDisplay(
                                            option.value,
                                            language,
                                            translationKey,
                                            facets,
                                        )}
                                    </Typography>
                                </span>
                            </div>
                            <span className="sui-multi-checkbox-facet__option-count">
                                {option.count.toLocaleString(user.formattingLocale)}
                            </span>
                        </label>
                    );
                })}
            </div>
            {showMore && (
                <Button
                    variant="text"
                    onClick={onMoreClick}
                    sx={{
                        p: 0,
                    }}
                >
                    {`+ ${i18n._('More')}`}
                </Button>
            )}
        </fieldset>
    );
};

export default CustomFacetView;
