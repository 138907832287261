import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    alpha,
} from '@mui/material';

interface Props {
    edit?: boolean;
    paused?: boolean;
}

export const NoAccessDialog: FC<Props> = ({ edit, paused }: Props) => {
    const { i18n } = useLingui();
    const part = edit ? 'edit' : (paused && 'paused') || 'general';

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    width: '80%',
                    maxHeight: 435,
                },
            }}
            maxWidth="xs"
            aria-labelledby="dialog-title"
            open
        >
            <DialogContent>
                <Box
                    mt={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                            color: 'warning.main',
                            mb: 2,
                        }}
                    >
                        <WarningRoundedIcon />
                    </Avatar>
                </Box>
                <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {i18n._('No access')}
                </Typography>
                <Typography
                    align="center"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                    style={{ whiteSpace: 'pre-wrap' }}
                    variant="body2"
                >
                    {i18n._(
                        "You don't have permission to access this object. Go back to the dashboard and see your active projects",
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Link to="/dashboard/ratecards" style={{ textDecoration: 'none' }}>
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            data-testid="backToDashboardButton"
                        >
                            {i18n._('Back to dashboard')}
                        </Button>
                    </Link>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
