const emToB = (value: string): string =>
    value?.replaceAll('<em>', '<b>')?.replaceAll('</em>', '</b>');

const removeEms = (value: string): string => value?.replaceAll('<em>', '')?.replaceAll('</em>', '');

const mergeSnippetArray = (field: { raw: Array<string>; snippet?: Array<string> }) => {
    const snippetValues = field.snippet?.map((s) => removeEms(s));
    const remainingValues = field.raw?.filter((r) => !snippetValues?.includes(r)) ?? [];
    const snippetBolded = field.snippet?.map((s) => emToB(s)) ?? [];

    return [...snippetBolded, ...remainingValues];
};

export { emToB, removeEms, mergeSnippetArray };
