import { useParams } from 'react-router';

import { appendAccessToken } from 'src/utils/appendAccessToken';

const ProjectCoverLetterPage = () => {
    const params = useParams();
    const { id } = params;
    window.location.replace(
        appendAccessToken(`${window.APADUA_SOURCE}app/rfp/${id}/cover_letter_choices`),
    );

    return null;
};

export default ProjectCoverLetterPage;
