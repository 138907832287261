import { FC, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Box, Typography } from '@mui/material';

import SummaryCardList from 'src/components/common/lists/SummaryCardList';
import Section from 'src/components/sections/section';
import CompanyProfileResultsList from 'src/explore/company/CompanyProfileResultsList';
import CompanyProfileSearchInput from 'src/explore/company/CompanyProfileSearchInput';
import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';
import { useCompanyFocusTopics } from 'src/hooks/useCompanyFocusTopics';
import { useCompanyTargetIndustries } from 'src/hooks/useCompanyTargetIndustries';

const CompanyAbout: FC = () => {
    const { i18n } = useLingui();
    const { company } = useContext(CompanyProfileContext);
    const { setSearch, refreshCompanyResultsQuery } = useContext(CompanyExploreContext);
    const [searchParams] = useSearchParams();
    const targetIndustriesEntries = useCompanyTargetIndustries(company);
    const focustTopicEntries = useCompanyFocusTopics(company);
    const searchBoxRef = useRef(null);

    const scrollSearchBoxIntoView = () => {
        searchBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleFocusTopicClick = (speciality) => {
        const newFilters = {
            keyword: speciality.key,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        searchParams.set('search', speciality.key);
        const newUrl = `${window.location.href.split('?')[0]}?${searchParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        refreshCompanyResultsQuery(newFilters);
        setSearch(speciality.key);
        scrollSearchBoxIntoView();
    };

    const handleTargetIndustryClick = (industry) => {
        const newFilters = {
            keyword: industry.key,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        searchParams.set('search', industry.key);
        const newUrl = `${window.location.href.split('?')[0]}?${searchParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        refreshCompanyResultsQuery(newFilters);
        setSearch(industry.key);
        scrollSearchBoxIntoView();
    };

    return (
        <Box>
            <Section title={i18n._('About')} divider>
                <Typography>{company?.generalInfo?.descriptionEn}</Typography>
            </Section>
            {company?.countries?.edges?.length > 0 && (
                <Section title={i18n._('Listed countries')} divider>
                    <Typography>
                        {company?.countries?.edges?.map((country, index) =>
                            index !== company?.countries?.totalCount - 1
                                ? `${country.node.commonNameEn}, `
                                : country.node.commonNameEn,
                        )}
                    </Typography>
                </Section>
            )}
            <Section
                title={i18n._('Target industries')}
                tooltipText={i18n._(
                    'Click the industries to check the related web content of this supplier. The identified content will be displayed below in the section: Web content.',
                )}
                divider
            >
                <SummaryCardList
                    items={targetIndustriesEntries}
                    onItemClick={handleTargetIndustryClick}
                    spacing={2}
                    listItemProps={{
                        md: 4,
                        sm: 6,
                        xs: 12,
                    }}
                />
            </Section>
            <Section
                title={i18n._('Focus topics')}
                description={i18n._(
                    'Click the focus topics to check related web content of this supplier. The identified content will be displayed below in the section: Web content.',
                )}
                tooltipText={i18n._(
                    'Besides reviewing the AI generated summaries for the top focus topics of this vendor, you may click on it to search for additional webcontent. The identified content will be displayed below in the section: Web content.',
                )}
                divider
            >
                <SummaryCardList
                    showDisclaimer
                    items={focustTopicEntries}
                    onItemClick={handleFocusTopicClick}
                    spacing={2}
                    listItemProps={{
                        md: 4,
                        sm: 6,
                        xs: 12,
                    }}
                />
            </Section>
            <Section title={i18n._('Web content')}>
                <Box
                    ref={searchBoxRef}
                    sx={{
                        py: 2,
                    }}
                >
                    <CompanyProfileSearchInput preview={!!true} />
                </Box>
                <CompanyProfileResultsList preview={!!true} />
            </Section>
        </Box>
    );
};

export default CompanyAbout;
