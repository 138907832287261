import { FC } from 'react';

import { useLingui } from '@lingui/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, AlertTitle } from '@mui/material';

import useAuth from 'src/hooks/useAuth';

export const InfoMessage: FC = () => {
    const { i18n } = useLingui();
    const { user } = useAuth();

    return (
        <Alert
            iconMapping={{
                error: <WarningAmberIcon fontSize="inherit" />,
            }}
            severity="info"
            sx={{ my: 2 }}
        >
            <AlertTitle>{i18n._('Internal Documents')}</AlertTitle>
            {user.isClient
                ? i18n._(
                      'The uploaded files are only accessible by the users of your project team. Supplier users participating in this tender, cannot see these files.',
                  )
                : i18n._(
                      'The uploaded files are only accessible by the users of your project team. Supplier and client users participating in this tender, cannot see these files.',
                  )}
        </Alert>
    );
};
