import { Dispatch, SetStateAction, createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface NotificationContextType {
    notifications?: any[];
    setNotifications?: Dispatch<SetStateAction<any[]>>;

    unreadNotifications?: any[];
    setUnreadNotifications?: Dispatch<SetStateAction<any[]>>;

    unreadNotificationsCount?: number;
    setUnreadNotificationsCount?: Dispatch<SetStateAction<number>>;

    lastNotificationCreatedAt?: Date;
    setLastNotificationCreatedAt?: Dispatch<SetStateAction<any>>;

    currentNotification?: any;
    setCurrentNotification?: Dispatch<SetStateAction<any>>;

    notificationDrawerOpen?: boolean;
    setNotificationDrawerOpen?: Dispatch<SetStateAction<boolean>>;

    shouldReloadTable?: boolean;
    setShouldReloadTable?: Dispatch<SetStateAction<boolean>>;

    notificationsQueryRef?: PreloadedQuery | null;
    refreshNotificationsQuery?: () => void;

    unreadNotificationsCountQueryRef?: PreloadedQuery | null;
    refreshUnreadNotificationsCountQuery?: () => void;

    changesQueryRef?: PreloadedQuery | null;
    refreshChangesQuery?: () => void;

    loadMoreNotifications?: () => void;
    setLoadMoreNotifications?: Dispatch<any>;
}

const initialValues: NotificationContextType = {
    notifications: null,
    setNotifications: null,
    unreadNotifications: null,
    setUnreadNotifications: null,
    unreadNotificationsCount: null,
    setUnreadNotificationsCount: null,
    currentNotification: null,
    setCurrentNotification: null,
    notificationDrawerOpen: null,
    setNotificationDrawerOpen: null,
    shouldReloadTable: null,
    setShouldReloadTable: null,
    notificationsQueryRef: null,
    refreshNotificationsQuery: () => {},
    unreadNotificationsCountQueryRef: null,
    refreshUnreadNotificationsCountQuery: () => {},
    changesQueryRef: null,
    refreshChangesQuery: () => {},
    loadMoreNotifications: () => {},
    setLoadMoreNotifications: () => {},
    lastNotificationCreatedAt: null,
    setLastNotificationCreatedAt: null,
};

export const NotificationContext = createContext(initialValues);
