import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { ServiceStandardsFetcher } from './thunk';

const initialState = fetcherInitialState;

export const serviceStandardsReducer = createSlice({
    name: 'serviceStandards',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ServiceStandardsFetcher.reducers(builder);
    },
});

export default serviceStandardsReducer.reducer;
