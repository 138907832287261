import uniqWith from 'lodash/uniqWith';
import { FC, MouseEvent as ME, useCallback } from 'react';

import { SearchBox, withSearch } from '@elastic/react-search-ui';

import SearchBoxView from './SearchBoxView';

type SearchToggleButtonsProps = {
    contentSearch: boolean;
    onToggleChange: (event: ME<HTMLButtonElement, MouseEvent>, value: any) => void;
    setSearchTerm?: (value: string) => void;
};

const SearchBoxComponent = ({
    contentSearch,
    onToggleChange: handleToggleChange,
    setSearchTerm,
}: SearchToggleButtonsProps) => {
    const SearchBoxViewWrapper = useCallback(
        ({ value, onChange, onSubmit, completeSuggestion, autocompletedResults }) => {
            const handleQueryChange = (newSearchString: string, updateQuery: boolean = false) => {
                onChange(newSearchString);
                if (updateQuery) {
                    setSearchTerm(newSearchString);
                }
            };
            const handleSubmit = (e) => {
                if ((e.target as any).value) {
                    onSubmit(e);
                }
                if (!value.endsWith(',') && value !== '') {
                    setSearchTerm(`${value},`);
                }
                e.stopPropagation();
                e.preventDefault();
            };
            const handleSuggestionClick = (newSearchString: string) => {
                onChange(newSearchString);
                setSearchTerm(newSearchString);
            };
            let deduplicatedAutocompletedResults = [];
            autocompletedResults.forEach((autocompletedResult) => {
                if (contentSearch) {
                    deduplicatedAutocompletedResults = uniqWith(
                        deduplicatedAutocompletedResults.concat(
                            autocompletedResult.ln_company_specialities_en?.snippet[0]
                                .replaceAll('<em>', '')
                                .replaceAll('</em>', ''),
                        ),
                        (a, b) => a?.toLowerCase() === b?.toLowerCase(),
                    );
                } else {
                    deduplicatedAutocompletedResults = uniqWith(
                        deduplicatedAutocompletedResults.concat(
                            autocompletedResult.name?.snippet[0]
                                .replaceAll('<em>', '')
                                .replaceAll('</em>', ''),
                        ),
                        (a, b) => a?.toLowerCase() === b?.toLowerCase(),
                    );
                }
            });
            return (
                <SearchBoxView
                    onChange={handleQueryChange}
                    onSubmit={handleSubmit}
                    onToggleChange={handleToggleChange}
                    onSearchButtonClick={handleSubmit}
                    onSuggestionClick={handleSuggestionClick}
                    autocompleteResults={deduplicatedAutocompletedResults}
                    contentSearch={contentSearch}
                />
            );
        },
        [contentSearch],
    );
    return (
        <SearchBox
            view={SearchBoxViewWrapper}
            autocompleteMinimumCharacters={3}
            autocompleteResults={{
                linkTarget: '_blank',
                sectionTitle: 'Companies',
                titleField: 'name',
                urlField: '_id',
                shouldTrackClickThrough: true,
            }}
            autocompleteSuggestions={!!true}
            debounceLength={500}
        />
    );
};

export default withSearch(({ setSearchTerm }) => ({ setSearchTerm }))(
    SearchBoxComponent,
) as FC<SearchToggleButtonsProps>;
