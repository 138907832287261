import { FC } from 'react';

import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';

interface Props {
    saveAndProceed?: () => void;
    discardAndProceed?: () => void;
    cancel?: () => void;
    editMode?: boolean;
}

export const SaveBeforeLeaveDialog: FC<Props> = ({
    saveAndProceed,
    discardAndProceed,
    cancel,
    editMode,
}: Props) => {
    const { i18n } = useLingui();

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    width: '80%',
                    maxHeight: 435,
                },
            }}
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open
        >
            <DialogTitle id="confirmation-dialog-title">
                {i18n._('You have unsaved changes')}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="subtitle2">
                    {i18n._('If you proceed without saving, you will lose all your progress.')}
                </Typography>
            </DialogContent>
            <DialogActions>
                {cancel && (
                    <Box m={1}>
                        <Button onClick={cancel} data-testid="cancel">
                            {i18n._('Cancel')}
                        </Button>
                    </Box>
                )}
                {discardAndProceed && (
                    <Button
                        color={saveAndProceed ? 'inherit' : 'primary'}
                        variant={saveAndProceed ? 'text' : 'contained'}
                        onClick={discardAndProceed}
                        data-testid="proceed"
                    >
                        {i18n._('Discard & proceed')}
                    </Button>
                )}
                {saveAndProceed && (
                    <Button
                        autoFocus
                        color="primary"
                        variant="contained"
                        onClick={saveAndProceed}
                        data-testid="saveProceed"
                    >
                        {editMode ? i18n._('Save & validate') : i18n._('Save as draft & proceed')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
