import { t } from '@lingui/macro';

interface ProjectDateData {
    pitchSession: boolean;
    rfpReleaseDueDate: string;
    proposalDueDate: string;
    presentationStartDate: string;
    presentationEndDate: string;
    projectAwardDate: string;
    startDate: string;
    endDate: string;
}

export interface ProjectTimelineEvent {
    id: string | number;
    name: string;
    eventType: string;
    date: Date;
}

export const formatProjectTimelineEntries = (data: ProjectDateData) => {
    const result = [];
    result.push({
        key: `startDate`,
        id: `startDate`,
        name: t`Project start date`,
        eventType: 'project_start_date',
        date: data.startDate ? new Date(data.startDate) : null,
    });
    result.push({
        key: `endDate`,
        id: `endDate`,
        name: t`Project end date`,
        eventType: 'project_end_date',
        date: data.endDate ? new Date(data.endDate) : null,
    });
    result.push({
        key: `rfpReleaseDueDate`,
        id: `rfpReleaseDueDate`,
        name: t`RFP Release Due`,
        eventType: 'rfp_release_due_date',
        date: data.rfpReleaseDueDate ? new Date(data.rfpReleaseDueDate) : null,
    });
    result.push({
        key: `proposalDueDate`,
        id: `proposalDueDate`,
        name: t`Proposal Due`,
        eventType: 'proposal_due_date',
        date: data.proposalDueDate ? new Date(data.proposalDueDate) : null,
    });
    if (data.pitchSession) {
        result.push({
            key: `presentationStartDate`,
            id: `presentationStartDate`,
            name: t`Pitch Presentation Start Date`,
            eventType: 'presentation_start_date',
            date: data.presentationStartDate ? new Date(data.presentationStartDate) : null,
        });
        result.push({
            key: `presentationEndDate`,
            id: `presentationEndDate`,
            name: t`Pitch Presentation End Date`,
            eventType: 'presentation_end_date',
            date: data.presentationEndDate ? new Date(data.presentationEndDate) : null,
        });
    }
    result.push({
        key: `projectAwardDate`,
        id: `projectAwardDate`,
        name: t`Project Award Date`,
        eventType: 'project_award_date',
        date: data.projectAwardDate ? new Date(data.projectAwardDate) : null,
    });
    return result;
};
