import Section from 'src/components/sections/section';
import QuoteAnnouncements from 'src/pages/rfp/QuoteAnnouncements';

const ProjectAnnouncementsPage = () => (
    <Section>
        <QuoteAnnouncements />
    </Section>
);

export default ProjectAnnouncementsPage;
