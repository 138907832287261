import { FC } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CompanyIconWithPopup } from './CompanyIconWithPopup';

interface Props {
    company: any;
    // companyLogo?: any,
    width?: number;
    height?: number;
    fontSize?: number;
    style?: any;
    variant?: 'circular' | 'rounded' | 'square';
    onLoad?: () => void;
}

const StyledAvatarBox = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
});

const StyledFallbackBox = styled(Box)(({ theme }) => ({
    overflow: 'auto',
    '&.displayNone': {
        display: 'none',
    },
    '&.paddingTopForWorkIcon': {
        paddingTop: theme.spacing(1),
    },
}));

const CustomCompanyIcon: FC<Props> = ({
    company,
    width,
    height,
    fontSize,
    style,
    variant,
    onLoad,
}: Props) => (
    <StyledAvatarBox>
        <StyledFallbackBox
            style={{
                width: width && width,
                height: height && height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            {...style}
        >
            <CompanyIconWithPopup
                variant={variant}
                company={company}
                width={width}
                height={height}
                fontSize={fontSize}
                onLoad={onLoad}
            />
        </StyledFallbackBox>
    </StyledAvatarBox>
);

export default CustomCompanyIcon;
