import React from 'react';

import { Trans } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';

interface Props {
    onClick: () => void;
}

const AddTaskButton = ({ onClick }: Props) => (
    <Grid display="flex" justifyContent="center" sx={{ marginBottom: 1, marginTop: 1 }}>
        <Button
            onClick={onClick}
            startIcon={<AddIcon />}
            variant="text"
            data-testid="checklist-add-task-button"
        >
            <Trans>New Task</Trans>
        </Button>
    </Grid>
);

export default AddTaskButton;
