import { graphql } from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay/hooks';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Divider } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';
import { RatecardProjectStepper } from 'src/components/ratecards/RatecardProjectStepper';

import SidebarOthers from '../utils/SidebarOthers';

export const RatecardProjectSidebar = () => {
    const { i18n } = useLingui();
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const location = useLocation();

    const ratecard = useFragment(
        graphql`
            fragment RatecardProjectSidebar_ratecardProject on RatecardProjectNode {
                id
                projectName
                editMode
                validation {
                    tenderPlanning
                    supplierSelection
                    ratecard
                    otherInformation
                    allSuccessful
                    errorMessage
                }
            }
        `,
        ratecardProjectQuery.ratecardProject,
    );

    const sections = [
        {
            title: (<SidebarOthers />) as unknown as string,
            items: [
                {
                    title: i18n._('Announcements'),
                    path: `/dashboard/ratecards/${ratecard.id}/setup/announcements`,
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: `/dashboard/ratecards/${ratecard.id}/setup/questions`,
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: `/dashboard/ratecards/${ratecard.id}/setup/documents`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: `/dashboard/ratecards/${ratecard.id}/setup/users`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            {ratecard.projectName ? (
                <StepperTitle title={ratecard.projectName} status="new" projectType="ratecards" />
            ) : null}
            <RatecardProjectStepper orientation="vertical" editMode={false} />
            <Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
