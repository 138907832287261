import { Trans } from '@lingui/macro';

import Section from 'src/components/sections/section';
import ProjectReviewAndSubmitContainer from 'src/project/projectSummary/projectReviewAndSubmitContainer';
import useProjectActions from 'src/project/useProjectActions';
import ProjectFooter from 'src/projectBrief/projectFooter';
import { useProjectReviewSubmitTour } from 'src/tours/projectReviewSubmitTour';

const ProjectReviewAndSubmitPage = () => {
    useProjectReviewSubmitTour();

    const actions = useProjectActions({ submitProject: true });

    return (
        <Section
            title={<Trans>Review & Submit</Trans>}
            actions={actions}
            data-testid="ProjectReviewSubmit"
        >
            <ProjectReviewAndSubmitContainer />
            <ProjectFooter submitProject />
        </Section>
    );
};

export default ProjectReviewAndSubmitPage;
