import { Dispatch, SetStateAction, createContext } from 'react';

interface SearchContextType {
    currentSearch?: string;
    setCurrentSearch?: Dispatch<SetStateAction<string>>;
}

const initialValues: SearchContextType = {
    currentSearch: null,
    setCurrentSearch: null,
};

export const SearchContext = createContext(initialValues);
