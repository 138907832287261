/**
 * @generated SignedSource<<8f15e927d06487849c6df76d53e996f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RatecardProjectStepper_ratecardProject$data = {
  readonly editMode: boolean;
  readonly id: string;
  readonly projectName: string | null;
  readonly validation: {
    readonly allSuccessful: boolean | null;
    readonly errorMessage: string | null;
    readonly otherInformation: string | null;
    readonly ratecard: string | null;
    readonly supplierSelection: string | null;
    readonly tenderPlanning: string | null;
  } | null;
  readonly " $fragmentType": "RatecardProjectStepper_ratecardProject";
};
export type RatecardProjectStepper_ratecardProject$key = {
  readonly " $data"?: RatecardProjectStepper_ratecardProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"RatecardProjectStepper_ratecardProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RatecardProjectStepper_ratecardProject",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RatecardProjectValidationNode",
      "kind": "LinkedField",
      "name": "validation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenderPlanning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplierSelection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratecard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherInformation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allSuccessful",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RatecardProjectNode",
  "abstractKey": null
};

(node as any).hash = "8e732350a49a9b03b7bf8256c636b87e";

export default node;
