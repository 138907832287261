import { useCallback, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { TourPolicy, UserTourInfo } from 'src/tours/types';

const LOCALSTORAGE_TOUR_KEY = 'tourVisited';

const defaultTourPolicy: UserTourInfo = Object.freeze({
    timesFinished: 0,
    timesShown: 0,
    lastShown: null,
    declined: false,
});

export const useUserTourPolicy = (tourName: string) => {
    const [userTourPolicy, setUserTourPolicy] = useState<UserTourInfo>(null);

    useEffect(() => {
        const tourVisited = localStorage.getItem(LOCALSTORAGE_TOUR_KEY);
        try {
            const parsedTourVisited = JSON.parse(tourVisited) as TourPolicy;

            setUserTourPolicy(parsedTourVisited[tourName] ?? defaultTourPolicy);
        } catch (e) {
            setUserTourPolicy(defaultTourPolicy);
        }
    }, [tourName]);

    const onTourShown = useCallback(() => {
        setUserTourPolicy((prev) => ({
            ...prev,
            timesShown: prev.timesShown + 1,
            lastShown: new Date().toISOString(),
        }));
    }, []);

    const onTourDeclined = useCallback(() => {
        setUserTourPolicy((prev) => ({
            ...prev,
            declined: true,
        }));
    }, []);

    const onTourFinished = useCallback(() => {
        setUserTourPolicy((prev) => ({
            ...prev,
            timesFinished: prev.timesFinished + 1,
        }));
    }, []);

    useEffect(() => {
        if (!userTourPolicy) {
            return;
        }
        const tourVisited = localStorage.getItem(LOCALSTORAGE_TOUR_KEY);
        try {
            const parsedTourVisited = (JSON.parse(tourVisited) as TourPolicy) ?? {};

            parsedTourVisited[tourName] = userTourPolicy;
            localStorage.setItem(LOCALSTORAGE_TOUR_KEY, JSON.stringify(parsedTourVisited));
        } catch (e) {
            Sentry.captureException(e);
        }
    }, [tourName, userTourPolicy]);

    return {
        policy: userTourPolicy,
        onTourShown,
        onTourDeclined,
        onTourFinished,
        shouldDisplay: userTourPolicy ? !userTourPolicy.declined : false,
    };
};
