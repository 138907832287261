import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

interface MainLayoutProps {
    children?: ReactNode;
}

const MainLayoutRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => (
    <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
);

MainLayout.propTypes = {
    children: PropTypes.node,
};

export default MainLayout;
