import { Field, useFormikContext } from 'formik';
import React from 'react';

import { StandardTextFieldProps } from '@mui/material';

import { TextField as FormikTextField } from 'src/components/formik-material-ui/TextField';
import { useFormFieldOnChange } from 'src/forms/useFormFieldOnChange';

interface TextFieldProps extends Omit<StandardTextFieldProps, 'multiline'> {}

const TextField: React.FC<TextFieldProps> = ({ label, rows, name, ...rest }) => {
    const { setFieldTouched, setFieldValue } = useFormikContext();
    const onChange = useFormFieldOnChange(name, setFieldTouched, setFieldValue);
    return (
        <Field
            fullWidth
            component={FormikTextField}
            name={name}
            onChange={onChange}
            data-testid={name}
            label={label}
            {...rest}
        />
    );
};

export default TextField;
