import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { CountriesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {},
    extraReducers(builder) {
        CountriesFetcher.reducers(builder);
    },
});

export default countriesSlice.reducer;
