import { useConfirm } from 'material-ui-confirm';
import { FC, useCallback, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { FileUploadWithProgress } from 'src/components/fileUpload/FileUploadWithProgress';
import { supportedFileTypes } from 'src/constants';
import { useDocumentTypes } from 'src/redux/documentTypes/hook';
import { downloadAttachmentFromUrl } from 'src/utils/file';
import getOptionKey from 'src/utils/language/getOptionKey';

import { Bucket, BucketContainer } from '../sections/bucketSection';

const StyledDropzone = styled('div')(({ theme }) => ({
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    height: theme.spacing(10),
    outline: 'none',
    '&:focus': {
        border: '2px solid black',
    },
}));
export interface UploadableFile {
    id: string;
    file: File;
    errors: FileError[];
    url?: string;
}

type FileDropzoneProps = {
    uploadApiUrl: string;
    parent?: string;
    viewIsReadOnly?: boolean;
    fileIds: any;
    setFileIds: any;
    onUploadCallback?: (updatedValues?: Array<any>) => void;
    onDeleteCallback?: (updatedValues?: Array<any>) => void;
    onFileTypeChangeCallback?: (updatedValues?: Array<any>) => void;
};

const getFileName = (data) => {
    if (data?.type === 'quote-documents') {
        if (data.attributes['file-path']) {
            return data.attributes['file-path'].split('/')[1];
        }
        if (data.attributes.file_path) {
            return data.attributes.file_path.split('/')[1];
        }
        return '';
    }
    return data.attributes.file;
};

export const FileDropzone: FC<FileDropzoneProps> = ({
    uploadApiUrl,
    parent,
    viewIsReadOnly,
    fileIds,
    setFileIds,
    onUploadCallback,
    onDeleteCallback,
    onFileTypeChangeCallback: handleFileTypeChangeCallback,
}: FileDropzoneProps) => {
    const { i18n } = useLingui();
    const documentTypes = useDocumentTypes();

    const [files, setFiles] = useState<UploadableFile[]>([]);
    const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
        const mappedAcc = accFiles.map((file) => ({ file, errors: [], id: file.name }));
        const mappedRej = rejFiles.map((r) => ({ ...r, id: r.file.name }));
        setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: supportedFileTypes,
    });

    function onUpload(file: File, errors: FileError[], newValue: any) {
        setFiles((curr) =>
            curr
                .filter((fileWrapper) => fileWrapper.file === file && errors.length > 0)
                .map((fw) => {
                    const newFile = { ...fw, errors };
                    return newFile;
                }),
        );
        if (newValue) {
            onUploadCallback?.(newValue);
        }
    }

    const confirm = useConfirm();
    const handleDeleteClick = (index) => {
        confirm({
            title: i18n._('Are you sure you want to delete this file?'),
            confirmationText: i18n._('Yes'),
            cancellationText: i18n._('Cancel'),
        })
            .then(() => {
                if (index > -1) {
                    const updatedValues = fileIds.filter((item) => item !== fileIds[index]);
                    setFileIds(updatedValues);
                    onDeleteCallback?.(updatedValues);
                }
            })
            .catch(() => {});
    };

    const handleOpenFileClick = (fileNode) => {
        const url = fileNode.data.data.attributes['file-path']?.startsWith('http')
            ? fileNode.data.data.attributes['file-path']
            : fileNode.data.data.attributes.file;
        downloadAttachmentFromUrl(url);
    };

    const onDropzoneClick = viewIsReadOnly ? (event) => event.stopPropagation() : null;

    const handleFileTypeChange = (index, value) => {
        const updatedValues = fileIds.filter((item) => item !== fileIds[index]);
        updatedValues.splice(index, 0, {
            data: {
                data: {
                    type: fileIds[index].data.data.type,
                    attributes: {
                        ...fileIds[index].data.data.attributes,
                        type: value,
                    },
                },
            },
        });
        setFileIds(updatedValues);
        handleFileTypeChangeCallback?.(updatedValues);
    };

    return (
        <Grid item>
            {!viewIsReadOnly && (
                <StyledDropzone
                    {...getRootProps({ onClick: onDropzoneClick })}
                    data-testid="uploadFile"
                >
                    <input {...getInputProps()} disabled={viewIsReadOnly} />
                    <Grid display="flex" container direction="column" alignItems="center">
                        <p>{i18n._('Drag and drop some files here, or click to select files')}</p>
                        <Typography variant="subtitle2">
                            {`(${supportedFileTypes.join(', ')})`}
                        </Typography>
                    </Grid>
                </StyledDropzone>
            )}
            <Grid container spacing={3}>
                {!viewIsReadOnly && (
                    <Grid item sm={6} xs={12}>
                        <Typography variant="h6" color="textPrimary" sx={{ mt: 3 }}>
                            {i18n._('Files status')}
                        </Typography>
                        {files.map((fileWrapper) => (
                            <Grid item key={fileWrapper.id}>
                                <Grid item key={fileWrapper.id}>
                                    <FileUploadWithProgress
                                        setFileIds={setFileIds}
                                        uploadApiUrl={uploadApiUrl}
                                        parent={parent}
                                        file={fileWrapper.file}
                                        onUpload={onUpload}
                                        errors={fileWrapper.errors}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Grid item sm={6} xs={12}>
                    <Typography variant="h6" color="textPrimary" sx={{ mt: 3 }}>
                        {i18n._('Uploaded files')}
                    </Typography>
                    {fileIds.length === 0 && (
                        <Grid item>
                            <Box display="flex">
                                <Typography color="textPrimary" variant="subtitle2" noWrap>
                                    {i18n._('No files were uploaded')}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {fileIds?.map((fileNode, index) => (
                        <Grid item key={fileNode.data.data.id}>
                            <BucketContainer isVertical={false}>
                                <Bucket xs={12} xl={7} withDivider={false}>
                                    {!viewIsReadOnly && (
                                        <Tooltip title={i18n._('Delete')} placement="top">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                sx={{ mt: 1, mr: 1 }}
                                                onClick={() => {
                                                    handleDeleteClick(index);
                                                }}
                                                data-testid="deleteFile"
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    <Tooltip title={i18n._('Download')} placement="top">
                                        <Button
                                            color="primary"
                                            sx={{ mt: 1, mr: 1 }}
                                            onClick={() => {
                                                handleOpenFileClick(fileNode);
                                            }}
                                            startIcon={<InsertDriveFileIcon fontSize="small" />}
                                        >
                                            <Typography
                                                color="textPrimary"
                                                variant="subtitle2"
                                                noWrap
                                            >
                                                {getFileName(fileNode?.data?.data)}
                                            </Typography>
                                        </Button>
                                    </Tooltip>
                                </Bucket>
                                <Bucket xs={12} xl={5}>
                                    {handleFileTypeChangeCallback && (
                                        <FormControl sx={{ mt: 1 }}>
                                            <InputLabel id="document-type-label">
                                                <Trans>Document Type</Trans>
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                value={fileNode?.data?.data?.attributes?.type || ''}
                                                label="Document type"
                                                id="demo-simple-select"
                                                onChange={(event) =>
                                                    handleFileTypeChange(index, event.target.value)
                                                }
                                            >
                                                {documentTypes.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {getOptionKey(item, 'documentType')}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Bucket>
                            </BucketContainer>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
