import React from 'react';

import { Grid } from '@mui/material';

import { RfpDetails } from 'src/project/rfpDetails';
import { useProjectDetailsTour } from 'src/tours/projectDetailsTour';

const ProjectRFPDetailsPage: React.FC = () => {
    useProjectDetailsTour();

    return (
        <Grid container flexDirection="column">
            <RfpDetails />
        </Grid>
    );
};

export default ProjectRFPDetailsPage;
