import { Form, Formik } from 'formik';
import { FC, MutableRefObject, ReactNode } from 'react';
import * as Yup from 'yup';

import { Trans, t } from '@lingui/macro';
import { Box, Button, Typography } from '@mui/material';

import SpacingContainer from 'src/components/sections/spacingContainer';
import AutocompleteField from 'src/forms/autocompleteField';
import TextareaField from 'src/forms/textareaField';
import { Cancellation } from 'src/proposal/types';
import { translateCancellationCause } from 'src/utils/language/translate';

import Modal, { ModalProps } from './modal';

export const validationSchema = Yup.object().shape({
    cancelCause: Yup.string()
        .required(() => t`Cancel cause is required`)
        .max(255, () => t`Input must not exceed ${255} characters`)
        .trim(() => t`Leading and trailing whitespaces are not allowed`),
    cancelReason: Yup.string()
        .required(() => t`Cancel reason is required`)
        .trim(() => t`Leading and trailing whitespaces are not allowed`),
});

interface CancellationModalProps extends ModalProps {
    formRef: MutableRefObject<any>;
    onSubmit: () => void;
    buttonLabel?: ReactNode;
    modalTitle?: ReactNode;
}

const causes = Object.freeze(Object.values(Cancellation));
const initialValues = Object.freeze({
    cancelCause: '',
    cancelReason: '',
});

export const CancellationModal: FC<CancellationModalProps> = ({
    formRef,
    onSubmit,
    buttonLabel,
    modalTitle,
    ...modalProps
}: CancellationModalProps) => (
    <Modal {...modalProps}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Typography variant="h5" color="primary">
                {modalTitle}
            </Typography>
            <Formik
                innerRef={formRef}
                onSubmit={onSubmit}
                validateOnChange={false}
                validateOnBlur
                validationSchema={validationSchema}
                initialValues={initialValues}
            >
                <Form>
                    <SpacingContainer flexDirection="column">
                        <AutocompleteField
                            options={causes}
                            name="cancelCause"
                            required
                            label={<Trans>Cancel cause</Trans>}
                            getOptionLabel={translateCancellationCause}
                            getOptionValue={(option: string) => option}
                        />
                        <TextareaField
                            name="cancelReason"
                            minRows={4}
                            label={<Trans>Cancel reason</Trans>}
                            required
                        />
                        <Button type="submit" variant="contained">
                            {buttonLabel}
                        </Button>
                    </SpacingContainer>
                </Form>
            </Formik>
        </Box>
    </Modal>
);
