import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { LanguagesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {},
    extraReducers(builder) {
        LanguagesFetcher.reducers(builder);
    },
});

export default languagesSlice.reducer;
