import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Modal as MuiModal, SxProps } from '@mui/material';

import { useMobile } from 'src/hooks/useMobile';

const defaultStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #ccc',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    p: 4,
};

export interface TriggerButtonProps {
    handleOpen: () => void;
    triggerButtonText: string;
}

export interface ModalProps {
    children?: React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    beforeClose?: () => void;
    style?: SxProps;
    triggerButtonText?: string;
    triggerButtonComponent?: React.ComponentType<{
        handleOpen: () => void;
        triggerButtonText: string;
    }>;
    externalControl?: boolean; // prop for external control
    isOpen?: boolean; // Prop for external control of modal visibility
    showTriggerButton?: boolean; // show/hide the trigger button
    rest?: any;
    withCloseIcon?: boolean;
}

const mobileStyle = { width: '100%' };

const DefaultTriggerButton = ({ handleOpen, triggerButtonText }: TriggerButtonProps) => (
    <Button onClick={handleOpen}>{triggerButtonText}</Button>
);

const Modal = ({
    children,
    onOpen,
    onClose,
    beforeClose,
    isOpen,
    style = {},
    // TODO: when externalControl is provided, we need to enforce onOpen, onClose functions as required props
    externalControl,
    showTriggerButton = true,
    triggerButtonComponent: TriggerButton = DefaultTriggerButton,
    triggerButtonText = 'Open Modal',
    withCloseIcon = true,
    ...rest
}: ModalProps) => {
    const [open, setOpen] = React.useState(externalControl ? isOpen : false);
    const isMobile = useMobile();

    React.useEffect(() => {
        if (externalControl) {
            setOpen(isOpen);
        }
    }, [externalControl, isOpen]);

    const handleOpen = () => {
        setOpen(true);
        if (onOpen) {
            onOpen();
        }
    };

    const handleClose = () => {
        if (externalControl) return;

        if (beforeClose) {
            beforeClose();
        }
        setOpen(false);
    };

    const mergedStyle = { ...defaultStyle, ...style, ...(isMobile ? mobileStyle : {}) } as SxProps;
    return (
        <>
            {showTriggerButton && (
                <TriggerButton handleOpen={handleOpen} triggerButtonText={triggerButtonText} />
            )}
            <MuiModal
                open={open}
                disableAutoFocus
                onClose={onClose || handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                {...rest}
            >
                <Box sx={mergedStyle}>
                    {withCloseIcon && (
                        <Grid display="flex" justifyContent="flex-end">
                            <IconButton onClick={onClose || handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid>{children}</Grid>
                </Box>
            </MuiModal>
        </>
    );
};

export default Modal;
