import differenceWith from 'lodash/differenceWith';
import { Dispatch, FC, SetStateAction, SyntheticEvent, useMemo } from 'react';

import { i18n } from '@lingui/core';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';

import { LocalisedValue } from '../localisedValue';
import TextArea from '../textArea';
import Modal, { ModalProps } from './modal';

export interface Reason {
    id: string;
    text: LocalisedValue;
}

interface ReportModalProps extends ModalProps {
    reasons: Reason[];
    selectedReasons: Reason[];
    onReasonSelect: (event: SyntheticEvent, newValue: Reason[]) => void;
    note: string;
    onNoteChange: Dispatch<SetStateAction<string>>;
    onSubmit: () => void;
    hasError?: boolean;
    errorMessage?: string;
    dropdownLabel?: string;
    buttonLabel?: string;
    modalTitle?: string;
}

export const ReportModal: FC<ReportModalProps> = ({
    reasons,
    selectedReasons,
    onReasonSelect,
    note,
    onNoteChange,
    onSubmit,
    hasError,
    errorMessage,
    dropdownLabel,
    buttonLabel,
    modalTitle,
    ...modalProps
}: ReportModalProps) => {
    const options = useMemo(
        () => differenceWith(reasons, selectedReasons, (a, b) => a.id === b.id),
        [reasons, selectedReasons],
    );

    return (
        <Modal {...modalProps}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h5" color="primary">
                    {modalTitle}
                </Typography>
                <Autocomplete
                    freeSolo
                    multiple
                    options={options}
                    getOptionLabel={(option: Reason) =>
                        i18n.locale === 'en' ? option.text.en || '' : option.text.de || ''
                    }
                    value={selectedReasons}
                    onChange={onReasonSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            label={dropdownLabel}
                            variant="outlined"
                            required={!!true}
                            error={hasError}
                            helperText={hasError ? errorMessage : ''}
                            style={{
                                backgroundColor: 'white',
                            }}
                        />
                    )}
                />
                <TextArea value={note} onChange={onNoteChange} />
                <Button onClick={onSubmit} variant="contained">
                    {buttonLabel}
                </Button>
            </Box>
        </Modal>
    );
};
