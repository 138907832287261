import { CompanySizeEmployeesAPI } from 'src/apis/companySizeEmployeesAPI';

import Fetcher from '../fetcher';
import { sanitizeCompanySizeEmployees } from './sanitizers';

const api = new CompanySizeEmployeesAPI();

export const CompanySizeEmployeesFetcher = new Fetcher(
    'companySizeEmployees/list',
    async () => api.getCompanySizeEmployees(),
    sanitizeCompanySizeEmployees,
);
