import { Trans } from '@lingui/macro';

import { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import SpacingContainer from 'src/components/sections/spacingContainer';
import AutocompleteField from 'src/forms/autocompleteField';
import TextField from 'src/forms/textField';
import { useSalutations } from 'src/redux/salutations/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

interface ProjectContactItemProps {
    index: number;
}

const ProjectContactItem = ({ index }: ProjectContactItemProps) => {
    const salutations = useSalutations();

    return (
        <BucketContainer isVertical>
            <Bucket xs={12}>
                <SpacingContainer flexDirection="column">
                    <AutocompleteField
                        options={salutations}
                        name={`quoteContacts.${index}.salutation`}
                        label={<Trans>Salutation</Trans>}
                        getOptionLabel={(option) => getOptionKey(option, 'salutation') || ''}
                    />
                    <TextField
                        name={`quoteContacts.${index}.firstName`}
                        label={<Trans>First Name</Trans>}
                        required
                    />
                    <TextField
                        name={`quoteContacts.${index}.lastName`}
                        label={<Trans>Last Name</Trans>}
                        required
                    />
                    <TextField
                        name={`quoteContacts.${index}.jobTitle`}
                        label={<Trans>Job Title</Trans>}
                        required
                    />
                </SpacingContainer>
            </Bucket>
        </BucketContainer>
    );
};

export default ProjectContactItem;
