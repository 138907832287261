import { t } from '@lingui/macro';

const AG_GRID_LOCALE_EN = {
    // Set Filter
    selectAll: () => t`(Select All)`,
    selectAllSearchResults: () => t`(Select All Search Results)`,
    addCurrentSelectionToFilter: () => t`Add current selection to filter`,
    searchOoo: () => t`Search...`,
    blanks: () => t`(Blanks)`,
    noMatches: () => t`No matches`,

    // Number Filter & Text Filter
    filterOoo: () => t`Filter...`,
    equals: () => t`Equals`,
    notEqual: () => t`Does not equal`,
    blank: () => t`Blank`,
    notBlank: () => t`Not blank`,
    empty: () => t`Choose one`,

    // Number Filter
    lessThan: () => t`Less than`,
    greaterThan: () => t`Greater than`,
    lessThanOrEqual: () => t`Less than or equal to`,
    greaterThanOrEqual: () => t`Greater than or equal to`,
    inRange: () => t`Between`,
    inRangeStart: () => t`From`,
    inRangeEnd: () => t`To`,

    // Text Filter
    contains: () => t`Contains`,
    notContains: () => t`Does not contain`,
    startsWith: () => t`Begins with`,
    endsWith: () => t`Ends with`,

    // Date Filter
    dateFormatOoo: () => t`yyyy-mm-dd`,
    before: () => t`Before`,
    after: () => t`After`,

    // Filter Conditions
    andCondition: () => t`AND`,
    orCondition: () => t`OR`,

    // Filter Buttons
    applyFilter: () => t`Apply`,
    resetFilter: () => t`Reset`,
    clearFilter: () => t`Clear`,
    cancelFilter: () => t`Cancel`,

    // Filter Titles
    textFilter: () => t`Text Filter`,
    numberFilter: () => t`Number Filter`,
    dateFilter: () => t`Date Filter`,
    setFilter: () => t`Set Filter`,

    // Group Column Filter
    groupFilterSelect: () => t`Select field:`,

    // Advanced Filter
    advancedFilterContains: () => t`contains`,
    advancedFilterNotContains: () => t`does not contain`,
    advancedFilterTextEquals: () => t`equals`,
    advancedFilterTextNotEqual: () => t`does not equal`,
    advancedFilterStartsWith: () => t`begins with`,
    advancedFilterEndsWith: () => t`ends with`,
    advancedFilterBlank: () => t`is blank`,
    advancedFilterNotBlank: () => t`is not blank`,
    advancedFilterEquals: () => t`=`,
    advancedFilterNotEqual: () => t`!=`,
    advancedFilterGreaterThan: () => t`>`,
    advancedFilterGreaterThanOrEqual: () => t`>=`,
    advancedFilterLessThan: () => t`<`,
    advancedFilterLessThanOrEqual: () => t`<=`,
    advancedFilterTrue: () => t`is true`,
    advancedFilterFalse: () => t`is false`,
    advancedFilterAnd: () => t`AND`,
    advancedFilterOr: () => t`OR`,
    advancedFilterApply: () => t`Apply`,
    advancedFilterBuilder: () => t`Builder`,
    advancedFilterValidationMissingColumn: () => t`Column is missing`,
    advancedFilterValidationMissingOption: () => t`Option is missing`,
    advancedFilterValidationMissingValue: () => t`Value is missing`,
    advancedFilterValidationInvalidColumn: () => t`Column not found`,
    advancedFilterValidationInvalidOption: () => t`Option not found`,
    advancedFilterValidationMissingQuote: () => t`Value is missing an end quote`,
    advancedFilterValidationNotANumber: () => t`Value is not a number`,
    advancedFilterValidationInvalidDate: () => t`Value is not a valid date`,
    advancedFilterValidationMissingCondition: () => t`Condition is missing`,
    advancedFilterValidationJoinOperatorMismatch:
        'Join operators within a condition must be the same',
    advancedFilterValidationInvalidJoinOperator: () => t`Join operator not found`,
    advancedFilterValidationMissingEndBracket: () => t`Missing end bracket`,
    advancedFilterValidationExtraEndBracket: () => t`Too many end brackets`,
    advancedFilterValidationMessage: () => t`Expression has an error. \${variable} - \${variable}.`,
    advancedFilterValidationMessageAtEnd: () =>
        t`Expression has an error. \${variable} at end of expression.`,
    advancedFilterBuilderTitle: () => t`Advanced Filter`,
    advancedFilterBuilderApply: () => t`Apply`,
    advancedFilterBuilderCancel: () => t`Cancel`,
    advancedFilterBuilderAddButtonTooltip: () => t`Add Filter or Group`,
    advancedFilterBuilderRemoveButtonTooltip: () => t`Remove`,
    advancedFilterBuilderMoveUpButtonTooltip: () => t`Move Up`,
    advancedFilterBuilderMoveDownButtonTooltip: () => t`Move Down`,
    advancedFilterBuilderAddJoin: () => t`Add Group`,
    advancedFilterBuilderAddCondition: () => t`Add Filter`,
    advancedFilterBuilderSelectColumn: () => t`Select a column`,
    advancedFilterBuilderSelectOption: () => t`Select an option`,
    advancedFilterBuilderEnterValue: () => t`Enter a value...`,
    advancedFilterBuilderValidationAlreadyApplied: () => t`Current filter already applied.`,
    advancedFilterBuilderValidationIncomplete: () => t`Not all conditions are complete.`,
    advancedFilterBuilderValidationSelectColumn: () => t`Must select a column.`,
    advancedFilterBuilderValidationSelectOption: () => t`Must select an option.`,
    advancedFilterBuilderValidationEnterValue: () => t`Must enter a value.`,

    // Side Bar
    columns: () => t`Columns`,
    filters: () => t`Filters`,

    // columns tool panel
    pivotMode: () => t`Pivot Mode`,
    groups: () => t`Row Groups`,
    rowGroupColumnsEmptyMessage: () => t`Drag here to set row groups`,
    values: () => t`Values`,
    valueColumnsEmptyMessage: () => t`Drag here to aggregate`,
    pivots: () => t`Column Labels`,
    pivotColumnsEmptyMessage: () => t`Drag here to set column labels`,

    // Row Drag
    rowDragRow: () => t`row`,
    rowDragRows: () => t`rows`,

    // Other
    loadingOoo: () => t`Loading...`,
    loadingError: () => t`ERR`,
    noRowsToShow: () => t`No Rows To Show`,
    enabled: () => t`Enabled`,

    // Menu
    pinColumn: () => t`Pin Column`,
    pinLeft: () => t`Pin Left`,
    pinRight: () => t`Pin Right`,
    noPin: () => t`No Pin`,
    valueAggregation: () => t`Value Aggregation`,
    noAggregation: () => t`None`,
    autosizeThiscolumn: () => t`Autosize This Column`,
    autosizeAllColumns: () => t`Autosize All Columns`,
    groupBy: () => t`Group by`,
    ungroupBy: () => t`Un-Group by`,
    ungroupAll: () => t`Un-Group All`,
    addToValues: () => t`Add \${variable} to values`,
    removeFromValues: () => t`Remove \${variable} from values`,
    addToLabels: () => t`Add \${variable} to labels`,
    removeFromLabels: () => t`Remove \${variable} from labels`,
    resetColumns: () => t`Reset Columns`,
    expandAll: () => t`Expand All Row Groups`,
    collapseAll: () => t`Close All Row Groups`,
    copy: () => t`Copy`,
    ctrlC: () => t`Ctrl+C`,
    ctrlX: () => t`Ctrl+X`,
    copyWithHeaders: () => t`Copy With Headers`,
    copyWithGroupHeaders: () => t`Copy with Group Headers`,
    cut: () => t`Cut`,
    paste: () => t`Paste`,
    ctrlV: () => t`Ctrl+V`,
    export: () => t`Export`,
    csvExport: () => t`CSV Export`,
    excelExport: () => t`Excel Export`,
    columnFilter: () => t`Column Filter`,
    columnChooser: () => t`Choose Columns`,
    sortAscending: () => t`Sort Ascending`,
    sortDescending: () => t`Sort Descending`,
    sortUnSort: () => t`Clear Sort`,

    // Enterprise Menu Aggregation and Status Bar
    sum: () => t`Sum`,
    first: () => t`First`,
    last: () => t`Last`,
    min: () => t`Min`,
    max: () => t`Max`,
    none: () => t`None`,
    count: () => t`Count`,
    avg: () => t`Average`,
    filteredRows: () => t`Filtered`,
    selectedRows: () => t`Selected`,
    totalRows: () => t`Total Rows`,
    totalAndFilteredRows: () => t`Rows`,
    more: () => t`More`,
    to: () => t`to`,
    of: () => t`of`,
    page: () => t`Page`,
    pageLastRowUnknown: () => t`?`,
    nextPage: () => t`Next Page`,
    lastPage: () => t`Last Page`,
    firstPage: () => t`First Page`,
    previousPage: () => t`Previous Page`,
    pageSizeSelectorLabel: () => t`Page Size:`,
    footerTotal: () => t`Total`,

    // Pivoting
    pivotColumnGroupTotals: () => t`Total`,

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: () => t`Pivot Chart & Pivot Mode`,
    pivotChart: () => t`Pivot Chart`,
    chartRange: () => t`Chart Range`,

    columnChart: () => t`Column`,
    groupedColumn: () => t`Grouped`,
    stackedColumn: () => t`Stacked`,
    normalizedColumn: () => t`100% Stacked`,

    barChart: () => t`Bar`,
    groupedBar: () => t`Grouped`,
    stackedBar: () => t`Stacked`,
    normalizedBar: () => t`100% Stacked`,

    pieChart: () => t`Pie`,
    pie: () => t`Pie`,
    donut: () => t`Donut`,

    line: () => t`Line`,

    xyChart: () => t`X Y (Scatter)`,
    scatter: () => t`Scatter`,
    bubble: () => t`Bubble`,

    areaChart: () => t`Area`,
    area: () => t`Area`,
    stackedArea: () => t`Stacked`,
    normalizedArea: () => t`100% Stacked`,

    histogramChart: () => t`Histogram`,
    histogramFrequency: () => t`Frequency`,

    polarChart: () => t`Polar`,
    radarLine: () => t`Radar Line`,
    radarArea: () => t`Radar Area`,
    nightingale: () => t`Nightingale`,
    radialColumn: () => t`Radial Column`,
    radialBar: () => t`Radial Bar`,

    statisticalChart: () => t`Statistical`,
    boxPlot: () => t`Box Plot`,
    rangeBar: () => t`Range Bar`,
    rangeArea: () => t`Range Area`,

    hierarchicalChart: () => t`Hierarchical`,
    treemap: () => t`Treemap`,
    sunburst: () => t`Sunburst`,

    specializedChart: () => t`Specialized`,
    waterfall: () => t`Waterfall`,
    heatmap: () => t`Heatmap`,

    combinationChart: () => t`Combination`,
    columnLineCombo: () => t`Column & Line`,
    AreaColumnCombo: () => t`Area & Column`,

    // Charts
    pivotChartTitle: () => t`Pivot Chart`,
    rangeChartTitle: () => t`Range Chart`,
    settings: () => t`Settings`,
    data: () => t`Data`,
    format: () => t`Format`,
    categories: () => t`Categories`,
    defaultCategory: () => t`(None)`,
    series: () => t`Series`,
    xyValues: () => t`X Y Values`,
    paired: () => t`Paired Mode`,
    axis: () => t`Axis`,
    radiusAxis: () => t`Radius Axis`,
    navigator: () => t`Navigator`,
    color: () => t`Color`,
    thickness: () => t`Thickness`,
    preferredLength: () => t`Preferred Length`,
    xType: () => t`X Type`,
    automatic: () => t`Automatic`,
    category: () => t`Category`,
    number: () => t`Number`,
    time: () => t`Time`,
    autoRotate: () => t`Auto Rotate`,
    xRotation: () => t`X Rotation`,
    yRotation: () => t`Y Rotation`,
    labelRotation: () => t`Rotation`,
    circle: () => t`Circle`,
    polygon: () => t`Polygon`,
    orientation: () => t`Orientation`,
    fixed: () => t`Fixed`,
    parallel: () => t`Parallel`,
    perpendicular: () => t`Perpendicular`,
    radiusAxisPosition: () => t`Position`,
    ticks: () => t`Ticks`,
    width: () => t`Width`,
    height: () => t`Height`,
    length: () => t`Length`,
    padding: () => t`Padding`,
    spacing: () => t`Spacing`,
    chart: () => t`Chart`,
    title: () => t`Title`,
    titlePlaceholder: () => t`Chart title - double click to edit`,
    background: () => t`Background`,
    font: () => t`Font`,
    top: () => t`Top`,
    right: () => t`Right`,
    bottom: () => t`Bottom`,
    left: () => t`Left`,
    labels: () => t`Labels`,
    calloutLabels: () => t`Callout Labels`,
    sectorLabels: () => t`Sector Labels`,
    positionRatio: () => t`Position Ratio`,
    size: () => t`Size`,
    shape: () => t`Shape`,
    minSize: () => t`Minimum Size`,
    maxSize: () => t`Maximum Size`,
    legend: () => t`Legend`,
    position: () => t`Position`,
    markerSize: () => t`Marker Size`,
    markerStroke: () => t`Marker Stroke`,
    markerPadding: () => t`Marker Padding`,
    itemSpacing: () => t`Item Spacing`,
    itemPaddingX: () => t`Item Padding X`,
    itemPaddingY: () => t`Item Padding Y`,
    layoutHorizontalSpacing: () => t`Horizontal Spacing`,
    layoutVerticalSpacing: () => t`Vertical Spacing`,
    strokeWidth: () => t`Stroke Width`,
    offset: () => t`Offset`,
    offsets: () => t`Offsets`,
    tooltips: () => t`Tooltips`,
    callout: () => t`Callout`,
    markers: () => t`Markers`,
    shadow: () => t`Shadow`,
    blur: () => t`Blur`,
    xOffset: () => t`X Offset`,
    yOffset: () => t`Y Offset`,
    lineWidth: () => t`Line Width`,
    lineDash: () => t`Line Dash`,
    lineDashOffset: () => t`Dash Offset`,
    normal: () => t`Normal`,
    bold: () => t`Bold`,
    italic: () => t`Italic`,
    boldItalic: () => t`Bold Italic`,
    predefined: () => t`Predefined`,
    fillOpacity: () => t`Fill Opacity`,
    strokeColor: () => t`Line Color`,
    strokeOpacity: () => t`Line Opacity`,
    histogramBinCount: () => t`Bin count`,
    connectorLine: () => t`Connector Line`,
    seriesItems: () => t`Series Items`,
    seriesItemType: () => t`Item Type`,
    seriesItemPositive: () => t`Positive`,
    seriesItemNegative: () => t`Negative`,
    seriesItemLabels: () => t`Item Labels`,
    columnGroup: () => t`Column`,
    barGroup: () => t`Bar`,
    pieGroup: () => t`Pie`,
    lineGroup: () => t`Line`,
    scatterGroup: () => t`X Y (Scatter)`,
    areaGroup: () => t`Area`,
    polarGroup: () => t`Polar`,
    statisticalGroup: () => t`Statistical`,
    hierarchicalGroup: () => t`Hierarchical`,
    specializedGroup: () => t`Specialized`,
    combinationGroup: () => t`Combination`,
    groupedColumnTooltip: () => t`Grouped`,
    stackedColumnTooltip: () => t`Stacked`,
    normalizedColumnTooltip: () => t`100% Stacked`,
    groupedBarTooltip: () => t`Grouped`,
    stackedBarTooltip: () => t`Stacked`,
    normalizedBarTooltip: () => t`100% Stacked`,
    pieTooltip: () => t`Pie`,
    donutTooltip: () => t`Donut`,
    lineTooltip: () => t`Line`,
    groupedAreaTooltip: () => t`Area`,
    stackedAreaTooltip: () => t`Stacked`,
    normalizedAreaTooltip: () => t`100% Stacked`,
    scatterTooltip: () => t`Scatter`,
    bubbleTooltip: () => t`Bubble`,
    histogramTooltip: () => t`Histogram`,
    radialColumnTooltip: () => t`Radial Column`,
    radialBarTooltip: () => t`Radial Bar`,
    radarLineTooltip: () => t`Radar Line`,
    radarAreaTooltip: () => t`Radar Area`,
    nightingaleTooltip: () => t`Nightingale`,
    rangeBarTooltip: () => t`Range Bar`,
    rangeAreaTooltip: () => t`Range Area`,
    boxPlotTooltip: () => t`Box Plot`,
    treemapTooltip: () => t`Treemap`,
    sunburstTooltip: () => t`Sunburst`,
    waterfallTooltip: () => t`Waterfall`,
    heatmapTooltip: () => t`Heatmap`,
    columnLineComboTooltip: () => t`Column & Line`,
    areaColumnComboTooltip: () => t`Area & Column`,
    customComboTooltip: () => t`Custom Combination`,
    innerRadius: () => t`Inner Radius`,
    startAngle: () => t`Start Angle`,
    endAngle: () => t`End Angle`,
    reverseDirection: () => t`Reverse Direction`,
    groupPadding: () => t`Group Padding`,
    seriesPadding: () => t`Series Padding`,
    group: () => t`Group`,
    tile: () => t`Tile`,
    whisker: () => t`Whisker`,
    cap: () => t`Cap`,
    capLengthRatio: () => t`Length Ratio`,
    labelPlacement: () => t`Placement`,
    inside: () => t`Inside`,
    outside: () => t`Outside`,
    noDataToChart: () => t`No data available to be charted.`,
    pivotChartRequiresPivotMode: () => t`Pivot Chart requires Pivot Mode enabled.`,
    chartSettingsToolbarTooltip: () => t`Menu`,
    chartLinkToolbarTooltip: () => t`Linked to Grid`,
    chartUnlinkToolbarTooltip: () => t`Unlinked from Grid`,
    chartDownloadToolbarTooltip: () => t`Download Chart`,
    seriesChartType: () => t`Series Chart Type`,
    seriesType: () => t`Series Type`,
    secondaryAxis: () => t`Secondary Axis`,

    // ARIA
    ariaAdvancedFilterBuilderItem: () => t`\${variable}. Level \${variable}. Press ENTER to edit.`,
    ariaAdvancedFilterBuilderItemValidation: () =>
        t`\${variable}. Level \${variable}. \${variable} Press ENTER to edit.`,
    ariaAdvancedFilterBuilderList: () => t`Advanced Filter Builder List`,
    ariaAdvancedFilterBuilderFilterItem: () => t`Filter Condition`,
    ariaAdvancedFilterBuilderGroupItem: () => t`Filter Group`,
    ariaAdvancedFilterBuilderColumn: () => t`Column`,
    ariaAdvancedFilterBuilderOption: () => t`Option`,
    ariaAdvancedFilterBuilderValueP: () => t`Value`,
    ariaAdvancedFilterBuilderJoinOperator: () => t`Join Operator`,
    ariaAdvancedFilterInput: () => t`Advanced Filter Input`,
    ariaChecked: () => t`checked`,
    ariaColumn: () => t`Column`,
    ariaColumnGroup: () => t`Column Group`,
    ariaColumnFiltered: () => t`Column Filtered`,
    ariaColumnSelectAll: () => t`Toggle Select All Columns`,
    ariaDateFilterInput: () => t`Date Filter Input`,
    ariaDefaultListName: () => t`List`,
    ariaFilterColumnsInput: () => t`Filter Columns Input`,
    ariaFilterFromValue: () => t`Filter from value`,
    ariaFilterInput: () => t`Filter Input`,
    ariaFilterList: () => t`Filter List`,
    ariaFilterToValue: () => t`Filter to value`,
    ariaFilterValue: () => t`Filter Value`,
    ariaFilterMenuOpen: () => t`Open Filter Menu`,
    ariaFilteringOperator: () => t`Filtering Operator`,
    ariaHidden: () => t`hidden`,
    ariaIndeterminate: () => t`indeterminate`,
    ariaInputEditor: () => t`Input Editor`,
    ariaMenuColumn: () => t`Press ALT DOWN to open column menu`,
    ariaFilterColumn: () => t`Press CTRL ENTER to open filter`,
    ariaRowDeselect: () => t`Press SPACE to deselect this row`,
    ariaRowSelectAll: () => t`Press Space to toggle all rows selection`,
    ariaRowToggleSelection: () => t`Press Space to toggle row selection`,
    ariaRowSelect: () => t`Press SPACE to select this row`,
    ariaSearch: () => t`Search`,
    ariaSortableColumn: () => t`Press ENTER to sort`,
    ariaToggleVisibility: () => t`Press SPACE to toggle visibility`,
    ariaToggleCellValue: () => t`Press SPACE to toggle cell value`,
    ariaUnchecked: () => t`unchecked`,
    ariaVisible: () => t`visible`,
    ariaSearchFilterValues: () => t`Search filter values`,
    ariaPageSizeSelectorLabel: () => t`Page Size`,

    // ARIA Labels for Drop Zones
    ariaRowGroupDropZonePanelLabel: () => t`Row Groups`,
    ariaValuesDropZonePanelLabel: () => t`Values`,
    ariaPivotDropZonePanelLabel: () => t`Column Labels`,
    ariaDropZoneColumnComponentDescription: () => t`Press DELETE to remove`,
    ariaDropZoneColumnValueItemDescription: () => t`Press ENTER to change the aggregation type`,
    ariaDropZoneColumnGroupItemDescription: () => t`Press ENTER to sort`,
    // used for aggregate drop zone, format: () => {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
    ariaDropZoneColumnComponentAggFuncSeparator: () => t` of `,
    ariaDropZoneColumnComponentSortAscending: () => t`ascending`,
    ariaDropZoneColumnComponentSortDescending: () => t`descending`,

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: () => t`Column Menu`,
    ariaLabelColumnFilter: () => t`Column Filter`,
    ariaLabelCellEditor: () => t`Cell Editor`,
    ariaLabelDialog: () => t`Dialog`,
    ariaLabelSelectField: () => t`Select Field`,
    ariaLabelRichSelectField: () => t`Rich Select Field`,
    ariaLabelTooltip: () => t`Tooltip`,
    ariaLabelContextMenu: () => t`Context Menu`,
    ariaLabelSubMenu: () => t`SubMenu`,
    ariaLabelAggregationFunction: () => t`Aggregation Function`,
    ariaLabelAdvancedFilterAutocomplete: () => t`Advanced Filter Autocomplete`,
    ariaLabelAdvancedFilterBuilderAddField: () => t`Advanced Filter Builder Add Field`,
    ariaLabelAdvancedFilterBuilderColumnSelectField: () =>
        t`Advanced Filter Builder Column Select Field`,
    ariaLabelAdvancedFilterBuilderOptionSelectField: () =>
        t`Advanced Filter Builder Option Select Field`,
    ariaLabelAdvancedFilterBuilderJoinSelectField:
        'Advanced Filter Builder Join Operator Select Field',

    // ARIA Labels for the Side Bar
    ariaColumnPanelList: () => t`Column List`,
    ariaFilterPanelList: () => t`Filter List`,

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: () => t`,`,
    decimalSeparator: () => t`.`,

    // Data types
    true: () => t`True`,
    false: () => t`False`,
    invalidDate: () => t`Invalid Date`,
    invalidNumber: () => t`Invalid Number`,
    january: () => t`January`,
    february: () => t`February`,
    march: () => t`March`,
    april: () => t`April`,
    may: () => t`May`,
    june: () => t`June`,
    july: () => t`July`,
    august: () => t`August`,
    september: () => t`September`,
    october: () => t`October`,
    november: () => t`November`,
    december: () => t`December`,
};

export default AG_GRID_LOCALE_EN;
