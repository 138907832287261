import { FC, MouseEvent as ME, useCallback } from 'react';

import { withSearch } from '@elastic/react-search-ui';
import { useLingui } from '@lingui/react';
import { Grid, Theme, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useToggleStyles = makeStyles((theme: Theme) => ({
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
    },
}));

type SearchToggleButtonsProps = {
    addFilter?: (name: string, value: any, type?: any) => void;
    removeFilter?: (name: string, value?: any, type?: any) => void;
    contentSearch: boolean;
    onToggleChange: (event: ME<HTMLButtonElement, MouseEvent>, value: any) => void;
};

const SearchToggleButtons: FC<SearchToggleButtonsProps> = ({
    addFilter,
    removeFilter,
    contentSearch,
    onToggleChange: handleToggleChange,
}: SearchToggleButtonsProps) => {
    const { i18n } = useLingui();
    const toggleClasses = useToggleStyles();

    const handleToggleChangeWrapper = useCallback((e, newSearchType) => {
        if (newSearchType !== 'contentSearch') {
            addFilter('searchType', 'nameSearch');
        } else {
            removeFilter('searchType');
        }
        handleToggleChange(e, newSearchType);
    }, []);

    return (
        <Grid container flexDirection="column" width="auto">
            <Grid item>
                <ToggleButtonGroup
                    size="small"
                    value={contentSearch ? 'contentSearch' : 'nameSearch'}
                    exclusive
                    onChange={handleToggleChangeWrapper}
                    aria-label="Platform"
                    sx={{
                        mr: 2,
                        height: '56px',
                    }}
                >
                    <Tooltip
                        title={i18n._(
                            'Choose this option if you wish to search for companies based on their name.',
                        )}
                    >
                        <ToggleButton
                            classes={toggleClasses}
                            className={contentSearch ? '' : toggleClasses.selected}
                            value="nameSearch"
                        >
                            {i18n._('Company')}
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip
                        title={i18n._(
                            'Choose this option if you want to search for companies based on the content that matches your search words.',
                        )}
                    >
                        <ToggleButton
                            classes={toggleClasses}
                            className={contentSearch ? toggleClasses.selected : ''}
                            value="contentSearch"
                        >
                            {i18n._('Content')}
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );
};

export default withSearch(({ addFilter, removeFilter }) => ({ addFilter, removeFilter }))(
    SearchToggleButtons,
) as FC<SearchToggleButtonsProps>;
