import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { sanitizeProjectInfo } from './sanitizers';
import { ProjectInfoFetcher, patchProjectInfoAsync } from './thunk';

export const projectInfoSlice = createSlice({
    name: 'projectInfo',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        ProjectInfoFetcher.reducers(builder);
        builder.addCase(patchProjectInfoAsync.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        });
        builder.addCase(patchProjectInfoAsync.fulfilled, (state, action) => {
            const sanitizedProjectInfo = sanitizeProjectInfo(action.payload);
            state.data = sanitizedProjectInfo;
            state.status = 'succeeded';
            state.loading = false;
        });
        builder.addCase(patchProjectInfoAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.loading = false;
        });
    },
});
