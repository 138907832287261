import React from 'react';

import { Grid, GridProps, Typography } from '@mui/material';

import './suggestion.css';

interface SuggestionProps extends GridProps {
    suggestion: string | React.ReactNode;
}

const Suggestion = ({ suggestion, ...gridProps }: SuggestionProps) => {
    const suggestionContent =
        typeof suggestion === 'string' ? <Typography>{suggestion}</Typography> : suggestion;

    return (
        <Grid
            {...gridProps}
            height="100%"
            sx={{ p: 1, borderRadius: 1, backgroundColor: 'baseColors.color3', ...gridProps?.sx }}
        >
            {suggestionContent}
        </Grid>
    );
};

export default Suggestion;
