import { Trans } from '@lingui/macro';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import { Information, ListInformation } from 'src/components/typography';
import Note from 'src/components/typography/note';
import { useCurrencies } from 'src/currencies/hook';
import useAuth from 'src/hooks/useAuth';
import EditAction from 'src/project/components/editAction';
import {
    useBudgetApprovalStatusTranslation,
    useEngangementModeTranslation,
} from 'src/project/hooks';
import { useProject, useProjectInfo } from 'src/project/state/hook';
import { BudgetApprovalStatus } from 'src/project/types';
import { useCountries } from 'src/redux/countries/hook';
import { useIndustryStandards } from 'src/redux/industryStandards/hook';
import { useServiceStandards } from 'src/redux/serviceStandards/hook';
import { NumberParser, dateFormatterShort } from 'src/utils/formattingUtils';
import getOptionKey from 'src/utils/language/getOptionKey';

interface ListItemsProps {
    items?: Array<any>;
}

const ListItems = ({ items }: ListItemsProps): any =>
    items?.map(({ title, description }, index) => (
        <ListInformation key={index} title={title} value={description} number={index + 1} />
    )) || null;

const ProjectInformation = () => {
    const { user } = useAuth();
    const countries = useCountries();
    const services = useServiceStandards();
    const currencies = useCurrencies();
    const projectInfo = useProjectInfo();
    const project = useProject();
    const selectedServiceValue = services.find(
        (item) => item.id === projectInfo?.selectedServiceID,
    );

    const mainLocation = countries.find((item) => item?.id === projectInfo?.primaryProjectLocation);
    const secondaryLocations = (projectInfo?.secondaryProjectLocations || []).reduce(
        (acc, currentID) => {
            const location = countries.find((item) => item?.id === currentID);
            if (location) {
                acc.push(location);
            }
            return acc;
        },
        [],
    );

    const industries = useIndustryStandards();
    const selectedIndustry = industries.find((item) => item.id === projectInfo?.industryStandardID);
    const selectedCurrency = currencies.find(
        (item) => item.rawID === projectInfo?.budgetEstimateCurrency,
    );

    const engagementMode = useEngangementModeTranslation(projectInfo.engagementMode);

    const budgetApprovalStatus = useBudgetApprovalStatusTranslation(
        projectInfo.budgetApprovalStatus,
    );

    return (
        <>
            <BucketSection
                title={<Trans>Project Information</Trans>}
                actions={<EditAction editRoute="#project-brief" />}
            >
                <Bucket xs={12} sm={6} md={4}>
                    <Information title={<Trans>Project Name</Trans>} value={project.projectTitle} />
                    <Information
                        title={<Trans>Project scope (Service)</Trans>}
                        value={getOptionKey(selectedServiceValue, 'service')}
                    />
                    <Information
                        title={<Trans>Client Industry</Trans>}
                        value={getOptionKey(selectedIndustry, 'industry')}
                    />
                </Bucket>
                <Bucket xs={12} sm={6} md={4}>
                    <Information
                        title={<Trans>Project Start Date</Trans>}
                        value={dateFormatterShort(
                            navigator.language,
                            Math.floor(new Date(projectInfo?.startDate).getTime()),
                        )}
                    />
                    <Information
                        title={<Trans>Project End Date</Trans>}
                        value={dateFormatterShort(
                            navigator.language,
                            Math.floor(new Date(projectInfo?.endDate).getTime()),
                        )}
                    />
                    <Information
                        title={<Trans>Internal Organization Reference</Trans>}
                        value={projectInfo?.internalOrganisationReference}
                    />
                </Bucket>
                <Bucket xs={12} sm={6} md={4}>
                    <Information title={<Trans>Mode of engagement</Trans>} value={engagementMode} />
                    <Information
                        title={<Trans>Project main location</Trans>}
                        value={getOptionKey(mainLocation, 'commonName')}
                    />
                    <Information
                        title={<Trans>Secondary locations</Trans>}
                        value={
                            secondaryLocations.length > 0
                                ? secondaryLocations
                                      .map((location) => `${getOptionKey(location, 'commonName')}`)
                                      .join(', ')
                                : undefined
                        }
                    />
                </Bucket>
            </BucketSection>
            {user?.isClient && (
                <BucketSection
                    title={<Trans>Budget Information</Trans>}
                    actions={<EditAction editRoute="#project-brief" />}
                >
                    <Bucket xs={12} sm={6} md={4}>
                        <Information
                            title={<Trans>Budget Approval Status</Trans>}
                            value={budgetApprovalStatus}
                        />
                    </Bucket>
                    <Bucket xs={12} sm={6} md={4}>
                        <Information
                            title={<Trans>Budget Estimate</Trans>}
                            value={
                                projectInfo?.budgetApprovalStatus !==
                                    BudgetApprovalStatus.unknown &&
                                new NumberParser(user.formattingLocale).format(
                                    projectInfo?.budgetEstimate,
                                    null,
                                )
                            }
                        />
                    </Bucket>
                    <Bucket xs={12} sm={6} md={4}>
                        <Information
                            title={<Trans>Budget Estimate Currency</Trans>}
                            value={
                                projectInfo?.budgetApprovalStatus !==
                                    BudgetApprovalStatus.unknown &&
                                selectedCurrency?.isoCurrencyCode
                            }
                        />
                    </Bucket>
                    <Bucket xs={12}>
                        <Note
                            sx={{ fontSize: 13 }}
                            value={
                                <Trans>Note: Budget information is not displayed to vendors</Trans>
                            }
                        />
                    </Bucket>
                </BucketSection>
            )}
            <BucketSection
                isVertical
                title={<Trans>Project Information</Trans>}
                actions={<EditAction editRoute="#project-outline" />}
            >
                <Bucket>
                    <Information
                        title={<Trans>Initial Situation</Trans>}
                        value={projectInfo?.initialSituation}
                    />
                </Bucket>
                <Bucket>
                    <Information
                        title={<Trans>Project Approach</Trans>}
                        value={projectInfo?.projectApproach}
                    />
                </Bucket>
            </BucketSection>
            <BucketSection
                title={<Trans>Objectives</Trans>}
                actions={<EditAction editRoute="#project-outline" />}
            >
                <Bucket>
                    <ListItems items={projectInfo?.projectObjectives} />
                </Bucket>
            </BucketSection>
            <BucketSection
                title={<Trans>Deliverables</Trans>}
                actions={<EditAction editRoute="#project-outline" />}
            >
                <Bucket>
                    <ListItems items={projectInfo?.projectDeliverables} />
                </Bucket>
            </BucketSection>
        </>
    );
};

export default ProjectInformation;
