import React from 'react';
import { GraphQLTaggedNode } from 'react-relay';

import { EMPTY_OBJECT } from 'src/utils/empty';

import useQueryRefetch from './useQueryRefetch';

export function useGenericQuery(query: GraphQLTaggedNode, params: any = EMPTY_OBJECT): any {
    const { refetch, data } = useQueryRefetch(query);

    React.useEffect(() => {
        refetch(params);
    }, [refetch, params]);

    return data;
}

/**
 * Maps a relay-query result to a list of items.
 * It also tried to replace id with entryId if it exists and adds a relayId field.
 * @param results
 */
export function mapEdgesToList(results) {
    const data = results?.edges?.map((edge) => edge.node) ?? [];
    return data.map((item) => ({
        ...item,
        id: item.entryId || item.id,
        relayId: item.id,
    }));
}
