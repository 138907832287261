import type { ProjectBriefFormData } from 'src/projectBrief/types';

export const sanitizeProjectBrief = (apiData: any): ProjectBriefFormData => ({
    id: apiData.id,
    projectTitle: apiData.project_name,
    startDate: apiData.start_date,
    endDate: apiData.end_date,
    internalDescription: apiData.internal_description,
    projectApproach: apiData.project_approach || '',
    initialSituation: apiData.initial_situation,
    internalOrganisationReference: apiData.organization_project_reference || '',
    engagementMode: apiData.remote_consulting,
    projectObjectives: (apiData.quote_project_goals || [{ name: '', description: '' }]).map(
        (item) => ({
            title: item.name,
            description: item.description,
        }),
    ),
    projectDeliverables: (apiData.project_deliverables || [{ name: '', description: '' }]).map(
        (item) => ({
            title: item.name,
            description: item.description,
        }),
    ),
    secondaryProjectLocations: apiData.secondary_locations,
    selectedServiceID: btoa(`ServiceStandardNode:${apiData.service_standard}`),
    industryStandardID: btoa(`IndustryStandardNode:${apiData?.industry_standard}`),
    primaryProjectLocation: apiData.main_location,
    budgetEstimate: apiData.budget_estimate,
    budgetApprovalStatus: apiData.budget_approval_status,
    budgetEstimateCurrency: apiData.budget_estimate_currency,
});
