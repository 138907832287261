import { t } from '@lingui/macro';

const useErrorMessage = () => {
    const extractErrorMessage = (errors: any, fallback?: string) => {
        if (errors === null || errors.length === 0) {
            return '';
        }

        const translationKey = errors[0].errorCode || fallback;

        switch (translationKey) {
            case 'genericError':
                return t`Something went wrong`;
            case 'connectionError':
                return t`Connection error`;
            case 'submitFailed':
                return t`Submit failed. Some of the steps are not complete`;
            case 'permissionError':
                return t`Permission required`;
            case 'cannotCreateQuote':
                return t`Cannot create quote`;
            case 'cannotMarkAllNotificationsAsRead':
                return t`Cannot mark all notifications as read`;
            case 'cannotCreateProject':
                return t`Cannot create project`;
            case 'tenderPlanningError':
                return t`The Tender Planning step contains errors`;
            case 'timelineError':
                return t`Cannot save timeline`;
            case 'supplierSelectionError':
                return t`The Supplier Selection step contains errors`;
            case 'ratecardError':
                return t`The Rate Card step contains errors`;
            case 'otherInformationError':
                return t`The Other Information step contains errors`;
            case 'cannotAddQuestion':
                return t`Cannot add question`;
            case 'cannotAnswer':
                return t`Cannot answer the question`;
            case 'cannotUpdateQuestion':
                return t`Cannot update the question`;
            case 'cannotUpdateAnswer':
                return t`Cannot update the answer`;
            case 'cannotAddAnnouncementError':
                return t`Cannot add announcement`;
            case 'userManagementAddError':
                return t`The user already exists in apadua. Please use the “add existing user” functionality to add this user to your project.`;
            case 'userManagementEditError':
                return t`Error while editing the user`;
            case 'userManagementDeleteError':
                return t`Error while removing the user`;
            case 'organizationInviteError':
                return t`Error while inviting the user`;
            case 'organizationEditError':
                return t`Error while editing the user`;
            case 'cannotRejectInvitationError':
                return t`Cannot reject the invitation`;
            case 'cannotRejectTermsError':
                return t`Cannot reject the terms`;
            case 'cannotAcceptInvitationError':
                return t`Cannot accept the invitation`;
            case 'attachmentsError':
                return t`The Attachments step contains errors`;
            case 'rebatesCantBeBlank':
                return t`Rebates can't be blank`;
            case 'ratesMustBeNumbers':
                return t`Number formatting incorrect. Please check all your rates and prices for errors highlighted in red.`;
            case 'ratesMustNotBeBlank':
                return t`Rates must not be blank`;
            case 'atLeastOneRate':
                return t`You have to provide at least one rate`;
            case 'cannotSaveBids':
                return t`Cannot save bids. Try again later.`;
            case 'cannotConfirmProposalError':
                return t`Cannot confirm the rate card proposal`;
            case 'cannotRejectProposalError':
                return t`Cannot reject the rate card proposal`;
            case 'cannotRevokeProposalError':
                return t`Cannot revoke the invitation`;
            case 'cannotDeleteProposalError':
                return t`Cannot delete the rate card proposal`;
            case 'cannotConfirmBidsError':
                return t`Cannot confirm the bids`;
            case 'cannotRejectBidsError':
                return t`Cannot reject the bids`;
            case 'cannotRequestBidsError':
                return t`Cannot request new bids`;
            case 'cannotSetBackProposalError':
                return t`Cannot set proposal back to accepted`;
            case 'cannotEnterEditMode':
                return t`Cannot enter edit mode`;
            case 'cannotLeaveEditMode':
                return t`Cannot leave edit mode`;
            case 'exitEditModeError':
                return t`Error while exiting edit mode`;
            case 'editModeMessageError':
                return t`Cannot submit message`;
            case 'cannotSetOnHold':
                return t`Cannot set rate card tender on hold`;
            case 'cannotLeaveOnHold':
                return t`Cannot resume rate card tender`;
            case 'cannotCancelProject':
                return t`Cannot cancel rate card tender`;
            case 'cannotMarkAsComleted':
                return t`Cannot mark tender as completed`;
            case 'proposalsNotMarked':
                return t`All proposals should be marked as confirmed/rejected/dismissed or deleted before marking the project as completed`;
            case 'tokenExpired':
                return t`Your token has expired, please log in again.`;
            default:
                return t`Something went wrong`;
        }
    };

    return extractErrorMessage;
};

export default useErrorMessage;
