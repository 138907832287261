import memoize from 'lodash/memoize';
import { useEffect, useState } from 'react';

import TextTranslationClient, {
    TranslatorCredential,
    isUnexpected,
} from '@azure-rest/ai-translation-text';
import { useLingui } from '@lingui/react';

const translateCedential: TranslatorCredential = {
    key: window.AZURE_TRANSLATOR_KEY,
    region: window.AZURE_TRANSLATOR_REGION,
};
const translationClient = TextTranslationClient(window.AZURE_TRANSLATOR_API, translateCedential);

const translate = memoize((textToTranslate, locale) => {
    const inputText = [{ text: textToTranslate }];
    const translation = translationClient
        .path('/translate')
        .post({
            body: inputText,
            queryParameters: {
                to: locale,
                from: 'en',
            },
        })
        .then(
            (translateResponse) => {
                if (isUnexpected(translateResponse)) {
                    return textToTranslate;
                }
                const translations = translateResponse.body;
                return translations[0]?.translations[0]?.text;
            },
            () => textToTranslate,
        );

    return translation;
});

const useTranslatedText = (textToTranslate: string) => {
    const { i18n } = useLingui();
    const [translatedText, setTranslatedText] = useState(textToTranslate);

    useEffect(() => {
        if (i18n.locale === 'en') {
            setTranslatedText(textToTranslate);
        } else {
            translate(textToTranslate, i18n.locale).then((translation) => {
                setTranslatedText(translation);
            });
        }
    }, [i18n.locale]);

    return translatedText;
};

export default useTranslatedText;
