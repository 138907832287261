import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import MuiTextField from '@mui/material/TextField';

import { Autocomplete } from 'src/components/formik-material-ui/Autocomplete';
import { useAppContext } from 'src/contexts/AppContext';

interface CurrencyNode {
    id: string;
    isoCurrencyCode: string;
}

function currencyToFlag(currency: CurrencyNode) {
    if (!currency || !currency.isoCurrencyCode) {
        return '';
    }
    return typeof String.fromCodePoint !== 'undefined'
        ? currency.isoCurrencyCode
              .slice(0, 2)
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : currency.isoCurrencyCode.slice(0, 2);
}

export const CurrencySelectAllCurrencies2Query = graphql`
    query CurrencySelectAllCurrencies2Query {
        allCurrencies {
            edges {
                node {
                    id
                    isoCurrencyCode
                }
            }
        }
    }
`;

export const CurrencySelect: FC<any> = (props: any) => {
    const { globalQueryRef } = useAppContext();
    const data = usePreloadedQuery(CurrencySelectAllCurrencies2Query, globalQueryRef);

    const allCurrencies = data.allCurrencies || { edges: [] };
    const options = allCurrencies.edges.map((edge) => edge.node);

    const { required } = props;

    return (
        <Autocomplete
            {...props}
            isOptionEqualToValue={(option: CurrencyNode, value?: CurrencyNode) =>
                option?.id === value?.id
            }
            getOptionLabel={(option: CurrencyNode): string =>
                option?.isoCurrencyCode !== '%'
                    ? `${currencyToFlag(option)} ${option?.isoCurrencyCode}`
                    : '%'
            }
            options={options}
            renderInput={(params: any): JSX.Element => (
                <MuiTextField
                    fullWidth
                    variant="outlined"
                    name={props.field.name}
                    {...params}
                    error={
                        props.validateontouch
                            ? !!(
                                  props.form.touched[props.field.name] &&
                                  props.form.errors[props.field.name]
                              )
                            : !!props.form.errors[props.field.name]
                    }
                    helperText={
                        props.validateontouch &&
                        props.form.touched[props.field.name] &&
                        props.form.errors[props.field.name]
                            ? props.form.errors[props.field.name]
                            : props.helpertext || props.form.errors[props.field.name]
                    }
                    label={props.label || 'Currency'}
                    required={!!required}
                />
            )}
        />
    );
};
