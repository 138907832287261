/**
 * @generated SignedSource<<7b41f2fc00b371ff0637a0baa7f0dfd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RatecardProjectLeaveEditModeMutationInput = {
  clientMutationId?: string | null;
  message?: string | null;
  ratecardProjectId: string;
};
export type EditModeLeaveMessageDialogExitEditModeMutation$variables = {
  input: RatecardProjectLeaveEditModeMutationInput;
};
export type EditModeLeaveMessageDialogExitEditModeMutation$data = {
  readonly ratecardProjectLeaveEditMode: {
    readonly ratecardProject: {
      readonly id: string;
    } | null;
  } | null;
};
export type EditModeLeaveMessageDialogExitEditModeMutation = {
  response: EditModeLeaveMessageDialogExitEditModeMutation$data;
  variables: EditModeLeaveMessageDialogExitEditModeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RatecardProjectLeaveEditModeMutationPayload",
    "kind": "LinkedField",
    "name": "ratecardProjectLeaveEditMode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RatecardProjectNode",
        "kind": "LinkedField",
        "name": "ratecardProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditModeLeaveMessageDialogExitEditModeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditModeLeaveMessageDialogExitEditModeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "736400a52f53837724ac0e09de053cda",
    "id": null,
    "metadata": {},
    "name": "EditModeLeaveMessageDialogExitEditModeMutation",
    "operationKind": "mutation",
    "text": "mutation EditModeLeaveMessageDialogExitEditModeMutation(\n  $input: RatecardProjectLeaveEditModeMutationInput!\n) {\n  ratecardProjectLeaveEditMode(input: $input) {\n    ratecardProject {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "47d9a7fda124df590fc72f0ed508bd31";

export default node;
