import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';

import { LoginNotes } from 'src/login/types';
import type { SSOResponse } from 'src/pages/companyLogin/loader';
import { navigateAction } from 'src/sagas/actions';
import { NavigatePayload } from 'src/types/navigation';

import Logo from '../../components/Logo';
import { LoginJWT } from '../../components/authentication/login';
import gtm from '../../lib/gtm';

interface Props {
    withSSO?: boolean;
}

const Login = ({ withSSO }: Props) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    const [queryParams] = useSearchParams();
    const note = queryParams.get('note');
    const nextURL = queryParams.get('next');

    const data = useLoaderData() as SSOResponse;
    const handleSSOLogin = () => {
        const payload = { url: data?.loginURL } as NavigatePayload;
        if (nextURL) {
            payload.searchParams = { next: nextURL };
        }
        dispatch(navigateAction(payload));
    };

    return (
        <>
            <Helmet>
                <title>{i18n._('Log in')}</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Container maxWidth="sm" sx={{ py: '80px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 8,
                        }}
                    >
                        <RouterLink to="/">
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 140,
                                }}
                            />
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3,
                                }}
                            >
                                <div>
                                    <Typography color="textPrimary" gutterBottom variant="h4">
                                        {i18n._('Log in')}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                        {i18n._('Log in on the Apadua platform')}
                                    </Typography>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3,
                                }}
                            >
                                <LoginJWT />
                            </Box>
                            {withSSO && (
                                <Box sx={{ mt: 1 }}>
                                    <LoadingButton
                                        color="primary"
                                        onClick={handleSSOLogin}
                                        disabled={!data?.loginURL}
                                        fullWidth
                                        size="large"
                                        data-testid="singleSignOnButton"
                                        variant="contained"
                                    >
                                        <Trans>Single sign-on</Trans>
                                    </LoadingButton>
                                </Box>
                            )}
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="info">
                                    <div>
                                        <Trans>
                                            Use the credentials for your
                                            <b> APADUA </b>account
                                        </Trans>
                                    </div>
                                </Alert>
                            </Box>
                            {note === LoginNotes.LOGIN_TO_CONNECT_SSO_ACCOUNT ? (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="warning">
                                        <div data-testid="ssoExistingAccountNote">
                                            <Trans>
                                                The account you are trying to access already exist.
                                                For security reasons you need to login with your
                                                apadua account and connect your single sign-on
                                                account from the Security tab in your account
                                                settings
                                            </Trans>
                                        </div>
                                    </Alert>
                                </Box>
                            ) : null}
                            {note === LoginNotes.SESSION_EXPIRED ? (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="warning">
                                        <div>
                                            <Trans>
                                                Your session has expired. Please login again. You
                                                can maintain a single session at a time. If you are
                                                logged in on another device, your session will be
                                                expired.
                                            </Trans>
                                        </div>
                                    </Alert>
                                </Box>
                            ) : null}
                            {note === LoginNotes.SSO_SIGNUP_DISABLED ? (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="warning">
                                        <div>
                                            <Trans>
                                                Single sign-on signup is disabled for new users.
                                                Please contact your administrator to add you as a
                                                user to apadua.
                                            </Trans>
                                        </div>
                                    </Alert>
                                </Box>
                            ) : null}
                            <Divider sx={{ my: 3 }} />
                            <Link
                                color="textSecondary"
                                component={RouterLink}
                                sx={{ mt: 1 }}
                                to="/authentication/password-recovery"
                                variant="body2"
                            >
                                {i18n._('Forgot password')}
                            </Link>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
