import { FC, Suspense } from 'react';

import { NotificationPopupComponent } from 'src/components/notifications/popup/NotificationPopupComponent';
import { NotificationIconSkeleton } from 'src/skeletons/notifications/NotificationIconSkeleton';

export const NotificationPopup: FC = () => (
    <Suspense fallback={<NotificationIconSkeleton />}>
        <NotificationPopupComponent />
    </Suspense>
);
