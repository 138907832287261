import * as React from 'react';

import {
    Table as MuiTable,
    TableClasses,
    TableContainer,
    TableContainerProps,
} from '@mui/material';

interface TableProps extends TableContainerProps {
    component: React.ReactNode;
    children: React.ReactNode;
    tableStyle?: React.CSSProperties;
    classes?: Partial<TableClasses>;
}
const Table = ({ children, tableStyle, classes, ...rest }: TableProps) => (
    <TableContainer {...rest}>
        <MuiTable classes={classes} style={tableStyle}>
            {children}
        </MuiTable>
    </TableContainer>
);

export default Table;
