import React from 'react';

import { useLingui } from '@lingui/react';
import { TextareaAutosizeProps } from '@mui/base';
import { styled } from '@mui/material/styles';

import { UnstyledTextarea } from 'src/components/common/styledTextArea';

export interface StyledTextareaProps extends TextareaAutosizeProps {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    done?: boolean;
}

const StyledTextarea = styled(UnstyledTextarea)<StyledTextareaProps>(({ theme, done }) => ({
    maxWidth: '100%',
    maxHeight: '60vh',
    fontWeight: 400,
    lineHeight: 1.3,
    width: '100%',
    paddingLeft: 10,
    backgroundColor: theme.palette.baseColors.color5,
    border: 'none',
    '&:focus-visible': {
        outline: 0,
    },
    textDecoration: done ? 'line-through' : 'none',
}));

const ChecklistItemTextArea = ({ name, done, value, onBlur, ...rest }: any) => {
    const { i18n } = useLingui();
    const [text, setText] = React.useState(value);
    const handleChange = (e) => {
        if (!done) {
            setText(e.target.value);
        }
    };

    const handleBlur = React.useCallback(() => {
        onBlur(name, text);
    }, [name, text]);

    return (
        <StyledTextarea
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            minRows={1}
            done={done}
            placeholder={i18n._('Optional: Create subitems or a descriptive text')}
            {...rest}
        />
    );
};

export default ChecklistItemTextArea;
