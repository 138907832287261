import { graphql } from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay/hooks';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Divider } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useRatecardProposalQuery } from 'src/components/ratecardproposals/RatecardProposalProvider';
import CalendarIcon from 'src/icons/Calendar';

import { RatecardProposalStepper } from './RatecardProposalStepper';

export const RatecardProposalSidebar: FC = () => {
    const { i18n } = useLingui();
    const { ratecardProposalQuery } = useRatecardProposalQuery();
    const location = useLocation();

    const ratecardProposal = useFragment(
        graphql`
            fragment RatecardProposalSidebar_ratecardProposal on RatecardProposalNode {
                id
                projectId
                projectName
                state
                projectInformation {
                    projectTimezone
                    state
                    selectiveBids
                    rates
                    supplements
                    vat
                    onHold
                }
                validation {
                    tenderIntroduction
                    ratecard
                    attachments
                    allSuccessful
                    errorMessage
                }
            }
        `,
        ratecardProposalQuery.ratecardProposal,
    );

    const proposalId = ratecardProposal?.id;
    const ratecardId = ratecardProposal?.projectId;
    const projectName = ratecardProposal?.projectName;

    let state = ratecardProposal?.state;

    if (ratecardProposal?.projectInformation?.state === 'cancelled') {
        state = 'cancelled';
    }

    if (ratecardProposal?.projectInformation?.onHold) {
        state = 'paused';
    }

    const sections = [
        {
            title: i18n._('Other'),
            items: [
                {
                    title: i18n._('Announcements'),
                    path: `/dashboard/ratecards/${ratecardId}/proposals/${proposalId}/setup/announcements`,
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: `/dashboard/ratecards/${ratecardId}/proposals/${proposalId}/setup/questions`,
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: `/dashboard/ratecards/${ratecardId}/proposals/${proposalId}/setup/documents`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: `/dashboard/ratecards/${ratecardId}/proposals/${proposalId}/setup/users`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Calendar'),
                    path: `/dashboard/ratecards/${ratecardId}/proposals/${proposalId}/setup/calendar`,
                    icon: <CalendarIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            {projectName ? (
                <StepperTitle
                    title={projectName}
                    status={state}
                    projectType="proposals"
                    userType="consultant"
                />
            ) : null}
            <RatecardProposalStepper orientation="vertical" />
            <Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
