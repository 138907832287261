import { FC, useState } from 'react';
import type { ComponentType } from 'react';
import ReactPlayer from 'react-player';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, IconButton } from '@mui/material';

import Modal from './modal';

const defaultTriggerButtonComponent = ({ handleOpen }) => (
    <IconButton size="small" color="primary" onClick={handleOpen} aria-label="modal-info">
        <InfoIcon fontSize="small" color="secondary" />
    </IconButton>
);

interface VideoModalProps {
    triggerButtonComponent?: ComponentType<{
        handleOpen: () => void;
        triggerButtonText: string;
    }>;
    videoUrl: string;
}

const VideoModal: FC<VideoModalProps> = ({ triggerButtonComponent, videoUrl }: VideoModalProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            externalControl
            onClose={handleClose}
            onOpen={handleOpen}
            isOpen={isOpen}
            triggerButtonComponent={triggerButtonComponent || defaultTriggerButtonComponent}
            style={{
                width: 'auto',
                padding: 0,
            }}
        >
            <Box>
                <Grid sx={{ pl: 4, pr: 4, pt: 2, pb: 4 }}>
                    <ReactPlayer
                        url={videoUrl}
                        className="react-player"
                        playing
                        controls={!!true}
                        width="880px"
                        height="495px"
                    />
                </Grid>
            </Box>
        </Modal>
    );
};

export default VideoModal;
