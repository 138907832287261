import { t } from '@lingui/macro';

import { Cancellation } from 'src/proposal/types';

export const translateEvent = (title) => {
    switch (title) {
        case 'RfP Release Due':
            return t`RfP Release Due`;
        case 'Proposal Due':
            return t`Proposal Due`;
        case 'Pitch Presentation Start':
            return t`Pitch Presentation Start`;
        case 'Pitch Presentation End':
            return t`Pitch Presentation End`;
        case 'Project Award':
            return t`Project Award`;
        case 'Project Start Date':
            return t`Project Start Date`;
        case 'Project End Date':
            return t`Project End Date`;
        default:
            return title;
    }
};

export const translateCancellationCause = (cause: string): string => {
    switch (cause) {
        case Cancellation.CONFLICT_OF_INTEREST:
            return t`Conflict of interest`;
        case Cancellation.NO_CAPACITY_TO_CONDUCT_THE_PROJECT:
            return t`No capacity to conduct the project`;
        case Cancellation.NO_CAPACITY_TO_PARTICIPATE_IN_TENDER:
            return t`No capacity to participate in tender`;
        case Cancellation.NO_EXPERTISE_IN_REQUESTED_SUBJECT_MATTER:
            return t`No expertise in requested subject matter`;
        case Cancellation.TENDER_INFORMATION_NOT_SUFFICIENT:
            return t`Tender information not suffient`;
        case Cancellation.TENDER_TIMELINE_TOO_SHORT:
            return t`Tender timeline too short`;
        case Cancellation.OTHERS:
            return t`Others`;
        default:
            return '';
    }
};
