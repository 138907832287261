import { Dispatch, SetStateAction, createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface Filters {
    [key: string]: any;
}

interface CompanyExploreContextType {
    results?: any[];
    setResults?: Dispatch<SetStateAction<any[]>>;
    companyResultsQueryRef?: PreloadedQuery | null;
    refreshCompanyResultsQuery?: (appliedfilters?: Filters) => void;
    filters: Filters;
    setFilters: (filters: Filters) => void;
    totalCount?: number;
    setTotalCount?: Dispatch<SetStateAction<number>>;
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
}

const initialValues: CompanyExploreContextType = {
    results: null,
    setResults: null,
    companyResultsQueryRef: null,
    filters: {},
    setFilters: () => {},
    refreshCompanyResultsQuery: () => {},
    totalCount: 0,
    setTotalCount: () => {},
    search: '',
    setSearch: () => {},
};

export const CompanyExploreContext = createContext(initialValues);
