import { ListData, TagRelation } from 'src/apis/ListsAPI';

export const sanitizeList = (
    apiData: ListData,
): Omit<ListData, 'tags_relations'> & { tagsRelations: TagRelation[] } => ({
    id: apiData.id,
    name: apiData.name,
    note: apiData.note,
    tagsRelations: apiData.tags_relations,
});
