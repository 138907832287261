import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { GlobalState } from '../../../redux/store';
import { clearSelectedVendors, deselectVendors, selectVendors } from './reducer';
import { fetchTotalVendorsCount } from './thunk';

const vendorsSelector = (state: GlobalState) => state.vendors;

const selectedVendorsSelector = (state: GlobalState) => vendorsSelector(state).selected;

const totalVendorsCountSelector = (state: GlobalState) => vendorsSelector(state).totalVendorsCount;

export const useSelectedVendors = () => useAppSelector(selectedVendorsSelector);
export const useTotalVendorsCount = () => useAppSelector(totalVendorsCountSelector);

export const useSelectVendor = () => {
    const dispatch = useAppDispatch();

    return {
        selectVendors: (ids) => {
            dispatch(selectVendors(ids));
        },
        deselectVendors: (ids) => {
            dispatch(deselectVendors(ids));
        },
        clearSelectedVendors: () => dispatch(clearSelectedVendors(undefined)),
    };
};

export const useFetchTotalVendorsCount = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchTotalVendorsCount());
    }, [dispatch]);
};
