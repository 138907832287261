import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

const ExploreSelectionHeader = () => (
    <Grid>
        <Typography variant="h5" color="primary">
            <Trans>Explore vendors database</Trans>
        </Typography>
    </Grid>
);

export default ExploreSelectionHeader;
