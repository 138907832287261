import React, { ReactNode } from 'react';

import { PagingInfo, ResultsPerPage } from '@elastic/react-search-ui';
import { useLingui } from '@lingui/react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import SessionManager from 'src/login/sessionManager';
import { NumberParser } from 'src/utils/formattingUtils';

import { ApaduaRole } from '../../../types/user';
import { useSelectedVendors } from '../state/hooks';
import ExploreAddToListButton from './exploreAddToListButton';
import ExploreAddToOrganisationListButton from './exploreAddToOrganisationListButton';

interface ExploreResultsHeaderProps {
    widgets?: ReactNode;
}

const ExploreResultsHeader: React.FC<ExploreResultsHeaderProps> = ({
    widgets,
}: ExploreResultsHeaderProps) => {
    const { i18n } = useLingui();
    const selectedVendors = useSelectedVendors();
    const { user } = useAuth();
    const isOrganisationAdmin = user?.apaduaRole === ApaduaRole.ADMIN;
    const isTenantSet = Boolean(SessionManager.getCurrentTenantId());

    return (
        <Grid container>
            <Grid container xs justifyContent="start" alignItems="center">
                <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{ ml: 0.5, mr: 1 }}
                >{`Selected ${selectedVendors.length} vendors`}</Typography>
                {!widgets && isTenantSet && <ExploreAddToListButton />}
                {isOrganisationAdmin && isTenantSet && !widgets && (
                    <ExploreAddToOrganisationListButton />
                )}
                {widgets}
            </Grid>
            <Grid container xs justifyContent="flex-end" sx={{ width: 'auto' }}>
                <PagingInfo
                    view={({ start, end, totalResults }) => (
                        <>
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                sx={{ px: '12px', py: '10px' }}
                            >
                                {i18n._('Showing')}{' '}
                                <strong>{`${new NumberParser(user.formattingLocale).format(
                                    start,
                                    null,
                                )} - ${new NumberParser(user.formattingLocale).format(
                                    end,
                                    null,
                                )}`}</strong>{' '}
                                {i18n._('out of')}{' '}
                                <strong>{`${new NumberParser(user.formattingLocale).format(
                                    totalResults,
                                    null,
                                )}`}</strong>
                            </Typography>
                            <ResultsPerPage
                                options={[10, 25, 50, 100]}
                                view={({ onChange, options, value }) => (
                                    <FormControl size="small">
                                        <InputLabel>{i18n._('Display')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            label={i18n._('Display')}
                                            id="demo-simple-select"
                                            value={value}
                                            onChange={(e) => onChange(Number(e.target.value))}
                                            style={{
                                                backgroundColor: 'white',
                                                width: '90px',
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '4px 8px',
                                                },
                                            }}
                                        >
                                            {options.map((o) => (
                                                <MenuItem key={o} value={o}>
                                                    {o}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default ExploreResultsHeader;
