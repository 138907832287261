import { CountriesAPI } from 'src/apis/countriesAPI';

import Fetcher from '../fetcher';
import { sanitizeCountries } from './sanitizers';

const api = new CountriesAPI();

export const CountriesFetcher = new Fetcher(
    'countries/list',
    async () => api.getCountries(),
    sanitizeCountries,
);
