import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Box, Divider } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';
import CalendarIcon from 'src/icons/Calendar';

import SidebarOthers from '../utils/SidebarOthers';
import { RatecardEvaluationStepper } from './RatecardEvaluationStepper';

export const RatecardEvaluationSidebar: FC = () => {
    const { i18n } = useLingui();
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const location = useLocation();

    const sections = [
        {
            title: (<SidebarOthers />) as unknown as string,
            items: [
                {
                    title: i18n._('Announcements'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/announcements`,
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/questions`,
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/documents`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/users`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Edit project'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/edit/info`,
                    icon: <NoteAltIcon fontSize="small" />,
                },
                {
                    title: i18n._('Calendar'),
                    path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/calendar`,
                    icon: <CalendarIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            {ratecardProjectQuery?.ratecardProject?.projectName ? (
                <StepperTitle
                    title={ratecardProjectQuery?.ratecardProject?.projectName}
                    status={ratecardProjectQuery?.ratecardProject?.state}
                    projectType="ratecards"
                />
            ) : null}
            <RatecardEvaluationStepper orientation="vertical" />
            <Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
