import React from 'react';

import { Trans } from '@lingui/macro';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CardSection } from 'src/components/sections';
import SpacingContainer from 'src/components/sections/spacingContainer';
import { Information } from 'src/components/typography';
import type { ProjectRequest } from 'src/project/types';

interface Props {
    request: ProjectRequest;
}

const RequesterSection = ({ request }: Props) => (
    <CardSection title={<Trans>Requester from your organization</Trans>}>
        <SpacingContainer flexDirection="column">
            <TableContainer sx={{ maxWidth: '600px' }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Trans>First Name</Trans>
                            </TableCell>
                            <TableCell align="center">
                                <Trans>Last Name</Trans>
                            </TableCell>
                            <TableCell align="center">
                                <Trans>Email</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">{request.firstName}</TableCell>
                            <TableCell align="center">{request.lastName}</TableCell>
                            <TableCell align="center">{request.email}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {request.additionalInformation && (
                <Information
                    title={<Trans>Additional information</Trans>}
                    value={request.additionalInformation}
                />
            )}
        </SpacingContainer>
    </CardSection>
);

export default RequesterSection;
