import { FC, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchContext } from 'src/explore/contexts/SearchContext';
import useAuth from 'src/hooks/useAuth';

interface Props {
    children: any;
}

export const SearchContextInitializer: FC<Props> = ({ children }: Props) => {
    const { setCurrentSearch } = useContext(SearchContext);

    const { user } = useAuth();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (window.location.pathname === '/explore') {
            setCurrentSearch(`${window.location.pathname}${window.location.search}`);
        }
    }, [searchParams, user]);

    return <>{children}</>;
};
