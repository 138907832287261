import React from 'react';

import { useLingui } from '@lingui/react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useAuth from 'src/hooks/useAuth';
import { NumberParser } from 'src/utils/formattingUtils';

import { useTotalVendorsCount } from '../state/hooks';

const ExploreCallToAction: React.FC = () => {
    const { i18n } = useLingui();
    const theme = useTheme();
    const { user } = useAuth();
    const totalVendorsCount = useTotalVendorsCount();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingY: 4,
            }}
        >
            <SearchIcon
                fontSize="large"
                style={{
                    width: '124px',
                    height: '124px',
                    color: theme.palette.secondary2.main,
                }}
            />
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '75%',
                }}
            >
                <Typography variant="h4" color="primary">
                    {i18n._('EXPLORE')}
                </Typography>
                <Typography variant="h5" color="primary">
                    {i18n._(
                        'Please start a search by typing in relevant search words in the search bar above.',
                    )}
                </Typography>
                <Typography color="primary">
                    {`${i18n._(
                        'The apadua EXPLORE database provides access to company profiles of',
                    )} ${new NumberParser(user.formattingLocale).format(
                        totalVendorsCount,
                        null,
                    )} ${i18n._(
                        'companies - and still growing. If you encounter any problem with the use, please send an email to',
                    )} `}
                    <a href="mailto:support@apadua.com">support@apadua.com</a>.
                </Typography>
            </Box>
        </Box>
    );
};

export default ExploreCallToAction;
