import SessionManager, { inferredTenantInterceptor } from 'src/login/sessionManager';

import HttpAPI, { APIHeaders } from './HttpAPI';

export class ApaduaAPI extends HttpAPI {
    resource: string = 'api/';

    constructor() {
        super(window.APADUA_API);

        this.api.interceptors.response.use(inferredTenantInterceptor, (error) =>
            Promise.reject(error),
        );
    }

    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        const token = SessionManager.getAccessToken();
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
    }
}
