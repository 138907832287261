import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { withSearch } from '@elastic/react-search-ui';
import { useLingui } from '@lingui/react';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Card, IconButton } from '@mui/material';

import SessionManager from 'src/login/sessionManager';
import {
    useFetchLists,
    useListsData,
    useOrganizationListsData,
    useUserListsData,
} from 'src/pages/listManager/state/hooks';
import { SearchFilters } from 'src/search';

import Filter from './filter';

interface CompanyListsFilterProps {
    addFilter?: (name: string, value: any, type?: any) => void;
    removeFilter?: (name: string, value?: any, type?: any) => void;
    filters?: any[];
}

const CompanyListsFilter: FC<CompanyListsFilterProps> = ({
    addFilter,
    removeFilter,
    filters,
}: CompanyListsFilterProps) => {
    const { i18n } = useLingui();
    const lists = useListsData();
    const userLists = useUserListsData();
    const organizationLists = useOrganizationListsData();
    const fetchLists = useFetchLists();
    const [selectedLists, setSelectedLists] = useState([]);
    const listsFilter = filters.find((el) => el.field === SearchFilters.LISTS);
    const isTenantSet = Boolean(SessionManager.getCurrentTenantId());
    const navigate = useNavigate();

    const handleIconClick = () => {
        navigate({
            pathname: `/user/lists`,
        });
    };

    const icon = (
        <IconButton color="primary" size="small" onClick={handleIconClick}>
            <PlaylistAddCheckIcon />
        </IconButton>
    );

    useEffect(() => {
        fetchLists();
    }, []);

    useEffect(() => {
        if (!listsFilter) {
            removeFilter('_id');
            setSelectedLists([]);
            return;
        }

        removeFilter('_id');
        lists.forEach((list) => {
            if (listsFilter.values.includes(list.id)) {
                addFilter(
                    '_id',
                    list.list_items.map((item) => item.object_id),
                    'any',
                );
            }
        });

        setSelectedLists(listsFilter.values);
    }, [lists, listsFilter]);

    const handleSelect = (value: any) => {
        const newSelectedLists = [...selectedLists, value.id];
        setSelectedLists(newSelectedLists);
        addFilter(SearchFilters.LISTS, value.id, 'any');
    };

    const handleRemove = (value: any) => {
        const newLists = selectedLists.filter((list) => list !== value.id);
        setSelectedLists(newLists);
        removeFilter(SearchFilters.LISTS, value.id, 'any');
    };

    if (!lists.length) {
        return null;
    }

    return (
        isTenantSet && (
            <Card sx={{ p: 1, mb: 2, backgroundColor: 'baseColors.color3' }}>
                <Filter
                    label={i18n._('My Lists')}
                    icon={icon}
                    options={userLists.map((list) => ({ label: list.name, ...list }))}
                    selectedOptions={selectedLists}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    showSearch
                />
                <Filter
                    label={i18n._('Organization Lists')}
                    options={organizationLists.map((list) => ({ label: list.name, ...list }))}
                    selectedOptions={selectedLists}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                    showSearch
                />
            </Card>
        )
    );
};

export default withSearch(({ addFilter, removeFilter, filters }) => ({
    addFilter,
    removeFilter,
    filters,
}))(CompanyListsFilter) as FC<CompanyListsFilterProps>;
