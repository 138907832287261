import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';

import CheckListItemTextField from './checkListItemTextField';
import ChecklistItemTextArea from './checklistItemTextArea';
import MoreActions from './moreActions';

const Divider = () => {
    const theme = useTheme();
    return <div style={{ width: '100%', borderBottom: `1px solid ${theme.palette.divider}` }} />;
};

const rotateSx = {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease',
};

const checkListItemBodySx = {
    padding: 0,
    pl: 6,
    pr: 2,
    m: 0,
};

interface CheckListItemActionsProps {
    expanded: boolean;
    onExpandClick: () => void;
    onRemove: () => void;
}

const CheckListItemActions = ({
    expanded,
    onExpandClick,
    onRemove,
    ...rest
}: CheckListItemActionsProps) => (
    <Grid>
        <IconButton size="small" onClick={onExpandClick} {...rest}>
            <ExpandMoreIcon sx={expanded ? rotateSx : {}} />
        </IconButton>
        <MoreActions onRemove={onRemove} />
    </Grid>
);

interface CheckListItemProps {
    done: boolean;
    aiGenerated?: boolean;
    text: string;
    title: string;
    index: number;
    onChange: (index: number, name: string, value: string | boolean) => void;
    onRemove: (index: number) => void;
    onBlur: (index: number, name: string, value: string | boolean) => void;
    setRequestRunningID: (id) => void;
    id: number;
}

const CheckListItem = ({
    title,
    text,
    done,
    onRemove,
    index,
    onBlur,
    aiGenerated,
    setRequestRunningID,
    id,
}: CheckListItemProps) => {
    const isExpanded = !done && aiGenerated;
    const [expanded, setExpanded] = React.useState(isExpanded);
    const [checked, setChecked] = React.useState(done);
    const theme = useTheme();

    const handleCheckBoxClick = () => {
        if (!checked) {
            setExpanded(false);
        }
        setChecked(!checked);
        onBlur(index, 'done', !checked);
    };

    const handleBlurChange = (name, value) => {
        // this if check is in order to avoid the deletion of this item when clicking outside of the modal.
        // When focusing on this input and clicking outside of the modal we fire both onBlur and handleClose
        // thus we wanna avoid deleting the below item.
        if (!title && !text && value) {
            setRequestRunningID(id);
        }
        onBlur(index, name, value);
    };

    const handleRemove = () => onRemove(index);
    const onExpandClick = () => setExpanded(!expanded);

    return (
        <Accordion
            disableGutters
            elevation={0}
            sx={{ '&:before': { height: '0px' } }}
            expanded={expanded}
        >
            <AccordionSummary sx={{ backgroundColor: 'transparent !important' }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid flex={1} display="flex" alignItems="center">
                        <Checkbox
                            onClick={handleCheckBoxClick}
                            checked={checked}
                            data-testid="checklist-item-checkbox"
                        />
                        <CheckListItemTextField
                            aiGenerated={aiGenerated}
                            data-testid={`checklist-item-textfield-${index}`}
                            done={checked}
                            value={title}
                            name="title"
                            onBlur={handleBlurChange}
                        />
                    </Grid>
                    <CheckListItemActions
                        data-testid={`checklist-item-more-actions-${index}`}
                        expanded={expanded}
                        onRemove={handleRemove}
                        onExpandClick={onExpandClick}
                    />
                    <Divider />
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={checkListItemBodySx}>
                <Grid>
                    <ChecklistItemTextArea
                        done={checked}
                        value={text}
                        onBlur={handleBlurChange}
                        name="text"
                        data-testid={`checklist-item-textarea-${index}`}
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default CheckListItem;
