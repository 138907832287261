import React from 'react';

import { Trans } from '@lingui/macro';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, IconButton, Popover } from '@mui/material';

interface MoreActionsProps {
    onRemove?: () => void;
}

interface Props {
    onClick: () => void;
}

const MoreActions = ({ onRemove }: MoreActionsProps) => {
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };
    const handleRemove = () => {
        onRemove();
        setOpen(false);
    };

    return (
        <>
            <Box component={IconButton} onClick={handleOpen} ref={anchorRef}>
                <MoreVertIcon />
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 148, height: 'auto' },
                }}
            >
                <Button
                    fullWidth
                    startIcon={<DeleteIcon color="secondary" />}
                    variant="text"
                    onClick={handleRemove}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: 2,
                    }}
                    data-testid="checklist-item-remove-button"
                >
                    <Trans>Delete</Trans>
                </Button>
            </Popover>
        </>
    );
};

export default MoreActions;
