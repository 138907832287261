import { FC } from 'react';

import { useLingui } from '@lingui/react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

interface Props {
    backAction: () => void;
    open: boolean;
}

export const RefreshDialog: FC<Props> = ({ backAction, open }: Props) => {
    const { i18n } = useLingui();

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            aria-labelledby="dialog-title"
            open={open}
        >
            <DialogContent>
                <Box
                    mt={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                            color: 'warning.main',
                            mb: 2,
                        }}
                    >
                        <WarningRoundedIcon />
                    </Avatar>
                </Box>
                <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                    {i18n._('Object Modified')}
                </Typography>
                <Typography
                    align="center"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                    style={{ whiteSpace: 'pre-wrap' }}
                    variant="body2"
                >
                    {i18n._(
                        'The state of the object has changed in the meantime. Please go back to see the current state.',
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={backAction}
                        data-testid="refreshDialogBackButton"
                    >
                        {i18n._('Back')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
