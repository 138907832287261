import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLingui } from '@lingui/react';

import { InternalDocumentComponentParent } from 'src/components/internalDocuments/InternalDocumentComponentParent';
import { useQuoteQuery } from 'src/components/rfp/QuoteProvider';

const InternalDocuments: FC = () => {
    const { i18n } = useLingui();

    const { quoteQuery } = useQuoteQuery();
    const internalDocumentSetId = quoteQuery?.sourceQuote?.internalDocumentSetId;

    return (
        <>
            <Helmet>
                <title>{i18n._('Internal Documents')}</title>
            </Helmet>
            <InternalDocumentComponentParent
                viewIsReadOnly={false}
                internalDocumentSetId={internalDocumentSetId}
            />
        </>
    );
};

export default InternalDocuments;
