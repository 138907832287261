import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';

import CompanyProfileResultEntry from './CompanyProfileResultEntry';
import CompanyProfileResultEntryPreview from './CompanyProfileResultEntryPreview';

interface Props {
    preview?: boolean;
}

const StyledLink = styled(Link)({
    textDecoration: 'none !important',
    '&.noPoiterEvents': {
        pointerEvents: 'none',
    },
});

const CompanyProfileResultsList: FC<Props> = ({ preview }: Props) => {
    const { i18n } = useLingui();
    const { results } = useContext(CompanyExploreContext);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('search');
    const { company } = useContext(CompanyProfileContext);

    return (
        <>
            {preview ? (
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {results
                        ?.slice(0, 3)
                        .map((result) => (
                            <CompanyProfileResultEntryPreview content={result} key={result.id} />
                        ))}
                    {results?.length === 0 && (
                        <Alert
                            severity="info"
                            sx={{
                                mb: 2,
                            }}
                        >
                            <Typography>
                                {i18n._('No results were found for the provided input.')}
                            </Typography>
                            <Typography>{i18n._('Please revise your input.')}</Typography>
                        </Alert>
                    )}
                    <StyledLink to={`/explore/${company.id}/explore?search=${searchQuery}`}>
                        <Button variant="outlined">{i18n._('See all results')}</Button>
                    </StyledLink>
                </Box>
            ) : (
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {results?.map((result) => (
                        <CompanyProfileResultEntry content={result} key={result.id} />
                    ))}
                    {results?.length === 0 && (
                        <Alert severity="info">
                            <Typography>
                                {i18n._('No results were found for the provided input.')}
                            </Typography>
                            <Typography>{i18n._('Please revise your input.')}</Typography>
                        </Alert>
                    )}
                </Box>
            )}
        </>
    );
};

export default CompanyProfileResultsList;
