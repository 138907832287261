import { createSelector } from '@reduxjs/toolkit';

import { useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

export const currenciesSelector = (state: GlobalState) => state.currencies;

export const currencieSelectorData = (state: GlobalState) => currenciesSelector(state).data;

export const selectCurrencies = createSelector(
    (state: GlobalState) => currenciesSelector(state).data,
    (currencies) => currencies.filter((currency) => currency.isoCurrencyCode !== '%'),
);

export const useAllCurrencies = () => useAppSelector(currencieSelectorData);
export const useCurrencies = () => useAppSelector(selectCurrencies);
