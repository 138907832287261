import PropTypes from 'prop-types';
import { useState } from 'react';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { Box, Button, Collapse, Link, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';

import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';

interface NavItemProps extends ListItemProps {
    active?: boolean;
    children?: ReactNode;
    depth: number;
    icon?: ReactNode;
    info?: ReactNode;
    open?: boolean;
    path?: string;
    onClick?: MouseEventHandler;
    title: string;
    external?: boolean;
}

const NavItem: FC<NavItemProps> = (props) => {
    const {
        active,
        children,
        depth,
        icon,
        info,
        open: openProp,
        path,
        title,
        onClick,
        external,
        ...other
    } = props;
    const [open, setOpen] = useState<boolean>(openProp);

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 16;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    // Branch
    if (children) {
        return (
            <ListItem
                disableGutters
                sx={{
                    display: 'block',
                    py: 0,
                }}
                {...other}
            >
                <Button
                    endIcon={
                        !open ? (
                            <ChevronRightIcon fontSize="small" />
                        ) : (
                            <ChevronDownIcon fontSize="small" />
                        )
                    }
                    data-testid={info}
                    onClick={handleToggle}
                    startIcon={icon}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        pl: `${paddingLeft}px`,
                        pr: '8px',
                        py: '12px',
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                    }}
                    variant="text"
                >
                    <Box sx={{ flexGrow: 1 }}>{title}</Box>
                    {info}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    // Leaf
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                py: 0,
            }}
        >
            {onClick ? (
                <Button
                    onClick={onClick}
                    startIcon={icon}
                    data-testid={title}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        pl: `${paddingLeft}px`,
                        pr: '8px',
                        py: '12px',
                        textTransform: 'none',
                        width: '100%',
                        ...(active && {
                            backgroundColor: '#EBECF0',
                            color: 'primary.main',
                            fontWeight: 'fontWeightBold',
                            '& svg': {
                                color: 'primary.main',
                            },
                        }),
                    }}
                    variant="text"
                >
                    <Box sx={{ flexGrow: 1 }}>{title}</Box>
                    {info}
                </Button>
            ) : (
                <Button
                    component={external ? path && Link : path && RouterLink}
                    startIcon={icon}
                    data-testid={title}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        pl: `${paddingLeft}px`,
                        pr: '8px',
                        py: '12px',
                        textTransform: 'none',
                        width: '100%',
                        ...(active && {
                            backgroundColor: '#EBECF0',
                            color: 'primary.main',
                            fontWeight: 'fontWeightBold',
                            '& svg': {
                                color: 'primary.main',
                            },
                        }),
                    }}
                    variant="text"
                    href={path}
                    to={path}
                >
                    <Box sx={{ flexGrow: 1 }}>{title}</Box>
                    {info}
                </Button>
            )}
        </ListItem>
    );
};

NavItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    depth: PropTypes.number.isRequired,
    icon: PropTypes.node,
    info: PropTypes.node,
    open: PropTypes.bool,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.any,
    external: PropTypes.bool,
};

NavItem.defaultProps = {
    active: false,
    open: false,
};

export default NavItem;
