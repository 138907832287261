/**
 * @generated SignedSource<<27933e8c5c742aabd370ed6f921bd06b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RatecardProposalStepper_ratecardProposal$data = {
  readonly id: string;
  readonly projectId: string | null;
  readonly projectName: string | null;
  readonly validation: {
    readonly allSuccessful: boolean | null;
    readonly attachments: string | null;
    readonly errorMessage: string | null;
    readonly ratecard: string | null;
    readonly tenderIntroduction: string | null;
  } | null;
  readonly " $fragmentType": "RatecardProposalStepper_ratecardProposal";
};
export type RatecardProposalStepper_ratecardProposal$key = {
  readonly " $data"?: RatecardProposalStepper_ratecardProposal$data;
  readonly " $fragmentSpreads": FragmentRefs<"RatecardProposalStepper_ratecardProposal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RatecardProposalStepper_ratecardProposal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RatecardProposalValidationNode",
      "kind": "LinkedField",
      "name": "validation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenderIntroduction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratecard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allSuccessful",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RatecardProposalNode",
  "abstractKey": null
};

(node as any).hash = "7af48015c30b51c45f7eead04e3eccba";

export default node;
