import React from 'react';

import { Button, Grid, SxProps, Typography } from '@mui/material';

import { Modal } from 'src/components/common/modal';
import type { TriggerButtonProps } from 'src/components/common/modal';

interface BaseConfirmationModalProps {
    triggerButtonText?: string;
    onConfirm: () => void;
    description?: React.ReactNode;
    cancelButtonText?: string;
    confirmButtonText?: string;
    triggerButtonComponent?: React.ComponentType<TriggerButtonProps>;
    children?: React.ReactNode;
    sx?: SxProps;
    rest?: any;
}

const ConfirmationModal = ({
    triggerButtonText,
    onConfirm,
    description,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    triggerButtonComponent,
    children,
    sx,
    ...rest
}: BaseConfirmationModalProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    return (
        <Modal
            triggerButtonComponent={triggerButtonComponent}
            showTriggerButton
            externalControl
            onClose={handleClose}
            onOpen={handleOpen}
            isOpen={isOpen}
            style={sx}
            {...rest}
        >
            <Grid>
                <Grid>
                    <Typography
                        paragraph
                        align="center"
                        data-testid="confirmation-modal-description"
                    >
                        {description}
                    </Typography>
                </Grid>
                {children}
                <Grid display="flex" justifyContent="center" alignContent="center">
                    <Button
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                        data-testid="confirmation-modal-cancel-button"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        sx={{ ml: 2 }}
                        size="medium"
                        variant="contained"
                        onClick={handleConfirm}
                        data-testid="confirmation-modal-confirm-button"
                    >
                        {confirmButtonText}
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ConfirmationModal;
