import { FC, useMemo } from 'react';

import { useLingui } from '@lingui/react';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import BalanceIcon from '@mui/icons-material/Balance';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';
import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';

import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import useAuth from 'src/hooks/useAuth';
import { useMobile } from 'src/hooks/useMobile';
import { NumberParser, convertToInternationalCurrencySystem } from 'src/utils/formattingUtils';
import { getCountry } from 'src/utils/locationUtils';

import CompanyProfileReportModal from './CompanyProfileReportModal';

interface CompanyProfileCardProps {
    company: any;
    onLogoLoad: () => void;
}

const CompanyProfileCard: FC<CompanyProfileCardProps> = ({
    company,
    onLogoLoad,
}: CompanyProfileCardProps) => {
    const { i18n } = useLingui();
    const { user } = useAuth();
    const isMobile = useMobile();
    const userCountry = getCountry();

    const contries = useMemo(
        () =>
            [...(company?.countries?.edges ?? [])].sort((a, b) => {
                if (a?.node?.iso2letter === userCountry.code) {
                    return -1;
                }
                if (b?.node?.iso2letter === userCountry.code) {
                    return 1;
                }
                return 0;
            }),
        [],
    );

    const turnover = useMemo(
        () => convertToInternationalCurrencySystem(company?.generalInfo?.turnover || 0),
        [company?.generalInfo?.turnover],
    );
    const getCompanyTurnover = () => {
        if (!turnover.unit) return '';

        switch (turnover?.unit) {
            case 'legalType':
                return i18n._('Legal type');
            case 'serviceLine':
                return i18n._('Service line');
            case 'diversification':
                return i18n._('Classification');
            case 'countries':
                return i18n._('Countries');
            case 'companySize':
                return i18n._('Company size');
            case 'companyTurnover':
                return i18n._('Company turnover');
            case 'foundationYear':
                return i18n._('Foundation year');
            case 'website':
                return i18n._('Website');
            case 'email':
                return i18n._('Email');
            case 'updated':
                return i18n._('Updated');
            case 'others':
                return i18n._('others');
            case 'reportCompany':
                return i18n._('Report dataset');
            case 'flagThisSupplier':
                return i18n._('Report');
            case 'reportedCompany':
                return i18n._(
                    'This dataset was already reported. By clicking, you will delete the reporting flag and the dataset can no longer be checked by our team. Please only perform this action if you are sure all data of this dataset is correct and consistent.',
                );
            case 'billion':
                return i18n._('Billion');
            case 'million':
                return i18n._('Million');
            case 'thousand':
                return i18n._('Thousands');
            case 'reasons':
                return i18n._('Reasons');
            case 'provideReasonForReport':
                return i18n._('Please select a reporting reason for this dataset');
            case 'atLeastOneReason':
                return i18n._('Select at least one reason');
            case 'summaryDisclaimerTitle':
                return i18n._('Summary of core competencies');
            case 'summaryDisclaimerDetails':
                return i18n._(
                    "To provide a better overview of the firm's core competencies, these are summarized based on the information available on the respective websites. The texts are generated through the use of AI and may therefore include inaccuracies.",
                );
            default:
                return '';
        }
    };

    return (
        <Box
            sx={{
                p: 2,
            }}
        >
            <Card>
                <Box
                    sx={{
                        p: 4,
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: 8,
                            }}
                        >
                            <CustomCompanyIcon
                                company={company}
                                width={200}
                                height={200}
                                fontSize={72}
                                variant="rounded"
                                onLoad={onLogoLoad}
                            />
                        </Box>
                    </Box>
                    <Box
                        style={{
                            marginLeft: isMobile ? 0 : '32px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                marginBottom: '16px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="h3" align="center">
                                {company?.companyNameAlias
                                    ? company?.companyNameAlias
                                    : company?.companyName}
                            </Typography>
                            <CompanyProfileReportModal
                                company={company}
                                style={{
                                    width: 'auto',
                                }}
                            />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BalanceIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">{i18n._('Legal type')}</Typography>
                                        <Typography variant="subtitle1">
                                            {company?.lnCompanyType?.companyTypeEn
                                                ? company?.lnCompanyType?.companyTypeEn
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ArchitectureIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {i18n._('Classification')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {company?.consultantType?.consultantTypeEn
                                                ? company?.consultantType?.consultantTypeEn
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <GroupsIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {i18n._('Company size')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {company?.lnCompanySizeEmployees?.employeesRangeEn
                                                ? company?.lnCompanySizeEmployees?.employeesRangeEn
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CalendarMonthIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {i18n._('Foundation year')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {company?.generalInfo?.foundationYear
                                                ? company?.generalInfo?.foundationYear
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider
                            sx={{
                                my: 2,
                            }}
                        />
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <WorkIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {i18n._('Service line')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {company?.serviceLine?.serviceLineEn
                                                ? company?.serviceLine?.serviceLineEn
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <PlaceIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">{i18n._('Countries')}</Typography>
                                        <Typography variant="subtitle1">
                                            {company?.countries?.totalCount === 0 ? (
                                                'N/A'
                                            ) : (
                                                <Box
                                                    style={{
                                                        overflow: 'hidden',
                                                        maxHeight: '56px',
                                                    }}
                                                >
                                                    <ul
                                                        style={{
                                                            listStyleType: 'none',
                                                            paddingLeft: 0,
                                                            margin: 0,
                                                        }}
                                                    >
                                                        {contries.map((country) => (
                                                            <li key={country.node.commonNameEn}>
                                                                {country.node.commonNameEn}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Box>
                                            )}
                                            {company?.countries?.totalCount > 2 && (
                                                <Typography>
                                                    {`+ ${
                                                        company?.countries?.totalCount - 2
                                                    } ${i18n._('others')}`}
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <BarChartIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">
                                            {i18n._('Company turnover')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {turnover.value &&
                                            company?.generalInfo?.turnoverCurrency?.isoCurrencyCode
                                                ? new NumberParser(user.formattingLocale)
                                                      .format(
                                                          company?.generalInfo?.turnover,
                                                          company?.generalInfo?.turnoverCurrency
                                                              ?.isoCurrencyCode,
                                                      )
                                                      .replace(
                                                          new NumberParser(
                                                              user.formattingLocale,
                                                          ).format(
                                                              company?.generalInfo?.turnover,
                                                              null,
                                                              2,
                                                          ),
                                                          `${
                                                              turnover.value
                                                          } ${getCompanyTurnover()}`,
                                                      )
                                                : 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6} lg={3}>
                                <Box
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LanguageIcon
                                            fontSize="large"
                                            sx={{
                                                mx: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h6">{i18n._('Website')}</Typography>
                                        <Typography variant="subtitle1">
                                            {company?.generalInfo?.website ? (
                                                <a
                                                    href={
                                                        company?.generalInfo?.website.includes(
                                                            'http',
                                                        )
                                                            ? company?.generalInfo?.website
                                                            : `http://${company?.generalInfo?.website}`
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {company?.generalInfo?.website.includes(
                                                        'http://',
                                                    )
                                                        ? company?.generalInfo?.website.split(
                                                              'http://',
                                                          )[1]
                                                        : null}
                                                    {company?.generalInfo?.website.includes(
                                                        'https://',
                                                    )
                                                        ? company?.generalInfo?.website.split(
                                                              'https://',
                                                          )[1]
                                                        : null}
                                                    {!company?.generalInfo?.website.includes('http')
                                                        ? company?.generalInfo?.website
                                                        : null}
                                                </a>
                                            ) : (
                                                'N/A'
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default CompanyProfileCard;
