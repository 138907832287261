import { t } from '@lingui/macro';

const useRfqStatus = (status: string, projectType: string, userType: string) => {
    let tooltip = '';
    let label = '';

    if (projectType === 'ratecards') {
        switch (status) {
            case 'new':
                tooltip = t`Draft | The event was created and saved in draft.`;
                label = t`Draft`;
                break;
            case 'sent':
                tooltip = t`Active | The event was submitted to the supplier. Bidding phase active.`;
                label = t`Active`;
                break;
            case 'completed':
                tooltip = t`Completed | The event was concluded.`;
                label = t`Completed`;
                break;
            case 'cancelled':
                tooltip = t`Cancelled | The event was cancelled.`;
                label = t`Cancelled`;
                break;
            case 'edit':
                tooltip = t`In Edit | The event was set to edit mode. Supplier do not have access while this status is active.`;
                label = t`In Edit`;
                break;
            case 'paused':
                tooltip = t`On Hold | The event was set on hold.`;
                label = t`On Hold`;
                break;
            default:
                tooltip = '';
                label = '';
        }
    }

    if (projectType === 'proposals') {
        if (userType === 'client') {
            switch (status) {
                case 'new':
                    tooltip = t`Invited | The supplier received the invitation to this event.`;
                    label = t`Invited`;
                    break;
                case 'opened':
                    tooltip = t`Opened | The supplier has agreed to the terms & conditions and has reviewed the content of this event.`;
                    label = t`Opened`;
                    break;
                case 'accepted':
                    tooltip = t`Accepted | The supplier has confirmed participation in the event after reviewing the content.`;
                    label = t`Accepted`;
                    break;
                case 'terms_rejected':
                    tooltip = t`Declined | The supplier has declined the terms & conditions to participate.`;
                    label = t`Declined`;
                    break;
                case 'rejected':
                    tooltip = t`Rejected | The supplier has rejected to participate in this proposal.`;
                    label = t`Rejected`;
                    break;
                case 'sent':
                    tooltip = t`Submitted | The supplier has submitted a proposal.`;
                    label = t`Submitted`;
                    break;
                case 'revision':
                    tooltip = t`Revision | The client has requested to revise the proposal.`;
                    label = t`Revision`;
                    break;
                case 'dismissed':
                    tooltip = t`Dismissed | You dismissed the supplier from this event.`;
                    label = t`Dismissed`;
                    break;
                case 'rejected_by_client':
                    tooltip = t`Rejected | You rejected the proposal of the supplier.`;
                    label = t`Rejected`;
                    break;
                case 'confirmed_by_client':
                    tooltip = t`Confirmed | You confirmed the proposal of the supplier.`;
                    label = t`Confirmed`;
                    break;
                case 'completed':
                    tooltip = t`Completed | The event was concluded.`;
                    label = t`Completed`;
                    break;
                case 'cancelled':
                    tooltip = t`Cancelled | The event was cancelled.`;
                    label = t`Cancelled`;
                    break;
                case 'edit':
                    tooltip = t`In Edit | The event was set to edit mode. Supplier do not have access while this status is active.`;
                    label = t`In Edit`;
                    break;
                case 'paused':
                    tooltip = t`On Hold | The event was set on hold.`;
                    label = t`On Hold`;
                    break;
                default:
                    tooltip = '';
                    label = '';
            }
        } else {
            switch (status) {
                case 'new':
                    tooltip = t`Invited | The supplier received the invitation to this event.`;
                    label = t`Invited`;
                    break;
                case 'opened':
                    tooltip = t`Opened | The supplier has agreed to the terms & conditions and has reviewed the content of this event.`;
                    label = t`Opened`;
                    break;
                case 'accepted':
                    tooltip = t`Accepted | The supplier has confirmed participation in the event after reviewing the content.`;
                    label = t`Accepted`;
                    break;
                case 'terms_rejected':
                    tooltip = t`Declined | The supplier has declined the terms & conditions to participate.`;
                    label = t`Declined`;
                    break;
                case 'rejected':
                    tooltip = t`Rejected | The supplier has rejected to participate in this proposal.`;
                    label = t`Rejected`;
                    break;
                case 'sent':
                    tooltip = t`Submitted | The supplier has submitted a proposal.`;
                    label = t`Submitted`;
                    break;
                case 'revision':
                    tooltip = t`Revision | The client has requested to revise the proposal.`;
                    label = t`Revision`;
                    break;
                case 'dismissed':
                    tooltip = t`Dismissed | You have been dismissed from this event.`;
                    label = t`Dismissed`;
                    break;
                case 'rejected_by_client':
                    tooltip = t`Rejected | The client has rejected your proposal.`;
                    label = t`Rejected`;
                    break;
                case 'confirmed_by_client':
                    tooltip = t`Confirmed | The client has confirmed your proposal.`;
                    label = t`Confirmed`;
                    break;
                case 'completed':
                    tooltip = t`Completed | The event was concluded.`;
                    label = t`Completed`;
                    break;
                case 'cancelled':
                    tooltip = t`Cancelled | The event was cancelled.`;
                    label = t`Cancelled`;
                    break;
                case 'edit':
                    tooltip = t`In Edit | The event was set to edit mode. Supplier do not have access while this status is active.`;
                    label = t`In Edit`;
                    break;
                case 'paused':
                    tooltip = t`On Hold | The event was set on hold.`;
                    label = t`On Hold`;
                    break;
                default:
                    tooltip = '';
                    label = '';
            }
        }
    }

    return { label, tooltip };
};

export default useRfqStatus;
