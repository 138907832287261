import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { useLingui } from '@lingui/react';
import { Box, Button, Grid, Typography } from '@mui/material';

import { TextField } from 'src/components/formik-material-ui/TextField';
import { RatecardProjectRoleSelect } from 'src/components/users/RatecardProjectRoleSelect';

interface Props {
    inviteNewUserAction: any;
    closeDrawer: any;
    afterSuccessAction?: any;
}

export const InviteUserForm: FC<Props> = ({
    inviteNewUserAction,
    closeDrawer,
    afterSuccessAction,
}: Props) => {
    const { i18n } = useLingui();
    const enableFormikReinitialize = true;

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        role: 'user',
    };

    const ValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(i18n._('Must be a valid email'))
            .max(120, i18n._('Email must be at most 120 characters'))
            .required(i18n._('Email is required')),
        firstName: Yup.string()
            .max(60, i18n._('First name must be at most 60 characters'))
            .required(i18n._('First name is required')),
        lastName: Yup.string()
            .max(60, i18n._('Last name must be at most 60 characters'))
            .required(i18n._('Last name is required')),
    });

    const onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
        inviteNewUserAction(values, {
            setSubmitting,
            setStatus,
            setErrors,
            closeDrawer,
            afterSuccessAction,
        });
    };

    const form = ({ isSubmitting }): JSX.Element => (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {i18n._('Invite a new user to your project')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="email"
                        data-testid="email"
                        label={i18n._('Email')}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="firstName"
                        data-testid="firstName"
                        label={i18n._('First name')}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={TextField}
                        name="lastName"
                        data-testid="lastName"
                        label={i18n._('Last name')}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={RatecardProjectRoleSelect}
                        name="role"
                        data-testid="role"
                        label={i18n._('Role')}
                        required
                    />
                </Grid>
                <Grid item xs={12} textAlign="right">
                    <Box sx={{ mt: 4, mb: 2, mr: 6, p: 2 }}>
                        <Button
                            sx={{ mr: 2 }}
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                            data-testid="save"
                            disabled={isSubmitting}
                        >
                            {i18n._('Save')}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            data-testid="cancel"
                            onClick={closeDrawer}
                            disabled={isSubmitting}
                        >
                            {i18n._('Cancel')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableFormikReinitialize}
        >
            {form}
        </Formik>
    );
};
