import { ProjectAnnouncementPayload } from 'src/types';

import { ApaduaAPI } from './ApaduaAPI';
import { APIHeaders } from './HttpAPI';

export class RatecardProjectAPI extends ApaduaAPI {
    resource: string = 'api/ratecard/';

    getHeaders(): APIHeaders {
        return {
            ...super.getHeaders(),
            'Cache-Control': 'no-store',
            Pragma: 'no-cache',
            Expires: '0',
        };
    }

    getProjectAnnouncements(projectId: string): any {
        return this.get(`${projectId}/announcements/`);
    }

    createProjectAnnouncement(payload: ProjectAnnouncementPayload, projectId: string): any {
        return this.post(`${projectId}/announcements/`, payload);
    }
}
