import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { HelmetProvider } from 'react-helmet-async';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { RelayProvider } from 'src/RelayProvider';
import 'src/routes';

import { SettingsProvider } from './contexts/SettingsContext';
import DependencyInjector from './dependencyInjection';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const host = window.ENVIRONMENT;

if (host === 'production' || host === 'demo' || host === 'staging') {
    Sentry.init({
        dsn: 'https://2a1c439f495245f994d2090b8e11f115@sentry.mibrex.com/7',
        integrations: [new Integrations.BrowserTracing()],
        environment: host,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    Sentry.configureScope((scope) => {
        scope.setExtras({
            environment: host,
        });

        window.addEventListener('unhandledrejection', (event) => {
            Sentry.captureException(event.reason);
        });

        window.onerror = function (message, source, lineno, colno, error) {
            Sentry.captureException(error);
        };
    });
}

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <RelayProvider>
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider>
                                <RouterProvider router={DependencyInjector.router} />
                            </SettingsProvider>
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </RelayProvider>
            </HelmetProvider>
        </Provider>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
