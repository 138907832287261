import React from 'react';

import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';

import {
    useCreateOrganisationVendorList,
    useCreateVendorListItems,
    useFetchLists,
    useOrganizationListsData,
} from 'src/pages/listManager/state/hooks';

import { useSelectedVendors } from '../state/hooks';

const ExploreAddToOrganisationListButton = () => {
    const { i18n } = useLingui();
    const organisationLists = useOrganizationListsData();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const selectedVendors = useSelectedVendors();
    const [newListName, setNewListName] = React.useState<string>('');

    const createOrganisationList = useCreateOrganisationVendorList();
    const createListItems = useCreateVendorListItems();
    const fetchLists = useFetchLists();

    const handleCreateNewList = () => {
        createOrganisationList({
            name: newListName,
            selectedVendors,
        });
        setNewListName('');
        setAnchorEl(null);
    };

    const handleAddToListButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleListClick = (list) => {
        createListItems(list.id, selectedVendors);
        setAnchorEl(null);
        fetchLists();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                variant="text"
                startIcon={<AddIcon fontSize="small" />}
                style={{ fontWeight: 400 }}
                onClick={handleAddToListButton}
            >
                {i18n._('Add to organisation list')}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem sx={{ px: '12px', py: '4px' }}>
                    <TextField
                        label={i18n._('Name of new list')}
                        variant="standard"
                        value={newListName}
                        onChange={(e) => {
                            setNewListName(e.target.value);
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <Button onClick={handleCreateNewList}>{i18n._('Add')}</Button>
                            ),
                        }}
                    />
                </MenuItem>
                {organisationLists.map((list) => (
                    <MenuItem
                        onClick={() => handleListClick(list)}
                        sx={{ px: '12px', py: '12px' }}
                        key={list.id}
                    >
                        {list.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default ExploreAddToOrganisationListButton;
