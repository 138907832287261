import React from 'react';

import TextArea from 'src/components/common/textArea';
import { ViewWithSuggestion } from 'src/components/common/viewWithSuggestion/index';

import LoadingDocumentGif from './loadingDocumentGif';
import Suggestion from './suggestion';
import { loadingSX } from './titledTextareaWithSuggestion';

interface TextareaWithSuggestionProps {
    entry: string;
    loading: boolean;
    suggestion: string;
    showSuggestions?: boolean;
    onChange: (text: string) => void;
    error?: boolean;
    name?: string;
}

const TextareaWithSuggestion = ({
    loading,
    entry,
    suggestion,
    showSuggestions,
    onChange,
    error,
    ...rest
}: TextareaWithSuggestionProps) => {
    const suggestionContent = loading ? <LoadingDocumentGif /> : suggestion;

    return (
        <ViewWithSuggestion
            loading={loading}
            entry={entry}
            suggestion={suggestion}
            suggestionView={
                <Suggestion
                    container
                    sx={loading ? loadingSX : {}}
                    suggestion={suggestionContent}
                />
            }
            showSuggestions={showSuggestions}
            onApply={() => onChange(suggestion)}
            applyDirectly={!entry?.length}
        >
            <TextArea {...rest} error={error} value={entry} onChange={onChange} />
        </ViewWithSuggestion>
    );
};

export default TextareaWithSuggestion;
