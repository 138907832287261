import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import { InfoMessage } from 'src/components/internalDocuments/InfoMessage';
import { InternalDocumentList } from 'src/components/internalDocuments/InternalDocumentList';
import { UploadInternalDocuments } from 'src/components/internalDocuments/UploadInternalDocuments';

const InternalDocumentComponentQuery = graphql`
    query InternalDocumentComponentQuery($internalDocumentSetId: String!) {
        internalDocuments(internalDocumentSetId: $internalDocumentSetId) {
            edges {
                node {
                    id
                    fileName
                    fileUrl
                    created
                    user {
                        id
                        firstName
                        lastName
                        email
                        company {
                            id
                            companyName
                            logo
                        }
                    }
                }
            }
        }
    }
`;

interface Props {
    internalDocumentQueryRef: any;
    refreshInternalDocumentQuery: any;
    internalDocumentSetId: string;
    viewIsReadOnly: boolean;
}

export const InternalDocumentComponent: FC<Props> = ({
    internalDocumentQueryRef,
    refreshInternalDocumentQuery,
    internalDocumentSetId,
    viewIsReadOnly,
}: Props) => {
    const query = usePreloadedQuery(InternalDocumentComponentQuery, internalDocumentQueryRef);
    const internalDocuments = query?.internalDocuments?.edges?.map((edge) => edge?.node);

    return (
        <>
            <InfoMessage />
            <InternalDocumentList
                internalDocuments={internalDocuments}
                refreshInternalDocumentQuery={refreshInternalDocumentQuery}
                viewIsReadOnly={viewIsReadOnly}
            />
            <UploadInternalDocuments
                internalDocumentSetId={internalDocumentSetId}
                viewIsReadOnly={viewIsReadOnly}
                refreshInternalDocumentQuery={refreshInternalDocumentQuery}
            />
        </>
    );
};
