import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { ProjectDetailsFormData } from '../types';
import { sanitizeProjectDetails } from './sanitizers';
import { ProjectDetailsFetcher, patchProjectDetailsAsync } from './thunk';

export const ProjectDetailsDefaultvalue: Omit<ProjectDetailsFormData, 'id' | 'projectLanguage'> =
    Object.freeze({
        useDefaultCompany: true,
        additionalInformation: null,
        otherMentions: null,
        approachPresentation: true,
        currency: null,
        proposalValidityWeeks: 8,
        pitchSession: false,
        clientIntroduction: '',
        companyName: '',
        companyAddressLineOne: '',
        companyAddressLineTwo: '',
        companyAddressLineThree: '',
        companyPostalCode: '',
        companyCity: '',
        companyWebsite: '',
        companyCountry: null,
        companyPortrait: false,
        companySizeEmployees: null,
        companySizeTurnover: null,
        fixedFee: true,
        hybridModel: false,
        mainLocation: null,
        mainLanguage: null,
        noPersonalContactInformation: false,
        outlineProjectPhases: false,
        projectPhases: [],
        projectReferences: false,
        projectsWorkedOn: false,
        proposalLanguage: null,
        questionRounds: 2,
        secondaryLanguages: [],
        standardProfile: true,
        successBased: false,
        timeMaterial: true,
        closedEnvelope: false,
        documents: [],
        quoteContacts: [],
        isComplete: false,
    });

const initalState = { ...fetcherInitialState, data: ProjectDetailsDefaultvalue };

export const projectDetailsSlice = createSlice({
    name: 'projectDetails',
    initialState: initalState,
    reducers: {},
    extraReducers(builder) {
        ProjectDetailsFetcher.reducers(builder);
        builder.addCase(patchProjectDetailsAsync.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        });
        builder.addCase(patchProjectDetailsAsync.fulfilled, (state, action) => {
            state.data = {
                ...state.data,
                ...sanitizeProjectDetails(action.payload),
            };
            state.status = 'succeeded';
            state.loading = false;
        });
        builder.addCase(patchProjectDetailsAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.loading = false;
        });
    },
});
