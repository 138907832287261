import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { QuoteContext } from 'src/contexts/rfp/QuoteContext';
import useAuth from 'src/hooks/useAuth';

export const QuoteProviderQuery = graphql`
    query QuoteProviderQuery($quoteId: ID!) {
        sourceQuote(quoteId: $quoteId) {
            id
            integerId
            announcementSetId
            internalDocumentSetId
            status
            projectName
            companyName
            proposals {
                edges {
                    node {
                        id
                        isActive
                        status
                        company {
                            id
                            companyName
                            logo
                        }
                    }
                }
            }
            ...QuoteUserManagementComponent_sourceQuote
            ...Questions_sourceQuote
        }
    }
`;

export const QuoteProviderConsultantQuery = graphql`
    query QuoteProviderConsultantQuery($quoteId: ID!) {
        sourceQuote(quoteId: $quoteId) {
            id
            projectName
            companyName
        }
    }
`;

export const useQuery = () => {
    const { user } = useAuth();
    return user.isClient ? QuoteProviderQuery : QuoteProviderConsultantQuery;
};

export const useQuoteQuery = () => {
    const query = useQuery();
    const { quoteQueryRef, refreshQuoteQuery } = useContext(QuoteContext);
    const quoteQuery = usePreloadedQuery(query, quoteQueryRef);
    return {
        quoteQuery,
        refreshQuoteQuery,
    };
};

interface Props {
    children: any;
}

export const QuoteProvider: FC<Props> = ({ children }: Props) => {
    const query = useQuery();
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(query);
    const location = useLocation();
    const matchProject = matchPath('/dashboard/rfp/:quoteId/*', location.pathname);
    const matchProjectV2 = matchPath('/project/:id/*', location.pathname);

    const projectId = matchProject?.params?.quoteId;
    const projectIdV2 = matchProjectV2?.params?.id
        ? btoa(`QuoteNode:${matchProjectV2.params.id}`)
        : undefined;

    const quoteId = projectId || projectIdV2;

    const refresh = () => {
        loadQuery({ quoteId }, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refresh();
        return () => {
            disposeQuery();
        };
    }, [quoteId]);

    if (queryRef === null) {
        return null;
    }

    return (
        <QuoteContext.Provider
            value={{
                quoteQueryRef: queryRef,
                refreshQuoteQuery: refresh,
            }}
        >
            {children}
        </QuoteContext.Provider>
    );
};
