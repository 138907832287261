export const routeURLs = {
    PROJECT_BASE: 'project',
    PROJECT: ':id',
    PROJECT_VENDORS: 'vendors',
    PROJECT_VENDORS_PROFILE: ':companyId',
    PROJECT_VENDORS_DETAILS: 'about',
    PROJECT_VENDORS_EXPLORE: 'explore',
    PROJECT_RFP_DETAILS: 'rfp-details',
    PROJECT_CRITERIA_EVALUATION: 'criteria-evaluation',
    PROJECT_COVER_LETTER: 'cover-letter',
    PROJECT_REVIEW_AND_SUBMIT: 'review-and-submit',
    DASHBOARD_RFP: '/dashboard/rfp',
    PROJECT_INTERNAL_DOCUMENTS: 'documents',
    PROJECT_USER_MANAGEMENT: 'user-management',
    PROJECT_ANNOUNCEMENTS: 'announcements',
    LOGIN_OTP_VALIDATION: 'otp-validation',
};
