import React, { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';

import { Information } from 'src/components/typography';
import useCancelTourOnUnmount from 'src/tours/cancelTourOnUnmount';
import { useUserTourPolicy } from 'src/tours/tourPolicy';

import TourContentWithDecline from './tourContentWithDecline';

export const useProjectVendorsTour = () => {
    useCancelTourOnUnmount();
    const { shouldDisplay, onTourShown, onTourDeclined, onTourFinished } =
        useUserTourPolicy('ProjectVendorsTour');
    const { setIsOpen, setSteps, setCurrentStep } = useTour();
    const computedSteps = useMemo(() => {
        const handleClose = () => {
            setIsOpen(false);
            setCurrentStep(0);
        };
        const tourSteps = [
            {
                selector: '[data-testid="projectSelectedVendors"]',
                content: (
                    <Information
                        title={<Trans>Selected project vendors</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        Here, you can see all the vendors which you have added to
                                        the project RFP. By clicking on “Add vendors” you can
                                        include vendors who are not yet in our database.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="projectExploreVendors"]',
                content: (
                    <Information
                        title={<Trans>Explore vendors database</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        Search for vendors by typing in keywords into the search bar
                                        and/or by clicking on the chips underneath the search bar.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="projectExploreFilters"]',
                content: (
                    <Information
                        title={<Trans>Filtering options</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        In the left-hand sidebar you will find filtering options
                                        that allow you to further narrow down your search. You can
                                        either filter by your lists or by other attributes such as
                                        countries or target industries.
                                    </Trans>
                                </Typography>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        <Trans>Done</Trans>
                                    </Button>
                                </Grid>
                            </TourContentWithDecline>
                        }
                    />
                ),
                actionAfter: onTourFinished,
                stepInteraction: false,
            },
        ];

        return tourSteps;
    }, []);

    useEffect(() => {
        setSteps(computedSteps);
        if (shouldDisplay) {
            onTourShown();

            setTimeout(() => setIsOpen(true), 1000);
        }
    }, [
        shouldDisplay,
        onTourShown,
        onTourDeclined,
        onTourFinished,
        setSteps,
        setIsOpen,
        computedSteps,
    ]);
};
