import * as Yup from 'yup';

import { t } from '@lingui/macro';

export const BaseValidationSchema = Yup.object().shape({
    projectTitle: Yup.string()
        .required(() => t`Project Title is required`)
        .max(255, () => t`Input must not exceed ${255} characters`)
        .trim(() => t`Leading and trailing whitespaces are not allowed`),
    selectedServiceID: Yup.string()
        .nullable()
        .required(() => t`A service must be selected`),
    industryStandardID: Yup.string()
        .nullable()
        .required(() => t`Industry Standard is required`),
    internalDescription: Yup.string()
        .required(() => t`Internal description is required`)
        .max(1000, () => t`Input must not exceed ${1000} characters`)
        .trim(() => t`Leading and trailing whitespaces are not allowed`),
});

export const ExtendedValidationSchema = BaseValidationSchema.shape({
    startDate: Yup.date()
        .nullable()
        .required(() => t`Start Date is required`),
    endDate: Yup.date()
        .required(() => t`End Date is required`)
        .nullable()
        .when('startDate', (startDate, schema) =>
            startDate ? schema.min(startDate, t`End date must be after start date`) : schema,
        ),
    initialSituation: Yup.string()
        .required(() => t`Initial situation is required`)
        .max(20000, () => t`Input must not exceed ${20000} characters`),
    projectApproach: Yup.string()
        .required(() => t`Project approach is required`)
        .max(20000, () => t`Input must not exceed ${20000} characters`),
    internalOrganisationReference: Yup.string()
        .optional()
        .max(255, t`Input must not exceed ${255} characters`),
    projectObjectives: Yup.array()
        .required(() => t`At least one project objective is required`)
        .min(1, () => t`At least one project objective is required`)
        .test(
            'atLeastOneNotEmpty',
            () => t`At least one project objective must not be an empty string`,
            (values) =>
                (values || []).some(
                    (value) => value?.title?.trim().length && value?.description?.trim().length,
                ),
        ),
    projectDeliverables: Yup.array()
        .required(() => t`At least one project deliverable is required`)
        .min(1, () => t`At least one project deliverable is required`)
        .test(
            'atLeastOneNotEmpty',
            () => t`At least one project deliverable must not be an empty string`,
            (values) =>
                (values || []).some(
                    (value) => value?.title?.trim().length && value?.description?.trim().length,
                ),
        ),
    primaryProjectLocation: Yup.number()
        .nullable()
        .required(() => t`Primary location is required`),
    secondaryProjectLocations: Yup.array().optional(),
    engagementMode: Yup.string().required(() => t`Engagement mode is required`),
    budgetEstimate: Yup.object().nullable().optional(),
    budgetApprovalStatus: Yup.string().nullable(),
    budgetEstimateCurrency: Yup.number().nullable(),
});
