import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { ProjectAnnouncementsFetcher, ProjectAnnouncementsFetcherCreate } from './thunk';

export const projectAnnouncementsSlice = createSlice({
    name: 'projectAnnouncements',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        ProjectAnnouncementsFetcher.reducers(builder);
        ProjectAnnouncementsFetcherCreate.reducers(builder);
    },
});
