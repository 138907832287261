import { takeEvery } from 'redux-saga/effects';

import DependencyInjection from 'src/dependencyInjection';
import { addSearchParams } from 'src/utils/addSearchParams';
import { appendAccessToken } from 'src/utils/appendAccessToken';

import { navigateAction } from './actions';

function* navigateSaga(action) {
    const { payload } = action;

    if (payload?.url || payload?.path?.startsWith('http')) {
        const url = addSearchParams(payload.url ?? payload.path, payload?.searchParams);
        const finalUrl =
            url.startsWith(window.CANONICAL_DOMAIN_SOURCE) ||
            url.startsWith(window.CANONICAL_DOMAIN_MONITOR)
                ? appendAccessToken(url)
                : url;
        if (payload?.options?.replace) {
            window.location.replace(finalUrl);
        } else {
            window.location.href = finalUrl;
        }
    } else if (payload?.path) {
        DependencyInjection.router.navigate(payload.path, payload.options);
    }
    yield;
}

export function* watchNavigateAction() {
    yield takeEvery(navigateAction.type, navigateSaga);
}
