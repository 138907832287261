import graphql from 'babel-plugin-relay/macro';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryLoader } from 'react-relay/hooks';
import { Outlet, useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse, IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AtlassianSidebar } from 'src/components/dashboard/AtlassianSidebar/AtlassianSidebar';
import { Navbar } from 'src/components/navbar/Navbar';
import { NotificationProvider } from 'src/components/notifications/providers/NotificationProvider';
import { CompanyUsersProvider } from 'src/components/users/CompanyUsersProvider';
import { AppContext, SaveRefCurrentType } from 'src/contexts/AppContext';
import { SessionContext } from 'src/contexts/SessionContext';
import { SearchContextProvider } from 'src/explore/providers/SearchContextProvider';
import useAuth from 'src/hooks/useAuth';
import { useMobile } from 'src/hooks/useMobile';
import useScrollReset from 'src/hooks/useScrollReset';
import DismissibleToast from 'src/utils/DismissibleToast';

const DashboardLayoutQuery = graphql`
    query DashboardLayoutQuery {
        allCountries {
            edges {
                node {
                    id
                    iso2letter
                    commonNameEn
                    commonNameDe
                }
            }
        }
        allCurrencies {
            edges {
                node {
                    id
                    isoCurrencyCode
                }
            }
        }
        allPublishedIndustryStandards {
            edges {
                node {
                    id
                    isLeaf
                    parentIndustryEn
                    parentIndustryDe
                    industryEn
                    industryDe
                }
            }
        }
        allPublishedServiceStandards {
            edges {
                node {
                    id
                    isLeaf
                    parentServiceEn
                    parentServiceDe
                    serviceEn
                    serviceDe
                }
            }
        }
        allLanguages {
            edges {
                node {
                    id
                    iso6391
                    iso6392
                    languageEn
                    languageDe
                }
            }
        }
        allSalutations {
            id
            salutationEn
            salutationDe
        }
        allAcademicTitles {
            id
            titleEn
            titleDe
        }
        allEmployeesRanges {
            edges {
                node {
                    id
                    employeesRange
                }
            }
        }
        allRevenueRanges {
            edges {
                node {
                    id
                    revenueRange
                }
            }
        }
    }
`;

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
}));

const ContentWrapper = styled('div')(() => ({
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
}));

const DesktopSidebarWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    transition: 'all 0.5s',
    transitionProperty: 'width minWidth',
    '&.SidebarClosed': {
        minWidth: 0,
        width: 0,
    },
    '&.SidebarOpen': {
        minWidth: '260px',
        width: '260px',
    },
    overflow: 'hidden auto',
}));

const MobileSidebarZeroWidthHack = styled('div')(() => ({
    width: '0',
    height: '100%',
    overflow: 'visible',
    zIndex: 1000,
}));

const MobileSidebarWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    transition: 'all 0.5s',
    transitionProperty: 'transform',
    minWidth: '260px',
    width: '260px',
    zIndex: 1150,
    '&.SidebarClosed': {
        transform: 'translate(-100%, 0)',
    },
    '&.SidebarOpen': {
        transform: 'translate(0, 0)',
    },
    overflow: 'hidden auto',
}));

const OutletWrapper = styled('div')(() => ({
    flexGrow: 1,
    overflow: 'auto',
}));

const DashboardLayout = () => {
    const { i18n } = useLingui();
    const isMobile = useMobile();
    const dashboardContentRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(!isMobile);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [alert1Open, setAlert1Open] = useState(true);
    const [alert2Open, setAlert2Open] = useState(true);
    const sideBarComponent = <AtlassianSidebar />;
    const saveRef = useRef<SaveRefCurrentType>();
    const currentLocation = useLocation();
    const { user, fetchUserProfile } = useAuth();

    useScrollReset(dashboardContentRef);

    const [resendValidationEmail] = useMutation(graphql`
        mutation DashboardLayoutBannerSendValidationEmailMutation(
            $input: SendValidationEmailInput!
        ) {
            sendValidationEmail(input: $input) {
                user {
                    trackingConsent
                    id
                }
            }
        }
    `);

    const triggerResendValidationEmail = () => {
        resendValidationEmail({
            variables: {
                input: {
                    email: user.email,
                },
            },
            onCompleted(completedData, errors) {
                if (errors) {
                    toast.error(DismissibleToast(i18n._('Connection error')));
                } else {
                    toast.success(DismissibleToast(i18n._('Email sent')));
                    fetchUserProfile();
                }
            },
            onError() {
                toast.error(DismissibleToast(i18n._('Connection error')));
            },
        });
    };

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (currentLocation.pathname === '/apalytics') {
            setOpen(false);
        }
    }, [currentLocation]);

    const [globalQueryRef, loadQuery, disposeQuery] = useQueryLoader(DashboardLayoutQuery);

    const toggleSidebar = () => {
        setOpen(!open);
    };

    useEffect(() => {
        loadQuery();
        return () => {
            disposeQuery();
        };
    }, []);

    const expireSession = () => {
        setSessionExpired(true);
    };

    const surpressBeforeUnload = (event) => {
        // surpress because it breaks selenium tests
        event.stopImmediatePropagation();
    };

    useEffect(() => {
        // Add event listener
        window.addEventListener('sessionExpired', expireSession);
        window.addEventListener('beforeunload', surpressBeforeUnload, true);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('sessionExpired', expireSession);
            window.removeEventListener('beforeunload', surpressBeforeUnload, true);
        };
    }, []); // Empty array ensures that effect is only run on mount

    if (globalQueryRef === null) {
        return null;
    }

    return (
        <AppContext.Provider
            value={{
                globalQueryRef,
                saveRef,
                toggleSidebar,
            }}
        >
            <CompanyUsersProvider>
                <Root data-testid="apaduaDashboard">
                    <SessionContext.Provider
                        value={{
                            dialogOpen: false,
                            setDialogOpen: () => {},
                            setSessionExpired,
                        }}
                    >
                        <NotificationProvider>
                            <SearchContextProvider>
                                <Navbar />
                                <ContentWrapper>
                                    {isMobile ? (
                                        <MobileSidebarZeroWidthHack>
                                            <MobileSidebarWrapper
                                                className={open ? 'SidebarOpen' : 'SidebarClosed'}
                                            >
                                                {sideBarComponent}
                                            </MobileSidebarWrapper>
                                        </MobileSidebarZeroWidthHack>
                                    ) : (
                                        <DesktopSidebarWrapper
                                            className={open ? 'SidebarOpen' : 'SidebarClosed'}
                                        >
                                            {sideBarComponent}
                                        </DesktopSidebarWrapper>
                                    )}
                                    <OutletWrapper ref={dashboardContentRef}>
                                        {!user?.profileCompleted && (
                                            <Collapse in={alert1Open}>
                                                <Alert
                                                    severity="warning"
                                                    sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                setAlert1Open(false);
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    {`${i18n._(
                                                        'Your profile is incomplete.',
                                                    )} ${i18n._('Please follow this')} `}
                                                    <Link href="/dashboard/account/profile">
                                                        {i18n._('link')}
                                                    </Link>
                                                    {` ${i18n._(
                                                        'to the profile section and complete the missing information.',
                                                    )}`}
                                                </Alert>
                                            </Collapse>
                                        )}
                                        {!user?.emailValidated && (
                                            <Collapse in={alert2Open}>
                                                <Alert
                                                    severity="warning"
                                                    sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                setAlert2Open(false);
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    {`${i18n._(
                                                        'Your email is not confirmed yet. Please check your Email in-box and also your spam folder for the related email. If you cannot find the email, let the system resent it to you by clicking this link:',
                                                    )} `}
                                                    <Link
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={triggerResendValidationEmail}
                                                    >
                                                        {i18n._('Resend Email')}
                                                    </Link>
                                                </Alert>
                                            </Collapse>
                                        )}
                                        <Outlet />
                                    </OutletWrapper>
                                </ContentWrapper>
                            </SearchContextProvider>
                        </NotificationProvider>
                    </SessionContext.Provider>
                </Root>
            </CompanyUsersProvider>
        </AppContext.Provider>
    );
};

export default DashboardLayout;
