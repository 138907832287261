import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProjectBriefAPI } from 'src/apis/projectBriefAPI';
import type { PostBriefPayload, ProjectBriefFormData } from 'src/projectBrief/types';
import { navigateAction } from 'src/sagas/actions';
import DismissibleToast from 'src/utils/DismissibleToast';

import { formatDate } from '../utils/formatDate';

const api = new ProjectBriefAPI();

export const fetchSuggestions = createAsyncThunk(
    'projectBrief/fetchSuggestions',
    async (values: ProjectBriefFormData) => {
        const payload = {
            ...values,
            ...(values.startDate ? { startDate: formatDate(values.startDate) } : {}),
            ...(values.endDate ? { endDate: formatDate(values.endDate) } : {}),
        };
        Object.keys(payload).forEach((key) => payload[key] === null && delete payload[key]);

        const response = await api.generateSuggestions(payload);
        return response;
    },
);

export const fetchProjectBrief = createAsyncThunk(
    'projectBrief/fetchProjectBrief',
    async (values: any) => {
        const response = await api.getBrief(values.id);
        return response;
    },
);

export const creatProjectBrief = createAsyncThunk(
    'projectBrief/creatProjectBrief',
    async (values: PostBriefPayload, thunkAPI) => {
        try {
            const payload = { ...values };
            const response = await api.createBrief(payload);
            if (!response) {
                throw new Error('Error while creating Project Brief.');
            }
            toast.success(DismissibleToast(i18n.t('Project Brief has been saved')), {
                duration: 4000,
            });
            const newPath = `/dashboard/brief-generation/${response.id}`;
            const { dispatch } = thunkAPI;
            dispatch(navigateAction({ path: newPath }));
            return response;
        } catch (e) {
            toast.error(DismissibleToast(i18n.t('Error while creating Project Brief.')), {
                duration: 4000,
            });
            return e;
        }
    },
);

export const patchProjectBrief = createAsyncThunk(
    'projectBrief/patchProjectBrief',
    async (values: PostBriefPayload): Promise<PostBriefPayload> => {
        const { id } = values;
        const payload = { ...values };
        const response = await api.patchBrief(id, payload);
        toast.success(DismissibleToast(i18n.t('Your Brief has been updated.')), {
            duration: 4000,
        });
        return response;
    },
);

export const fetchAllProjectBrief = createAsyncThunk(
    'projectBrief/fetchAllProjectBrief',
    async () => {
        const response = await api.getAllBriefs();
        return response;
    },
);
