import * as EmailValidator from 'email-validator';
import React from 'react';

import { useLingui } from '@lingui/react';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Drawer, Grid, TextField, Typography } from '@mui/material';

import DynamicList from 'src/components/common/dynamicList';
import Section from 'src/components/sections/section';
import useWindowSize from 'src/utils/useWindowSize';

interface PropTypes {
    open: boolean;
    disabled: boolean;
    handleSubmit: (recipients: Array<string>, personalMessage: string, includePersonlEmail) => void;
    isLoading: boolean;
    handleClose: () => void;
}

const ERROR_CHECK = 'Invalid email address';

const EmailDrawer = ({ open, handleSubmit, disabled, isLoading, handleClose }: PropTypes) => {
    const { i18n } = useLingui();
    const windowSize = useWindowSize();
    const [recipients, setRecipients] = React.useState<Array<string>>(['']);
    const [personalMessage, setPersonalMessage] = React.useState<string>('');
    const [includePersonlEmail, setIncludePersonalEmail] = React.useState(true);
    const [errors, setErrors] = React.useState<{
        recipients: Array<string>;
        personalMessage: string;
    }>({
        recipients: [],
        personalMessage: '',
    });

    const validationFunction = React.useCallback(() => {
        const validationErrors = {
            personalMessage: '',
            recipients: recipients.map(() => ''),
        };

        validationErrors.personalMessage = !personalMessage.trim()
            ? i18n._('Personal message cannot be empty')
            : '';

        recipients.forEach((recipient, index) => {
            if (!EmailValidator.validate(recipient)) {
                validationErrors.recipients[index] = ERROR_CHECK;
            }
        });

        setErrors(() => ({
            personalMessage: validationErrors.personalMessage,
            recipients: [...validationErrors.recipients],
        }));
        return validationErrors;
    }, [recipients, personalMessage, i18n]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludePersonalEmail(event.target.checked);
    };

    const handleTextChange = React.useCallback(
        (index, value) => {
            const currentRecipients = [...recipients];
            currentRecipients[index] = value || '';
            setRecipients(currentRecipients);
        },
        [recipients],
    );

    const renderItem = React.useCallback(
        (_value, index) => (
            <Grid item xs={12} sx={{ mb: 1 }} key={index}>
                <TextField
                    size="small"
                    value={_value}
                    onChange={(e) => handleTextChange(index, e.target.value)}
                    fullWidth
                    label="email"
                    variant="outlined"
                    onBlur={validationFunction}
                    error={errors.recipients[index] === ERROR_CHECK}
                    helperText={errors.recipients[index] && i18n._('Invalid email address')}
                />
            </Grid>
        ),
        [handleTextChange, errors, i18n, validationFunction],
    );

    const handleFormSubmit = () => {
        const validationErrors = validationFunction();
        if (
            validationErrors.personalMessage ||
            validationErrors.recipients.some((error) => error !== '')
        ) {
            return;
        }

        const validRecipients = recipients.filter((recipient) =>
            EmailValidator.validate(recipient),
        );
        handleSubmit(validRecipients, personalMessage, includePersonlEmail);
    };

    const onDiscard = () => {
        setIncludePersonalEmail(false);
        setRecipients(['']);
        setErrors({
            recipients: [],
            personalMessage: '',
        });
        setPersonalMessage('');
        handleClose();
    };

    const handlePersonlMessageChange = (e) => {
        setPersonalMessage(e.target.value);
        const personalMessageError = !e.target.value.trim()
            ? i18n._('Personal message cannot be empty')
            : '';
        setErrors((prevState) => ({
            ...prevState,
            personalMessage: personalMessageError,
        }));
    };

    return (
        <Drawer
            style={{ zIndex: 1300 }}
            ModalProps={{
                onBackdropClick: isLoading ? () => null : handleClose,
            }}
            PaperProps={{
                sx: {
                    width: windowSize.width >= 520 ? 520 : '100%',
                },
            }}
            anchor="right"
            open={open}
        >
            <Box>
                <Grid sx={{ py: 1, mt: 3 }} container direction="row">
                    <Section
                        title={i18n._('Share this description via email')}
                        description={i18n._('Recipients: add email(s) here')}
                    >
                        <DynamicList
                            itemContainerProps={{
                                display: 'flex',
                                mb: 1,
                            }}
                            items={recipients}
                            renderItem={renderItem}
                            onChange={(updatedValues) => setRecipients([...updatedValues])}
                        />
                    </Section>
                </Grid>
                <Section title={i18n._('Personal message to recipients:')}>
                    <Grid sx={{ mt: 0.5 }}>
                        <TextField
                            fullWidth
                            size="medium"
                            value={personalMessage}
                            placeholder={i18n._('Please enter your personal message here')}
                            name="internalDescription"
                            onChange={handlePersonlMessageChange}
                            error={!!errors.personalMessage}
                            helperText={
                                errors.personalMessage && i18n._('Personal message cannot be empty')
                            }
                        />
                    </Grid>
                    <Grid
                        sx={{ mt: 2 }}
                        display="flex"
                        item
                        alignItems="center"
                        justifyItems="flex-start"
                        flexDirection="row"
                        xs={12}
                    >
                        <Grid>
                            <Typography>{i18n._('Send copy to myself:')}</Typography>
                        </Grid>
                        <Grid>
                            <Checkbox
                                checked={includePersonlEmail}
                                onChange={handleCheckboxChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleFormSubmit}
                            disabled={disabled}
                            loading={disabled || isLoading}
                            style={{ display: 'flex', alignItems: 'center' }}
                            sx={{ p: 1 }}
                        >
                            <Typography>{i18n._('Send')}</Typography>
                            <SendIcon sx={{ ml: 1 }} fontSize="inherit" />
                        </LoadingButton>
                        <Button sx={{ ml: 2 }} variant="outlined" color="error" onClick={onDiscard}>
                            <Typography>{i18n._('Discard')}</Typography>
                        </Button>
                    </Grid>
                </Section>
            </Box>
        </Drawer>
    );
};

export default EmailDrawer;
