import { ReactNodeLike } from 'prop-types';
import { FC, Suspense } from 'react';

import { QuoteProvider } from 'src/components/rfp/QuoteProvider';

interface Props {
    children: ReactNodeLike;
}

const QuoteRoute: FC<Props> = ({ children }: Props) => (
    <Suspense fallback="">
        <QuoteProvider>{children}</QuoteProvider>
    </Suspense>
);

export default QuoteRoute;
