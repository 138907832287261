import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollReset = (containerRef): null => {
    const location = useLocation();

    useLayoutEffect(() => {
        if (location.hash) {
            const element = document.querySelector(`${location.hash}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        }
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.pathname, location.hash, containerRef.current]);

    return null;
};

export default useScrollReset;
