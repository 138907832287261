import { Member, MemberResponse } from 'src/types';

export interface CancelPayload {
    cancel_reason: string;
    cancel_cause: string;
}

export interface ProposalCancellationData {
    cancelReason: string;
    cancelCause: string;
}

export interface CancellationResponse {
    object_id: number;
    content_type: string;
    cancel_reason: string;
    cancel_cause: string;
}

export interface ProposalResponse {
    id: number;
    quote: number;
    status: string;
    cancellation: CancellationResponse;
    members: MemberResponse[];
}

export interface CancellationData {
    objectId: number;
    contentType: string;
    cancelReason: string;
    cancelCause: string;
}

export interface ProposalData {
    id: number;
    quote: number;
    status: string;
    cancellation: CancellationData;
    members: Member[];
}

export enum Cancellation {
    NO_CAPACITY_TO_CONDUCT_THE_PROJECT = 'no capacity to conduct the project',
    NO_CAPACITY_TO_PARTICIPATE_IN_TENDER = 'no capacity to participate in tender',
    NO_EXPERTISE_IN_REQUESTED_SUBJECT_MATTER = 'no expertise in requested subject matter',
    TENDER_TIMELINE_TOO_SHORT = 'tender timeline too short',
    TENDER_INFORMATION_NOT_SUFFICIENT = 'tender information not sufficient',
    CONFLICT_OF_INTEREST = 'conflict of interest',
    OTHERS = 'others',
}
