import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { Box, Divider, Tooltip, Typography } from '@mui/material';

import { StatusChip } from 'src/components/ratecards/chips/StatusChip';
import { StatusChip as LegacyStatusChip } from 'src/components/rfp/StatusChip';

interface StepperTitleProps {
    title: string;
    editMode?: boolean;
    status?: string;
    projectType?: string;
    userType?: string;
}

export const StepperTitle: FC<StepperTitleProps> = ({
    title,
    editMode,
    status,
    projectType,
    userType,
}: StepperTitleProps) => {
    const { i18n } = useLingui();

    return (
        <>
            <Tooltip placement="top" title={<Typography variant="subtitle2">{title}</Typography>}>
                <Typography color="textPrimary" variant="h6" align="center">
                    {title}
                </Typography>
            </Tooltip>
            <Box py={2}>
                {status && projectType && (
                    <StatusChip status={status} projectType={projectType} userType={userType} />
                )}
                {status && !projectType && <LegacyStatusChip status={status} />}
                {editMode && (
                    <Typography color="textPrimary" variant="subtitle1" align="center" noWrap>
                        {i18n._('Edit mode')}
                    </Typography>
                )}
            </Box>
            <Divider sx={{ mb: 2, mt: 1 }} />
        </>
    );
};
