import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import useAuth from 'src/hooks/useAuth';
import DismissibleToast from 'src/utils/DismissibleToast';

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '12px',
}));

const CookieConsentBanner: FC = () => {
    const { i18n } = useLingui();
    const { user, fetchUserProfile } = useAuth();
    const open = user && user.trackingConsent === null;

    const [updateTrackingConsent] = useMutation(graphql`
        mutation CookieConsentBannerUpdateUserTrackingConsentMutation(
            $input: UpdateUserTrackingConsentInput!
        ) {
            updateUserTrackingConsent(input: $input) {
                user {
                    trackingConsent
                    id
                }
            }
        }
    `);

    const handleUpdateTrackingConsent = (trackingConsent) => {
        if (user.apps.every((app) => app === 'references')) {
            window.localStorage.setItem('referencesGaConsent', trackingConsent);
            fetchUserProfile();
        } else {
            updateTrackingConsent({
                variables: {
                    input: {
                        trackingConsent,
                    },
                },
                onCompleted(completedData, errors) {
                    if (errors) {
                        toast.error(DismissibleToast(i18n._('Connection error')));
                    } else {
                        fetchUserProfile();
                    }
                },
                onError() {
                    toast.error(DismissibleToast(i18n._('Connection error')));
                },
            });
        }
    };

    return (
        <div>
            <Drawer anchor="bottom" variant="persistent" open={open}>
                <FlexBox>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                            mt: 1,
                        }}
                    >
                        {i18n._(
                            'This website uses cookies to ensure you get the best experience on our website.',
                        )}
                    </Typography>
                    <Box>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleUpdateTrackingConsent(true);
                            }}
                            data-testid="rfpNewQuoteDialogBackButton"
                        >
                            {i18n._('Accept cookies')}
                        </Button>
                        <Button
                            sx={{ ml: 2 }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                handleUpdateTrackingConsent(false);
                            }}
                            data-testid="rfpNewQuoteDialogBackButton"
                        >
                            {i18n._('Decline cookies')}
                        </Button>
                    </Box>
                </FlexBox>
            </Drawer>
        </div>
    );
};

export default CookieConsentBanner;
