import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import FeedIcon from '@mui/icons-material/Feed';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoteIcon from '@mui/icons-material/Note';
import PollIcon from '@mui/icons-material/Poll';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WorkIcon from '@mui/icons-material/Work';
import { Box } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useQuoteQuery } from 'src/components/rfp/QuoteProvider';
import { appendAccessToken } from 'src/utils/appendAccessToken';

export const QuoteSidebar: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();

    const { quoteQuery } = useQuoteQuery();

    const quoteId = quoteQuery?.sourceQuote?.id;
    const quoteIntegerId = quoteQuery?.sourceQuote?.integerId;
    const projectName = quoteQuery?.sourceQuote?.projectName;

    const sections = [
        {
            title: '',
            items: [
                {
                    title: i18n._('Project info'),
                    path: `/project/${quoteIntegerId}`,
                    external: true,
                    icon: <FeedIcon fontSize="small" />,
                },
                {
                    title: i18n._('Consulting firms'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/rfp/${quoteIntegerId}/consultants`,
                    ),
                    external: true,
                    icon: <WorkIcon fontSize="small" />,
                },
                {
                    title: i18n._('RfP details'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/rfp/${quoteIntegerId}/project_details`,
                    ),
                    external: true,
                    icon: <FindInPageIcon fontSize="small" />,
                },
                {
                    title: i18n._('Criteria evaluation'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/rfp/${quoteIntegerId}/criteria_evaluation`,
                    ),
                    external: true,
                    icon: <PollIcon fontSize="small" />,
                },
                {
                    title: i18n._('Cover letter'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/rfp/${quoteIntegerId}/cover_letter`,
                    ),
                    external: true,
                    icon: <NoteIcon fontSize="small" />,
                },
                {
                    title: i18n._('Review & submit'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/rfp/${quoteIntegerId}/confirm`,
                    ),
                    external: true,
                    icon: <RateReviewIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: `/dashboard/rfp/${quoteId}/setup/users`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: `/dashboard/rfp/${quoteId}/setup/documents`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            {projectName ? (
                <StepperTitle
                    title={projectName}
                    status={
                        quoteQuery?.sourceQuote?.status === 'on hold'
                            ? 'onHold'
                            : quoteQuery?.sourceQuote?.status
                    }
                />
            ) : null}
            <Box>
                {sections.map((section) => (
                    <NavSection
                        key={section.title}
                        pathname={location.pathname}
                        sx={{
                            '& + &': {
                                mt: 3,
                            },
                        }}
                        {...section}
                    />
                ))}
            </Box>
        </Box>
    );
};
