import React from 'react';

import { Trans } from '@lingui/macro';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';

const InitialStateActions = ({ generateSuggestion, onCreateCustomContent, loading }: any) => (
    <Grid
        container
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
    >
        <Grid>
            <LoadingButton
                data-testid="generateSuggestions"
                loading={loading}
                size="medium"
                color="primary"
                startIcon={<AutoFixHighIcon />}
                onClick={generateSuggestion}
                variant="contained"
            >
                <Trans>Create AI suggestions</Trans>
            </LoadingButton>
        </Grid>
        <Grid mt={1}>
            <Button
                disabled={loading}
                onClick={onCreateCustomContent}
                variant="text"
                size="medium"
                data-testid="createCustomContent"
            >
                <Trans>Create custom content</Trans>
            </Button>
        </Grid>
    </Grid>
);

export default InitialStateActions;
