import type { ProjectBriefFormData } from 'src/projectBrief/types';

import type { ProjectBriefData } from '../types';

export const sanitizeFormValues = (
    values: ProjectBriefFormData,
    currentLanguage: string,
    serviceEdges: Array<any>,
    industryEdges: Array<any>,
): ProjectBriefData => {
    const result: ProjectBriefData = {
        projectApproach: values.projectApproach,
        internalDescription: values.internalDescription,
        projectTitle: values.projectTitle,
        initialSituation: values.initialSituation,
        startDate: '',
        endDate: '',
        projectObjectives: [],
        projectDeliverables: [],
        selectedService: '',
        selectedIndustry: '',
    };
    result.startDate = values.startDate ? new Date(values.startDate).toLocaleDateString() : null;
    result.endDate = values.endDate ? new Date(values.endDate).toLocaleDateString() : null;
    result.projectObjectives = (values.projectObjectives || []).filter(
        (value) => value && (value.description !== '' || value.title !== ''),
    );
    result.projectDeliverables = (values.projectDeliverables || []).filter(
        (value) => value && (value.description !== '' || value.title !== ''),
    );

    const selectedService = serviceEdges.find((item) => item.id === values.selectedServiceID);
    const selectedIndustry = industryEdges.find((item) => item.id === values.industryStandardID);

    result.selectedService =
        currentLanguage === 'en' ? selectedService?.serviceEn : selectedService?.serviceDe;

    result.selectedIndustry =
        currentLanguage === 'en' ? selectedIndustry?.industryEn : selectedIndustry?.industryDe;

    return { ...result };
};
