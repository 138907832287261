import * as Yup from 'yup';

import { t } from '@lingui/macro';

export const urlRegex =
    /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/[\w#-]+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const validationSchema = Yup.object().shape({
    clientIntroduction: Yup.string()
        .nullable()
        .trim(() => t`Leading and trailing whitespaces are not allowed`)
        .max(5000, (m) => t`Client introduction must be at most 5000 characters long`)
        .required(() => t`Client Introduction is required`),
    projectPhases: Yup.array()
        .test(
            'at-least-one-selected',
            () => t`At least one project phase must be selected`,
            (value) => value.some((phase) => phase.selected === true),
        )
        .required(() => t`Project Phases is required`),
    mainLanguage: Yup.number()
        .nullable()
        .required(() => t`Project Language is required`),
    proposalLanguage: Yup.number()
        .nullable()
        .required(() => t`Proposal Language is required`),
    currency: Yup.number()
        .nullable()
        .required(() => t`Currency is required`),
    proposalValidityWeeks: Yup.number()
        .min(1)
        .required(() => t`Proposal Validity Weeks is required`),
    closedEnvelope: Yup.boolean().required(() => t`Closed Envelope is required`),
    pitchSession: Yup.boolean().required(() => t`Pitch Session is required`),
    teamMemberInformation: Yup.array().of(Yup.object()).notRequired(),
    noPersonalContactInformation: Yup.boolean(),
    useDefaultCompany: Yup.boolean(),
    documents: Yup.array().optional(),
    companyName: Yup.string()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.string()
                .nullable()
                .required(() => t`Company Address Line One is required`),
        }),
    companyAddressLineOne: Yup.string()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.string()
                .nullable()
                .required(() => t`Company Address Line One is required`),
        }),
    companyPostalCode: Yup.string()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.string()
                .nullable()
                .required(() => t`Company Postal Code is required`),
        }),
    companyCountry: Yup.number()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.number().required(() => t`Company Country is required`),
        }),
    companyWebsite: Yup.string()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.string()
                .matches(urlRegex, 'Invalid URL format')
                .nullable()
                .required(() => t`Company Website is required`),
        }),
    companySizeEmployees: Yup.number()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.number().required(() => t`Company Size is required`),
        }),
    companySizeTurnover: Yup.number()
        .nullable()
        .when('useDefaultCompany', {
            is: false,
            then: Yup.number()
                .nullable()
                .required(() => t`Company Turnover is required`),
        }),
    quoteContacts: Yup.array().when('noPersonalContactInformation', {
        is: false,
        then: Yup.array()
            .of(
                Yup.object().shape({
                    firstName: Yup.string().required(() => t`First Name is required`),
                    lastName: Yup.string().required(() => t`Last Name is required`),
                    jobTitle: Yup.string()
                        .nullable()
                        .required(() => t`Job title is required`),
                    salutation: Yup.object()
                        .nullable()
                        .required(() => t`Salutation is required`),
                }),
            )
            .min(1, () => t`At least 1 contact is required`)
            .required(() => t`Contact information is required`),
    }),
    otherMentions: Yup.string()
        .nullable()
        .max(15000, () => t`Additional information must not exceed ${15000} characters`),
    // Timeline
    startDate: Yup.date()
        .required(() => t`Project start date is required`)
        .nullable()
        .test(
            'startDate',
            () => t`Project start date should be after project award date`,
            function (value) {
                const { projectAwardDate } = this.parent;
                if (!projectAwardDate) {
                    return true;
                }
                if (projectAwardDate > value) {
                    return false;
                }
                return true;
            },
        ),
    endDate: Yup.date()
        .required(() => t`Project end date is required`)
        .nullable()
        .test(
            'endDate',
            () => t`Project end date should be after project start date`,
            function (value) {
                const { startDate } = this.parent;
                if (!startDate) {
                    return true;
                }
                if (startDate > value) {
                    return false;
                }
                return true;
            },
        ),
    proposalDueDate: Yup.date()
        .required(() => t`Proposal due date is required`)
        .nullable()
        .test(
            'proposalDueDate',
            () => t`Proposal due date should be after rfp release due date`,
            function (value) {
                const { rfpReleaseDueDate } = this.parent;
                if (!rfpReleaseDueDate) {
                    return true;
                }
                if (rfpReleaseDueDate > value) {
                    return false;
                }
                return true;
            },
        ),
    projectAwardDate: Yup.date()
        .required(() => t`Project award date is required`)
        .nullable()
        .test(
            'projectAwardDateValidation',
            () => t`Project award date should be after proposal due date`,
            function (value) {
                const { proposalDueDate } = this.parent;
                if (!proposalDueDate) {
                    return true;
                }
                return value > proposalDueDate;
            },
        ),
    rfpReleaseDueDate: Yup.date()
        .required(() => t`RFP release due date is required`)
        .nullable()
        .test(
            'rfpReleaseDate',
            () => t`RFP release date should be after today`,
            (value) => value > new Date(),
        ),
    presentationStartDate: Yup.date()
        .nullable()
        .when('pitchSession', {
            is: true,
            then: Yup.date()
                .required(() => t`Pitch presentation start date is required`)
                .test(
                    'presentationStartDate',
                    () => t`Pitch presentation start date should be after proposal due date`,
                    function (value) {
                        const { proposalDueDate } = this.parent;
                        if (!proposalDueDate) {
                            return true;
                        }
                        return value > proposalDueDate;
                    },
                ),
        }),
    presentationEndDate: Yup.date()
        .nullable()
        .when('pitchSession', {
            is: true,
            then: Yup.date()
                .required(() => t`Pitch presentation end date is required`)
                .test(
                    'presentationEndDate',
                    () =>
                        t`Pitch presentation end date should be after pitch presentation start date`,
                    function (value) {
                        const { presentationStartDate } = this.parent;
                        if (!presentationStartDate) {
                            return true;
                        }
                        return value > presentationStartDate;
                    },
                ),
        }),
});

export { validationSchema };
