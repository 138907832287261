import toast from 'react-hot-toast';

import { RatecardProjectAPI } from 'src/apis/ratecardProjectAPI';
import { sanitizeProjectAnnouncements } from 'src/project/state/sanitizers';
import Fetcher, { FetcherState } from 'src/redux/fetcher';
import { ProjectAnnouncementPayload, ProjectAnnouncementResponse } from 'src/types';
import DismissibleToast from 'src/utils/DismissibleToast';

const projectAPI = new RatecardProjectAPI();

export const RatecardProjectAnnouncementsFetcher = new Fetcher(
    'ratecardProjectAnnouncements/fetchProjectAnnouncements',
    async (id: string) => projectAPI.getProjectAnnouncements(id),
    sanitizeProjectAnnouncements,
);

interface CreateAnnouncementThunk {
    dataPayload: ProjectAnnouncementPayload;
    projectID: string;
}

export const RatecardProjectAnnouncementsFetcherCreate = new Fetcher(
    'ratecardProjectAnnouncements/createProjectAnnouncementAsync',
    async (payload: CreateAnnouncementThunk) => {
        try {
            const response = await projectAPI.createProjectAnnouncement(
                payload.dataPayload,
                payload.projectID,
            );
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    (
        data: ProjectAnnouncementResponse,
        state: FetcherState<Array<ProjectAnnouncementResponse>>,
        _,
    ) => {
        const sanitizedAnnouncements = sanitizeProjectAnnouncements([data]);
        return [...sanitizedAnnouncements, ...state.data];
    },
);
