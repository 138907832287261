import { FC } from 'react';

import { Trans } from '@lingui/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    TableBody,
    Typography,
} from '@mui/material';

import Table from 'src/components/common/table';
import { useProject } from 'src/project/state/hook';

import ServiceTableHead from './serviceTableHead';
import ServiceTableRow from './serviceTableRow';

const RequestedVendors: FC = () => {
    const project = useProject();
    const projectRequestedVendors = project?.request?.projectRequestedVendors;

    const tableRows = projectRequestedVendors?.map((company) => (
        <ServiceTableRow key={company.id} row={company} />
    ));

    return projectRequestedVendors?.length ? (
        <Accordion defaultExpanded sx={{ mb: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" mb={1} color="primary">
                    <Trans>The requester is asking to add the following service provider(s):</Trans>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table component={Paper}>
                    <ServiceTableHead />
                    <TableBody>{tableRows}</TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    ) : null;
};

export default RequestedVendors;
