import { Field } from 'formik';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { FormikNumberTextField } from 'src/components/FormikNumberTextField';
import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import { InternalSection } from 'src/components/sections/section';
import RadioGroupField from 'src/forms/radioGroupField';

const ProposalValidityForm = () => {
    const { i18n } = useLingui();
    const closedEnvelopeRadios = [
        {
            value: true,
            label: (
                <Trans>
                    Yes - All proposals will be disclosed once the proposal due date has passed.
                </Trans>
            ),
        },
        {
            value: false,
            label: (
                <Trans>
                    No - Each proposal will be disclosed once it is submitted by the vendor.
                </Trans>
            ),
        },
    ];

    return (
        <BucketSection
            isVertical={false}
            title={<Trans>Proposal Validity</Trans>}
            id="proposal-validity"
        >
            <Bucket xs={12} withDivider={false}>
                <InternalSection title={<Trans>How long should the proposals be valid?</Trans>}>
                    <Field
                        fullWidth
                        component={FormikNumberTextField}
                        currencySymbol=""
                        variant="outlined"
                        data-testid="proposalValidityWeeks"
                        name="proposalValidityWeeks"
                        label={i18n._('Number of weeks')}
                        required
                        integer
                    />
                </InternalSection>
            </Bucket>
            <Bucket xs={12} withDivider={false}>
                <InternalSection title={<Trans>Submission procedure/closed envelope RFP</Trans>}>
                    <RadioGroupField name="closedEnvelope" radios={closedEnvelopeRadios} required />
                </InternalSection>
            </Bucket>
        </BucketSection>
    );
};

export default ProposalValidityForm;
