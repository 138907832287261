import { useMemo } from 'react';

import { useLingui } from '@lingui/react';

import { useCountries } from 'src/redux/countries/hook';
import { Country } from 'src/types/country';
import getOptionKey from 'src/utils/language/getOptionKey';

import AutocompleteField, { AutocompleteFieldProps } from './autocompleteField';

function countryToFlag(country: Country) {
    if (!country || !country.iso2Letter) {
        return '';
    }
    return typeof String.fromCodePoint !== 'undefined'
        ? country.iso2Letter
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : country.iso2Letter;
}

interface CountryFieldProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<
        AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>,
        'renderInput' | 'options'
    > {}

function CountryField<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({ name, label, required, ...rest }: CountryFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
    const countries = useCountries();
    const { i18n } = useLingui();
    const sortedCountries = useMemo(
        () =>
            [...countries].sort((a, b) =>
                getOptionKey(a, 'commonName') > getOptionKey(b, 'commonName') ? 1 : -1,
            ),
        [countries, i18n.locale],
    );

    return (
        <AutocompleteField
            fullWidth
            options={sortedCountries}
            getOptionLabel={(option): string =>
                `${countryToFlag(option)} ${getOptionKey(option, 'commonName')}`
            }
            label={label}
            name={name}
            data-testid={name}
            required={required}
            {...rest}
        />
    );
}

export default CountryField;
