import React from 'react';

import { Grid } from '@mui/material';

export interface FileHeaderProps {
    file: File;
}

export function FileHeader({ file }: FileHeaderProps) {
    return (
        <Grid container spacing={3}>
            <Grid item sx={{ mt: 2 }}>
                {file.name}
            </Grid>
        </Grid>
    );
}
