import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { navigateAction } from './actions';

export const useNavigate = () => {
    const dispatch = useDispatch();

    return useCallback((payload) => {
        dispatch(navigateAction(payload));
    }, []);
};
