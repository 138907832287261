import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, ListSubheader, MenuItem, Select, Typography } from '@mui/material';

import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import SessionManager from 'src/login/sessionManager';
import { useTenantsData } from 'src/redux/tenants/hook';
import useCurrentRoute from 'src/router/useCurrentRoute';

const CROSS_TENANTS_ID = 'cross-tenants';

const TenantsDropdown = ({ ...rest }) => {
    const activeTenantId = SessionManager.getCurrentTenantId();
    const [selectedTenant, setSelectedTenant] = useState<number | string>(activeTenantId);
    const tenants = useTenantsData();
    const isVendor = useIsUserSupplier();
    const location = useCurrentRoute();
    const requiresTenant = location?.requiresTenant;
    const currentTenant = tenants.find((tenant) => tenant.id === activeTenantId);

    const handleChange = (event) => {
        if (event.target.value === CROSS_TENANTS_ID) {
            SessionManager.switchToCrossTenants();
        } else {
            SessionManager.switchTenant(event.target.value);
        }
        setSelectedTenant(event.target.value);
    };

    return (
        <Select
            {...rest}
            value={selectedTenant ?? CROSS_TENANTS_ID}
            onChange={handleChange}
            displayEmpty
            disabled={isVendor || tenants.length === 1 || requiresTenant}
            IconComponent={tenants.length === 1 ? () => null : undefined}
            variant="standard"
            disableUnderline
            renderValue={(value) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, paddingLeft: 5 }}>
                    {currentTenant ? (
                        <CustomCompanyIcon
                            company={{
                                companyId: currentTenant?.id,
                                companyName: currentTenant?.name,
                                logo: currentTenant?.logo,
                            }}
                            width={48}
                            height={48}
                            fontSize={12}
                            variant="rounded"
                        />
                    ) : (
                        <LanguageIcon
                            fontSize="large"
                            sx={{
                                mx: 1,
                            }}
                        />
                    )}
                    <Box>
                        <Typography variant="caption" color="secondary">
                            <Trans>Workspace</Trans>
                        </Typography>
                        <Typography>
                            {currentTenant?.name || <Trans>All Workspaces</Trans>}
                        </Typography>
                    </Box>
                </Box>
            )}
        >
            <ListSubheader>
                <Typography variant="subtitle2" color="secondary" sx={{ ml: 2 }}>
                    <Trans>Switch workspace</Trans>
                </Typography>
            </ListSubheader>
            {(tenants || []).map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CustomCompanyIcon
                            company={{
                                companyId: tenant?.id,
                                companyName: tenant?.name,
                                logo: tenant?.logo,
                            }}
                            width={48}
                            height={48}
                            fontSize={12}
                            variant="rounded"
                        />
                        <Typography>{tenant?.name}</Typography>
                    </Box>
                </MenuItem>
            ))}
            {tenants.length > 1 && (
                <MenuItem value={CROSS_TENANTS_ID}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LanguageIcon
                            fontSize="large"
                            sx={{
                                mx: 1,
                            }}
                        />
                        <Trans>
                            <span>All Workspaces</span>
                        </Trans>
                    </Box>
                </MenuItem>
            )}
        </Select>
    );
};

export default TenantsDropdown;
