import clone from 'lodash/clone';

import { Facet, withSearch } from '@elastic/react-search-ui';
import { useLingui } from '@lingui/react';
import { Box, Button } from '@mui/material';

import CompanyListsFilter from 'src/components/common/filters/companyListsFilter';

import CustomFacetView from './CustomFacetView';

const companySizeSorter = (a, b) => {
    if (parseInt(a.value, 10) > parseInt(b.value, 10)) {
        return -1;
    }
    return 1;
};
const sortCompanySizeFacet = (facets) => {
    const clonedFacets = clone(facets);
    clonedFacets?.['ln_company_size_employees_en.keyword']?.[0]?.data?.sort(companySizeSorter);
    return clonedFacets;
};

interface ExploreSideFiltersProps {
    filters?: any[];
    facets?: any;
    addFilter?: (name: string, value: any, type?: any) => void;
    removeFilter?: (name: string, value?: any, type?: any) => void;
    clearFilters?: () => void;
    setCurrent?: (page: number) => void;
    wasSearched?: boolean;
    disjunctiveFacets?: any[];
    setDisjunctiveFacets?: (disjunctiveFacets: any[]) => void;
}
const ExploreSideFilters = ({
    filters,
    facets,
    addFilter,
    removeFilter,
    clearFilters,
    setCurrent,
    wasSearched,
    disjunctiveFacets,
    setDisjunctiveFacets,
}: ExploreSideFiltersProps) => {
    const { i18n } = useLingui();
    const newDisjunctive = [...disjunctiveFacets];
    filters.forEach((filter) => {
        if (filter.type === 'all') {
            const facetIdx = disjunctiveFacets.indexOf(filter.field);
            if (facetIdx !== -1) {
                newDisjunctive.splice(facetIdx, 1);
            }
        }
    });

    if (newDisjunctive.length !== disjunctiveFacets.length) {
        setDisjunctiveFacets(newDisjunctive);
    }

    return (
        <div>
            {wasSearched && (
                <Button
                    variant="contained"
                    sx={{
                        mb: 4,
                    }}
                    onClick={() => {
                        clearFilters();
                        setCurrent(1);
                        setDisjunctiveFacets([
                            '_id',
                            'countries_en.keyword',
                            'ln_company_industry_en.keyword',
                            'consultant_type_en.keyword',
                            'ln_company_size_employees_en.keyword',
                            'ln_company_specialities_en.keyword',
                            'ln_company_type_de.keyword',
                            'zones_en.keyword',
                        ]);
                    }}
                >
                    {i18n._('Clear filters')}
                </Button>
            )}
            <Box data-testid="projectExploreFilters">
                {wasSearched && <CompanyListsFilter key="listsFacet" />}
                <Facet
                    key="1"
                    field="countries_en.keyword"
                    label={i18n._('Countries')}
                    isFilterable={!!true}
                    filterType={
                        filters[filters.findIndex((el) => el.field === 'countries_en.keyword')]
                            ?.type ||
                        (disjunctiveFacets.includes('countries_en.keyword') ? 'any' : 'all')
                    }
                    view={(props) => (
                        <CustomFacetView
                            {...props}
                            facets={facets}
                            facetId="countries_en.keyword"
                            disjunctiveFacets={disjunctiveFacets}
                            setDisjunctiveFacets={setDisjunctiveFacets}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            filters={filters}
                            translationKey="countries"
                        />
                    )}
                />
            </Box>
            <Facet
                key="2"
                field="zones_en.keyword"
                label={i18n._('Regions')}
                isFilterable={!!true}
                filterType={
                    filters[filters.findIndex((el) => el.field === 'zones_en.keyword')]?.type ||
                    (disjunctiveFacets.includes('zones_en.keyword') ? 'any' : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="zones_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="zones"
                    />
                )}
            />
            <Facet
                key="3"
                field="ln_company_industry_en.keyword"
                label={i18n._('Service Lines')}
                isFilterable={!!true}
                filterType={
                    filters[
                        filters.findIndex((el) => el.field === 'ln_company_industry_en.keyword')
                    ]?.type ||
                    (disjunctiveFacets.includes('ln_company_industry_en.keyword') ? 'any' : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="ln_company_industry_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="ln_company_industry"
                    />
                )}
            />
            <Facet
                key="31"
                field="target_industries_en.keyword"
                label={i18n._('Industry Focus')}
                isFilterable
                filterType={
                    filters[filters.findIndex((el) => el.field === 'target_industries_en.keyword')]
                        ?.type ||
                    (disjunctiveFacets.includes('target_industries_en.keyword') ? 'any' : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="target_industries_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="target_industries"
                    />
                )}
            />
            <Facet
                key="4"
                field="consultant_type_en.keyword"
                label={i18n._('Classification')}
                isFilterable={!!true}
                filterType={
                    filters[filters.findIndex((el) => el.field === 'consultant_type_en.keyword')]
                        ?.type ||
                    (disjunctiveFacets.includes('consultant_type_en.keyword') ? 'any' : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="consultant_type_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="countries"
                    />
                )}
            />
            <Facet
                key="5"
                field="ln_company_size_employees_en.keyword"
                label={i18n._('Size by Employees')}
                filterType={
                    filters[
                        filters.findIndex(
                            (el) => el.field === 'ln_company_size_employees_en.keyword',
                        )
                    ]?.type ||
                    (disjunctiveFacets.includes('ln_company_size_employees_en.keyword')
                        ? 'any'
                        : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="ln_company_size_employees_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="ln_company_size_employees"
                    />
                )}
            />
            <Facet
                key="6"
                field="ln_company_specialities_en.keyword"
                label={i18n._('Focus topics')}
                isFilterable={!!true}
                filterType={
                    filters[
                        filters.findIndex((el) => el.field === 'ln_company_specialities_en.keyword')
                    ]?.type ||
                    (disjunctiveFacets.includes('ln_company_specialities_en.keyword')
                        ? 'any'
                        : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="ln_company_specialities_en.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="ln_company_specialities"
                    />
                )}
            />
            <Facet
                key="7"
                field="ln_company_type_de.keyword"
                label={i18n._('Legal type')}
                filterType={
                    filters[filters.findIndex((el) => el.field === 'ln_company_type_de.keyword')]
                        ?.type ||
                    (disjunctiveFacets.includes('ln_company_type_de.keyword') ? 'any' : 'all')
                }
                view={(props) => (
                    <CustomFacetView
                        {...props}
                        facets={facets}
                        facetId="ln_company_type_de.keyword"
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        filters={filters}
                        translationKey="ln_company_type"
                    />
                )}
            />
        </div>
    );
};

export default withSearch(
    ({ filters, facets, addFilter, removeFilter, clearFilters, setCurrent, wasSearched }) => ({
        filters,
        facets,
        addFilter,
        removeFilter,
        clearFilters,
        setCurrent,
        wasSearched,
    }),
)(ExploreSideFilters) as React.FC<ExploreSideFiltersProps>;
