import React from 'react';
import { useNavigate } from 'react-router-dom';

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Grid, IconButton } from '@mui/material';

const ListManagerButton = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate({
            pathname: `/user/lists`,
        });
    };

    return (
        <Grid
            sx={{
                mr: 1,
            }}
        >
            <IconButton color="primary" onClick={handleClick} data-testid="listsModule">
                <PlaylistAddCheckIcon />
            </IconButton>
        </Grid>
    );
};

export default ListManagerButton;
