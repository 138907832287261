import { intervalToDuration } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { FC } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Tooltip, Typography, TypographyProps } from '@mui/material';

import useFormatting from 'src/hooks/useFormatting';
import {
    dateFormatterShort,
    dateFormatterWithTimezone,
    timeFormatterWithTimezone,
} from 'src/utils/formattingUtils';

export const transformTimezone = (date, fromTimezone, toTimezone) =>
    utcToZonedTime(zonedTimeToUtc(date, toTimezone), fromTimezone);

export const getUserTimezone = () => {
    const userBrowserTimezone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
    const userTimezone = userBrowserTimezone || 'UTC';

    return userTimezone;
};

export const getTimeWithTimezone = (date: Date, timeZone: string, locale: string) =>
    dateFormatterWithTimezone(locale, date?.getTime(), timeZone || getUserTimezone());

interface Props {
    date: Date;
    timezone: any;
    typographyProps?: TypographyProps;
}

export const DateWithTime: FC<Props> = ({ date, timezone, typographyProps }: Props) => {
    const { i18n } = useLingui();
    const { formattingLocale } = useFormatting();
    const projectTime = date;

    return date ? (
        <Typography {...typographyProps}>
            <Box>
                <Typography variant="subtitle2">
                    {dateFormatterShort(formattingLocale, date.getTime())}
                </Typography>
                <Typography>
                    {`
                        ${timeFormatterWithTimezone(
                            formattingLocale || getUserTimezone(),
                            date?.getTime(),
                            getUserTimezone(),
                        )}
                        `}
                </Typography>
                {timezone !== getUserTimezone() && timezone !== null && timezone !== undefined && (
                    <Typography variant="subtitle2">
                        {`${i18n._('Project: ')} ${getTimeWithTimezone(
                            projectTime,
                            timezone,
                            formattingLocale,
                        )}`}
                    </Typography>
                )}
            </Box>
        </Typography>
    ) : (
        <Typography {...typographyProps} color="error">
            <Trans>Date not set</Trans>
        </Typography>
    );
};

export const DateWithTooltip: FC<Props> = ({ date, timezone, typographyProps }: Props) => {
    const { i18n } = useLingui();
    const { formattingLocale, dateFormatterShortByUserLocale } = useFormatting();
    const projectTime = date;

    return (
        <Tooltip
            data-testid="dateTooltip"
            placement="top"
            title={
                <Box>
                    <Typography variant="subtitle2">
                        {`${i18n._('Local: ')} ${getTimeWithTimezone(
                            date,
                            getUserTimezone(),
                            formattingLocale,
                        )}`}
                    </Typography>
                    {timezone !== getUserTimezone() &&
                        timezone !== null &&
                        timezone !== undefined && (
                            <Typography variant="subtitle2">
                                {`${i18n._('Project: ')} ${getTimeWithTimezone(
                                    projectTime,
                                    timezone,
                                    formattingLocale,
                                )}`}
                            </Typography>
                        )}
                </Box>
            }
        >
            {date ? (
                <Typography {...typographyProps}>
                    {dateFormatterShortByUserLocale(date?.getTime())}
                </Typography>
            ) : (
                <Typography {...typographyProps} color="error">
                    <Trans>Date not set</Trans>
                </Typography>
            )}
        </Tooltip>
    );
};

export const DateWithTimezone: FC<Props> = ({ date, timezone }: Props) => {
    const { i18n } = useLingui();
    const { formattingLocale } = useFormatting();
    const projectTime = transformTimezone(date, timezone, getUserTimezone());

    return (
        <Box>
            <Typography variant="subtitle2">
                {`${i18n._('Local time: ')} ${getTimeWithTimezone(
                    date,
                    getUserTimezone(),
                    formattingLocale,
                )}`}
            </Typography>
            {timezone !== getUserTimezone() && timezone !== null && timezone !== undefined && (
                <Typography variant="subtitle2">
                    {`${i18n._('Project time: ')} ${getTimeWithTimezone(
                        projectTime,
                        timezone,
                        formattingLocale,
                    )}`}
                </Typography>
            )}
        </Box>
    );
};

// elapsed time
const getElapsedTime = (duration: Duration) => {
    if (duration?.years) {
        return { value: duration.years, type: duration.years === 1 ? 'year' : 'years' };
    }
    if (duration?.months) {
        return {
            value: duration.months,
            type: duration.months === 1 ? 'month' : 'months',
        };
    }
    if (duration?.weeks) {
        return { value: duration.weeks, type: duration.weeks === 1 ? 'week' : 'weeks' };
    }
    if (duration?.days) {
        return { value: duration.days, type: duration.days === 1 ? 'day' : 'days' };
    }
    if (duration?.hours) {
        return { value: duration.hours, type: duration.hours === 1 ? 'hour' : 'hours' };
    }
    if (duration?.minutes) {
        return {
            value: duration.minutes,
            type: duration.minutes === 1 ? 'minute' : 'minutes',
        };
    }
    if (duration?.seconds) {
        return {
            value: duration.seconds,
            type: duration.seconds === 1 ? 'second' : 'seconds',
        };
    }
    return { value: null, type: 'now' };
};

const getElapsedTimeTranslation = (value: number, type: string) => {
    let translation = '';
    const prefix = ``;

    switch (type) {
        case 'year':
            translation = t`year ago`;
            break;
        case 'years':
            translation = t`years ago`;
            break;
        case 'month':
            translation = t`month ago`;
            break;
        case 'months':
            translation = t`months ago`;
            break;
        case 'week':
            translation = t`week ago`;
            break;
        case 'weeks':
            translation = t`weeks ago`;
            break;
        case 'day':
            translation = t`day ago`;
            break;
        case 'days':
            translation = t`days ago`;
            break;
        case 'hour':
            translation = t`hour ago`;
            break;
        case 'hours':
            translation = t`hours ago`;
            break;
        case 'minute':
            translation = t`minute ago`;
            break;
        case 'minutes':
            translation = t`minutes ago`;
            break;
        case 'second':
            translation = t`second ago`;
            break;
        case 'seconds':
            translation = t`seconds ago`;
            break;
        default:
            translation = t`just now`;
            break;
    }

    return `${prefix} ${value || ''} ${translation}`;
};

interface ElapsedTimeProps {
    start?: Date;
    end?: Date;
}

export const ElapsedTime: FC<ElapsedTimeProps> = ({ start, end }: ElapsedTimeProps) => {
    end = end || new Date();
    start = start || new Date();
    const elapsedTime = intervalToDuration({ start, end });
    const { value, type } = getElapsedTime(elapsedTime);

    return (
        <Typography variant="subtitle2" color="textSecondary">
            {getElapsedTimeTranslation(value, type)}
        </Typography>
    );
};

interface ElapsedTimeWithTooltipProps {
    start?: Date;
    end?: Date;
}

export const ElapsedTimeWithTooltip: FC<ElapsedTimeWithTooltipProps> = ({
    start,
    end,
}: ElapsedTimeWithTooltipProps) => {
    const { formattingLocale } = useFormatting();

    return (
        <Tooltip
            title={getTimeWithTimezone(new Date(start), null, formattingLocale)}
            placement="top-start"
        >
            <div>
                <ElapsedTime start={start} end={end} />
            </div>
        </Tooltip>
    );
};
