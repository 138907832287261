import { FC } from 'react';

import { Box, Divider, Link, Typography } from '@mui/material';

interface CompanyProfileResultEntryProps {
    content: any;
}

const CompanyProfileResultEntry: FC<CompanyProfileResultEntryProps> = ({
    content,
}: CompanyProfileResultEntryProps) => (
    <>
        <Box
            sx={{
                pb: 1,
            }}
        >
            <Typography
                variant="h6"
                dangerouslySetInnerHTML={{
                    __html: content.title,
                }}
            />
            <Typography
                dangerouslySetInnerHTML={{
                    __html: content.content,
                }}
            />
            <Link
                variant="h6"
                style={{
                    wordWrap: 'break-word',
                }}
                href={content.url}
                target="_blank"
            >
                {content.url}
            </Link>
        </Box>
        <Divider />
    </>
);

export default CompanyProfileResultEntry;
