import { graphql } from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/styles';

import { StepIcon } from 'src/components/StepIcon';
import { StepWithLink } from 'src/components/StepWithLink';
import { useRatecardProposalQuery } from 'src/components/ratecardproposals/RatecardProposalProvider';

interface Props {
    orientation: 'horizontal' | 'vertical';
}

const StyledStep = styled(Step)({
    '& a': {
        textDecoration: 'none !important',
    },
});

export const RatecardProposalStepper: FC<Props> = ({ orientation }: Props) => {
    const { i18n } = useLingui();
    const { ratecardProposalQuery } = useRatecardProposalQuery();
    const location = useLocation();

    const ratecardProposal = useFragment(
        graphql`
            fragment RatecardProposalStepper_ratecardProposal on RatecardProposalNode {
                id
                projectId
                projectName
                validation {
                    tenderIntroduction
                    ratecard
                    attachments
                    allSuccessful
                    errorMessage
                }
            }
        `,
        ratecardProposalQuery.ratecardProposal,
    );

    const steps = [
        {
            step: 1,
            label: i18n._('Tender introduction'),
            status: ratecardProposal?.validation?.tenderIntroduction,
            path: `/dashboard/ratecards/${ratecardProposal?.projectId}/proposals/${ratecardProposal?.id}/setup/tender-introduction`,
        },
        {
            step: 2,
            label: i18n._('Rate card'),
            status: ratecardProposal?.validation?.ratecard,
            path: `/dashboard/ratecards/${ratecardProposal?.projectId}/proposals/${ratecardProposal?.id}/setup/ratecard`,
        },
        {
            step: 3,
            label: i18n._('Attachments'),
            status: ratecardProposal?.validation?.attachments,
            path: `/dashboard/ratecards/${ratecardProposal?.projectId}/proposals/${ratecardProposal?.id}/setup/attachments`,
        },
    ];

    const activeStep = steps.findIndex((step) => matchPath(step.path, location.pathname));

    return (
        <Stepper nonLinear activeStep={activeStep} orientation={orientation}>
            {steps.map((step, i) => (
                <StyledStep key={step.step}>
                    <StepWithLink to={step.path} isCurrent={i === activeStep}>
                        <StepLabel
                            style={{ cursor: 'pointer' }}
                            error={step.status === 'error'}
                            icon={<StepIcon type={step.status} step={step.step} />}
                        >
                            {step.label}
                        </StepLabel>
                    </StepWithLink>
                </StyledStep>
            ))}
        </Stepper>
    );
};
