import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

import { ProjectPhasesFetcher } from './thunk';

export const projectPhasesSelector = (state: GlobalState) => state.projectPhases;

const projectPhasesSelectorData = (state: GlobalState) => projectPhasesSelector(state).data;

const projectPhasesStatusSelector = (state: GlobalState) => projectPhasesSelector(state).status;
export const useProjectPhases = () => useAppSelector(projectPhasesSelectorData);

export const useFetchProjectPhases = () => {
    const dispatch = useAppDispatch();
    const projectPhasesStatus = useAppSelector(projectPhasesStatusSelector);

    return useCallback(() => {
        if (projectPhasesStatus !== 'loading') {
            dispatch(ProjectPhasesFetcher.action());
        }
    }, [dispatch, projectPhasesStatus]);
};
