import { i18n } from '@lingui/core';

const getOptionKey = (object: any, key: string) => {
    const { locale: language } = i18n;

    if (object === null || object === undefined) {
        return object;
    }

    if (language === 'de') {
        return object[`${key}De`] || object[`${key}En`];
    }

    return object[`${key}En`];
};

export default getOptionKey;
