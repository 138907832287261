/**
 * @generated SignedSource<<70516b119622164a7a7e2ac8edd320ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JWTContextUserQuery$variables = {};
export type JWTContextUserQuery$data = {
  readonly userApps: ReadonlyArray<string | null> | null;
  readonly userProfile: {
    readonly activeRfps: number | null;
    readonly activeRfqs: number | null;
    readonly apaduaRole: string | null;
    readonly apalyticsRole: string | null;
    readonly briefRole: string | null;
    readonly chatwootSession: string | null;
    readonly chatwootToken: string | null;
    readonly email: string | null;
    readonly emailValidated: boolean | null;
    readonly exploreRole: string | null;
    readonly formattingLocale: string | null;
    readonly hasPassword: boolean | null;
    readonly isAgent: boolean | null;
    readonly isClient: boolean | null;
    readonly isConsultant: boolean | null;
    readonly isReferencesEmailUser: boolean | null;
    readonly isStaff: boolean | null;
    readonly isUserManager: boolean | null;
    readonly language: string | null;
    readonly monitorRole: string | null;
    readonly profileCompleted: boolean | null;
    readonly trackingConsent: boolean | null;
    readonly user: {
      readonly academicTitle: {
        readonly id: string;
        readonly titleDe: string;
        readonly titleEn: string;
      } | null;
      readonly company: {
        readonly companyName: string;
        readonly companyNameAlias: string;
        readonly companySizeEmployees: {
          readonly employeesRangeDe: string;
          readonly employeesRangeEn: string;
          readonly id: string;
          readonly published: boolean;
          readonly rangeMax: number | null;
          readonly rangeMin: number | null;
        } | null;
        readonly generalInfo: {
          readonly turnover: string | null;
          readonly website: string | null;
        } | null;
        readonly id: string;
        readonly mainAddress: {
          readonly address: {
            readonly addressLine1: string;
            readonly addressLine2: string | null;
            readonly addressLine3: string | null;
            readonly city: string;
            readonly country: {
              readonly commonNameDe: string;
              readonly commonNameEn: string;
              readonly id: string;
            };
            readonly postalCode: string;
          };
        } | null;
      } | null;
      readonly firstName: string;
      readonly id: string;
      readonly jobTitle: string | null;
      readonly lastName: string;
      readonly mobilePhone: string | null;
      readonly salutation: {
        readonly id: string;
        readonly salutationDe: string;
        readonly salutationEn: string;
      } | null;
      readonly ssoConnectUrl: string | null;
      readonly ssoConnected: boolean | null;
    } | null;
  } | null;
};
export type JWTContextUserQuery = {
  response: JWTContextUserQuery$data;
  variables: JWTContextUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userApps",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ssoConnected",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ssoConnectUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyNameAlias",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanySizeEmployeesNode",
  "kind": "LinkedField",
  "name": "companySizeEmployees",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeesRangeDe",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeesRangeEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeMax",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turnover",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLine1",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLine2",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLine3",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "CountryNode",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commonNameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commonNameDe",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobilePhone",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "SalutationNode",
  "kind": "LinkedField",
  "name": "salutation",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutationEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutationDe",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "AcademicTitleNode",
  "kind": "LinkedField",
  "name": "academicTitle",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleDe",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingConsent",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattingLocale",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailValidated",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileCompleted",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStaff",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPassword",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClient",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAgent",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isConsultant",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isReferencesEmailUser",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "briefRole",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apaduaRole",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exploreRole",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apalyticsRole",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monitorRole",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUserManager",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chatwootSession",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chatwootToken",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeRfps",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeRfqs",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JWTContextUserQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileNode",
        "kind": "LinkedField",
        "name": "userProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GeneralInfoNode",
                    "kind": "LinkedField",
                    "name": "generalInfo",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyAddressNode",
                    "kind": "LinkedField",
                    "name": "mainAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddressNode",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JWTContextUserQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfileNode",
        "kind": "LinkedField",
        "name": "userProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyNode",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GeneralInfoNode",
                    "kind": "LinkedField",
                    "name": "generalInfo",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyAddressNode",
                    "kind": "LinkedField",
                    "name": "mainAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddressNode",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18a9f3038ac3e0e66b5b7b9481cb62ac",
    "id": null,
    "metadata": {},
    "name": "JWTContextUserQuery",
    "operationKind": "query",
    "text": "query JWTContextUserQuery {\n  userApps\n  userProfile {\n    user {\n      id\n      firstName\n      ssoConnected\n      ssoConnectUrl\n      lastName\n      company {\n        id\n        companyName\n        companyNameAlias\n        companySizeEmployees {\n          id\n          published\n          employeesRangeDe\n          employeesRangeEn\n          rangeMin\n          rangeMax\n        }\n        generalInfo {\n          website\n          turnover\n          id\n        }\n        mainAddress {\n          address {\n            addressLine1\n            addressLine2\n            addressLine3\n            city\n            country {\n              id\n              commonNameEn\n              commonNameDe\n            }\n            postalCode\n            id\n          }\n          id\n        }\n      }\n      mobilePhone\n      jobTitle\n      salutation {\n        id\n        salutationEn\n        salutationDe\n      }\n      academicTitle {\n        id\n        titleEn\n        titleDe\n      }\n    }\n    language\n    trackingConsent\n    formattingLocale\n    email\n    emailValidated\n    profileCompleted\n    isStaff\n    hasPassword\n    isClient\n    isAgent\n    isConsultant\n    isReferencesEmailUser\n    briefRole\n    apaduaRole\n    exploreRole\n    apalyticsRole\n    monitorRole\n    isUserManager\n    chatwootSession\n    chatwootToken\n    activeRfps\n    activeRfqs\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ba7c2bdd77fa95fa0ca3efdd99bc60c";

export default node;
