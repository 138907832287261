import React, { ReactNode } from 'react';

import { ClickProps, TourProvider } from '@reactour/tour';

import DetectClickEvents from './detectClickEvents';

export interface ApaduaTourProps {
    children: ReactNode;
}

const handleClickClose = (clickProps: ClickProps) => {
    const { setCurrentStep, setIsOpen } = clickProps;
    setIsOpen(false);
    setCurrentStep(0);
};

const handleClickMask = (clickProps: ClickProps) => {
    const { steps, currentStep } = clickProps;
    steps[currentStep].onClickMask?.(clickProps);
};

const handleClickHighlighted = (e, clickProps: ClickProps) => {
    // library issue not working: https://github.com/elrumordelaluz/reactour/issues/619
    const { steps, currentStep } = clickProps;
    steps[currentStep].onClickHighlighted?.(e, clickProps);
};
const ApaduaTourProvider = ({ children, ...rest }: ApaduaTourProps) => (
    <TourProvider
        // @ts-ignore
        disableWhenSelectorFalsy
        steps={[]}
        onClickMask={handleClickMask}
        onClickHighlighted={handleClickHighlighted}
        onClickClose={handleClickClose}
        scrollSmooth
        defaultOpen={false}
        {...rest}
    >
        <DetectClickEvents>{children}</DetectClickEvents>
    </TourProvider>
);
export default ApaduaTourProvider;
