import { FieldArray } from 'formik';

import { StandardTextFieldProps } from '@mui/material';

import DynamicList, { DynamicListProps } from 'src/components/common/dynamicList';

interface TextFieldProps
    extends Omit<DynamicListProps, 'onChange'>,
        Omit<StandardTextFieldProps, 'multiline' | 'onChange'> {}

const DyanmicListField = ({ name, items, renderItem, emptyItem, ...rest }: TextFieldProps) => (
    <FieldArray
        name={name}
        render={(arrayHelper) => {
            const handleAdd = () => {
                arrayHelper.push(emptyItem);
            };
            const handleRemove = (index: number) => {
                arrayHelper.remove(index);
            };
            return (
                <DynamicList
                    items={items}
                    renderItem={renderItem}
                    onChange={null}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                    {...rest}
                />
            );
        }}
    />
);

export default DyanmicListField;
