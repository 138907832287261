import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
    paddingRight: theme.spacing(3),
}));

export const NameRenderer: React.FunctionComponent<ICellRendererParams> = ({
    value,
}: ICellRendererParams) => <StyledDiv>{value}</StyledDiv>;
