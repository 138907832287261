import { Link } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Alert, AlertProps } from '@mui/material';

interface NoCollaboratorsAlertProps extends AlertProps {
    userManagementRoute: string;
}

const NoCollaboratorsAlert = ({ userManagementRoute, ...rest }: NoCollaboratorsAlertProps) => (
    <Alert severity="warning" {...rest}>
        <Trans>
            You are the only user of this project. You can easily invite other users to this project
            in the <Link to={userManagementRoute}>User Management</Link> page
        </Trans>
    </Alert>
);

export default NoCollaboratorsAlert;
