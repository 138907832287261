import React from 'react';

import { Grid } from '@mui/material';

import DocumentGit from 'src/images/documentLoadingGray.gif';

const LoadingDocumentGif = () => (
    <Grid height="100%">
        <img
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            src={DocumentGit}
            alt=""
        />
    </Grid>
);

export default LoadingDocumentGif;
