import { Field } from 'formik';
import React from 'react';

import { StandardTextFieldProps } from '@mui/material';

import { FormikQuillEditor } from 'src/components/FormikQuillEditor';
import ErrorMessage from 'src/components/typography/errorMessage';

interface QuillFieldProps extends Omit<StandardTextFieldProps, 'multiline'> {
    errorMessage: string;
}

const QuillField: React.FC<QuillFieldProps> = ({ label, name, errorMessage, ...rest }) => (
    <>
        <Field
            fullWidth
            component={FormikQuillEditor}
            name={name}
            data-testid={name}
            label={label}
            {...rest}
        />
        <ErrorMessage errorMessage={errorMessage} />
    </>
);

export default QuillField;
