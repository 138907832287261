import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface StepWithLinkProps extends LinkProps {
    isCurrent: boolean;
    children: ReactNode;
}

export const StepWithLink: FC<StepWithLinkProps> = ({
    to,
    isCurrent,
    children,
}: StepWithLinkProps) => (!isCurrent ? <Link to={to}>{children}</Link> : <>{children}</>);
