import { FC } from 'react';

import VideoModal from 'src/components/common/modal/videoModal';

interface UserManagementModalProps {
    startTime?: number;
}

const UserManagementModal: FC<UserManagementModalProps> = ({ startTime }) => {
    const params = new URLSearchParams(startTime ? { t: `${startTime}s` } : null);
    const videoUrl = `https://www.youtube.com/watch?v=QRlR4afDz5g&${params.toString()}`;

    return <VideoModal videoUrl={videoUrl} />;
};

export default UserManagementModal;
