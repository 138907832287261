import { FC, useEffect, useRef, useState } from 'react';

import { i18n } from '@lingui/core';

import { ChatWootContext } from 'src/contexts/ChatWootContext';
import useAuth from 'src/hooks/useAuth';
import { environmentIsProduction } from 'src/utils/environment';

interface ApaduaChatWootAttributes {
    userId?: string;
    companyId?: string;
    companyName?: string;
}

interface ChatWootUser {
    email?: string;
    name?: string;
    identifier_hash?: string;
}

interface ChatWootApi {
    reset: () => void;
    setUser: (session: string, user: ChatWootUser) => void;
    setCustomAttributes: (attributes: ApaduaChatWootAttributes) => void;
    setLocale: (locale: string) => void;
    toggle: (mode: 'open' | 'close') => void;
}

interface Props {
    children: any;
}

export const ChatWootProvider: FC<Props> = ({ children }: Props) => {
    const { user } = useAuth();
    const [chatReady, setChatReady] = useState(false);
    const toggleChatRef = useRef(() => {});
    const { locale: language } = i18n;

    const chatwoot = (window as any)?.$chatwoot as ChatWootApi;

    useEffect(() => {
        let isMounted = true;

        const chatwootReady = () => {
            if (!isMounted || !chatwoot) {
                return;
            }

            setChatReady(true);

            chatwoot.setLocale(language);

            toggleChatRef.current = () => {
                chatwoot.toggle('open');
            };

            if (user !== null) {
                chatwoot.setUser(user.chatwootSession, {
                    email: user.email,
                    name: `${user.firstName} ${user.lastName}`,
                    identifier_hash: user.chatwootToken,
                });
                chatwoot.setCustomAttributes({
                    userId: user.id,
                    companyId: user.companyId,
                    companyName: user.companyName,
                });
            }
        };

        window.addEventListener('chatwoot:ready', chatwootReady);

        return () => {
            isMounted = false;
            window.removeEventListener('chatwoot:ready', chatwootReady);
        };
    }, [user, language]);

    useEffect(() => {
        if (chatwoot && environmentIsProduction()) {
            chatwoot.reset();
        }
    }, [user?.id]);

    return (
        <ChatWootContext.Provider
            value={{
                chatReady,
                toggleChat: () => {
                    toggleChatRef.current();
                },
            }}
        >
            {children}
        </ChatWootContext.Provider>
    );
};
