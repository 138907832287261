import { matchRoutes, useLocation } from 'react-router-dom';

import routes from '../routes';

const useCurrentRoute = () => {
    const location = useLocation();
    const matchedRoutes = matchRoutes(routes, location);
    if (!matchedRoutes) {
        return null;
    }

    // Check if any parent requires the tenant to be set
    matchedRoutes[matchedRoutes.length - 1].route.requiresTenant = matchedRoutes.some(
        (route) => route.route.requiresTenant,
    );
    return matchedRoutes[matchedRoutes.length - 1].route;
};

export default useCurrentRoute;
