import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import MuiTextField from '@mui/material/TextField';

import { Autocomplete } from 'src/components/formik-material-ui/Autocomplete';
import { useAppContext } from 'src/contexts/AppContext';
import getOptionKey from 'src/utils/language/getOptionKey';
import { sortByOptionLabelPair } from 'src/utils/sorting';

interface IndustryStandard {
    id: string;
    industryEn: string;
    industryDe: string;
}

const IndustryStandardSelectAllIndustryStandardsQuery = graphql`
    query IndustryStandardSelectAllIndustryStandardsQuery {
        allPublishedIndustryStandards {
            edges {
                node {
                    id
                    isLeaf
                    parentIndustryEn
                    parentIndustryDe
                    industryEn
                    industryDe
                }
            }
        }
    }
`;

const IndustryStandardSelect: FC<any> = (props: any) => {
    const { globalQueryRef } = useAppContext();
    const data = usePreloadedQuery(IndustryStandardSelectAllIndustryStandardsQuery, globalQueryRef);

    const allPublishedIndustryStandards = data.allPublishedIndustryStandards || {
        edges: [],
    };
    const options = allPublishedIndustryStandards.edges
        .map((edge) => edge.node)
        .sort(sortByOptionLabelPair('parentIndustry', 'industry'))
        .filter((x) => x.isLeaf === 'true');

    const { required } = props;

    return (
        <Autocomplete
            {...props}
            isOptionEqualToValue={(option: IndustryStandard, value?: IndustryStandard) =>
                option?.id === value?.id
            }
            getOptionLabel={(option: IndustryStandard): string => getOptionKey(option, 'industry')}
            groupBy={(option: IndustryStandard): string => getOptionKey(option, 'parentIndustry')}
            options={options}
            renderInput={(params: any): JSX.Element => (
                <MuiTextField
                    fullWidth
                    name={props.field.name}
                    variant="outlined"
                    {...params}
                    error={
                        props.validateontouch
                            ? !!(
                                  props.form.touched[props.field.name] &&
                                  props.form.errors[props.field.name]
                              )
                            : !!props.form.errors[props.field.name]
                    }
                    helperText={
                        props.validateontouch
                            ? props.form.touched[props.field.name] &&
                              props.form.errors[props.field.name]
                            : props.form.errors[props.field.name]
                    }
                    label={props.label || 'Industry Standard'}
                    required={!!required}
                />
            )}
        />
    );
};

export { IndustryStandardSelectAllIndustryStandardsQuery, IndustryStandardSelect };
