import React from 'react';

import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

import Section from 'src/components/sections/section';
import ProjectReviewAndSubmitContainer from 'src/project/projectSummary/projectReviewAndSubmitContainer';
import SupplierActions from 'src/project/projectSummary/supplierActions';
import { useFullProject } from 'src/project/state/hook';

const ProjectSummaryPage = () => {
    const fullProject = useFullProject();
    const { companyInformation } = fullProject;
    const companyName = companyInformation?.name;

    const actions = [<SupplierActions project={fullProject} />];

    return (
        <Section
            title={<Trans>RFP summary</Trans>}
            actions={actions}
            data-testid="ProjectReviewSubmit"
        >
            <Grid sx={{ mb: 1 }} display="flex" alignItems="center" flexDirection="column">
                <Typography color="gray" variant="h5">
                    <Trans>Request for Proposal</Trans>
                </Typography>
                {companyName && (
                    <Typography color="gray" variant="subtitle1">
                        <Trans>submitted by {companyName}</Trans>
                    </Typography>
                )}
            </Grid>
            <ProjectReviewAndSubmitContainer />
        </Section>
    );
};

export default ProjectSummaryPage;
