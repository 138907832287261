import { createSlice } from '@reduxjs/toolkit';

import { StatusValueType } from 'src/redux/fetcher';
import { combineSlices } from 'src/redux/utils';

import { projectAnnouncementsSlice } from './projectAnnouncementsSlice';
import { projectChecklistSlice } from './projectChecklistSlice';
import { projectDetailsSlice } from './projectDetailsSlice';
import { projectInfoSlice } from './projectInfoSlice';
import { projectTimelineSlice } from './projectTimelineSlice';
import { projectVendorsSlice } from './projectVendorsSlice';
import { sanitizeProject } from './sanitizers';
import {
    creatProjectAndpatchProjectInfoAsync,
    createProjectAsync,
    fetchProjectData,
} from './thunk';

export interface StepInterface {
    step: number;
    label: string;
    status: string;
    path: string;
}

export interface ProjectState {
    data: any;
    status: StatusValueType;
    error: any;

    step: {
        previousStep: StepInterface;
        currentStep: StepInterface;
        nextStep: StepInterface;
    };
}

const initialState: ProjectState = {
    data: {},
    status: 'idle',
    error: null,
    step: {
        previousStep: null,
        currentStep: null,
        nextStep: null,
    },
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjectStepState: (state, action) => {
            state.step.previousStep = action.payload.previousStep;
            state.step.currentStep = action.payload.currentStep;
            state.step.nextStep = action.payload.nextStep;
        },
        updateCurrentProjectValues: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(createProjectAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(createProjectAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = sanitizeProject(action.payload);
        });
        builder.addCase(createProjectAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error;
        });

        builder.addCase(fetchProjectData.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchProjectData.fulfilled, (state, action) => {
            state.data = sanitizeProject(action.payload);
            state.status = 'succeeded';
        });
        builder.addCase(fetchProjectData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error;
        });

        builder.addCase(creatProjectAndpatchProjectInfoAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(creatProjectAndpatchProjectInfoAsync.fulfilled, (state, action) => {
            state.data = sanitizeProject(action.payload);
            state.status = 'succeeded';
        });
        builder.addCase(creatProjectAndpatchProjectInfoAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error;
        });
    },
});

export const { setProjectStepState, updateCurrentProjectValues } = projectSlice.actions;

export default combineSlices(projectSlice.reducer, initialState, {
    projectVendors: projectVendorsSlice.reducer,
    projectInfo: projectInfoSlice.reducer,
    projectDetails: projectDetailsSlice.reducer,
    projectTimeline: projectTimelineSlice.reducer,
    projectChecklist: projectChecklistSlice.reducer,
    projectAnnouncements: projectAnnouncementsSlice.reducer,
});
