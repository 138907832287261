import { useEffect, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Button, Grid, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';

import { Information } from 'src/components/typography';
import useCancelTourOnUnmount from 'src/tours/cancelTourOnUnmount';
import { useUserTourPolicy } from 'src/tours/tourPolicy';

import TourContentWithDecline from './tourContentWithDecline';

export const useProjectBriefTour = () => {
    useCancelTourOnUnmount();
    const { shouldDisplay, onTourShown, onTourDeclined, onTourFinished } =
        useUserTourPolicy('ProjectBriefTour');
    const { setIsOpen, setSteps, setCurrentStep } = useTour();
    const computedSteps = useMemo(() => {
        const handleClose = () => {
            setIsOpen(false);
            setCurrentStep(0);
        };
        const tourSteps = [
            {
                selector: '[data-testid="generateSuggestions"]',
                content: (
                    <Information
                        title={
                            <Trans>
                                Generate AI-based suggestions/Disable AI-based suggestions
                            </Trans>
                        }
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        After filling in the context information and the project
                                        description of the brief you can generate AI-based
                                        suggestions for the project brief. If you do not want to use
                                        the AI-based suggestions, you can disable them by clicking
                                        on the toggle.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="saveLoadBrief"]',
                content: (
                    <Information
                        title={<Trans>Save and load brief</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        By clicking on “Save Brief” you can save the progress you
                                        have made on your project brief. Also, you can load saved
                                        project briefs by clicking on “load brief”.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="pdfDownloadButton"]',
                content: (
                    <Information
                        title={<Trans>Share brief</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        You can easily share your project brief as a pdf via email.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            },
            {
                selector: '[data-testid="createProject"]',
                content: (
                    <Information
                        title={<Trans>Create project</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        You can create an RFP project directly from the contents of
                                        your brief.
                                    </Trans>
                                </Typography>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        <Trans>Done</Trans>
                                    </Button>
                                </Grid>
                            </TourContentWithDecline>
                        }
                    />
                ),
                actionAfter: onTourFinished,
                stepInteraction: false,
            },
        ];

        if (window.ENABLE_FIND_VENDORS) {
            tourSteps.splice(3, 0, {
                selector: '[data-testid="findVendors"]',
                content: (
                    <Information
                        title={<Trans>Find vendors</Trans>}
                        value={
                            <TourContentWithDecline onTourDeclined={onTourDeclined}>
                                <Typography>
                                    <Trans>
                                        From the project brief generator, you can directly go to
                                        explore to search for vendors based on the contents of your
                                        brief.
                                    </Trans>
                                </Typography>
                            </TourContentWithDecline>
                        }
                    />
                ),
                stepInteraction: false,
            });
        }

        return tourSteps;
    }, []);

    useEffect(() => {
        setSteps(computedSteps);
        if (shouldDisplay) {
            onTourShown();

            setTimeout(() => setIsOpen(true), 1000);
        }
    }, [
        shouldDisplay,
        onTourShown,
        onTourDeclined,
        onTourFinished,
        setSteps,
        setIsOpen,
        computedSteps,
    ]);
};
