import { dictionaryCamelCaseConverter } from 'src/utils/dictionaryCamelCaseConverter';

import { CancellationData, CancellationResponse, ProposalData, ProposalResponse } from '../types';

export const sanitizeCancellation = (cancellation: CancellationResponse): CancellationData => ({
    objectId: cancellation.object_id,
    contentType: cancellation.content_type,
    cancelCause: cancellation.cancel_cause,
    cancelReason: cancellation.cancel_reason,
});

export const sanitizeProposal = (proposal: ProposalResponse): ProposalData => ({
    ...proposal,
    members: dictionaryCamelCaseConverter(proposal.members),
    cancellation: sanitizeCancellation(proposal.cancellation),
});
