import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FeedIcon from '@mui/icons-material/Feed';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaptopIcon from '@mui/icons-material/Laptop';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PollIcon from '@mui/icons-material/Poll';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

import NavSection from 'src/components/NavSection';

export const QuoteProposalSidebarSkeleton: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();

    const sections = [
        {
            title: '',
            items: [
                {
                    title: i18n._('Summary'),
                    path: '',
                    external: true,
                    icon: <FeedIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: '',
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Proposal'),
                    path: '',
                    external: true,
                    icon: <TableChartIcon fontSize="small" />,
                },
                {
                    title: i18n._('Pitch presentation'),
                    path: '',
                    external: true,
                    icon: <LaptopIcon fontSize="small" />,
                },
                {
                    title: i18n._('Result'),
                    path: '',
                    external: true,
                    icon: <PollIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: '',
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Announcements'),
                    path: '',
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: '',
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            <Typography color="textPrimary" variant="h6" align="center" noWrap>
                <Skeleton />
            </Typography>
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box>
                {sections.map((section) => (
                    <NavSection
                        key={section.title}
                        pathname={location.pathname}
                        sx={{
                            '& + &': {
                                mt: 3,
                            },
                        }}
                        {...section}
                    />
                ))}
            </Box>
        </Box>
    );
};
