import { FC } from 'react';

import { Chip, Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import useRfpStatus from 'src/translations/hooks/useRfpStatus';

interface Props {
    status: string;
}

const StyledRoleChip = styled(Chip)({
    color: 'white',
    fontWeight: 700,
    borderRadius: '4px',
    minWidth: '112px',
    '&.draft': {
        backgroundColor: '#5bc0de',
    },
    '&.sent': {
        backgroundColor: '#337ab7',
    },
    '&.completed': {
        backgroundColor: '#4caf50',
    },
    '&.onHold': {
        backgroundColor: '#c2c2c2',
    },
    '&.cancelled': {
        backgroundColor: '#750000',
    },
    '&.requested': {
        backgroundColor: '#f0ad4e',
    },
    '&.rejected': {
        backgroundColor: '#d9534f',
    },
    '&.invited': {
        backgroundColor: '#6f6f6f',
    },
    '&.accepted': {
        backgroundColor: '#5bc0de',
    },
    '&.submitted': {
        backgroundColor: '#4caf50',
    },
    '&.awarded': {
        backgroundColor: '#4caf50',
    },
    '&.dismissed': {
        backgroundColor: '#d9534f',
    },
    '&.lost': {
        backgroundColor: '#d9534f',
    },
    '&.declined': {
        backgroundColor: '#d9534f',
    },
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 14,
    },
}));

export const StatusChip: FC<Props> = ({ status }: Props) => {
    const { label, tooltip } = useRfpStatus(status);

    return (
        <CustomTooltip title={tooltip} placement="top">
            <StyledRoleChip label={label} size="small" className={status} />
        </CustomTooltip>
    );
};
