import { Trans } from '@lingui/macro';
import { timelineContentClasses } from '@mui/lab/TimelineContent';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import OptionsSection from 'src/components/sections/optionsSection';
import { Information, Language, ListInformation } from 'src/components/typography';
import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import EditAction from 'src/project/components/editAction';
import { ProjectTimeline } from 'src/project/rfpDetails/projectTimeline';
import { useProjectDetails, useProjectTimeline } from 'src/project/state/hook';
import { ProjectDetailsFormData } from 'src/project/types';
import getOptionKey from 'src/utils/language/getOptionKey';

import { formatProjectTimelineDates } from './formatProjectTimelineDates';

function getRenumerationModel(projectDetails: ProjectDetailsFormData) {
    if (projectDetails?.timeMaterial) {
        return <Trans>Time & Material</Trans>;
    }
    if (projectDetails?.fixedFee) {
        return <Trans>Fixed Fee</Trans>;
    }
    if (projectDetails?.successBased) {
        return <Trans>Success Based</Trans>;
    }
    if (projectDetails?.hybridModel) {
        return <Trans>Hybrid Model</Trans>;
    }

    return '-';
}

const RFPDetails = () => {
    const isSupplier = useIsUserSupplier();
    const projectDetails = useProjectDetails();
    const projectTimeline = useProjectTimeline();
    const formattedDateData = formatProjectTimelineDates(projectTimeline);
    const timelineEntries = (projectTimeline?.timelineEntries || []).map((item, index) => ({
        id: item.id,
        name: item.name,
        eventType: 'GENERAL',
        hardLimit: false,
        description: item?.description || '',
        date: new Date(item.date),
    }));

    const timeLineData = [...formattedDateData, ...timelineEntries];

    const timelineEvents =
        timeLineData?.sort((event1, event2) => event1.date.getTime() - event2.date.getTime()) || [];
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const baseFields = [
        'startDate',
        'endDate',
        'rfpReleaseDueDate',
        'proposalDueDate',
        'projectAwardDate',
    ];
    const pitchFields = ['presentationStartDate', 'presentationEndDate'];
    const checkFields = (data, withPitch) =>
        baseFields.some((f) => !data[f]) || (withPitch && pitchFields.some((f) => !data[f]));
    const notAllEventsScheduled = checkFields(projectTimeline, projectDetails?.pitchSession);

    return (
        <BucketSection
            title={<Trans>RFP Details</Trans>}
            actions={<EditAction editRoute="rfp-details#rfp-schedule" />}
        >
            <Bucket xs={12} sm={7}>
                <ProjectTimeline
                    editable={false}
                    timelineEvents={timelineEvents}
                    errorMessage={
                        notAllEventsScheduled && !isSupplier ? (
                            <Trans>Not all events have been scheduled</Trans>
                        ) : null
                    }
                    setTimelineEvents={() => null}
                    timezone={userTimeZone}
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.25,
                        },
                        [`& .${timelineContentClasses.root}`]: {
                            flex: 0.75,
                        },
                    }}
                />
            </Bucket>
            <Bucket xs={12} sm={5}>
                <Language
                    title={<Trans>Required Proposal Language</Trans>}
                    value={projectDetails?.proposalLanguage}
                />
                <OptionsSection title={<Trans>Project Phases</Trans>}>
                    {projectDetails?.projectPhases?.map(
                        (item, index) =>
                            item.selected && (
                                <ListInformation
                                    key={item.projectPhaseEn}
                                    number={index}
                                    value={getOptionKey(item, 'projectPhase')}
                                />
                            ),
                    )}
                </OptionsSection>

                <Information
                    title={<Trans>Required remuneration model</Trans>}
                    value={getRenumerationModel(projectDetails)}
                />
                <Information
                    title={<Trans>Submission procedure/closed envelope RFP</Trans>}
                    value={
                        projectDetails?.closedEnvelope ? (
                            <Trans>
                                Yes - All proposals will be disclosed once the proposal due date has
                                passed.
                            </Trans>
                        ) : (
                            <Trans>
                                No - Each proposal will be disclosed once it is submitted by the
                                vendor.
                            </Trans>
                        )
                    }
                />
            </Bucket>
        </BucketSection>
    );
};

export default RFPDetails;
