import { Field } from 'formik';
import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import OptionsSection from 'src/components/sections/optionsSection';
import ErrorMessage from 'src/components/typography/errorMessage';
import getOptionKey from 'src/utils/language/getOptionKey';

interface Props {
    values: [any];
    name: string;
    sectionTitle: React.ReactNode;
    labelKey: string;
}

const CheckBoxWithSection = ({ values, name, sectionTitle, labelKey }: Props) => (
    <Field name={name}>
        {({ meta, form }) => (
            <OptionsSection
                title={sectionTitle}
                header={<ErrorMessage errorMessage={meta.touched && meta.error} />}
            >
                {values.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                onChange={() => {
                                    const currentValue = values;
                                    form.setFieldTouched(name);
                                    form.setFieldValue(
                                        `${name}.${index}.selected`,
                                        !currentValue[index].selected,
                                        true,
                                    );
                                }}
                                checked={values[index]?.selected}
                                name={`${name}.${index}.selected`}
                                required
                            />
                        }
                        label={getOptionKey(item, labelKey)}
                    />
                ))}
            </OptionsSection>
        )}
    </Field>
);

export default CheckBoxWithSection;
