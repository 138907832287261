import React from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks';

const useQueryRefetch = (query: any) => {
    const environment = useRelayEnvironment();
    const [data, setData] = React.useState<any>({});
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const refetch = React.useCallback(
        (params) => {
            setIsRefreshing(true);
            fetchQuery(environment, query, params).subscribe({
                complete: () => {
                    setIsRefreshing(false);
                },
                error: () => {
                    setIsRefreshing(false);
                    setData([]);
                },
                next: (result) => {
                    setData(result);
                },
            });
        },
        [environment],
    );

    return {
        refetch,
        data,
        isRefreshing,
    };
};

export default useQueryRefetch;
