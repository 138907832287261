import { Form, Formik } from 'formik';
import debounce from 'lodash/debounce';
import React from 'react';
import * as Yup from 'yup';

import { Trans, t } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

import SpacingContainer from 'src/components/sections/spacingContainer';
import AutocompleteField from 'src/forms/autocompleteField';
import { useCreateProjectExtraVendorAsync, useProjectVendorsStatus } from 'src/project/state/hook';
import { appendProtocol } from 'src/utils/url';

import useCompaniesRefetch from './useCompaniesRefetch';

const initialValues = Object.freeze({
    companyName: '',
    website: '',
});

const validationSchema = Yup.object().shape({
    companyName: Yup.string()
        .nullable()
        .trim(() => t`Leading and trailing whitespaces are not allowed`)
        .required(() => t`Company Name is required`),
    website: Yup.string()
        .url()
        .transform(appendProtocol)
        .nullable()
        .trim(() => t`Leading and trailing whitespaces are not allowed`)
        .required(() => t`Company Website is required`),
});

const ExternalVendorForm = () => {
    const createProjectExtraVendor = useCreateProjectExtraVendorAsync();
    const [showExtraVendorForm, setShowExtraVendorForm] = React.useState<boolean>(false);
    const vendorsStatus = useProjectVendorsStatus();
    const isVendorsLoading = vendorsStatus === 'loading';

    const openExternalVendorForm = () => {
        setShowExtraVendorForm(true);
    };

    const closeExternalVendorForm = () => {
        setShowExtraVendorForm(false);
    };

    const saveExtraVendor = (values) => {
        values.website = appendProtocol(values.website);
        createProjectExtraVendor(values);
        closeExternalVendorForm();
    };

    const { companies, refetch } = useCompaniesRefetch();

    const debouncedChangeHandler = React.useMemo(
        () =>
            debounce((e) => {
                refetch(e.target.value);
            }, 300),
        [refetch],
    );
    const companiesWithWebSite = companies.filter((item) => item?.generalInfo?.website);

    return (
        <Box mt={2}>
            {showExtraVendorForm ? (
                <Formik
                    onSubmit={saveExtraVendor}
                    validateOnChange={false}
                    validateOnBlur
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                >
                    {(): JSX.Element => (
                        <Form>
                            <SpacingContainer flexDirection="column">
                                <AutocompleteField
                                    inputProps={{
                                        onChange: debouncedChangeHandler,
                                        required: true,
                                    }}
                                    freeSolo
                                    getOptionValue={(option: any) => option?.companyName}
                                    options={companies}
                                    name="companyName"
                                    label={<Trans>Company Name</Trans>}
                                    required
                                    getOptionLabel={(option: any): string =>
                                        option?.companyName || ''
                                    }
                                />
                                <AutocompleteField
                                    inputProps={{
                                        onChange: debouncedChangeHandler,
                                        required: true,
                                    }}
                                    options={companiesWithWebSite}
                                    name="website"
                                    freeSolo
                                    label={<Trans>Company Website</Trans>}
                                    required
                                    getOptionLabel={(option: any): string =>
                                        option?.generalInfo?.website || ''
                                    }
                                    getOptionValue={(option: any) => option?.generalInfo?.website}
                                />

                                <SpacingContainer>
                                    <LoadingButton
                                        disabled={isVendorsLoading}
                                        loading={isVendorsLoading}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Save
                                    </LoadingButton>
                                    <Button onClick={closeExternalVendorForm}>Cancel</Button>
                                </SpacingContainer>
                            </SpacingContainer>
                        </Form>
                    )}
                </Formik>
            ) : (
                <LoadingButton
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={openExternalVendorForm}
                    disabled={isVendorsLoading}
                    loading={isVendorsLoading}
                >
                    <Trans>Add own vendor</Trans>
                </LoadingButton>
            )}
        </Box>
    );
};

export default ExternalVendorForm;
