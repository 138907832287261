import { Trans } from '@lingui/macro';
import { Box, TableCell, TableHead, TableRow } from '@mui/material';

export const descriptionDisplayStyle = {
    xs: 'none',
    md: 'none',
    lg: 'table-cell',
};

const MR1 = Object.freeze({ mr: 1 }); // needed to match the marging on the delete button

const VendorsTableHead = () => (
    <TableHead>
        <TableRow>
            <TableCell>{/* This cell is intentionally left empty for aligning logos */}</TableCell>
            <TableCell>
                <Trans>Vendor</Trans>
            </TableCell>
            <TableCell>
                <Trans>Website</Trans>
            </TableCell>
            <TableCell>
                <Trans>Contact</Trans>
            </TableCell>
            <TableCell align="right">
                <Box sx={MR1}>
                    <Trans>Actions</Trans>
                </Box>
            </TableCell>
        </TableRow>
    </TableHead>
);

export default VendorsTableHead;
