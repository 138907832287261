import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect, useRef, useState } from 'react';
import { commitMutation } from 'react-relay';
import { useRelayEnvironment } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';

export const LanguagePopover: FC = () => {
    const { i18n } = useLingui();
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const { user } = useAuth();
    const environment = useRelayEnvironment();

    const changeLanguage = (language) =>
        commitMutation(environment, {
            mutation: graphql`
                mutation LanguagePopoverUpdateUserLanguageMutation(
                    $input: UpdateUserLanguageInput!
                ) {
                    updateUserLanguage(input: $input) {
                        user {
                            language
                            id
                        }
                    }
                }
            `,
            variables: {
                input: {
                    language,
                },
            },
        });

    const languageOptions = {
        en: {
            icon: '/static/icons/uk_flag.svg',
            label: `${i18n._('English')}`,
        },
        de: {
            icon: '/static/icons/de_flag.svg',
            label: `${i18n._('German')}`,
        },
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChangeLanguage = (language: string): void => {
        i18n.activate(language);
        setOpen(false);

        user.language = language;
        changeLanguage(language);
    };

    const selectedOption = languageOptions[user.language];

    useEffect(() => {
        i18n.activate(user.language);
    }, []);

    return (
        <>
            <IconButton onClick={handleOpen} ref={anchorRef}>
                <Box
                    sx={{
                        display: 'flex',
                        height: 20,
                        width: 20,
                        '& img': {
                            width: '100%',
                        },
                    }}
                >
                    <img alt={selectedOption.label} src={selectedOption.icon} />
                </Box>
            </IconButton>
            {open && (
                <Popover
                    anchorEl={anchorRef.current}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                    }}
                    keepMounted
                    onClose={handleClose}
                    open
                    PaperProps={{
                        sx: { width: 240 },
                    }}
                >
                    {Object.keys(languageOptions).map((language) => (
                        <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
                            <ListItemIcon>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: 20,
                                        width: 20,
                                        '& img': {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <img
                                        alt={languageOptions[language].label}
                                        src={languageOptions[language].icon}
                                    />
                                </Box>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography color="textPrimary" variant="subtitle2">
                                        {languageOptions[language].label}
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    ))}
                </Popover>
            )}
        </>
    );
};
