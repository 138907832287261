import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ListsAPI } from 'src/apis/ListsAPI';
import type { ListData, ListItem } from 'src/apis/ListsAPI';
import Fetcher from 'src/redux/fetcher';
import { ListTypeEnum } from 'src/types';

import DismissibleToast from '../../../utils/DismissibleToast';

const api = new ListsAPI();

export const ListsFetcher = new Fetcher('lists/fetchLists', async (id: string) =>
    api.getLists({ includeItems: true }),
);

interface CreateListPayload {
    name: string;
    note: string;
    type: string;
    selectedVendors?: Array<ListItem>;
}

export const createList = createAsyncThunk(
    'lists/createList',
    async (listData: CreateListPayload) => {
        const { name, note, selectedVendors } = listData;
        const type = ListTypeEnum.VENDORS_LIST;
        const list: ListData = await api.createList({ name, note, type });
        if (selectedVendors) {
            await api.createListItems(list.id, selectedVendors);
        }
        const response = await api.getLists({ includeItems: true });
        toast.success(DismissibleToast('New list created'));

        return response;
    },
);

export const createOrganisationList = createAsyncThunk(
    'lists/createOrganisationList',
    async (listData: CreateListPayload) => {
        const { name, note, selectedVendors } = listData;
        const type = ListTypeEnum.VENDORS_LIST;
        const list: ListData = await api.createOrganisationList({ name, note, type });
        if (selectedVendors) {
            await api.createListItems(list.id, selectedVendors);
        }
        const response = await api.getList(list.id, { includeItems: true });

        toast.success(DismissibleToast(i18n.t('New organisation list created')));

        return response;
    },
);

interface CreateListItemPayload {
    listId: string;
    listItems: Array<ListItem>;
}

interface DeleteListItemPayload {
    listId: string;
    listItemId: string;
}

interface DeleteItemPayload {
    listId: string;
    listItemId: string;
}

export const createListItem = createAsyncThunk(
    'lists/createListItem',
    async ({ listId, listItems }: CreateListItemPayload) => {
        await api.createListItems(listId, listItems);
        const response = await api.getLists({ includeItems: true });

        toast.success(DismissibleToast('Vendor list updated'));

        return response;
    },
);

export const deleteListItem = createAsyncThunk(
    'lists/deleteListItem',
    async ({ listId, listItemId }: DeleteListItemPayload): Promise<DeleteItemPayload> => {
        await api.deleteListItem(listId, listItemId);

        toast.success(DismissibleToast('Vendor deleted'));

        return { listId, listItemId };
    },
);

export const deleteList = createAsyncThunk('lists/deleteList', async (listId: string) => {
    await api.deleteList(listId);
    const response = await api.getLists({ includeItems: true });

    toast.success(DismissibleToast('List deleted'));

    return response;
});

export const updateList = createAsyncThunk('lists/updateList', async (list: ListData) => {
    await api.updateList(list.id, list);
    const response = await api.getLists({ includeItems: true });

    toast.success(DismissibleToast('List updated'));

    return response;
});

interface UpdateItemPayload {
    listId: string;
    itemId: string;
    item: ListItem;
}

export const updateListItem = createAsyncThunk(
    'lists/updateListItem',
    async ({ listId, itemId, item }: UpdateItemPayload) => {
        await api.updateListItem(listId, itemId, item);
        const response = await api.getLists({ includeItems: true });

        toast.success(DismissibleToast('Vendor note updated'));

        return response;
    },
);
