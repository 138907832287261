import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Divider, Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';

import NavSection from 'src/components/NavSection';
import { StepIcon } from 'src/components/StepIcon';
import { StepWithLink } from 'src/components/StepWithLink';
import { StepperTitle } from 'src/components/StepperTitle';
import SidebarOthers from 'src/components/utils/SidebarOthers';
import {
    useFullProject,
    useProjectCoverLetterStatus,
    useProjectDetailsStepStatus,
    useProjectInfoStepStatus,
    useProjectVendorsStepStatus,
} from 'src/project/state/hook';
import { StepInterface, setProjectStepState } from 'src/project/state/reducer';
import { ProjectStatus } from 'src/project/types';
import { useAppDispatch } from 'src/redux/hooks';
import { routeURLs } from 'src/router/routeURLs';

const StyledStepLabel = styled(StepLabel)(() => ({
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const ProjectSideBar = () => {
    const fullProject = useFullProject();
    const { i18n } = useLingui();
    const dispatch = useAppDispatch();
    const currentLocation = useLocation();
    const params = useParams();
    const { id } = params;
    const projectInfoIsComplete = useProjectInfoStepStatus();
    const projectVendorsIsComplete = useProjectVendorsStepStatus();
    const projectDetailsIsComplete = useProjectDetailsStepStatus();
    const projectCoverLetterStatus = useProjectCoverLetterStatus();

    const steps: Array<StepInterface> = React.useMemo(
        () => [
            {
                step: 1,
                label: i18n._('Project Info'),
                status: projectInfoIsComplete,
                path: `/project/${id}`,
            },
            {
                step: 2,
                label: i18n._('Vendors'),
                status: projectVendorsIsComplete,
                path: `/project/${id}/${routeURLs.PROJECT_VENDORS}`,
            },
            {
                step: 3,
                label: i18n._('RFP Details'),
                status: projectDetailsIsComplete,
                path: `/project/${id}/${routeURLs.PROJECT_RFP_DETAILS}`,
            },
            {
                step: 4,
                label: i18n._('Criteria Evaluation'),
                status: 'success',
                path: `/project/${id}/${routeURLs.PROJECT_CRITERIA_EVALUATION}`,
            },
            {
                step: 5,
                label: i18n._('Cover letter'),
                status: projectCoverLetterStatus,
                path: `/project/${id}/${routeURLs.PROJECT_COVER_LETTER}`,
            },
            {
                step: 6,
                label: i18n._('Review & Submit'),
                status: 'warning',
                path: `/project/${id}/${routeURLs.PROJECT_REVIEW_AND_SUBMIT}`,
            },
        ],
        [
            i18n.locale,
            id,
            projectInfoIsComplete,
            projectVendorsIsComplete,
            projectDetailsIsComplete,
            projectCoverLetterStatus,
        ],
    );

    const extraSections =
        fullProject?.status !== ProjectStatus.draft
            ? [
                  {
                      title: i18n._('Announcements'),
                      path: `/project/${id}/${routeURLs.PROJECT_ANNOUNCEMENTS}`,
                      icon: <ChatIcon fontSize="small" />,
                  },
              ]
            : [];

    const sections = [
        {
            title: (<SidebarOthers />) as unknown as string,
            items: [
                {
                    title: i18n._('Internal Documents'),
                    path: `/project/${id}/${routeURLs.PROJECT_INTERNAL_DOCUMENTS}`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
                {
                    title: i18n._('User Management'),
                    path: `/project/${id}/${routeURLs.PROJECT_USER_MANAGEMENT}`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                ...extraSections,
            ],
        },
    ];

    const activeStepIndex = steps.findIndex((step) => `${step.path}` === currentLocation.pathname);

    React.useEffect(() => {
        if (typeof activeStepIndex === 'number' && activeStepIndex >= 0) {
            dispatch(
                setProjectStepState({
                    previousStep: activeStepIndex ? steps[activeStepIndex - 1] : null,
                    currentStep: steps[activeStepIndex],
                    nextStep: steps[activeStepIndex + 1],
                }),
            );
        }
    }, [steps, activeStepIndex, dispatch]);

    return (
        <Box sx={{ my: 1, px: 2, pb: 2 }}>
            {fullProject?.projectTitle ? (
                <StepperTitle title={fullProject.projectTitle} status={fullProject?.status} />
            ) : null}
            <Stepper
                nonLinear
                activeStep={activeStepIndex}
                orientation="vertical"
                data-testid="projectStepper"
            >
                {steps.map((step, i) => (
                    <Step key={step.step}>
                        <StepWithLink to={step.path} isCurrent={i === activeStepIndex}>
                            <StyledStepLabel
                                data-testid={step.label}
                                error={step.status === 'error'}
                                icon={<StepIcon type={step.status} step={step.step} />}
                            >
                                {step.label}
                            </StyledStepLabel>
                        </StepWithLink>
                    </Step>
                ))}
            </Stepper>
            <Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={currentLocation.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default ProjectSideBar;
