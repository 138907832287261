import { intervalToDuration } from 'date-fns';

import * as Sentry from '@sentry/react';

import { notificationOptions } from 'src/components/notifications/notificationTypeProps';
import { environmentIsDemo, environmentIsProduction } from 'src/utils/environment';

const getNotificationTimeSection = (notification) => {
    const start = new Date(notification?.created);
    const end = new Date();
    const { years, months, days, hours, minutes, seconds } = intervalToDuration({
        start,
        end,
    });

    if (years || months || days > 7) return 'older';
    if (days <= 7 && days > 1) return 'lastWeek';
    if (days === 1) return 'yesterday';
    if (days === 0 && (hours || minutes || seconds)) return 'today';
    return null;
};

export const getNotificationCategories = (notifications) => {
    const todaysNotifications = [];
    const yesterdaysNotifications = [];
    const lastWeeksNotifications = [];
    const olderNotifications = [];

    notifications?.forEach((notification) => {
        const timeSection = getNotificationTimeSection(notification);
        switch (timeSection) {
            case 'today':
                todaysNotifications.push(notification);
                break;
            case 'yesterday':
                yesterdaysNotifications.push(notification);
                break;
            case 'lastWeek':
                lastWeeksNotifications.push(notification);
                break;
            case 'older':
                olderNotifications.push(notification);
                break;
            default:
                break;
        }
    });

    return {
        todaysNotifications,
        yesterdaysNotifications,
        lastWeeksNotifications,
        olderNotifications,
    };
};

// Check required fileds
export const validateRequiredContextFields = async (notification) => {
    const { id, context, notificationType } = notification;
    const { requiredContextFields } = notificationOptions[notificationType];
    requiredContextFields?.forEach((requiredField) => {
        // the required field is not present in the context
        if (!(requiredField in context)) {
            // send message to sentry only on demo and production
            if (environmentIsProduction() || environmentIsDemo()) {
                Sentry.captureMessage(
                    `${requiredField} missing in context from notification ${notificationType}: ${id}`,
                );
            } else {
                console.error(
                    `${requiredField} missing in context from notification ${notificationType}: ${id}`,
                );
            }
        }
    });
};

export const validateNotificationsContext = async (notifications) => {
    notifications?.forEach((notification) => {
        validateRequiredContextFields(notification);
    });
};
