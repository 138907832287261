import type { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { navigateAction } from 'src/sagas/actions';

interface NavigationLinkProps extends PropsWithChildren<{}> {
    href?: string;
}

const NavigationLink = ({ href, children }: NavigationLinkProps) => {
    const dispatch = useDispatch();

    const handleNavigation = () => {
        dispatch(navigateAction({ path: href }));
    };

    return (
        <Link to={null} onClick={handleNavigation}>
            {children}
        </Link>
    );
};

export default NavigationLink;
