import { FC, useContext, useState } from 'react';

import { Box, TablePagination } from '@mui/material';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';

const CompanyResultsPaginationControls: FC = () => {
    const { refreshCompanyResultsQuery, totalCount, search, filters } =
        useContext(CompanyExploreContext);
    const { company } = useContext(CompanyProfileContext);
    const [pageSize, setPageSize] = useState(filters.last || 5);
    const [page, setPage] = useState(0);

    const seekLeft = () => {
        const newFilters = {
            keyword: search,
            first: (page + 1) * pageSize,
            last: pageSize,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        if (newFilters.first > 2 * pageSize - 1) {
            newFilters.first -= pageSize;
        } else {
            newFilters.first = pageSize;
        }
        refreshCompanyResultsQuery(newFilters);
    };

    const seekRight = () => {
        const newFilters = {
            keyword: search,
            first: (page + 1) * pageSize,
            last: pageSize,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        newFilters.first += pageSize;
        refreshCompanyResultsQuery(newFilters);
    };

    const handlePageSizeChange = (size) => {
        if (typeof size === 'string') {
            size = Number.parseInt(size, 10);
        }
        setPageSize(size);
        const newFilters = {
            keyword: search,
            first: (page + 1) * pageSize,
            last: pageSize,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        newFilters.last = size;
        newFilters.first = size;
        refreshCompanyResultsQuery(newFilters);
        setPage(0);
    };

    const handlePageChange = (event, pageNumber) => {
        if (pageNumber > page) {
            seekRight();
        } else {
            seekLeft();
        }
        setPage(pageNumber);
    };

    return (
        <Box
            sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <TablePagination
                component="div"
                count={totalCount || 0}
                page={page}
                rowsPerPageOptions={[5, 10]}
                onPageChange={handlePageChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
            />
        </Box>
    );
};

export default CompanyResultsPaginationControls;
