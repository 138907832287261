import uniq from 'lodash/uniq';

import { createSlice } from '@reduxjs/toolkit';

import { fetchTotalVendorsCount } from './thunk';

export type VendorsState = {
    selected: Set<string>;
};

export const vendorsSlice = createSlice({
    name: 'vendors',
    initialState: {
        selected: [],
        totalVendorsCount: 0,
    },
    reducers: {
        selectVendors: (state, action) => {
            action.payload.forEach((vendorId) => state.selected.push(vendorId));
            state.selected = uniq(state.selected);
        },
        deselectVendors: (state, action) => {
            state.selected = state.selected.filter((id) => !action.payload.includes(id));
        },
        clearSelectedVendors: (state, action) => {
            state.selected = [];
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchTotalVendorsCount.fulfilled, (state, action) => {
            state.totalVendorsCount = action.payload.count;
        });
    },
});

// Action creators are generated for each case reducer function
export const { selectVendors, deselectVendors, clearSelectedVendors } = vendorsSlice.actions;

export default vendorsSlice.reducer;
