/**
 * @generated SignedSource<<05f33c59e80dc8bfef9a0662bd9b862c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RatecardProjectEditModeSidebar_ratecardProject$data = {
  readonly editMode: boolean;
  readonly id: string;
  readonly isCurrentUserAdmin: boolean | null;
  readonly projectName: string | null;
  readonly validation: {
    readonly allSuccessful: boolean | null;
    readonly errorMessage: string | null;
    readonly otherInformation: string | null;
    readonly ratecard: string | null;
    readonly supplierSelection: string | null;
    readonly tenderPlanning: string | null;
  } | null;
  readonly " $fragmentType": "RatecardProjectEditModeSidebar_ratecardProject";
};
export type RatecardProjectEditModeSidebar_ratecardProject$key = {
  readonly " $data"?: RatecardProjectEditModeSidebar_ratecardProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"RatecardProjectEditModeSidebar_ratecardProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RatecardProjectEditModeSidebar_ratecardProject",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentUserAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RatecardProjectValidationNode",
      "kind": "LinkedField",
      "name": "validation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenderPlanning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplierSelection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratecard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherInformation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allSuccessful",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RatecardProjectNode",
  "abstractKey": null
};

(node as any).hash = "503c5d09be3dcad9888f9cac929e4ef9";

export default node;
