import React from 'react';

import { Trans } from '@lingui/macro';

import { useFullProject, usePatchProjectInfoAsync } from 'src/project/state/hook';
import { updateCurrentProjectValues } from 'src/project/state/reducer';
import { ProjectFormData } from 'src/project/state/sanitizers';
import { patchProjectInfoAsync } from 'src/project/state/thunk';
import ProjectBriefContainer from 'src/projectBrief/projectBriefContainer';
import { useAppDispatch } from 'src/redux/hooks';
import { useProjectInformationTour } from 'src/tours/projectInformationTour';

const ProjectBriefPage = () => {
    useProjectInformationTour();
    const fullProject = useFullProject();
    const patchProjectInfo = usePatchProjectInfoAsync();
    const dispatch = useAppDispatch();

    const handleSave = async (values) => {
        await patchProjectInfo(values);
    };

    const handleChange = (values: ProjectFormData) => {
        dispatch(updateCurrentProjectValues({ projectTitle: values.projectTitle }));
        dispatch({
            type: patchProjectInfoAsync.fulfilled.type,
            payload: values,
        });
    };

    return (
        <ProjectBriefContainer
            title={<Trans>Project Information</Trans>}
            withActions
            onSave={handleSave}
            onChange={handleChange}
            isEditMode
            project={{
                ...fullProject.info,
                request: fullProject.request,
                projectTitle: fullProject.projectTitle,
            }}
        />
    );
};

export default ProjectBriefPage;
