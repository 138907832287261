import graphql from 'babel-plugin-relay/macro';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-relay/hooks';
import { Element } from 'react-scroll';

import { useLingui } from '@lingui/react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

import { FileDropzone } from 'src/components/fileUpload/FileDropzone';
import DismissibleToast from 'src/utils/DismissibleToast';

type Props = {
    internalDocumentSetId: string;
    viewIsReadOnly?: boolean;
    refreshInternalDocumentQuery: any;
};

export const UploadInternalDocuments: FC<Props> = ({
    internalDocumentSetId,
    viewIsReadOnly,
    refreshInternalDocumentQuery,
}: Props) => {
    const { i18n } = useLingui();
    const uploadApiUrl = 'api/upload-internal-documents/';

    const [fileIds, setFileIds] = useState([]);

    const [setInternalDocumentSetForFiles] = useMutation(graphql`
        mutation UploadInternalDocumentsSetInternalDocumentSetForFilesMutation(
            $input: SetInternalDocumentSetForFilesMutationInput!
        ) {
            setInternalDocumentSetForFiles(input: $input) {
                success
            }
        }
    `);

    const publishDocuments = () => {
        if (fileIds.length > 0) {
            const files = fileIds.map((fileNode) => fileNode.data.data.id);
            setInternalDocumentSetForFiles({
                variables: {
                    input: {
                        internalDocumentSetId,
                        fileIds: files,
                    },
                },
                onCompleted(completedDataFileList, errors) {
                    if (errors == null) {
                        toast.success(DismissibleToast(i18n._('Documents uploaded successfully')));
                        setFileIds([]);
                        refreshInternalDocumentQuery();
                    } else {
                        toast.error(DismissibleToast(i18n._('Could not upload documents')));
                    }
                },
                onError() {
                    toast.error(DismissibleToast(i18n._('Connection error')));
                },
            });
        }
    };

    return (
        <Card variant="outlined" sx={{ mb: 4 }}>
            <CardHeader title={i18n._('Upload documents')} />
            <Divider />
            <CardContent>
                <Element name="newInternalDocument" />
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        minHeight: '100%',
                        p: 3,
                    }}
                    data-testid="fileUploadArea"
                >
                    <Typography color="textSecondary" variant="subtitle2">
                        <FileDropzone
                            uploadApiUrl={uploadApiUrl}
                            viewIsReadOnly={viewIsReadOnly}
                            fileIds={fileIds}
                            setFileIds={setFileIds}
                        />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{ px: 3 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={publishDocuments}
                        startIcon={<UploadFileIcon />}
                        data-testid="publishInternalDocument"
                    >
                        {i18n._('Publish')}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
