import { FormHelperText, FormHelperTextProps } from '@mui/material';
import Grid from '@mui/material/Grid';

export interface NoteProps extends FormHelperTextProps {
    value: React.ReactNode;
    defaultValue?: string;
}

const Note = ({ value, defaultValue = '-', ...rest }: NoteProps) => (
    <Grid item xs>
        <FormHelperText {...rest}>{value || defaultValue}</FormHelperText>
    </Grid>
);

export default Note;
