import graphql from 'babel-plugin-relay/macro';
import { useConfirm } from 'material-ui-confirm';
import { FC } from 'react';
import { useMutation } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { UserAvatarWithPopup } from 'src/components/dashboard/UserAvatarWithPopup';
import { useMobile } from 'src/hooks/useMobile';
import { downloadAttachmentFromUrl } from 'src/utils/file';
import { scrollTo } from 'src/utils/scroll';
import { DateWithTooltip, getUserTimezone } from 'src/utils/time';
import InternalDocumentTutorialModalModal from 'src/videoModals/InternalDocumentTutorialModal';

const StyledFileNameBox = styled('div')({
    display: 'flex',
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

interface Props {
    internalDocuments: any;
    refreshInternalDocumentQuery: any;
    viewIsReadOnly: boolean;
}

export const InternalDocumentList: FC<Props> = ({
    internalDocuments,
    refreshInternalDocumentQuery,
    viewIsReadOnly,
}: Props) => {
    const { i18n } = useLingui();
    const isMobile = useMobile();
    const confirm = useConfirm();

    const timezone = getUserTimezone();

    const [commitDelete] = useMutation(graphql`
        mutation InternalDocumentListDeleteMutation($input: DeleteInternalDocumentMutationInput!) {
            deleteInternalDocument(input: $input) {
                success
            }
        }
    `);

    const openFile = (url) => {
        downloadAttachmentFromUrl(url);
    };

    const handleDeleteClick = (id) => {
        confirm({
            title: i18n._('Are you sure you want to delete this file?'),
            description: i18n._('This action is permanent!'),
            confirmationText: i18n._('Yes'),
            cancellationText: i18n._('Cancel'),
        })
            .then(() => {
                commitDelete({
                    variables: {
                        input: {
                            internalDocumentId: id,
                        },
                    },
                    onCompleted(completedData, errors) {
                        if (errors === null) {
                            refreshInternalDocumentQuery();
                        }
                    },
                });
            })
            .catch(() => {});
    };

    return (
        <Card variant="outlined" sx={{ mb: 4 }}>
            <Box
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ p: 2 }}
            >
                <Box sx={{ pr: 2 }} flexGrow={1} display="flex" alignItems="baseline">
                    <Typography variant="h6" color="textPrimary">
                        {i18n._('Internal Documents')}
                    </Typography>
                    <InternalDocumentTutorialModalModal />
                </Box>
                <Box>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => scrollTo('newInternalDocument')}
                        startIcon={<ArrowDownwardIcon />}
                    >
                        {i18n._('Add new documents')}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <CardContent sx={isMobile ? { p: 0 } : {}}>
                {!internalDocuments || internalDocuments?.length === 0 ? (
                    <Typography color="textPrimary" variant="subtitle2" sx={{ pt: 2, pl: 2 }}>
                        {i18n._('No internal documents')}
                    </Typography>
                ) : (
                    <Box sx={{ mx: 3, mb: 3 }}>
                        {internalDocuments?.map((fileNode) => (
                            <Box key={fileNode?.id}>
                                <Box display="flex" alignItems="center" sx={{ my: 1 }}>
                                    {/* Avatar */}
                                    <Box sx={{ mr: 2 }}>
                                        <UserAvatarWithPopup user={fileNode?.user} />
                                    </Box>
                                    {/* File and upload date */}
                                    <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                                        {/* File name */}
                                        <Box
                                            onClick={() => {
                                                openFile(fileNode?.fileUrl);
                                            }}
                                        >
                                            <Tooltip
                                                title={i18n._('Download')}
                                                placement="top-start"
                                            >
                                                <StyledFileNameBox>
                                                    <InsertDriveFileIcon />
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                        noWrap
                                                        sx={{ pl: 1 }}
                                                    >
                                                        {fileNode?.fileName}
                                                    </Typography>
                                                </StyledFileNameBox>
                                            </Tooltip>
                                        </Box>
                                        {/* Upload date */}
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                {`${i18n._('Uploaded at')} `}
                                                <DateWithTooltip
                                                    date={new Date(fileNode?.created)}
                                                    timezone={timezone}
                                                />
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* Delete button */}
                                    <Box>
                                        <IconButton
                                            onClick={() => handleDeleteClick(fileNode?.id)}
                                            disabled={viewIsReadOnly}
                                            data-testid="deleteInternalDocument"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};
