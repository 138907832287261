/**
 * @generated SignedSource<<ce9a245306080064b9455eab336648fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IndustryStandardSelectAllIndustryStandardsQuery$variables = {};
export type IndustryStandardSelectAllIndustryStandardsQuery$data = {
  readonly allPublishedIndustryStandards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly industryDe: string;
        readonly industryEn: string;
        readonly isLeaf: string | null;
        readonly parentIndustryDe: string | null;
        readonly parentIndustryEn: string | null;
      } | null;
    } | null>;
  } | null;
};
export type IndustryStandardSelectAllIndustryStandardsQuery = {
  response: IndustryStandardSelectAllIndustryStandardsQuery$data;
  variables: IndustryStandardSelectAllIndustryStandardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "IndustryStandardNodeConnection",
    "kind": "LinkedField",
    "name": "allPublishedIndustryStandards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IndustryStandardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndustryStandardNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLeaf",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentIndustryEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentIndustryDe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industryEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industryDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IndustryStandardSelectAllIndustryStandardsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IndustryStandardSelectAllIndustryStandardsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3acf54bc8d62a550bd915b3dd86cdf39",
    "id": null,
    "metadata": {},
    "name": "IndustryStandardSelectAllIndustryStandardsQuery",
    "operationKind": "query",
    "text": "query IndustryStandardSelectAllIndustryStandardsQuery {\n  allPublishedIndustryStandards {\n    edges {\n      node {\n        id\n        isLeaf\n        parentIndustryEn\n        parentIndustryDe\n        industryEn\n        industryDe\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71a5e2035a73328f1ff6e774728a7f00";

export default node;
