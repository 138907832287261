import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect, useState } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';

import { NotificationDrawer } from 'src/components/notifications/NotificationDrawer';
import { NotificationContextInitializer } from 'src/components/notifications/providers/NotificationContextInitializer';
import { notificationsLimit } from 'src/constants';
import { NotificationContext } from 'src/contexts/NotificationContext';
import useAuth from 'src/hooks/useAuth';
import { validateNotificationsContext } from 'src/utils/notification';

const NotificationsQuery = graphql`
    query NotificationContextProviderNotificationsQuery($first: Int) {
        userNotifications(first: $first, orderby: "-created") {
            edges {
                node {
                    id
                    notificationType
                    created
                    unread
                    context
                }
            }
        }
        unreadNotifications: userNotifications(first: $first, unread: true, orderby: "-created") {
            edges {
                node {
                    id
                    notificationType
                    created
                    unread
                    context
                }
            }
        }
    }
`;

const UnreadNotificationsCountQuery = graphql`
    query NotificationContextProviderUnreadNotificationsCountQuery {
        unreadNotificationsCount
    }
`;

const NotificationChangesQuery = graphql`
    query NotificationContextProviderNotificationChangesQuery {
        lastNotificationCreatedAt
    }
`;

export const useNotificationsQuery = () => {
    const { notificationsQueryRef, refreshNotificationsQuery, loadMoreNotifications } =
        useContext(NotificationContext);
    const notificationsQuery = usePreloadedQuery(NotificationsQuery, notificationsQueryRef);

    const { unreadNotificationsCountQueryRef, refreshUnreadNotificationsCountQuery } =
        useContext(NotificationContext);
    const unreadNotificationsCountQuery = usePreloadedQuery(
        UnreadNotificationsCountQuery,
        unreadNotificationsCountQueryRef,
    );
    return {
        notificationsQuery,
        refreshNotificationsQuery,
        unreadNotificationsCountQuery,
        refreshUnreadNotificationsCountQuery,
        loadMoreNotifications,
    };
};

interface Props {
    children: any;
    changesQueryRef: any;
    refreshChangesQuery: () => void;
}

export const NotificationContextProvider: FC<Props> = ({
    children,
    changesQueryRef,
    refreshChangesQuery,
}: Props) => {
    const { user } = useAuth();

    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [lastNotificationCreatedAt, setLastNotificationCreatedAt] = useState(null);

    const [currentNotification, setCurrentNotification] = useState(null);
    const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
    const [shouldReloadTable, setShouldReloadTable] = useState(false);

    const [loadMoreNotifications, setLoadMoreNotifications] = useState(null);

    const [notificationsQueryRef, loadNotificationsQuery, disposeNotificationsQuery] =
        useQueryLoader(NotificationsQuery);
    const [unreadCountQueryRef, loadUnreadCountQuery, disposeUnreadCountQuery] = useQueryLoader(
        UnreadNotificationsCountQuery,
    );

    const refreshNotificationsQuery = () => {
        loadNotificationsQuery({ first: notificationsLimit }, { fetchPolicy: 'store-and-network' });
    };

    const refreshUnreadQuery = () => {
        loadUnreadCountQuery({}, { fetchPolicy: 'store-and-network' });
    };

    useEffect(() => {
        refreshNotificationsQuery();
        refreshUnreadQuery();
        return () => {
            disposeNotificationsQuery();
            disposeUnreadCountQuery();
        };
    }, [user]);

    const changesQuery = usePreloadedQuery(NotificationChangesQuery, changesQueryRef);
    useEffect(() => {
        if (
            lastNotificationCreatedAt &&
            changesQuery?.lastNotificationCreatedAt &&
            changesQuery.lastNotificationCreatedAt !== lastNotificationCreatedAt
        ) {
            refreshNotificationsQuery();
            refreshUnreadQuery();
            setShouldReloadTable(true);

            // validate new notifications required fields
            const notificationsToValidate = notifications.filter(
                (notification) => notification.created >= lastNotificationCreatedAt,
            );
            validateNotificationsContext(notificationsToValidate);
        }
        setLastNotificationCreatedAt(changesQuery.lastNotificationCreatedAt);
    }, [changesQuery]);

    if (notificationsQueryRef === null || unreadCountQueryRef === null) {
        return null;
    }

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                setNotifications,
                unreadNotifications,
                setUnreadNotifications,
                unreadNotificationsCount,
                setUnreadNotificationsCount,
                currentNotification,
                setCurrentNotification,
                notificationDrawerOpen,
                setNotificationDrawerOpen,
                shouldReloadTable,
                setShouldReloadTable,
                notificationsQueryRef,
                refreshNotificationsQuery,
                unreadNotificationsCountQueryRef: unreadCountQueryRef,
                refreshUnreadNotificationsCountQuery: refreshUnreadQuery,
                changesQueryRef,
                refreshChangesQuery,
                loadMoreNotifications,
                setLoadMoreNotifications,
                lastNotificationCreatedAt,
                setLastNotificationCreatedAt,
            }}
        >
            <NotificationContextInitializer>
                {children}
                {notificationDrawerOpen && (
                    <NotificationDrawer
                        notification={currentNotification}
                        closeDrawer={() => {
                            setNotificationDrawerOpen(false);
                        }}
                    />
                )}
            </NotificationContextInitializer>
        </NotificationContext.Provider>
    );
};
