import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

import BucketSection, { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import { InternalSection } from 'src/components/sections/section';
import Note from 'src/components/typography/note';
import { useFullProject } from 'src/project/state/hook';

const RequestAdditionalInformation = () => {
    const project = useFullProject();
    const { request } = project;

    if (!request?.additionalInformation) {
        return null;
    }

    return (
        <BucketSection title={<Trans>Additional Information (from the requester)</Trans>}>
            <Bucket>
                <InternalSection>
                    <BucketContainer isVertical>
                        <Bucket xs={12}>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: request.additionalInformation }}
                            />
                            <Note
                                sx={{ fontSize: 13 }}
                                value={
                                    <Trans>
                                        Note: This information is not displayed to vendors
                                    </Trans>
                                }
                            />
                        </Bucket>
                    </BucketContainer>
                </InternalSection>
            </Bucket>
        </BucketSection>
    );
};

export default RequestAdditionalInformation;
