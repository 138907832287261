import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RatecardProjectContext } from 'src/contexts/RatecardProjectContext';
import useAuth from 'src/hooks/useAuth';

export const RatecardProjectProviderRatecardProjectQuery = graphql`
    query RatecardProjectProviderRatecardProjectQuery($id: ID!) {
        ratecardProject(id: $id) {
            id
            announcementSetId
            questionSetId
            internalDocumentSetId
            state
            projectName
            projectTimezone
            isCurrentUserAdmin
            referenceCurrency {
                id
                isoCurrencyCode
            }
            members {
                id
                email
                firstName
                lastName
            }
            noRatecardsCreated
            editMode
            selectiveBids
            rates
            supplements
            vat
            proposals {
                edges {
                    node {
                        id
                        state
                        company {
                            id
                            companyName
                            logo
                        }
                    }
                }
            }
            onHold
            ...RatecardProjectSidebar_ratecardProject
            ...RatecardProjectEditModeSidebar_ratecardProject
            ...RatecardProjectStepper_ratecardProject
            ...TenderPlanning_ratecardProject
            ...SupplierSelection_ratecardProject
            ...OtherInformation_ratecardProject
            ...OverviewRatecardSummary_ratecardProject
        }
    }
`;

export const RatecardProjectProviderRatecardProjectProposalsQuery = graphql`
    query RatecardProjectProviderRatecardProjectProposalsQuery($id: ID!) {
        ratecardProjectProposals(id: $id) {
            projectId
            state
            editMode
            onHold
            projectName
            proposals {
                projectId
                proposalId
                state
                archived
            }
        }
    }
`;

export const useQuery = () => {
    const { user } = useAuth();
    return user.isClient
        ? RatecardProjectProviderRatecardProjectQuery
        : RatecardProjectProviderRatecardProjectProposalsQuery;
};

export const useRatecardProjectQuery = () => {
    const query = useQuery();
    const { ratecardProjectQueryRef, refreshRatecardProjectQuery } =
        useContext(RatecardProjectContext);
    const ratecardProjectQuery = usePreloadedQuery(query, ratecardProjectQueryRef);
    return {
        ratecardProjectQuery,
        refreshRatecardProjectQuery,
    };
};

interface Props {
    children: any;
}

export const RatecardProjectProvider: FC<Props> = ({ children }: Props) => {
    const query = useQuery();
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(query);
    const location = useLocation();
    const matchProject = matchPath('/dashboard/ratecards/:ratecardId/*', location.pathname);

    const id = matchProject?.params?.ratecardId;

    const refresh = () => {
        loadQuery({ id }, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refresh();
        return () => {
            disposeQuery();
        };
    }, [id]);

    if (queryRef === null) {
        return null;
    }

    return (
        <RatecardProjectContext.Provider
            value={{
                ratecardProjectQueryRef: queryRef,
                refreshRatecardProjectQuery: refresh,
            }}
        >
            {children}
        </RatecardProjectContext.Provider>
    );
};
