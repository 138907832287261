import { graphql } from 'babel-plugin-relay/macro';
import { useContext, useState } from 'react';
import { useFragment } from 'react-relay/hooks';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';
import { RatecardProjectStepper } from 'src/components/ratecards/RatecardProjectStepper';
import { ExitEditModeDialog } from 'src/components/ratecards/dialogs/ExitEditModeDialog';
import { SaveBeforeLeaveDialog } from 'src/components/ratecards/dialogs/SaveBeforeLeaveDialog';
import { useAppContext } from 'src/contexts/AppContext';
import { SessionContext } from 'src/contexts/SessionContext';

export const RatecardProjectEditModeSidebar = () => {
    const { i18n } = useLingui();
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const location = useLocation();
    const [exitEditModeDialogOpen, setExitEditModeDialogOpen] = useState(false);

    const ratecard = useFragment(
        graphql`
            fragment RatecardProjectEditModeSidebar_ratecardProject on RatecardProjectNode {
                id
                projectName
                editMode
                isCurrentUserAdmin
                validation {
                    tenderPlanning
                    supplierSelection
                    ratecard
                    otherInformation
                    allSuccessful
                    errorMessage
                }
            }
        `,
        ratecardProjectQuery.ratecardProject,
    );

    const section = {
        title: '',
        items: [
            {
                title: i18n._('Edit project'),
                path: `/dashboard/ratecards/${ratecardProjectQuery?.ratecardProject?.id}/evaluation/edit/info`,
                icon: <NoteAltIcon />,
            },
        ],
    };

    const { saveRef } = useAppContext();

    const { dialogOpen, setSessionExpired } = useContext(SessionContext);

    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const handleLeaveEditMode = () => {
        if (saveRef.current.isDirty()) {
            setAlertDialogOpen(true);
            setSessionExpired(true);
        } else {
            setExitEditModeDialogOpen(true);
        }
    };

    const closeExitEditModeDialog = () => {
        setExitEditModeDialogOpen(false);
    };

    const cancel = () => {
        setAlertDialogOpen(false);
    };

    const discardAndProceed = () => {
        setAlertDialogOpen(false);
        setExitEditModeDialogOpen(true);
    };

    const saveAndProceed = () => {
        saveRef.current.save().then(() => {
            setAlertDialogOpen(false);
        });
    };

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            {ratecard.projectName ? (
                <StepperTitle title={ratecard.projectName} editMode={ratecard.editMode} />
            ) : null}
            <NavSection
                key="info"
                pathname={location.pathname}
                sx={{
                    '& a': {
                        pl: 1,
                    },
                }}
                {...section}
            />
            <RatecardProjectStepper orientation="vertical" editMode />
            <Box>
                <Divider sx={{ mt: 2 }} />
                {ratecard.editMode && (
                    <Box sx={{ my: 2 }}>
                        {ratecard.isCurrentUserAdmin ? (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ExitToAppIcon />}
                                onClick={handleLeaveEditMode}
                                data-testid="leaveEditModeButton"
                            >
                                {i18n._('Leave edit mode')}
                            </Button>
                        ) : (
                            <Tooltip
                                placement="top"
                                title={
                                    <Typography variant="subtitle2">
                                        {i18n._(
                                            'Project admin permission is required for this action',
                                        )}
                                    </Typography>
                                }
                            >
                                <span>
                                    <Button
                                        variant="contained"
                                        startIcon={<ExitToAppIcon />}
                                        data-testid="leaveEditModeButtondisabled"
                                        disabled
                                    >
                                        {i18n._('Leave edit mode')}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </Box>
            {exitEditModeDialogOpen && (
                <ExitEditModeDialog continueEditing={closeExitEditModeDialog} noSave />
            )}
            {alertDialogOpen && !dialogOpen && (
                <SaveBeforeLeaveDialog
                    cancel={cancel}
                    discardAndProceed={discardAndProceed}
                    saveAndProceed={saveAndProceed}
                    editMode
                />
            )}
        </Box>
    );
};
