import { t } from '@lingui/macro';

interface ProjectDateData {
    rfpReleaseDueDate: string;
    proposalDueDate: string;
    presentationStartDate: string;
    presentationEndDate: string;
    projectAwardDate: string;
    startDate: string;
    endDate: string;
}

export const formatProjectTimelineDates = (data: ProjectDateData) => {
    const result = [];
    if (data.rfpReleaseDueDate) {
        result.push({
            id: `rfpReleaseDueDate-internal_1}`,
            name: t`RFP Release Due`,
            eventType: 'rfp_release_due_date',
            hardLimit: false,
            description: '',
            date: new Date(data.rfpReleaseDueDate),
        });
    }
    if (data.proposalDueDate) {
        result.push({
            id: `proposalDueDate-internal_2}`,
            name: t`Proposal Due`,
            eventType: 'proposal_due_date',
            hardLimit: false,
            description: '',
            date: new Date(data.proposalDueDate),
        });
    }
    if (data.presentationStartDate) {
        result.push({
            id: `presentationStartDate-internal_3}`,
            name: t`Pitch Presentation Start Date`,
            eventType: 'presentation_start_date',
            hardLimit: false,
            description: '',
            date: new Date(data.presentationStartDate),
        });
    }
    if (data.presentationEndDate) {
        result.push({
            id: `presentationEndDate-internal_4}`,
            name: t`Pitch Presentation End Date`,
            eventType: 'presentation_end_date',
            hardLimit: false,
            description: '',
            date: new Date(data.presentationEndDate),
        });
    }
    if (data.projectAwardDate) {
        result.push({
            id: `projectAwardDate-internal_5}`,
            name: t`Project Award Date`,
            eventType: 'project_award_date',
            hardLimit: false,
            description: '',
            date: new Date(data.projectAwardDate),
        });
    }

    if (data.startDate) {
        result.push({
            id: `startDate-internal_6}`,
            name: t`Project Start Date`,
            eventType: 'project_start_date',
            hardLimit: false,
            description: '',
            date: new Date(data.startDate),
        });
    }

    if (data.endDate) {
        result.push({
            id: `endDate-internal_1}`,
            name: t`Project End Date`,
            eventType: 'project_end_date',
            hardLimit: false,
            description: '',
            date: new Date(data.endDate),
        });
    }
    return result;
};
