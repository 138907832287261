import { LicenseManager } from 'ag-grid-enterprise';
import { ConfirmProvider } from 'material-ui-confirm';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import type { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ChatWootProvider } from 'src/components/ChatWootProvider';
import useSkipResizeObserverErrorOnDevelopment from 'src/hooks/useSkipResizeObserverErrorOnDevelopment';
import { messages as deMessages } from 'src/locales/de/messages';
import { messages as enMessages } from 'src/locales/en/messages';
import ApaduaTourProvider from 'src/tours/tourProvider';
import { localeFromString } from 'src/utils/locale';

import CookieConsentBanner from './components/CookieConsentBanner';
import GaTracker from './components/GaTracker';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';
import ApaduaAnnouncementModal from './components/common/modal/apaduaAnnouncementModal';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import { useTheme } from './theme';

LicenseManager.setLicenseKey(
    'CompanyName=apadua GmbH,LicensedApplication=apadua,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025809,ExpiryDate=7_April_2023_[v2]_MTY4MDgyMjAwMDAwMA==6083df03f6e3c37c9e9a7aaabf75d53b',
);

i18n.load('en', enMessages);
i18n.load('de', deMessages);
i18n.activate('en');

const App: FC = ({ children }: { children: ReactNode }) => {
    const { settings } = useSettings();
    const auth = useAuth();
    const theme = useTheme();

    const location = useLocation();
    const navigate = useNavigate();
    useSkipResizeObserverErrorOnDevelopment();

    useEffect(() => {
        window.onpopstate = (e) => {
            if (
                e.target.window.location.search.includes('size') ||
                e.target.window.location.search.includes('search') ||
                location.search.includes('size') ||
                location.search.includes('search')
            ) {
                navigate(
                    {
                        pathname: e.target.window.location.pathname,
                        search: e.target.window.location.search,
                    },
                    {
                        replace: true,
                    },
                );
            }
        };
    }, [location]);

    useEffect(() => {
        if (gtmConfig.containerId) {
            gtm.initialize(gtmConfig);
        }
    }, []);

    const locale = localeFromString(auth?.user?.formattingLocale, auth?.user?.language);

    return (
        <I18nProvider i18n={i18n}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale.date}>
                <ApaduaTourProvider>
                    <StyledEngineProvider injectFirst>
                        <ChatWootProvider>
                            <ThemeProvider theme={theme}>
                                <RTL direction={settings.direction}>
                                    <ConfirmProvider
                                        defaultOptions={{
                                            confirmationButtonProps: { autoFocus: true },
                                        }}
                                    >
                                        <GaTracker />
                                        <Toaster
                                            position="top-center"
                                            toastOptions={{
                                                duration: 10000,
                                                success: {
                                                    duration: 10000,
                                                },
                                            }}
                                        />
                                        <GlobalStyles />
                                        <CookieConsentBanner />
                                        <ApaduaAnnouncementModal />
                                        {/* <SettingsDrawer /> */}
                                        {children || <Outlet />}
                                    </ConfirmProvider>{' '}
                                </RTL>
                            </ThemeProvider>
                        </ChatWootProvider>
                    </StyledEngineProvider>
                </ApaduaTourProvider>
            </LocalizationProvider>
        </I18nProvider>
    );
};

export default App;
