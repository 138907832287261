import Section from 'src/components/sections/section';
import QuoteUserManagement from 'src/pages/rfp/QuoteUserManagement';

const ProjectUserManagementPage = () => (
    <Section>
        <QuoteUserManagement />
    </Section>
);

export default ProjectUserManagementPage;
