import { Paging } from '@elastic/react-search-ui';
import { Pagination } from '@mui/material';

const ExplorePagination = () => (
    <Paging
        view={({ current, onChange, totalPages }) => (
            <Pagination
                sx={{
                    mt: 1,
                }}
                count={totalPages}
                page={current}
                onChange={(event, page) => onChange(page)}
            />
        )}
    />
);

export default ExplorePagination;
