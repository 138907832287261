import type { FC } from 'react';

import { useLingui } from '@lingui/react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Button, Card, CardContent, Divider, Skeleton, Typography } from '@mui/material';

import { DelayedRender } from 'src/components/DelayedRender';
import { InfoMessage } from 'src/components/internalDocuments/InfoMessage';

export const InternalDocumentSkeleton: FC = () => {
    const { i18n } = useLingui();

    const rows = [];

    for (let index = 0; index < 4; index++) {
        rows.push(
            <Box key={index}>
                <Skeleton sx={{ my: 2 }} variant="rectangular" height="50px" />
                <Divider />
            </Box>,
        );
    }

    return (
        <>
            <DelayedRender>
                <InfoMessage />
                <Card variant="outlined" sx={{ mb: 4 }}>
                    <Box
                        display="flex"
                        alignItems="baseline"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        sx={{ p: 2 }}
                    >
                        <Box sx={{ pr: 2 }} flexGrow={1}>
                            <Typography variant="h6" color="textPrimary">
                                {i18n._('Internal Documents')}
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<ArrowDownwardIcon />}
                            >
                                {i18n._('Add new documents')}
                            </Button>
                        </Box>
                    </Box>
                    <Divider />

                    <CardContent>{rows}</CardContent>
                </Card>
            </DelayedRender>
        </>
    );
};
