import React from 'react';
import * as Yup from 'yup';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import StyledTextarea from 'src/components/common/styledTextArea';

import FormModal, { FormModalProps } from '../../components/common/formModal';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required(() => t`Name is required`)
        .min(1, () => t`Name cannot be empty`),
    note: Yup.string().trim().default(''),
});

const useListFormFields = () => [
    {
        name: 'name',
        label: t`Name`,
        variant: 'outlined' as const,
        fullWidth: true,
    },
    {
        name: 'note',
        label: t`Note`,
        minRows: 3,
        component: StyledTextarea,
        placeHolder: t`Write a note`,
        variant: 'outlined' as const,
    },
];

export interface ListFormValues {
    name: string;
    note?: string;
}

export type ListFormModalProps = Omit<
    FormModalProps<ListFormValues>,
    'title' | 'fields' | 'validationSchema'
>;

const ListFormModal = (props: ListFormModalProps) => {
    const { i18n } = useLingui();
    const fields = useListFormFields();
    return (
        <FormModal
            title={i18n._('Manage list')}
            fields={fields}
            validationSchema={validationSchema}
            {...props}
        />
    );
};

export default ListFormModal;
