import { FC, createContext, useState } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';

import relayEnvironment from './relayEnvironment';

interface RelayWorkingContextType {
    relayWorking: boolean;
    setRelayWorking: any;
}

const initialValues: RelayWorkingContextType = {
    relayWorking: true,
    setRelayWorking: null,
};

export const RelayWorkingContext = createContext(initialValues);

interface Props {
    children: any;
}

export const RelayProvider: FC<Props> = ({ children }: Props) => {
    const [relayWorking, setRelayWorking] = useState(true);

    return (
        <RelayWorkingContext.Provider
            value={{
                relayWorking,
                setRelayWorking,
            }}
        >
            <RelayEnvironmentProvider environment={relayEnvironment}>
                {children}
            </RelayEnvironmentProvider>
        </RelayWorkingContext.Provider>
    );
};
