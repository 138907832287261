import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import {
    BulkDeleteChecklistItemsFetcher,
    GenerateChecklistItemsFetcher,
    ProjectChecklistFetcher,
    ProjectChecklistFetcherCreate,
    ProjectChecklistFetcherDelete,
    ProjectChecklistFetcherPatch,
} from './thunk';

const initialState = {
    ...fetcherInitialState,
    data: {
        name: null,
        checkListItems: [],
    },
    generateLoading: false,
};

export const projectChecklistSlice = createSlice({
    name: 'projectChecklist',
    initialState,
    reducers: {
        setGenerateLoading: (state, action) => {
            state.generateLoading = action.payload.loading;
        },
    },
    extraReducers(builder) {
        ProjectChecklistFetcher.reducers(builder);
        ProjectChecklistFetcherCreate.reducers(builder);
        ProjectChecklistFetcherPatch.reducers(builder);
        ProjectChecklistFetcherDelete.reducers(builder);
        GenerateChecklistItemsFetcher.reducers(builder);
        BulkDeleteChecklistItemsFetcher.reducers(builder);
    },
});
