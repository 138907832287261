import React from 'react';
import toast from 'react-hot-toast';

import * as Sentry from '@sentry/react';

import { ProjectBriefAPI } from 'src/apis/projectBriefAPI';
import DismissibleToast from 'src/utils/DismissibleToast';

import type { VendorsRequiredValuesType } from '../types';

function constructExploreSearchURL(currentUrl, keywordArray) {
    const encodedKeywords = keywordArray.map((keyword) => encodeURIComponent(keyword)).join(',');
    let baseUrl = currentUrl;
    if (!baseUrl) {
        baseUrl = '/explore';
    }
    return `${baseUrl}?q=${encodedKeywords},&size=n_25_n`;
}

const api = new ProjectBriefAPI();

const serializeFindVendorsPayload = (values: any) => ({
    initialSituation: values?.initialSituation,
    projectApproach: values?.projectApproach,
    projectObjectives: values?.projectObjectives.filter((item) => item.title && item.description),
    projectDeliverables: values?.projectDeliverables.filter(
        (item) => item.title && item.description,
    ),
});

const useFindVendors = () => {
    const [isVendorsLoading, setIsVendorsLoading] = React.useState<boolean>(false);

    const findVendors = React.useCallback(async (values: VendorsRequiredValuesType) => {
        try {
            setIsVendorsLoading(true);
            const payload = serializeFindVendorsPayload(values);
            const data = await api.findVendors(payload);
            if (data.length > 0) {
                const newUrl = constructExploreSearchURL(undefined, data);
                window.open(newUrl, '_blank');
            }
        } catch (error) {
            toast.error(DismissibleToast('Something went wrong.'), {
                duration: 4000,
            });
            Sentry.captureException(error);
        } finally {
            setIsVendorsLoading(false);
        }
    }, []);

    return {
        isVendorsLoading,
        findVendors,
    };
};

export default useFindVendors;
