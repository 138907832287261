import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Typography } from '@mui/material';

const ActionsRenderer: React.FunctionComponent<ICellRendererParams> = ({
    value,
    data,
}: ICellRendererParams) => {
    const { i18n } = useLingui();
    const { handleEdit, removeUser } = value;

    return data.role === 'company_admin' ? (
        <Typography variant="caption" color="textSecondary" fontStyle="italic">
            {i18n._('No actions')}
        </Typography>
    ) : (
        <div>
            <Button
                sx={{ mr: 1 }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    handleEdit(data);
                }}
                data-testid="editButton"
            >
                <EditIcon fontSize="small" sx={{ mr: 1 }} />
                {i18n._('Edit')}
            </Button>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                    removeUser(data.user.id);
                }}
                data-testid="removeButton"
            >
                <CloseIcon fontSize="small" sx={{ mr: 1 }} />
                {i18n._('Remove')}
            </Button>
        </div>
    );
};

export default ActionsRenderer;
