import set from 'lodash/set';

export const validateFormValues = async (formRef, schema) => {
    const values = formRef.current?.values;
    try {
        await schema.validate(values, { abortEarly: false });
        formRef.current?.setErrors({});
        return true;
    } catch (yupErrors) {
        const formErrors = yupErrors.inner.reduce(
            (errors, yupError) => ({
                ...errors,
                [yupError.path]: yupError.message,
            }),
            {},
        );
        formRef.current?.setErrors(formErrors);
        formRef.current?.setTouched(
            Object.keys(formErrors).reduce((acc, key) => set(acc, key, true), {}),
        );
        return Object.keys(formErrors).length === 0;
    }
};
