import { useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import { timelineContentClasses } from '@mui/lab/TimelineContent';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Typography } from '@mui/material';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import ErrorMessage from 'src/components/typography/errorMessage';
import RadioGroupField from 'src/forms/radioGroupField';

import { formatProjectTimelineEntries } from './formatProjectTimelineEntries';
import { ProjectTimeline } from './projectTimeline';

interface ProjectTimelineErrors {
    rfpReleaseDueDate: string;
    proposalDueDate: string;
    projectAwardDate: string;
    presentationStartDate: string;
    presentationEndDate: string;
}

const ProjectTimelineForm = () => {
    const { values } = useFormikContext() as any;
    const { errors, setFieldValue } = useFormikContext<ProjectTimelineErrors>();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const pitchSessionRadios = [
        { value: true, label: <Trans>Yes</Trans> },
        { value: false, label: <Trans>No</Trans> },
    ];

    const setTimelineEvents = (events) => {
        const generalEntries = [];
        events.forEach((event) => {
            if (event.eventType === 'rfp_release_due_date') {
                setFieldValue('rfpReleaseDueDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'proposal_due_date') {
                setFieldValue('proposalDueDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'project_award_date') {
                setFieldValue('projectAwardDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'presentation_end_date') {
                setFieldValue('presentationEndDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'presentation_start_date') {
                setFieldValue('presentationStartDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'project_start_date') {
                setFieldValue('startDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'project_end_date') {
                setFieldValue('endDate', event.date?.toISOString(), true);
            }
            if (event.eventType === 'GENERAL') {
                generalEntries.push({
                    id: event.id,
                    name: event.name,
                    eventType: event.eventType,
                    slug: '',
                    key: event.key,
                    date: event.date?.toISOString(),
                });
            }
        });
        setFieldValue('timelineEntries', generalEntries, true);
    };

    const timelineEvents = React.useMemo(() => {
        const hardcodedProjectTimelineEvents = formatProjectTimelineEntries(values);
        const sanitizedTimeLineEntries = values.timelineEntries.map((entry, index) => ({
            ...entry,
            eventType: 'GENERAL',
            date: entry.date ? new Date(entry.date) : null,
            key: `new-${index}`,
        }));
        return [...hardcodedProjectTimelineEvents, ...sanitizedTimeLineEntries].sort(
            (a, b) => a.date?.getTime() - b.date?.getTime(),
        );
    }, [formatProjectTimelineEntries, values]);

    const timelineErrorFields = [
        'startDate',
        'endDate',
        'rfpReleaseDueDate',
        'proposalDueDate',
        'projectAwardDate',
        'presentationStartDate',
        'presentationEndDate',
    ];

    return (
        <BucketSection title={<Trans>RFP Schedule</Trans>} id="rfp-schedule">
            <Bucket xs={12} lg={8}>
                {timelineErrorFields.map(
                    (errorField) =>
                        errors[errorField] && <ErrorMessage errorMessage={errors[errorField]} />,
                )}
                <ProjectTimeline
                    editable
                    timelineEvents={timelineEvents}
                    setTimelineEvents={setTimelineEvents}
                    timezone={userTimeZone}
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.2,
                        },
                        [`& .${timelineContentClasses.root}`]: {
                            flex: 0.8,
                        },
                    }}
                />
            </Bucket>
            <Bucket xs={12} lg={4} withDivider={false}>
                <Typography variant="h6">
                    <Trans>Should the RFP contain a pitch presentation phase? </Trans>
                </Typography>
                <RadioGroupField name="pitchSession" radios={pitchSessionRadios} required />
            </Bucket>
        </BucketSection>
    );
};

export default ProjectTimelineForm;
