import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { GridProps, useTheme } from '@mui/material';

import List from './List';
import ListItem, { ListItemProps } from './ListItem';
import SummaryCardItem, { SummaryCardItemProps } from './SummaryCardItem';

interface SummaryCardListProps extends Omit<GridProps, 'item' & 'container'> {
    items: Array<SummaryCardItemProps & { key?: string }>;
    onItemClick?: (element: SummaryCardItemProps) => void;
    listItemProps?: ListItemProps;
    showDisclaimer?: boolean;
}

const SummaryCardList: FC<SummaryCardListProps> = ({
    items,
    onItemClick,
    listItemProps,
    showDisclaimer,
    ...gridProps
}: SummaryCardListProps) => {
    const { i18n } = useLingui();
    const theme = useTheme();

    return (
        <List {...gridProps}>
            {showDisclaimer && (
                <ListItem key="disclaimer" {...listItemProps} buttonSx={{ pointerEvents: 'none' }}>
                    <SummaryCardItem
                        sx={{
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'none',
                            backgroundColor: theme.palette.background.default,
                        }}
                        header={i18n._('Summary of core competencies')}
                        content={i18n._(
                            "To provide a better overview of the firm's core competencies, these are summarized based on the information available on the respective websites. The texts are generated through the use of AI and may therefore include inaccuracies.",
                        )}
                    />
                </ListItem>
            )}
            {items.map((item, index) => (
                <ListItem onClick={() => onItemClick(item)} key={item.header} {...listItemProps}>
                    <SummaryCardItem
                        sx={item.sx}
                        header={item.header}
                        content={item.content}
                        onFlyTranslate={item.onFlyTranslate}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default SummaryCardList;
