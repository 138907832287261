import { Trans } from '@lingui/macro';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';

interface Props {
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
}

const PreviousAction = ({ disabled, onClick, loading }: Props) => (
    <LoadingButton
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        data-testid="PreviousAction"
        loading={loading}
        startIcon={<ArrowBackIcon />}
    >
        <Trans>Previous</Trans>
    </LoadingButton>
);

export default PreviousAction;
