import { Outlet } from 'react-router-dom';

import NoCollaboratorsAlert from 'src/alerts/noCollaboratorsAlert';
import { useFullProject } from 'src/project/state/hook';

const ProjectPage = () => {
    const project = useFullProject();
    const hasInvitedUsers = project.members?.length > 1;

    return (
        <>
            {!hasInvitedUsers && (
                <NoCollaboratorsAlert sx={{ m: 2 }} userManagementRoute="user-management" />
            )}
            <Outlet />
        </>
    );
};

export default ProjectPage;
