import { FC, useEffect, useRef, useState } from 'react';

import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NotificationLongContentFormatter } from 'src/components/notifications/NotificationLongContentFormatter';
import { notificationOptions } from 'src/components/notifications/notificationTypeProps';

interface Props {
    notification: any;
}

const ContentCard = styled(Card)(({ theme }) => ({
    width: '100%',
    maxHeight: '70px',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const OverflowHiddenBox = styled('div')({
    maxHeight: '60px',
    overflow: 'hidden',
    '&.overflowGradient': {
        background: 'linear-gradient(180deg, #6b778c 66%, white)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        boxDecorationBreak: 'clone',
        textShadow: '0px 0px transparent',
    },
});

export const NotificationLongContent: FC<Props> = ({ notification }: Props) => {
    const notificationProps = notificationOptions[notification.notificationType];
    const { longFormat, requiredInputName } = notificationProps;

    const contentRef = useRef(null);
    const [overflowDetected, setOverflowDetected] = useState(false);
    useEffect(() => {
        if (contentRef?.current?.clientHeight < contentRef?.current?.scrollHeight) {
            setOverflowDetected(true);
        } else {
            setOverflowDetected(false);
        }
    }, [contentRef]);

    if (longFormat && (requiredInputName === null || requiredInputName in notification.context)) {
        return (
            <ContentCard>
                <OverflowHiddenBox
                    className={overflowDetected ? 'overflowGradient' : ''}
                    ref={contentRef}
                >
                    <NotificationLongContentFormatter
                        notification={notification}
                        variant="caption"
                        color="textSecondary"
                    />
                </OverflowHiddenBox>
            </ContentCard>
        );
    }

    return null;
};
