import graphql from 'babel-plugin-relay/macro';
import { FC, useState } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';

import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getAverageRGB, valueToHex } from 'src/utils/accentColorUtils';

import CompanyProfileCard from './CompanyProfileCard';
import CompanyProfileTabs from './CompanyProfileTabs';

const ProfileRoot = styled(Box)(({ theme }) => ({
    background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.background.default} 350px)`,
    minHeight: '100vh',
}));

const CompanyProfileCompanyQuery = graphql`
    query CompanyProfileCompanyQuery($id: ID!) {
        company(id: $id) {
            id
            companyName
            reported

            allSocialAccounts {
                totalCount
                edges {
                    node {
                        network
                        profileUrl
                        followers
                    }
                }
            }

            serviceLine {
                serviceLineEn
                serviceLineDe
            }

            serviceIndividuals {
                totalCount
                edges {
                    node {
                        allServices
                    }
                }
            }

            countries {
                totalCount
                edges {
                    node {
                        commonNameEn
                        iso2letter
                    }
                }
            }

            generalInfo {
                descriptionEn
                descriptionDe
                foundationYear
                website
                email
                numberOfEmployees
                turnover
                turnoverCurrency {
                    isoCurrencyCode
                }
            }
            services {
                totalCount
                edges {
                    node {
                        id
                        serviceEn
                    }
                }
            }
            industries {
                totalCount
                edges {
                    node {
                        id
                        industryEn
                    }
                }
            }
            lnCompanySizeEmployees {
                employeesRangeEn
                employeesRangeDe
            }
            consultantType {
                consultantTypeEn
                consultantTypeDe
            }
            lnCompanyType {
                companyTypeEn
                companyTypeDe
            }
            lnCompanySpecialities {
                totalCount
                edges {
                    node {
                        id
                        keywordEn
                        keywordDe
                    }
                }
            }
            targetIndustries {
                totalCount
                edges {
                    node {
                        id
                        industryEn
                        industryDe
                    }
                }
            }
            logo
            allOffices {
                totalCount
                edges {
                    node {
                        id
                        country
                    }
                }
            }
            studies {
                totalCount
                edges {
                    node {
                        title
                        descriptionEn
                        descriptionDe
                        year
                        logo
                    }
                }
            }
            allKeywordSummaries {
                id
                keyword {
                    id
                    keywordEn
                    keywordDe
                }
                summary
            }
            importYear
        }
    }
`;

interface CompanyProfileProps {
    companyQueryRef: any;
}

const CompanyProfile: FC<CompanyProfileProps> = ({ companyQueryRef }: CompanyProfileProps) => {
    const query = usePreloadedQuery(CompanyProfileCompanyQuery, companyQueryRef);
    const [accentColor, setAccentColor] = useState(null);
    const { company } = query;
    const theme = useTheme();

    const onLogoLoad = () => {
        const accentRgb = getAverageRGB(document.getElementById('companyLogo'));
        if (accentRgb.r !== 0 && accentRgb.g !== 0 && accentRgb.b !== 0) {
            setAccentColor(
                `#${valueToHex(accentRgb.r)}${valueToHex(accentRgb.g)}${valueToHex(accentRgb.b)}`,
            );
        }
    };

    return (
        <ProfileRoot
            style={{
                background:
                    accentColor &&
                    `linear-gradient(${accentColor}, ${theme.palette.background.default} 350px)`,
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <CompanyProfileCard company={company} onLogoLoad={onLogoLoad} />
                </Box>
                <Box>
                    <CompanyProfileTabs company={company} />
                </Box>
            </Box>
        </ProfileRoot>
    );
};

export default CompanyProfile;
