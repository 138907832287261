import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { FormikNumberTextField } from 'src/components/FormikNumberTextField';
import CardSection from 'src/components/sections/cardSection';
import Note from 'src/components/typography/note';
import { useCurrencies } from 'src/currencies/hook';
import AutocompleteField from 'src/forms/autocompleteField';
import CountryField from 'src/forms/countryField';
import FormikTextField from 'src/forms/textField';
import { BudgetApprovalStatus, EngagementMode } from 'src/project/types';
import { useIndustryStandards } from 'src/redux/industryStandards/hook';
import { useServiceStandards } from 'src/redux/serviceStandards/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

import ProjectBriefFormActions from './projectBriefFormActions';
import { sanitizeFormValues } from './utils/sanitizeFormValues';

export interface ProjectBriefFormProps {
    isEditMode?: boolean;
    clearAll: () => void;
    createProjectAction: () => void;
    validateProject: () => Promise<boolean>;
    handleSelectBrief: (values: any) => void;
}

export const renderAutocompleteInput = (params, label, error, isTouched) => (
    <TextField
        {...params}
        label={label}
        variant="outlined"
        name={params.name}
        error={isTouched && !!error}
        helperText={(isTouched && error) || ''}
        fullWidth
    />
);
const ProjectBriefForm: React.FC<ProjectBriefFormProps> = ({
    clearAll,
    isEditMode,
    createProjectAction,
    validateProject,
    handleSelectBrief,
}: ProjectBriefFormProps) => {
    const { i18n } = useLingui();
    const {
        setFieldTouched,
        setFieldValue,
        values,
        isValid: isFormValid,
    } = useFormikContext() as any;

    const currentLanguage = i18n.locale;
    const currencies = useCurrencies();
    const industryStandards = useIndustryStandards();
    const serviceStandards = useServiceStandards();

    const componentProps = React.useMemo(() => {
        if (!isFormValid || !serviceStandards || !industryStandards) {
            return null;
        }
        return sanitizeFormValues(values, currentLanguage, serviceStandards, industryStandards);
    }, [values, industryStandards, serviceStandards, currentLanguage, isFormValid]);

    return (
        <CardSection
            actions={
                <>
                    <ProjectBriefFormActions
                        validateProject={validateProject}
                        isEditMode={isEditMode}
                        values={values}
                        isFormValid={isFormValid}
                        createProjectAction={createProjectAction}
                        clearAll={clearAll}
                        componentProps={componentProps}
                        handleSelectBrief={handleSelectBrief}
                    />
                </>
            }
            id="project-brief"
            title={`${i18n._('Project Brief')}`}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <Grid container spacing={2} sx={{ pb: 2 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <FormikTextField
                                fullWidth
                                label={i18n._('Project title')}
                                name="projectTitle"
                                type="text"
                                value={values.projectTitle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <AutocompleteField
                                name="selectedServiceID"
                                required
                                label="Selected Service"
                                options={serviceStandards}
                                getOptionLabel={(option) => getOptionKey(option, 'service') || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <AutocompleteField
                                name="industryStandardID"
                                id="industry-select-autocomplete"
                                label="Industry Standard"
                                required
                                options={industryStandards}
                                getOptionLabel={(option) => getOptionKey(option, 'industry') || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Field name="startDate" type="text">
                                {({ meta }) => (
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        value={values.startDate ? dayjs(values.startDate) : null}
                                        onChange={(date) => {
                                            setFieldTouched('startDate');
                                            setFieldValue(
                                                'startDate',
                                                date.isValid() ? date.toDate().toISOString() : null,
                                                true,
                                            );
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: meta.touched && meta.error && true,
                                                helperText: meta.touched && meta.error,
                                                name: 'startDate',
                                            },
                                        }}
                                        label={i18n._('Planned Project Start')}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Field name="endDate" type="text">
                                {({ meta }) => (
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        value={values.endDate ? dayjs(values.endDate) : null}
                                        onChange={(date) => {
                                            setFieldTouched('endDate');
                                            setFieldValue(
                                                'endDate',
                                                date.isValid() ? date.toDate().toISOString() : null,
                                                true,
                                            );
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: meta.touched && meta.error && true,
                                                helperText: meta.touched && meta.error,
                                                name: 'endDate',
                                            },
                                        }}
                                        label={i18n._('Planned Project End')}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <FormikTextField
                                fullWidth
                                label={i18n._('Internal Organization Reference')}
                                name="internalOrganisationReference"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <CountryField
                                name="primaryProjectLocation"
                                required
                                label={<Trans>Primary project locations</Trans>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <CountryField
                                name="secondaryProjectLocations"
                                multiple
                                required
                                label={<Trans>Secondary project locations</Trans>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <FormikTextField
                                id="select-type-of-engagement"
                                name="engagementMode"
                                label={<Trans>Mode of engagement</Trans>}
                                value={values?.engagementMode}
                                select
                            >
                                <MenuItem value={EngagementMode.on_site}>
                                    <Trans>On-Site</Trans>
                                </MenuItem>
                                <MenuItem value={EngagementMode.hybrid}>
                                    <Trans>Hybrid</Trans>
                                </MenuItem>
                                <MenuItem value={EngagementMode.remote}>
                                    <Trans>Remote</Trans>
                                </MenuItem>
                            </FormikTextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <FormikTextField
                                fullWidth
                                name="budgetApprovalStatus"
                                label={<Trans>Budget Approval Status</Trans>}
                                value={values?.budgetApprovalStatus || ''}
                                select
                            >
                                <MenuItem value={BudgetApprovalStatus.unknown}>
                                    <Trans>Not relevant</Trans>
                                </MenuItem>
                                <MenuItem value={BudgetApprovalStatus.pending}>
                                    <Trans>Pending</Trans>
                                </MenuItem>
                                <MenuItem value={BudgetApprovalStatus.approved}>
                                    <Trans>Approved</Trans>
                                </MenuItem>
                            </FormikTextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Field
                                disabled={
                                    values?.budgetApprovalStatus === BudgetApprovalStatus.unknown
                                }
                                fullWidth
                                component={FormikNumberTextField}
                                currencySymbol=""
                                variant="outlined"
                                name="budgetEstimate"
                                data-testid="budgetEstimate"
                                label={i18n._('Budget Estimate')}
                                integer
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <AutocompleteField
                                disabled={
                                    values?.budgetApprovalStatus === BudgetApprovalStatus.unknown
                                }
                                options={currencies}
                                name="budgetEstimateCurrency"
                                required={false}
                                label={i18n._('Budget Estimate Currency')}
                                getOptionLabel={(option) => option?.isoCurrencyCode || ''}
                                getOptionValue={(option: any) => option?.rawID}
                            />
                        </Grid>
                        {isEditMode && (
                            <Note
                                sx={{ fontSize: 13 }}
                                value={
                                    <Trans>
                                        Note: Budget information is not displayed to vendors
                                    </Trans>
                                }
                            />
                        )}
                    </Grid>
                </DemoContainer>
            </LocalizationProvider>
        </CardSection>
    );
};

export default ProjectBriefForm;
