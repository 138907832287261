import Section from 'src/components/sections/section';
import QuoteInternalDocuments from 'src/pages/rfp/InternalDocuments';

const ProjectInternalDocumentsPage = () => (
    <Section>
        <QuoteInternalDocuments />
    </Section>
);

export default ProjectInternalDocumentsPage;
