import { Field, Form, Formik } from 'formik';
import { FC, useRef, useState } from 'react';
import * as Yup from 'yup';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, Card, CardHeader, Divider, FormHelperText, Typography } from '@mui/material';

import { FormikFormErrors } from 'src/components/FormikFormErrors';
import { FormikQuillEditor } from 'src/components/FormikQuillEditor';
import { FileDropzone } from 'src/components/fileUpload/FileDropzone';
import { AnnouncementProposal } from 'src/types';

import { Bucket, BucketContainer } from '../sections/bucketSection';
import SelectSection from '../sections/selectSection';

interface Props {
    proposals: Array<AnnouncementProposal>;
    viewIsReadOnly: boolean;
    createNewAnnouncement: any;
    announcementSetId: string;
}

export const AnnouncementNewData: FC<Props> = ({
    proposals = [],
    viewIsReadOnly,
    createNewAnnouncement,
    announcementSetId,
}: Props) => {
    const { i18n } = useLingui();
    const enableFormikReinitialize = true;
    const formRef = useRef(null);

    const [fileIds, setFileIds] = useState([]);
    const uploadApiUrl = 'api/upload-announcement-documents/';

    const ValidationSchema = Yup.object().shape({
        content: Yup.string()
            .required(i18n._('Please provide a description for your announcement'))
            .test(
                'Test if the content contains text',
                i18n._('Please provide a description for your announcement'),
                (value) => value?.replace(/<(.|\n)*?>/g, '').trim().length !== 0,
            ),
        proposals: Yup.array()
            .test(
                'at-least-one-selected',
                () => t`At least one company must be selected`,
                (value) => value.length > 0,
            )
            .required(() => t`At least one company is required`),
    });

    const [selectedProposals, setSelectedProposals] = useState(proposals);

    const equalityFn = (a, b) => a.id === b.id;

    const renderLabel = (a) => a.companyName;

    const onSubmit = (values, { setSubmitting, setStatus, setErrors, resetForm }) => {
        createNewAnnouncement(
            values,
            selectedProposals.map((p) => p.id),
            fileIds,
            {
                setFileIds,
                setSubmitting,
                setStatus,
                setErrors,
                resetForm,
            },
        );
        setSelectedProposals(proposals);
    };

    const onSelectProposal = (newValues, newValue) => {
        formRef?.current?.setFieldTouched('proposals');
        formRef?.current?.setFieldValue('proposals', newValues);
        setSelectedProposals(newValues);
    };

    return (
        <>
            <Formik
                initialValues={{
                    content: '<p><br></p>',
                    proposals,
                }}
                innerRef={formRef}
                validationSchema={ValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize={enableFormikReinitialize}
            >
                {({ errors, status, isSubmitting, touched }): JSX.Element => (
                    <>
                        <Form>
                            <Box margin={0}>
                                <Card variant="outlined" sx={{ mb: 4 }}>
                                    <CardHeader title={i18n._('New Announcement')} />
                                    <Divider />
                                    <BucketContainer isVertical={false}>
                                        <Bucket xs={12} sm={3}>
                                            <Box
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    minHeight: '100%',
                                                    p: 3,
                                                }}
                                            >
                                                <SelectSection
                                                    sectionTitle={<Trans>Suppliers</Trans>}
                                                    values={proposals}
                                                    selected={selectedProposals}
                                                    onSelect={onSelectProposal}
                                                    equalityFn={equalityFn}
                                                    renderLabel={renderLabel}
                                                />
                                                {Boolean(touched.proposals && errors.proposals) && (
                                                    <Box
                                                        sx={{
                                                            p: 3,
                                                        }}
                                                    >
                                                        <FormHelperText error>
                                                            {errors.proposals}
                                                        </FormHelperText>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Bucket>
                                        <Bucket xs={12} sm={9}>
                                            <Box
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    minHeight: '100%',
                                                    p: 3,
                                                }}
                                            >
                                                <Field
                                                    error={Boolean(
                                                        touched.content && errors.content,
                                                    )}
                                                    helperText={touched.content && errors.content}
                                                    fullWidth
                                                    component={FormikQuillEditor}
                                                    height={280}
                                                    disabled={viewIsReadOnly}
                                                    name="content"
                                                    data-testid="announcementContent"
                                                    label="Content"
                                                />
                                                {Boolean(touched.content && errors.content) && (
                                                    <Box
                                                        sx={{
                                                            p: 3,
                                                        }}
                                                    >
                                                        <FormHelperText error>
                                                            {errors.content}
                                                        </FormHelperText>
                                                    </Box>
                                                )}
                                                <Typography
                                                    color="textSecondary"
                                                    variant="subtitle2"
                                                    data-testid="fileUploadArea"
                                                    sx={{ my: 3 }}
                                                >
                                                    <FileDropzone
                                                        uploadApiUrl={uploadApiUrl}
                                                        fileIds={fileIds}
                                                        setFileIds={setFileIds}
                                                        viewIsReadOnly={viewIsReadOnly}
                                                        parent={announcementSetId}
                                                    />
                                                </Typography>
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || viewIsReadOnly}
                                                    variant="contained"
                                                    data-testid="createAnnouncement"
                                                    sx={{ mt: 3 }}
                                                >
                                                    {i18n._('Send announcement')}
                                                </Button>
                                            </Box>
                                        </Bucket>
                                    </BucketContainer>
                                </Card>
                            </Box>
                            <FormikFormErrors status={status} />
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
};
