/**
 * @generated SignedSource<<97193a0f1fbf2a7c27b0a32f03d0ecea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RatecardProposalSidebar_ratecardProposal$data = {
  readonly id: string;
  readonly projectId: string | null;
  readonly projectInformation: {
    readonly onHold: boolean | null;
    readonly projectTimezone: string | null;
    readonly rates: string | null;
    readonly selectiveBids: boolean | null;
    readonly state: string | null;
    readonly supplements: string | null;
    readonly vat: string | null;
  } | null;
  readonly projectName: string | null;
  readonly state: string;
  readonly validation: {
    readonly allSuccessful: boolean | null;
    readonly attachments: string | null;
    readonly errorMessage: string | null;
    readonly ratecard: string | null;
    readonly tenderIntroduction: string | null;
  } | null;
  readonly " $fragmentType": "RatecardProposalSidebar_ratecardProposal";
};
export type RatecardProposalSidebar_ratecardProposal$key = {
  readonly " $data"?: RatecardProposalSidebar_ratecardProposal$data;
  readonly " $fragmentSpreads": FragmentRefs<"RatecardProposalSidebar_ratecardProposal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RatecardProposalSidebar_ratecardProposal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RatecardProjectInformation",
      "kind": "LinkedField",
      "name": "projectInformation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectTimezone",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectiveBids",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rates",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supplements",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onHold",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RatecardProposalValidationNode",
      "kind": "LinkedField",
      "name": "validation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenderIntroduction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ratecard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allSuccessful",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RatecardProposalNode",
  "abstractKey": null
};
})();

(node as any).hash = "22c03dbdd4e3a9b53c14927ce4581a89";

export default node;
