import { Switch } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';

import BlockIconSvg from 'src/images/blockIcon.svg';
import IconWandSVG from 'src/images/materialWand.svg';

const GenerateStyledSwitch = styled(Switch)(({ theme }) => {
    const bgColor =
        theme.palette.mode === 'dark'
            ? theme.palette.baseColors.color1
            : theme.palette.baseColors.color2;
    return {
        width: 62,
        height: 34,
        padding: 8,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('${BlockIconSvg}')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: `${bgColor}`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? lighten(theme.palette.primary.main, 0.1)
                    : theme.palette.primary.main,
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('${IconWandSVG}')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${bgColor}`,
            borderRadius: 20 / 2,
        },
    };
});

export default GenerateStyledSwitch;
