import React from 'react';

import { useLingui } from '@lingui/react';
import { Grid, TextField } from '@mui/material';

import StyledTextarea from 'src/components/common/styledTextArea';
import { ViewWithSuggestion } from 'src/components/common/viewWithSuggestion/index';

import LoadingDocumentGif from './loadingDocumentGif';
import Suggestion from './suggestion';

interface TitledEntry {
    title: string;
    description: string;
}

interface TitledTextareaWithSuggestionProps {
    entry: TitledEntry;
    loading: boolean;
    suggestion: TitledEntry;
    onChange: (entry: TitledEntry) => void;
    showSuggestions?: boolean;
    dataTestId?: string;
}

export const loadingSX = {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    pT: 20,
    pB: 20,
};

const SUGGESTION_TITLE_HEIGHT = 56;

const TitledTextareaWithSuggestion = ({
    entry,
    suggestion,
    showSuggestions,
    loading,
    onChange,
    dataTestId,
}: TitledTextareaWithSuggestionProps) => {
    const { i18n } = useLingui();

    const handleTextChange = (value) =>
        onChange({
            ...entry,
            description: value,
        });

    const handleTitleChange = (value) =>
        onChange({
            ...entry,
            title: value,
        });

    const handleApplySuggestion = () => onChange(suggestion);
    const suggestionDescriptionContent = loading ? <LoadingDocumentGif /> : suggestion?.description;

    const renderSuggestionView = (
        <Grid container style={{ height: '100%' }}>
            {!loading && (
                <Grid item xs={12} sx={{ height: SUGGESTION_TITLE_HEIGHT }}>
                    <Suggestion suggestion={suggestion?.title} />
                </Grid>
            )}
            <Grid
                item
                xs={12}
                style={{ height: `calc(100% - ${SUGGESTION_TITLE_HEIGHT}px)` }}
                sx={{ pt: 1 }}
            >
                <Suggestion
                    sx={loading ? loadingSX : {}}
                    suggestion={suggestionDescriptionContent}
                />
            </Grid>
        </Grid>
    );

    return (
        <ViewWithSuggestion
            loading={loading}
            entry={entry}
            suggestion={suggestion}
            suggestionView={renderSuggestionView}
            showSuggestions={!!showSuggestions}
            onApply={handleApplySuggestion}
            applyDirectly={!entry?.title?.length && !entry?.description?.length}
        >
            <Grid data-testid="titledTextareaWithSuggestion" container>
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <TextField
                        label={i18n._('Title')}
                        name="title"
                        value={entry?.title ?? ''}
                        onChange={(e) => handleTitleChange(e.target.value || '')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextarea
                        placeholder={i18n._('Description')}
                        value={entry?.description}
                        name="description"
                        onChange={(e) => handleTextChange(e.target.value || '')}
                        minRows={5}
                    />
                </Grid>
            </Grid>
        </ViewWithSuggestion>
    );
};

export default TitledTextareaWithSuggestion;
