const getAverageRGB = (imgEl) => {
    if (!imgEl) {
        return { r: 0, g: 0, b: 0 };
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext && canvas.getContext('2d');
    if (!context) {
        return { r: 0, g: 0, b: 0 };
    }

    const height = imgEl?.naturalHeight || imgEl?.offsetHeight || imgEl?.height;
    canvas.height = imgEl?.naturalHeight || imgEl?.offsetHeight || imgEl?.height;
    const width = imgEl?.naturalWidth || imgEl?.offsetWidth || imgEl?.width;
    canvas.width = imgEl?.naturalWidth || imgEl?.offsetWidth || imgEl?.width;
    context.drawImage(imgEl, 0, 0);

    let data;
    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        return { r: 0, g: 0, b: 0 };
    }

    const { length } = data.data;
    const marginThreshold = 16; // ignore the margins of the logo
    const blockSize = 8; // visit every 8th pixel
    const rgb = { r: 0, g: 0, b: 0 };
    let i = width * marginThreshold * 4; // ignore the top margin;
    let count = 0;
    // eslint-disable-next-line
    while ((i += blockSize * 4) < length - width * marginThreshold * 4) {
        // ignore the bottom margin
        if (
            data.data[i] !== 255 &&
            data.data[i + 1] !== 255 &&
            data.data[i + 2] !== 255 &&
            i % width > marginThreshold &&
            i % width < width - marginThreshold
        ) {
            // ignore left and right margins
            ++count;
            rgb.r += data.data[i];
            rgb.g += data.data[i + 1];
            rgb.b += data.data[i + 2];
        }
    }

    // eslint-disable-next-line
    rgb.r = ~~(rgb.r / count);
    // eslint-disable-next-line
    rgb.g = ~~(rgb.g / count);
    // eslint-disable-next-line
    rgb.b = ~~(rgb.b / count);
    return rgb;
};

const valueToHex = (value: number) => {
    if (value < 10) {
        return `0${value}`;
    }

    return value.toString(16);
};

export { getAverageRGB, valueToHex };
