import React from 'react';
import * as Yup from 'yup';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Divider, Grid, IconButton, Typography } from '@mui/material';

import ConfirmationModal from 'src/components/common/confirmationModal';
import FormModal from 'src/components/common/formModal';
import StyledTextarea from 'src/components/common/styledTextArea';
import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import { style } from 'src/explore/dashboard/table/exploreTableRow';

const editNoteFieldsValidationSchema = Yup.object().shape({
    note: Yup.string().trim().default(''),
});

const useEditNoteFields = () => [
    {
        name: 'note',
        label: t`Note`,
        minRows: 3,
        placeHolder: t`Write a note`,
        variant: 'outlined' as const,
        component: StyledTextarea,
    },
];

interface ListItemProps {
    item: any;
    onItemUpdate: (item: any, values: any) => void;
    onDelete: (item: any) => void;
    canAlterList: boolean;
}

const ListItem = ({ item, onItemUpdate, onDelete, canAlterList }: ListItemProps) => {
    const { i18n } = useLingui();
    const editNoteFields = useEditNoteFields();

    const handleUpdateItem = (values: { note: string }) => {
        onItemUpdate(item, values);
    };

    return (
        <>
            <Grid container sx={{ py: 1 }} display="flex" alignItems="center">
                <Grid sx={{ width: 48 }} display="flex" alignItems="center" justifyContent="center">
                    <CustomCompanyIcon
                        company={{
                            companyId: item.id,
                            companyName: item.companyName,
                            logo: item.logo,
                        }}
                    />
                </Grid>
                <Grid item xs={3} md={2}>
                    <Typography variant="body1">{item?.companyName}</Typography>
                </Grid>
                <Grid item xs sx={style}>
                    <Typography variant="body2">{item?.listItem?.note || '-'}</Typography>
                </Grid>
                <Grid item xs={2} md={1} display="flex" justifyContent="flex-end">
                    {canAlterList && (
                        <FormModal
                            triggerButtonComponent={({ handleOpen }) => (
                                <IconButton onClick={handleOpen} sx={{ width: 48 }}>
                                    <EditNoteIcon />
                                </IconButton>
                            )}
                            onConfirm={handleUpdateItem}
                            title={i18n._('Update note')}
                            confirmButtonText={i18n._('Confirm')}
                            cancelButtonText={i18n._('Cancel')}
                            fields={editNoteFields}
                            validationSchema={editNoteFieldsValidationSchema}
                            initialValues={{
                                note: item?.listItem?.note,
                            }}
                        />
                    )}
                    {canAlterList && (
                        <ConfirmationModal
                            triggerButtonComponent={({ handleOpen }) => (
                                <IconButton color="error" onClick={handleOpen} sx={{ width: 48 }}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            onConfirm={() => onDelete(item)}
                            description={i18n._('Delete vendor?')}
                            confirmButtonText={i18n._('Confirm')}
                            cancelButtonText={i18n._('Cancel')}
                        />
                    )}
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

export default ListItem;
