/**
 * @generated SignedSource<<8b9e15a13248b535b48f30410dfeca59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceStandardSelectAllServiceStandardsQuery$variables = {};
export type ServiceStandardSelectAllServiceStandardsQuery$data = {
  readonly allPublishedServiceStandards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isLeaf: string | null;
        readonly parentServiceDe: string | null;
        readonly parentServiceEn: string | null;
        readonly serviceDe: string;
        readonly serviceEn: string;
      } | null;
    } | null>;
  } | null;
};
export type ServiceStandardSelectAllServiceStandardsQuery = {
  response: ServiceStandardSelectAllServiceStandardsQuery$data;
  variables: ServiceStandardSelectAllServiceStandardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ServiceStandardNodeConnection",
    "kind": "LinkedField",
    "name": "allPublishedServiceStandards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceStandardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceStandardNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLeaf",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentServiceEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentServiceDe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceStandardSelectAllServiceStandardsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ServiceStandardSelectAllServiceStandardsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "47f7f1eae7d14f77e8ddc18b3af2f344",
    "id": null,
    "metadata": {},
    "name": "ServiceStandardSelectAllServiceStandardsQuery",
    "operationKind": "query",
    "text": "query ServiceStandardSelectAllServiceStandardsQuery {\n  allPublishedServiceStandards {\n    edges {\n      node {\n        id\n        isLeaf\n        parentServiceEn\n        parentServiceDe\n        serviceEn\n        serviceDe\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aab9379cba43d1c39ff1350931e1459d";

export default node;
