import { t } from '@lingui/macro';

const useTimelineEventType = () => {
    const getTimelineEventType = (eventType: string) => {
        switch (eventType) {
            case 'START':
                return t`Start date`;
            case 'PROPOSAL_DUE':
                return t`Proposal due date`;
            case 'GENERAL':
                return t`General date`;
            case 'project_start_date':
                return t`Project start date`;
            case 'project_end_date':
                return t`Project end date`;
            case 'rfp_release_due_date':
                return t`RfP release due date`;
            case 'proposal_due_date':
                return t`Proposal due date`;
            case 'presentation_start_date':
                return t`Pitch presentation start date`;
            case 'presentation_end_date':
                return t`Pitch presentation end date`;
            case 'project_award_date':
                return t`Project award date`;
            default:
                return '';
        }
    };

    return getTimelineEventType;
};

export default useTimelineEventType;
