import { FC, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

import { formValidationErros } from 'src/utils/backendErrors';

import { RefreshDialog } from './RefreshDialog';

interface FormikFormErrorEntryProps {
    error: string;
}

const FormikFormErrorEntry: FC<FormikFormErrorEntryProps> = ({
    error,
}: FormikFormErrorEntryProps) => {
    const { i18n } = useLingui();

    return (
        <Typography color="error" variant="subtitle1">
            {error}
        </Typography>
    );
};

interface FormikFormErrorsProps {
    status;
}

export const FormikFormErrors: FC<FormikFormErrorsProps> = ({ status }: FormikFormErrorsProps) => {
    const { i18n } = useLingui();
    let errors = formValidationErros(status?.errors);
    const [refreshDialogOpen, setRefreshDialogOpen] = useState(false);

    const toggleRefreshDialog = () => {
        window.location.reload();
        setRefreshDialogOpen(false);
    };

    useEffect(() => {
        if (errors.length === 1 && errors[0] === 'invalid state') {
            setRefreshDialogOpen(true);
        }
    }, [errors]);

    if (errors.length === 1 && errors[0] === undefined) {
        errors = [];
    }

    return errors.length !== 0 ? (
        <>
            <Card sx={{ mt: 3, mb: 3 }}>
                <CardHeader title={i18n._('Errors')} />
                <Divider />
                <CardContent>
                    <div>
                        {errors.map((error) => (
                            <FormikFormErrorEntry key={error} error={error} />
                        ))}
                    </div>
                </CardContent>
            </Card>
            <RefreshDialog open={refreshDialogOpen} backAction={toggleRefreshDialog} />
        </>
    ) : null;
};
