import { FC, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material';

import UserAvatar from 'src/components/dashboard/UserAvatar';
import useAuth from 'src/hooks/useAuth';
import CogIcon from 'src/icons/Cog';
import UserIcon from 'src/icons/User';

export const AccountPopover: FC = () => {
    const { i18n } = useLingui();
    const { user } = useAuth();

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        navigate('/logout');
    };

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: 1,
                }}
            >
                <UserAvatar user={user} />
            </Box>
            {open && (
                <Popover
                    anchorEl={anchorRef.current}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                    }}
                    keepMounted
                    onClose={handleClose}
                    open
                    PaperProps={{
                        sx: { width: 240 },
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <Typography color="textPrimary" variant="subtitle1" noWrap>
                            {` ${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle2" noWrap>
                            {user.email}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <Typography color="textSecondary" variant="subtitle2" noWrap>
                                {user.companyName}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                        <MenuItem component={Link} to="/dashboard/account/profile">
                            <ListItemIcon>
                                <UserIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography color="textPrimary" variant="subtitle2">
                                        {i18n._('User profile')}
                                    </Typography>
                                }
                            />
                        </MenuItem>
                        <MenuItem component={Link} to="/dashboard/account/security">
                            <ListItemIcon>
                                <CogIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography color="textPrimary" variant="subtitle2">
                                        {i18n._('Change Password')}
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Button
                            color="primary"
                            fullWidth
                            onClick={handleLogout}
                            variant="outlined"
                            startIcon={<LogoutIcon />}
                        >
                            {i18n._('Log out')}
                        </Button>
                    </Box>
                </Popover>
            )}
        </>
    );
};
