import PropTypes from 'prop-types';
import React from 'react';
import type { FC, ReactNode } from 'react';

import useIsUserSupplier from 'src/hooks/useIsUserSupplier';
import SessionManager from 'src/login/sessionManager';

interface VendorCrossTenantGuardProps {
    children: ReactNode;
}

const VendorCrossTenantGuard: FC<VendorCrossTenantGuardProps> = ({ children }) => {
    const activeTenantId = SessionManager.getCurrentTenantId();
    const crossTenant = SessionManager.getCrossTenants();
    const isVendor = useIsUserSupplier();

    React.useEffect(() => {
        if (isVendor && activeTenantId && crossTenant.length > 1) {
            SessionManager.switchToCrossTenants();
        }
    }, [isVendor, activeTenantId]);
    return <>{children}</>;
};

VendorCrossTenantGuard.propTypes = {
    children: PropTypes.node,
};

export default VendorCrossTenantGuard;
