import { FC, useState } from 'react';

import { Avatar, Box, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    user: any;
}

const StyledWrapperBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
}));

const StyledUserAvatar = styled(Avatar)({
    fontSize: '1rem',
    width: '32px',
    height: '32px',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    height: 56,
    width: 'auto',
    marginBottom: theme.spacing(1),
    '&.displayNone': {
        display: 'none',
    },
    '&.logoLoaded': {
        paddingRight: theme.spacing(2),
    },
}));

export const UserAvatarWithPopup: FC<Props> = ({ user }: Props) => {
    const { firstName, lastName, email } = user;
    const firstLetter = firstName?.trim().charAt(0);
    const lastLetter = lastName?.trim().charAt(0);
    const emailLetter = email?.trim().charAt(0);

    const title = !firstLetter && !lastLetter ? emailLetter : `${user.firstName} ${user.lastName}`;
    const content =
        !firstLetter && !lastLetter ? emailLetter : `${firstLetter || ''}${lastLetter || ''}`;

    const [logoLoaded, setLogoLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <StyledUserAvatar
                key={user.id}
                title={title}
                aria-describedby={id}
                onClick={handleClick}
            >
                {content}
            </StyledUserAvatar>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <StyledWrapperBox>
                    <StyledAvatar
                        variant="square"
                        className={user?.company?.logo && logoLoaded ? 'logoLoaded' : 'displayNone'}
                        alt={user?.company?.companyName}
                        src={user?.company?.logo}
                        onLoad={() => {
                            setLogoLoaded(true);
                        }}
                    />

                    <Box>
                        <Typography variant="h6">{`${firstName} ${lastName}`}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {user.jobTitle}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {user?.company?.companyName}
                        </Typography>
                    </Box>
                </StyledWrapperBox>
            </Popover>
        </>
    );
};
