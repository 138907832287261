import { FC } from 'react';

import { useLingui } from '@lingui/react';

import { AnnouncementHistory } from 'src/components/announcements/AnnouncementHistory';
import { AnnouncementNewData } from 'src/components/announcements/AnnouncementNewData';
import { AnnouncementProposal, ProjectAnnouncement } from 'src/types';
import { downloadAttachmentFromUrl } from 'src/utils/file';

interface Props {
    proposals: Array<AnnouncementProposal>;
    announcements?: Array<ProjectAnnouncement>;
    createNewAnnouncement?: CallableFunction;
    announcementSetId?: string;
    addNewAnnouncement: boolean;
    viewIsReadOnly: boolean;
    timezone?: string;
    announcementNumber?: number;
}

export const AnnouncementComponent: FC<Props> = ({
    proposals,
    announcements,
    createNewAnnouncement,
    announcementSetId,
    addNewAnnouncement,
    viewIsReadOnly,
    timezone,
    announcementNumber,
}: Props) => {
    const { i18n } = useLingui();

    const title = announcementNumber ? i18n._('Latest Announcements') : i18n._('Announcements');

    const openSavedFile = (url) => {
        downloadAttachmentFromUrl(url);
    };

    return (
        <>
            {addNewAnnouncement && (
                <AnnouncementNewData
                    proposals={proposals}
                    viewIsReadOnly={viewIsReadOnly}
                    createNewAnnouncement={createNewAnnouncement}
                    announcementSetId={announcementSetId}
                />
            )}
            <AnnouncementHistory
                announcements={announcements}
                timezone={timezone}
                openFile={openSavedFile}
                title={title}
                announcementNumber={announcementNumber}
            />
        </>
    );
};
