import { createSlice } from '@reduxjs/toolkit';

import { StatusValueType } from 'src/redux/fetcher';

import { CancelProposalParticipationFetcher, ProposalFetcher } from './thunk';

export interface ProposalState {
    data: any;
    status: StatusValueType;
    error: any;
}

const initialState: ProposalState = {
    data: {},
    status: 'idle',
    error: null,
};

export const proposalSlice = createSlice({
    name: 'proposal',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ProposalFetcher.reducers(builder);
        CancelProposalParticipationFetcher.reducers(builder);
    },
});

export default proposalSlice.reducer;
