import { useMemo } from 'react';

/**
 * Filters a string aray based on provided array of values
 * @param array
 * @param filterOut
 * @param numberOfResults
 * @returns array of strings of given length
 */
export const useFilteredArray = (array: string[], filterOut: string[], numberOfResults: number) => {
    const suggestionEntries = useMemo(() => {
        if (array) {
            return array
                .filter((entry) => entry !== '' && !filterOut.includes(entry.toLowerCase()))
                .slice(0, numberOfResults);
        }
        return [];
    }, [array, filterOut]);

    return suggestionEntries;
};
