import * as React from 'react';
import toast from 'react-hot-toast';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, Typography, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import SnackbarContent from '@mui/material/SnackbarContent';

import { UserAPI } from 'src/apis/userAPI';
import { Modal } from 'src/components/common/modal';
import useAuth from 'src/hooks/useAuth';
import DismissibleToast from 'src/utils/DismissibleToast';

const userAPI = new UserAPI();

const IncompleteUserProfileModal = ({ isOpen, close, ...rest }: any) => {
    const [loading, setLoading] = React.useState(false);
    const { i18n } = useLingui();
    const { user } = useAuth();
    const { emailValidated, profileCompleted } = user;
    const theme = useTheme();
    const resendEmail = async () => {
        try {
            setLoading(true);
            await userAPI.sendValidationEmail();
            toast.success(
                DismissibleToast(i18n._('The email has been sent. Please check your email.')),
            );
        } catch (e) {
            toast.error(
                DismissibleToast(i18n._('Something went wrong. Please contact an admiistrator.')),
            );
        } finally {
            setLoading(false);
            close();
        }
    };
    return (
        <Modal
            externalControl
            onClose={close}
            isOpen={isOpen}
            withCloseIcon={false}
            triggerButtonComponent={() => null}
            style={{
                width: 'auto',
                zIndex: 1501,
            }}
            {...rest}
        >
            <>
                <Grid display="flex" justifyContent="center">
                    <Typography variant="h6" color="gray">
                        <Trans>Profile incompleted</Trans>
                    </Typography>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <SnackbarContent
                        message={
                            <Grid>
                                <Typography color="success">
                                    <Trans>
                                        Before you can submit your RfP you have to confirm your
                                        email and complete your profile.
                                    </Trans>
                                </Typography>
                            </Grid>
                        }
                        style={{
                            backgroundColor: theme.palette.warning.main,
                        }}
                    />
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <SnackbarContent
                        message={
                            emailValidated ? (
                                <>
                                    <Typography alignItems="center" display="flex" color="success">
                                        <DoneIcon color="inherit" />
                                        <Trans>Your email is confirmed.</Trans>
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography alignItems="center" display="flex" color="success">
                                        <CloseIcon color="inherit" />
                                        <Trans>Your email is not confirmed.</Trans>
                                        &nbsp;
                                        <Link
                                            onClick={resendEmail}
                                            tabIndex={0}
                                            fontSize="inherit"
                                            component="button"
                                        >
                                            Resend validation email
                                        </Link>
                                        {loading && (
                                            <CircularProgress
                                                sx={{ ml: 2 }}
                                                color="inherit"
                                                size={16}
                                            />
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                        sx={{
                            backgroundColor: emailValidated
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                        }}
                    />
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <SnackbarContent
                        message={
                            profileCompleted ? (
                                <Typography alignItems="center" display="flex" color="success">
                                    {' '}
                                    <DoneIcon color="inherit" />
                                    <Trans>Your profile is complete</Trans>
                                </Typography>
                            ) : (
                                <Typography alignItems="center" display="flex" color="success">
                                    <CloseIcon />
                                    <Trans>Your profile is not complete.</Trans>
                                    &nbsp;
                                    <Link
                                        href={`${window.APADUA_SOURCE}app/account/profile?returnToProject=true`}
                                    >
                                        Complete Profile
                                    </Link>
                                </Typography>
                            )
                        }
                        sx={{
                            backgroundColor: profileCompleted
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                        }}
                    />
                </Grid>
            </>
        </Modal>
    );
};

export default IncompleteUserProfileModal;
