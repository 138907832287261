import { useFormikContext } from 'formik';
import React from 'react';

import { Trans } from '@lingui/macro';

import CardSection from 'src/components/sections/cardSection';
import QuillField from 'src/forms/quillField';

const ProjectAdditionalInformation = () => {
    const { values, errors } = useFormikContext() as any;
    return (
        <CardSection
            title={
                <Trans>
                    Additional terms and information (will be displayed in the project summary)
                </Trans>
            }
            id="additional-information"
        >
            <QuillField
                value={values.otherMentions}
                error={!!errors.otherMentions}
                errorMessage={errors.otherMentions}
                name="otherMentions"
            />
        </CardSection>
    );
};

export default ProjectAdditionalInformation;
