import useAuth from './useAuth';

// TODO create an enum that matches the backend roles and point to the relevant file
export const USER_BRIEF_ROLE = 'brief';

const useIsBriefEnabled = () => {
    const { user } = useAuth();
    const isBriefEnabled = window.ENABLE_PROJECT_BRIEFING && user.isClient;
    return isBriefEnabled;
};

export default useIsBriefEnabled;
