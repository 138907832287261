import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import {
    RatecardProjectAnnouncementsFetcher,
    RatecardProjectAnnouncementsFetcherCreate,
} from './thunk';

export const RatecardProjectAnnouncementsSlice = createSlice({
    name: 'ratecardProjectAnnouncements',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        RatecardProjectAnnouncementsFetcher.reducers(builder);
        RatecardProjectAnnouncementsFetcherCreate.reducers(builder);
    },
});
