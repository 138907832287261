import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RatecardProposalContext } from 'src/contexts/RatecardProposalContext';
import useAuth from 'src/hooks/useAuth';

const RatecardProposalProviderRatecardProposalClientQuery = graphql`
    query RatecardProposalProviderRatecardProposalClientQuery($id: ID!) {
        ratecardProposal(id: $id) {
            id
            state
            projectId
            company {
                id
                companyName
            }
            hasOpenBids
            hasAcceptedBids
            ...RebatesRatecardProposalFragment_ratecardProposal
            ...InformationRatecardProposalFragment_ratecardProposal
            ...UploadedDocumentsRatecardProposalFragment_ratecardProposal
            ...SupplierDetailsRatecardProposalFragment_ratecardProposal
        }
    }
`;

const RatecardProposalProviderRatecardProposalConsultantQuery = graphql`
    query RatecardProposalProviderRatecardProposalConsultantQuery($id: ID!) {
        ratecardProposal(id: $id) {
            id
            ignoreDeadlines
            announcementSetId
            internalDocumentSetId
            state
            isCurrentUserAdmin
            projectName
            projectId
            questionSetId
            referenceCurrency {
                id
                isoCurrencyCode
            }
            projectInformation {
                projectTimezone
                state
                selectiveBids
                rates
                supplements
                vat
                onHold
            }
            members {
                id
                email
                firstName
                lastName
            }
            hasOpenBids
            hasAcceptedBids
            ...RatecardProposalSidebar_ratecardProposal
            ...RatecardProposalStepper_ratecardProposal
            ...TenderIntroductionRatecardSummary_ratecardProposal
            ...RatecardProposalFinancialTable_ratecardProposal
            ...BiddingDetailsSummary_ratecardProposal
            ...AttachmentsForm_ratecardProposal
            ...AcceptProposalInvitationButton_ratecardProposal
        }
    }
`;

const useQuery = () => {
    const { user } = useAuth();
    return user.isClient
        ? RatecardProposalProviderRatecardProposalClientQuery
        : RatecardProposalProviderRatecardProposalConsultantQuery;
};

export const useRatecardProposalQuery = () => {
    const query = useQuery();
    const { ratecardProposalQueryRef, refreshRatecardProposalQuery } =
        useContext(RatecardProposalContext);
    const ratecardProposalQuery = usePreloadedQuery(query, ratecardProposalQueryRef);
    return {
        ratecardProposalQuery,
        refreshRatecardProposalQuery,
    };
};

interface Props {
    children: any;
}

export const RatecardProposalProvider: FC<Props> = ({ children }: Props) => {
    const query = useQuery();
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(query);
    const location = useLocation();
    const matchProposal = matchPath(
        '/dashboard/ratecards/:ratecardId/proposals/:proposalId/*',
        location.pathname,
    );
    const matchSupplier = matchPath(
        '/dashboard/ratecards/:ratecardId/evaluation/suppliers/:proposalId/*',
        location.pathname,
    );

    const id = matchProposal?.params?.proposalId || matchSupplier?.params?.proposalId;

    const refresh = () => {
        loadQuery({ id }, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refresh();
        return () => {
            disposeQuery();
        };
    }, [id]);

    if (queryRef === null) {
        return null;
    }

    return (
        <RatecardProposalContext.Provider
            value={{
                ratecardProposalQueryRef: queryRef,
                refreshRatecardProposalQuery: refresh,
            }}
        >
            {children}
        </RatecardProposalContext.Provider>
    );
};
