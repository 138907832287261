import { getIn } from 'formik';
import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { MobileDateTimePicker as MuiDateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';

import useFormatting from 'src/hooks/useFormatting';
import { tzOffest } from 'src/utils/timezones';

export const DateTimePicker: FC<any> = ({
    field,
    form,
    helperText,
    disabled,
    options,
    'data-testid': dataTestId,
    ampm,
    timezone,
    ...others
}: any) => {
    const { i18n } = useLingui();
    const { name, value, onChange, ...fieldOthers } = field;
    const { errors, touched, isSubmitting, setFieldValue, setFieldTouched } = form;
    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;
    const { getDateFormatFromUserLocale, dateFormatterByUserLocale } = useFormatting();

    const nullSafeValue = value !== null ? value : '';

    if (nullSafeValue === undefined) {
        throw new Error(`missing initial value for ${name}`);
    }

    const handleRenderInput = (params) => {
        if (params.inputProps.value) {
            if (typeof params.inputProps.value === 'string') {
                params.value = dateFormatterByUserLocale(Date.parse(value));
                params.inputProps.value = dateFormatterByUserLocale(Date.parse(value));
            } else {
                params.value = dateFormatterByUserLocale(value);
                params.inputProps.value = dateFormatterByUserLocale(value);
            }
        }
        return (
            <TextField
                {...params}
                onBlur={() => {
                    setFieldTouched(name);
                }}
                error={showError}
                helperText={showError ? fieldError : helperText}
                disabled={disabled ?? isSubmitting}
                data-testid={dataTestId}
                {...others}
            />
        );
    };

    return (
        <MuiDateTimePicker
            onChange={(newValue) => {
                setFieldValue(name, newValue);
            }}
            value={nullSafeValue}
            ampm={ampm}
            inputFormat={getDateFormatFromUserLocale(false)}
            renderInput={handleRenderInput}
            toolbarTitle={timezone ? `${timezone} (GMT${tzOffest(timezone)})` : ''}
            okText={i18n._('OK')}
            cancelText={i18n._('Cancel')}
            {...fieldOthers}
            {...others}
        />
    );
};
