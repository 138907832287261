import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { ProjectPhasesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const projectPhasesSlice = createSlice({
    name: 'projectPhases',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ProjectPhasesFetcher.reducers(builder);
    },
});

export default projectPhasesSlice.reducer;
