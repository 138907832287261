import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { CompanySizeTurnoverFetcher } from './thunk';

const initialState = fetcherInitialState;

export const companySizeTurnoverSlice = createSlice({
    name: 'companySizeTurnover',
    initialState,
    reducers: {},
    extraReducers(builder) {
        CompanySizeTurnoverFetcher.reducers(builder);
    },
});

export default companySizeTurnoverSlice.reducer;
