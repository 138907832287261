import { FC, MouseEvent as ME } from 'react';

import { useLingui } from '@lingui/react';
import { Button } from '@mui/material';

type SearchSubmitButtonGroupProps = {
    handleSearchButtonClick: (event: ME<HTMLButtonElement, MouseEvent>) => void;
};

const SearchSubmitButtonGroup: FC<SearchSubmitButtonGroupProps> = ({
    handleSearchButtonClick,
}: SearchSubmitButtonGroupProps) => {
    const { i18n } = useLingui();

    return (
        <Button
            variant="contained"
            onClick={handleSearchButtonClick}
            sx={{
                ml: 2,
                height: '56px',
            }}
        >
            {i18n._('Search')}
        </Button>
    );
};

export default SearchSubmitButtonGroup;
