import React from 'react';

import { Trans } from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { useMobile } from 'src/hooks/useMobile';

const InformationSupport = () => {
    const isMobile = useMobile();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return isMobile ? (
        <Grid>
            <IconButton color="primary" size="small" onClick={handleOpen}>
                <SupportAgentIcon />
            </IconButton>
            <Dialog onClose={handleClose} open={open}>
                <Grid
                    sx={{ p: 2, pt: 2, pb: 2, minHeight: 140, minWidth: '80vw' }}
                    container
                    flexDirection="column"
                    alignItems="center"
                >
                    <CloseIcon onClick={handleClose} sx={{ alignSelf: 'end' }} />
                    <Typography color="textPrimary" variant="subtitle1">
                        <Trans>Contact us for assistance</Trans>
                    </Typography>
                    <Typography color="textPrimary" variant="subtitle1">
                        <a href="mailto:support@apadua.com">support@apadua.com</a>
                    </Typography>
                    <Typography color="textPrimary" variant="subtitle1">
                        <a href="tel:+4922164303470">+49 221 6430 3470</a>
                    </Typography>
                </Grid>
            </Dialog>
        </Grid>
    ) : (
        <Grid container alignItems="center" justifyContent="center">
            <Typography color="textPrimary" variant="subtitle1">
                <Trans> Contact us for assistance</Trans>{' '}
                <a href="mailto:support@apadua.com">support@apadua.com</a> |{' '}
                <a href="tel:+4922164303470">+49 221 6430 3470</a>
            </Typography>
        </Grid>
    );
};

export default InformationSupport;
