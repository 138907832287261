import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';
import { ReactNode, useCallback } from 'react';

import {
    AutocompleteProps,
    AutocompleteRenderInputParams,
    InputProps,
    TextField,
} from '@mui/material';

import { Autocomplete } from 'src/components/formik-material-ui/Autocomplete';
import ErrorMessage from 'src/components/typography/errorMessage';

export interface AutocompleteFieldProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<
        AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        'name' | 'value' | 'defaultValue' | 'renderInput'
    > {
    name: string;
    label: ReactNode;
    required?: boolean;
    getOptionValue?: (option: T) => any;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    inputProps?: InputProps;
}

const CustomAutocomplete = ({ field, form, renderInput, ...rest }: any) => {
    const { inputProps, ...restProps } = rest;
    return (
        <>
            <Autocomplete
                renderInput={(params) =>
                    renderInput({
                        ...params,
                        InputProps: {
                            ...params.InputProps,
                            ...inputProps,
                        },
                        error: !!(form.touched[field.name] && form.errors[field.name]),
                    })
                }
                form={form}
                field={field}
                {...restProps}
            />
            {form.touched[field.name] && form.errors[field.name] && (
                <ErrorMessage errorMessage={form.errors[field.name]} />
            )}
        </>
    );
};

const getDefaultOptionValue = (value: any) => value?.id;

/*
 IMPORTANT NOTICE
 Right now the below component does not work as expected with multiple prop
*/

function AutocompleteField<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    name,
    options,
    label,
    renderInput,
    getOptionValue,
    getOptionLabel,
    multiple,
    freeSolo,
    ...rest
}: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
    const defaultRenderInput = (params) => (
        <TextField required={params?.inputProps?.required} {...params} label={label} />
    );

    const { setFieldTouched, setFieldValue, values } = useFormikContext();
    const getValue = getOptionValue || getDefaultOptionValue;

    const onChange = useCallback(
        (_, newValue) => {
            setFieldTouched(name);
            setFieldValue(name, multiple ? newValue.map(getValue) : getValue(newValue), true);
        },
        [setFieldTouched, name, setFieldValue, getValue, multiple],
    );
    const selectedValue = get(values, name);

    const selectedOption = multiple
        ? options.filter((item) => selectedValue?.includes(getValue(item)))
        : options.find((item) => getValue(item) === selectedValue);

    const defaultRenderOption = useCallback(
        (optionProps, option: any) => (
            <li {...optionProps} key={option?.id || optionProps?.id}>
                {getOptionLabel(option)}
            </li>
        ),
        [getOptionLabel],
    );

    const handleBlur = (e) => {
        if (!selectedOption || selectedValue !== e.target.value) {
            setFieldTouched(name);
            setFieldValue(name, e.target.value, true);
        }
    };

    return (
        <Field
            fullWidth
            options={options}
            value={selectedOption || null}
            component={CustomAutocomplete}
            name={name}
            onChange={onChange}
            data-testid={name}
            onBlur={freeSolo ? handleBlur : undefined}
            renderInput={renderInput ?? defaultRenderInput}
            getOptionLabel={getOptionLabel}
            renderOption={defaultRenderOption}
            multiple={multiple}
            freeSolo={freeSolo}
            {...rest}
        />
    );
}

export default AutocompleteField;
