import { ReactNode, useEffect } from 'react';

import { useTour } from '@reactour/tour';

/**
 * DetectClickEvents is a component that listens for click events and triggers the next step in the tour if the onClickNextCondition is met.
 * It also checks if the current step is healthy and if not, it will try to see if the next step is healthy otherwise it will return to the previous step.
 *
 * There is no way to check what happened in the highlighted area in the tour step, so we have to rely on the onClickNextCondition to trigger the next step.
 * The library is augmented with some extra per-step configuration options:
 * - onClickNextCondition: a function that returns a boolean to check if the next step should be triggered
 * - onClickNextConditionDelay: a number to delay the check of the onClickNextCondition
 * - onClickNextConditionRetries: a number to retry the check of the onClickNextCondition
 *
 * @param children
 * @constructor
 */
const DetectClickEvents = ({ children }: { children: ReactNode }) => {
    const { setIsOpen, setCurrentStep, currentStep, steps, setSteps, meta, setMeta } = useTour();
    useEffect(() => {
        let timeoutId = null;
        const checkStepNextCondition = (e, step, retry = 0) => {
            if (
                steps?.[step]?.onClickNextCondition?.({
                    setIsOpen,
                    setCurrentStep,
                    currentStep,
                    steps,
                    setSteps,
                    meta,
                    setMeta,
                })
            ) {
                setCurrentStep(step + 1);
            } else if (steps?.[step]?.onClickNextConditionRetries > retry) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(
                    () => checkStepNextCondition(e, step, retry + 1),
                    steps?.[step]?.onClickNextConditionDelay,
                );
            }
        };

        const isStepHealthy = (step) =>
            step?.selector ? !!document.querySelector(step.selector) : true;

        const globalClickListener = (e) => {
            if (steps?.[currentStep]?.onClickNextConditionDelay) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(
                    () => checkStepNextCondition(e, currentStep),
                    steps?.[currentStep]?.onClickNextConditionDelay,
                );
            } else {
                checkStepNextCondition(e, currentStep);
            }
        };

        const healthCheckIntervalId = steps?.length
            ? setInterval(() => {
                  if (!isStepHealthy(steps[currentStep]) && currentStep > 0) {
                      if (currentStep + 1 < steps.length && isStepHealthy(steps[currentStep + 1])) {
                          setCurrentStep(currentStep + 1);
                      } else {
                          setCurrentStep(currentStep - 1);
                      }
                  }
              }, 300)
            : null;
        document.addEventListener('click', globalClickListener, true);
        return () => {
            document.removeEventListener('click', globalClickListener, true);
            clearTimeout(timeoutId);
            clearInterval(healthCheckIntervalId);
        };
    }, [steps, currentStep]);
    return <>{children}</>;
};

export default DetectClickEvents;
