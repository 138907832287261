import { createContext, useContext } from 'react';

interface ChatWootContextType {
    chatReady: boolean;
    toggleChat: () => void;
}

const initialValues: ChatWootContextType = {
    chatReady: false,
    toggleChat: () => {},
};

export const ChatWootContext = createContext(initialValues);
export const useChatWootContext = () => useContext(ChatWootContext);
