import { FC } from 'react';

import { useLingui } from '@lingui/react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { AnnouncementFile } from 'src/types';

interface Props {
    announcementFiles: AnnouncementFile[];
    openFile: any;
}

export const AnnouncementFiles: FC<Props> = ({ announcementFiles, openFile }: Props) => {
    const { i18n } = useLingui();

    return (
        <>
            <Box sx={{ mt: 2 }}>
                {announcementFiles.map((fileNode) => (
                    <Box key={fileNode.id}>
                        <Tooltip title={i18n._('Download')} placement="top">
                            <IconButton
                                size="small"
                                color="primary"
                                sx={{ mt: -1.2, mr: 1 }}
                                onClick={() => {
                                    openFile(fileNode?.filePath);
                                }}
                            >
                                <InsertDriveFileIcon fontSize="small" />
                                <Typography color="textPrimary" variant="subtitle2" noWrap>
                                    {fileNode.file}
                                </Typography>
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
        </>
    );
};
