import { Trans } from '@lingui/macro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButton } from '@mui/lab';

interface Props {
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
}

const NextAction = ({ disabled, onClick, loading }: Props) => (
    <LoadingButton
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        data-testid="NextAction"
        loading={loading}
        endIcon={<ArrowForwardIcon />}
    >
        <Trans>Next</Trans>
    </LoadingButton>
);

export default NextAction;
