/**
 * @generated SignedSource<<1b2ae32d4f66ac29969cd0fbd658e78a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuoteProviderConsultantQuery$variables = {
  quoteId: string;
};
export type QuoteProviderConsultantQuery$data = {
  readonly sourceQuote: {
    readonly companyName: string | null;
    readonly id: string;
    readonly projectName: string | null;
  } | null;
};
export type QuoteProviderConsultantQuery = {
  response: QuoteProviderConsultantQuery$data;
  variables: QuoteProviderConsultantQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "quoteId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "quoteId",
        "variableName": "quoteId"
      }
    ],
    "concreteType": "QuoteNode",
    "kind": "LinkedField",
    "name": "sourceQuote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuoteProviderConsultantQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuoteProviderConsultantQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ecbaaaa0c5ec8bcec37e2c5c019f6d95",
    "id": null,
    "metadata": {},
    "name": "QuoteProviderConsultantQuery",
    "operationKind": "query",
    "text": "query QuoteProviderConsultantQuery(\n  $quoteId: ID!\n) {\n  sourceQuote(quoteId: $quoteId) {\n    id\n    projectName\n    companyName\n  }\n}\n"
  }
};
})();

(node as any).hash = "d831447d024c8630be7e2a82798cf284";

export default node;
