import toast from 'react-hot-toast';

import { i18n } from '@lingui/core';

import { ProposalAPI } from 'src/apis/proposalAPI';
import { CancelPayload } from 'src/proposal/types';
import Fetcher from 'src/redux/fetcher';
import DismissibleToast from 'src/utils/DismissibleToast';

import { sanitizeProposal } from './sanitizers';

const proposalAPI = new ProposalAPI();

interface CancelProposalParticipationPayload {
    proposalId: string;
    cancelPayload: CancelPayload;
}

export const ProposalFetcher = new Fetcher(
    'proposal/fetchProposal',
    async (id: string) => proposalAPI.getProposal(id),
    sanitizeProposal,
);

export const CancelProposalParticipationFetcher = new Fetcher(
    'proposal/patchProposal',
    async (payload: CancelProposalParticipationPayload) => {
        try {
            const response = await proposalAPI.cancelProposalParticipation(
                payload.cancelPayload,
                payload.proposalId,
            );

            toast.success(DismissibleToast(i18n.t('Proposal participation cancelled')), {
                duration: 4000,
            });
            return response;
        } catch (e) {
            toast.error(DismissibleToast(e.message));
            throw new Error(e);
        }
    },
    null,
);
