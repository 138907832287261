import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { CompanySizeEmployeesFetcher } from './thunk';

const initialState = fetcherInitialState;

export const companySizeEmployeesSlice = createSlice({
    name: 'companySizeEmployees',
    initialState,
    reducers: {},
    extraReducers(builder) {
        CompanySizeEmployeesFetcher.reducers(builder);
    },
});

export default companySizeEmployeesSlice.reducer;
