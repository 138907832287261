import graphql from 'babel-plugin-relay/macro';
import { FC, useContext } from 'react';
import { useMutation } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import { Button, Card, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NotificationIcon } from 'src/components/notifications/NotificationIcon';
import { NotificationLink } from 'src/components/notifications/NotificationLinkFormatter';
import {
    NotificationDateBox,
    NotificationFlexBoxColumn,
    NotificationFlexGrowBox,
    NotificationHeaderBox,
    NotificationHeadingBox,
    NotificationUnreadBox,
    StyledCircleIcon,
} from 'src/components/notifications/ReusableStyledComponents';
import { formatNotificationTitle } from 'src/components/notifications/formatNotificationTitle';
import { notificationOptions } from 'src/components/notifications/notificationTypeProps';
import { NotificationLongContent } from 'src/components/notifications/popup/NotificationLongContent';
import { NotificationContext } from 'src/contexts/NotificationContext';
import { ElapsedTimeWithTooltip } from 'src/utils/time';
import useWindowSize from 'src/utils/useWindowSize';

import { getNotificationHeading } from '../getNotificationHeading';

const NotificationBox = styled('div')({
    width: '100%',
});

const NoticationCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#f4f5f7',
    },
}));

const LongContentButton = styled(Button)({
    padding: 0,
    '&:hover': {
        backgroundColor: 'inherit',
    },
});

interface Props {
    notification: any;
    closePopup: () => void;
}
export const NotificationElement: FC<Props> = ({ notification, closePopup }: Props) => {
    const { i18n } = useLingui();
    const windowSize = useWindowSize();
    const theme = useTheme();

    const smallScreen = windowSize.width <= theme.breakpoints.values.sm;

    const longFormat = notificationOptions[notification.notificationType]?.longFormat;
    const requiredInputName = notificationOptions[notification.notificationType]?.requiredInputName;

    const {
        setCurrentNotification,
        setNotificationDrawerOpen,
        setUnreadNotificationsCount,
        refreshNotificationsQuery,
    } = useContext(NotificationContext);

    const [markNotificationAsRead] = useMutation(graphql`
        mutation NotificationElementMarkAsReadMutation($input: MarkNotificationAsReadInput!) {
            markNotificationAsRead(input: $input) {
                notification {
                    id
                    notificationType
                    created
                    unread
                    context
                }
            }
        }
    `);

    const markAsRead = () => {
        markNotificationAsRead({
            variables: {
                input: {
                    notificationId: notification?.id,
                },
            },
            onCompleted: (completedData, errors) => {
                if (!errors) {
                    setUnreadNotificationsCount((current) => current - 1);
                    refreshNotificationsQuery();
                }
                closePopup();
            },
        });
    };

    const showLongContent = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setCurrentNotification(notification);
        setNotificationDrawerOpen(true);
        if (notification.unread) {
            markAsRead();
        }
    };

    const onClick = () => {
        if (notification.unread) {
            markAsRead();
        }
        closePopup();
    };

    return (
        <NotificationBox onClick={onClick}>
            <NotificationLink notification={notification}>
                <NoticationCard>
                    <NotificationHeaderBox>
                        <NotificationIcon notificationType={notification.notificationType} />
                        <NotificationFlexBoxColumn>
                            <NotificationHeadingBox>
                                <NotificationFlexGrowBox>
                                    <Typography variant="body2" color="primary" align="justify">
                                        <b>{getNotificationHeading(notification)}</b>
                                    </Typography>
                                </NotificationFlexGrowBox>
                                {/* desktop - notification date */}
                                {!smallScreen && (
                                    <NotificationDateBox>
                                        <ElapsedTimeWithTooltip
                                            start={new Date(notification.created)}
                                        />
                                    </NotificationDateBox>
                                )}
                            </NotificationHeadingBox>
                            <Typography variant="body2" color="primary" align="justify">
                                {formatNotificationTitle(notification)}
                            </Typography>
                            {/* mobile - notification date and icon */}
                            {smallScreen && (
                                <NotificationHeadingBox>
                                    <NotificationDateBox>
                                        <ElapsedTimeWithTooltip
                                            start={new Date(notification.created)}
                                        />
                                    </NotificationDateBox>
                                    <NotificationUnreadBox>
                                        <StyledCircleIcon
                                            sx={{
                                                color: notification.unread
                                                    ? 'warning'
                                                    : 'transparent',
                                            }}
                                        />
                                    </NotificationUnreadBox>
                                </NotificationHeadingBox>
                            )}
                            {/* desktop - long description */}
                            {!smallScreen && (
                                <>
                                    {longFormat &&
                                        (requiredInputName === null ||
                                            notification.context[requiredInputName]) && (
                                            <Tooltip
                                                title={i18n._('Show full content')}
                                                placement="top"
                                            >
                                                <LongContentButton
                                                    disableRipple
                                                    onClick={(event) => {
                                                        showLongContent(event);
                                                    }}
                                                >
                                                    <NotificationLongContent
                                                        notification={notification}
                                                    />
                                                </LongContentButton>
                                            </Tooltip>
                                        )}
                                </>
                            )}
                        </NotificationFlexBoxColumn>
                        {/* desktop - notification icon */}
                        {!smallScreen && (
                            <NotificationUnreadBox>
                                <StyledCircleIcon
                                    sx={{
                                        color: notification.unread ? 'warning' : 'transparent',
                                    }}
                                />
                            </NotificationUnreadBox>
                        )}
                    </NotificationHeaderBox>
                    {/* mobile - long description */}
                    {smallScreen && (
                        <NotificationFlexBoxColumn>
                            {longFormat &&
                                (requiredInputName === null ||
                                    requiredInputName in notification.context) && (
                                    <Tooltip title={i18n._('Show full content')} placement="top">
                                        <LongContentButton
                                            disableRipple
                                            onClick={(event) => {
                                                showLongContent(event);
                                            }}
                                        >
                                            <NotificationLongContent notification={notification} />
                                        </LongContentButton>
                                    </Tooltip>
                                )}
                        </NotificationFlexBoxColumn>
                    )}
                </NoticationCard>
            </NotificationLink>
        </NotificationBox>
    );
};
