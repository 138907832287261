import union from 'lodash/union';
import uniqWith from 'lodash/uniqWith';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { useLingui } from '@lingui/react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import { SearchContext } from 'src/explore/contexts/SearchContext';
import 'src/explore/styles/searchUi.css';
import { emToB, removeEms } from 'src/utils/emUtils';

const StyledLink = styled(Link)({
    marginLeft: '-12px',
    textDecoration: 'none !important',
    '&.noPoiterEvents': {
        pointerEvents: 'none',
    },
});

const computeHighlighted = (object: any, key: string, languageTag: string): string[] | string => {
    if (Array.isArray(object[`${key}_${languageTag}`]?.raw)) {
        const highlighted = uniqWith(
            union(object[`${key}_${languageTag}`]?.snippet, object[`${key}_${languageTag}`]?.raw),
            (a: string, b: string) => removeEms(a) === removeEms(b),
        );
        return highlighted.length ? highlighted : object[`${key}_${languageTag}`]?.raw;
    }
    let highlighted = object[`${key}_${languageTag}`]?.raw;
    object[`${key}_${languageTag}`]?.snippet?.forEach((highlight) => {
        highlighted = highlighted?.replaceAll(removeEms(highlight), highlight);
    });
    return highlighted;
};

export const getOptionKey = (object: any, key: string) => {
    const { i18n } = useLingui();
    const { locale: language } = i18n;

    if (object === null || object === undefined) {
        return object;
    }

    if (language === 'de') {
        return computeHighlighted(object, key, 'de');
    }

    return computeHighlighted(object, key, 'en');
};

type CustomResultViewProps = {
    result: any;
    qp: any;
    isLoading: any;
};

const CustomResultView: FC<CustomResultViewProps> = ({
    result,
    qp,
    isLoading,
}: CustomResultViewProps) => {
    const { i18n } = useLingui();
    const { setCurrentSearch } = useContext(SearchContext);

    return (
        <li>
            {isLoading ? (
                <Skeleton
                    variant="rectangular"
                    style={{
                        maxHeight: '96px',
                        height: '96px',
                        marginTop: '18px',
                        borderRadius: '4px',
                    }}
                />
            ) : (
                <StyledLink
                    onClick={() =>
                        setCurrentSearch(`${window.location.pathname}${window.location.search}`)
                    }
                    to={
                        qp
                            ? `${btoa(
                                  `CompanyNode:${result.result.id?.raw}`,
                              )}/about?search=${encodeURIComponent(qp)}`
                            : `${btoa(`CompanyNode:${result.result.id.raw}`)}/about`
                    }
                >
                    <Card
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                        style={{
                            maxHeight: '96px',
                            height: '96px',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '48px',
                                }}
                            >
                                <CustomCompanyIcon
                                    company={{
                                        companyId: result.result.id.raw,
                                        companyName: result.result.name?.raw,
                                        logo: result.result.logo?.raw,
                                    }}
                                />
                            </Box>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    height: '96px',
                                }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            pt: 0.5,
                                            pl: 0.5,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 3,
                                        }}
                                    >
                                        {result.result.name?.raw}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        minWidth: '108px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            pt: 0.5,
                                        }}
                                    >
                                        {getOptionKey(result.result, 'ln_company_size_employees')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    lg={3.6}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            pt: 0.5,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 3,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                emToB(
                                                    getOptionKey(
                                                        result.result,
                                                        'ln_company_specialities',
                                                    )?.join(', '),
                                                ) || i18n._('Not available'),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                    display={{ xs: 'none', lg: 'flex' }}
                                >
                                    <Typography
                                        sx={{
                                            pt: 0.5,
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 3,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                emToB(getOptionKey(result.result, 'description')) ||
                                                i18n._('Not available'),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </StyledLink>
            )}
        </li>
    );
};

export default CustomResultView;
