import { useFormikContext } from 'formik';

import { Trans } from '@lingui/macro';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import OptionsSection from 'src/components/sections/optionsSection';
import DyanmicListField from 'src/forms/dyanmicListField';
import RadioGroupField from 'src/forms/radioGroupField';

import ProjectContactItem from './projectContactItem';

const ProjectContactInformationForm = () => {
    const personalInformationRadios = [
        {
            value: true,
            label: <Trans>The cover letter shall not contain personal contact information</Trans>,
        },
        {
            value: false,
            label: <Trans>The cover letter shall contain personal contact information</Trans>,
        },
    ];

    return (
        <BucketSection
            isVertical
            title={<Trans>Contact Information</Trans>}
            id="contact-information"
        >
            <Bucket xs={12} withDivider={false}>
                <OptionsSection
                    title={
                        <Trans>
                            Would you like to provide personal contact information in your cover
                            letter?
                        </Trans>
                    }
                >
                    <RadioGroupField
                        name="noPersonalContactInformation"
                        radios={personalInformationRadios}
                        required
                    />
                </OptionsSection>
                <QuoteContacts />
            </Bucket>
        </BucketSection>
    );
};

const QuoteContacts = () => {
    const { values } = useFormikContext() as any;

    return (
        !values.noPersonalContactInformation && (
            <DyanmicListField
                items={values.quoteContacts}
                name="quoteContacts"
                renderItem={(value, index) => <ProjectContactItem index={index} />}
                itemContainerProps={{ display: 'flex', gap: 1, alignItems: 'center' }}
            />
        )
    );
};

export default ProjectContactInformationForm;
