import { MutableRefObject, createContext, useContext, useImperativeHandle } from 'react';
import { PreloadedQuery } from 'react-relay';

export interface SaveRefCurrentType {
    isDirty?: () => boolean;
    save?: () => Promise<void>;
}

interface AppContextType {
    globalQueryRef: PreloadedQuery | null;
    saveRef: MutableRefObject<SaveRefCurrentType>;
    toggleSidebar: () => void;
}

const initialValues: AppContextType = {
    globalQueryRef: null,
    saveRef: null,
    toggleSidebar: () => {},
};

export const AppContext = createContext(initialValues);

export const useAppContext = () => useContext<AppContextType>(AppContext);

export const useSaveMethod = (init: () => SaveRefCurrentType) => {
    const { saveRef } = useAppContext();
    useImperativeHandle(saveRef, init);
};
