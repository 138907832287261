import { RouteObject } from 'react-router';

import App from 'src/App';
import WrappedApp from 'src/WrappedApp';
import GuestGuard from 'src/components/GuestGuard';
import Login from 'src/pages/authentication/Login';
import CompanyLoginPage from 'src/pages/companyLogin/companyLoginPage';
import { companyLoginLoader, loginLoader } from 'src/pages/companyLogin/loader';
import LoginOtpPage from 'src/pages/loginOtp/loginOtpPage';
import { routeURLs } from 'src/router/routeURLs';

const routes: RouteObject[] = [
    {
        index: true,
        loader: loginLoader,
        element: (
            <App>
                <GuestGuard>
                    <Login />
                </GuestGuard>
            </App>
        ),
    },
    {
        path: routeURLs.LOGIN_OTP_VALIDATION,

        element: (
            <WrappedApp>
                <GuestGuard>
                    <LoginOtpPage />
                </GuestGuard>
            </WrappedApp>
        ),
    },
    {
        path: 'company',
        children: [
            {
                loader: companyLoginLoader,
                path: ':companyAlias',
                element: (
                    <App>
                        <GuestGuard>
                            <CompanyLoginPage />
                        </GuestGuard>
                    </App>
                ),
            },
        ],
    },
];
export default routes;
