interface Props {
    errors: any;
    name: any;
    message?: string;
    code?: string;
}

export enum QuoteAPIErrors {
    IncompleteUser = 'incomplete_user',
}

export class QuotesLegacyAPIError extends Error {
    readonly errors: Array<any>;

    readonly code: string;

    constructor({ errors, name, message, code }: Props) {
        super(message);
        this.name = name;
        this.errors = errors;
        this.code = code;
    }
}
