import { SalutationsAPI } from 'src/apis/salutationsAPI';

import Fetcher from '../fetcher';
import { sanitizeSalutations } from './sanitizers';

const api = new SalutationsAPI();

export const SalutationsFetcher = new Fetcher(
    'salutations/list',
    async () => api.getSalutations(),
    sanitizeSalutations,
);
