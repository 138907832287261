import { FC } from 'react';

import useTranslatedText from './useTranslatedText';

interface Props {
    children: string;
}

const TranslatedText: FC<Props> = ({ children }: Props) => {
    const translation = useTranslatedText(children);

    return <>{translation}</>;
};

export default TranslatedText;
