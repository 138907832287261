import { FC, useState } from 'react';

import { Avatar, Box, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
    company: any;
    width?: number;
    height?: number;
    fontSize?: number;
    variant?: 'circular' | 'rounded' | 'square';
    onLoad?: () => void;
}

const StyledWrapperBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
}));

const StyledCompanyAvatar = styled(Avatar)({
    fontSize: '1rem',
    width: '32px',
    height: '32px',
    '& img': {
        objectFit: 'contain',
    },
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    height: 56,
    width: 'auto',
    marginBottom: theme.spacing(1),
    '&.displayNone': {
        display: 'none',
    },
    '&.logoLoaded': {
        marginRight: theme.spacing(2),
    },
    '& img': {
        width: '64px',
        height: 'auto',
        objectFit: 'contain',
    },
}));

const StyledPopover = styled(Popover)({
    pointerEvents: 'none',
});

export const CompanyIconWithPopup: FC<Props> = ({
    company,
    width,
    height,
    fontSize,
    variant,
    onLoad,
}: Props) => {
    const { id: companyId, companyName, logo } = company;
    const firstLetters = companyName?.trim().substring(0, 2);

    const content = firstLetters;

    const [logoLoaded, setLogoLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'mouse-over-popover' : undefined;

    return (
        <>
            <StyledCompanyAvatar
                imgProps={{
                    id: 'companyLogo',
                    crossOrigin: 'anonymous',
                }}
                variant={variant}
                key={companyId}
                src={logo}
                aria-owns={id}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onLoad={onLoad}
                style={{
                    width: width && width,
                    height: height && height,
                    fontSize: fontSize && fontSize,
                }}
            >
                {content}
            </StyledCompanyAvatar>
            <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <StyledWrapperBox>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <StyledAvatar
                            variant="rounded"
                            className={logo && logoLoaded ? 'logoLoaded' : 'displayNone'}
                            alt={companyName}
                            src={logo}
                            onLoad={() => {
                                setLogoLoaded(true);
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="subtitle2" color="textSecondary">
                            {companyName}
                        </Typography>
                    </Box>
                </StyledWrapperBox>
            </StyledPopover>
        </>
    );
};
