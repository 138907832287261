import { Field, FieldProps, getIn } from 'formik';
import React from 'react';

import { StandardTextFieldProps } from '@mui/material';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import StyledTextarea from 'src/components/common/styledTextArea';

interface TextareaProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

function Textarea({
    children,
    field: { onBlur: fieldOnBlur, ...field },
    onBlur,
    helperText,
    disabled,
    ...props
}: TextareaProps) {
    const fieldError = getIn(props.form.errors, field.name);
    const showError = getIn(props.form.touched, field.name) && !!fieldError;

    return (
        <MuiTextField
            variant={props.variant}
            error={showError}
            helperText={showError ? fieldError : helperText}
            disabled={disabled ?? props.form.isSubmitting}
            onBlur={onBlur ?? fieldOnBlur}
            InputProps={{
                inputComponent: StyledTextarea,
                inputProps: {
                    style: {
                        resize: 'vertical',
                    },
                },
            }}
            InputLabelProps={{ shrink: !!field.value || props.focused }}
            {...field}
            {...props}
        >
            {children}
        </MuiTextField>
    );
}

Textarea.displayName = 'FormikMaterialUITextarea';

interface TextareaFieldProps extends Omit<StandardTextFieldProps, 'multiline'> {}

const TextareaField: React.FC<TextareaFieldProps> = ({
    label,
    rows,
    name,
    minRows,
    value,
    ...rest
}) => (
    <Field
        fullWidth
        multiline
        minRows={minRows || 4}
        component={Textarea}
        name={name}
        data-testid={name}
        label={label}
        {...rest}
    />
);

export default TextareaField;
