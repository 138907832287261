import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';

import { useLingui } from '@lingui/react';
import { Box, Button, Grid, Typography } from '@mui/material';

import { CompanyUserSelect } from 'src/components/users/CompanyUserSelect';
import { RatecardProjectRoleSelect } from 'src/components/users/RatecardProjectRoleSelect';

interface Props {
    users: any[];
    userToEdit?: any;
    addOrEditExistingUserAction: any;
    closeDrawer: any;
    afterSuccessAction?: any;
}

export const AddOrEditUserForm: FC<Props> = ({
    users,
    userToEdit,
    addOrEditExistingUserAction,
    closeDrawer,
    afterSuccessAction,
}: Props) => {
    const { i18n } = useLingui();
    const enableFormikReinitialize = true;

    const initialValues = userToEdit
        ? {
              user: {
                  id: userToEdit.user.id,
                  firstName: userToEdit.user.firstName,
                  lastName: userToEdit.user.lastName,
                  email: userToEdit.email,
              },
              role: userToEdit.role,
          }
        : {
              user: null,
              role: 'user',
          };

    const ValidationSchema = Yup.object().shape({
        user: Yup.mixed().required(i18n._('Select a user from the list')),
    });

    const onSubmit = (values, { setSubmitting, setStatus, setErrors }) => {
        addOrEditExistingUserAction(values, !!userToEdit, {
            setSubmitting,
            setStatus,
            setErrors,
            closeDrawer,
            afterSuccessAction,
        });
    };

    const form = ({ isSubmitting }): JSX.Element => (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{i18n._('Add a user to your project')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={CompanyUserSelect}
                        name="user"
                        data-testid="user"
                        label={i18n._('Name')}
                        disabled={!!userToEdit}
                        users={users}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        component={RatecardProjectRoleSelect}
                        name="role"
                        data-testid="role"
                        label={i18n._('Role')}
                        required
                    />
                </Grid>
                <Grid item xs={12} textAlign="right">
                    <Box sx={{ mt: 4, mb: 2, mr: 6, p: 2 }}>
                        <Button
                            sx={{ mr: 2 }}
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                            data-testid="save"
                            disabled={isSubmitting}
                        >
                            {i18n._('Save')}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            data-testid="cancel"
                            onClick={closeDrawer}
                            disabled={isSubmitting}
                        >
                            {i18n._('Cancel')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize={enableFormikReinitialize}
        >
            {form}
        </Formik>
    );
};
