import { FC } from 'react';

import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

interface UserAvatarProps {
    user?: {
        firstName?: string;
        lastName?: string;
        email?: string;
    };
}

const StyledAvatar = styled(Avatar)({
    fontSize: '1rem',
    width: '32px',
    height: '32px',
});

const UserAvatar: FC<UserAvatarProps> = ({ user }: UserAvatarProps) => {
    const { firstName, lastName } = user;

    const firstLetter = firstName?.trim().charAt(0);
    const lastLetter = lastName?.trim().charAt(0);

    if (!firstLetter && !lastLetter) {
        const email = user.email?.trim().charAt(0);
        return <StyledAvatar>{email}</StyledAvatar>;
    }
    return <StyledAvatar>{`${firstLetter || ''}${lastLetter || ''}`}</StyledAvatar>;
};

export default UserAvatar;
