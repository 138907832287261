import { de as dateDE, enUS as dateEN } from 'date-fns/locale';
import * as dateFnsLocales from 'date-fns/locale';

import { deDE as themeDE, enUS as themeEN } from '@mui/material/locale';
import * as muiLocales from '@mui/material/locale';

import AgGridLocale from 'src/locales/agGridLocale';

const supportedLocales = {
    en: {
        theme: themeEN,
        date: dateEN,
    },
    de: {
        theme: themeDE,
        date: dateDE,
    },
};

export const localeFromLanguage = (languageCode: string) => {
    const locale = supportedLocales[languageCode];
    if (locale) {
        return locale;
    }
    return supportedLocales.en;
};

export const agGridLocaleProps = (i18n: any, seed?: string) => ({
    key: `${seed}`,
    getLocaleText: (props) => {
        if (!AgGridLocale[props.key]) {
            console.log(`No agGridLocale translation found for ${props.key}`);
            return props.defaultValue;
        }
        const key = AgGridLocale[props.key];
        return key();
    },
});

/**
 * Method used to map a locale string to an entry in a list of locales
 *
 * @param {string} localeString - A string representing a locale (eg. enUS)
 * @param {list} localeList - A list of dateFnsLocales.Locale or muiLocales.Localization
 * @returns {dateFnsLocales.Locale || muiLocales.Localization} - A locale/localization object
 */
const mapLocaleStringToLocaleFromList = (
    localeString: string,
    localeList: any,
): Locale & muiLocales.Localization => {
    let localeFromString = localeList[`${localeString}`];
    if (localeFromString === undefined) {
        // a direct match was not found
        const slicedLocaleString = localeString.substring(0, 2); // so we will try to match only the first 2 chars, the language (eg. en for enUS)
        localeFromString = localeList[`${slicedLocaleString}`];
        if (localeFromString === undefined) {
            // a match still wasn't found
            const allLocalesFromList = Object.keys(localeList);
            let targetLocale = 'enUS';
            targetLocale = allLocalesFromList.find((element) =>
                element.startsWith(slicedLocaleString),
            ); // so we try to match any locale starting with the code for language (eg. enCA for enUS)
            localeFromString = localeList[`${targetLocale}`];
        }
    }

    return localeFromString;
};

/**
 * Method used to map a locale string to a dateFns Locale
 *
 * @param {string} localeString - A string representing a locale (eg. enUS)
 * @returns {dateFnsLocales.Locale} - A dateFns Locale object
 */
export const dateFnsLocaleFromString = (localeString: string): Locale => {
    if (!localeString) {
        // if no locale string was provided, it returns the en Locale as default
        return dateEN;
    }

    return mapLocaleStringToLocaleFromList(localeString, dateFnsLocales);
};

/**
 * Method used to map a locale string to a mui Localization
 *
 * @param {string} localeString - A string representing a locale (eg. enUS)
 * @returns {muiLocales.Localization} - A mui Localization object
 */
export const muiLocaleFromString = (localeString: string): muiLocales.Localization => {
    if (!localeString) {
        // if no locale string was provided, it returns the en Locale as default
        return themeEN;
    }

    return mapLocaleStringToLocaleFromList(localeString, muiLocales);
};

/**
 * Method that maps a locale string to a dictionary of mui Localization and dateFns Locale objects
 * this is used to pass as context for the mui date/datetime inputs
 *
 * @param {string} localeString - A string representing a locale (eg. enUS)
 * @returns {dict} - A dict of mui Localization and dateFns Locale objects
 */
export const localeFromString = (localeString: string, languageCode: string) => {
    if (!localeString) {
        return {
            theme: themeEN,
            date: dateEN,
        };
    }

    return {
        theme: supportedLocales[languageCode]
            ? supportedLocales[languageCode].theme
            : muiLocaleFromString(localeString.replaceAll('-', '')),
        date: dateFnsLocaleFromString(localeString.replaceAll('-', '')),
    };
};
