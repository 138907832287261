import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import {
    ListsFetcher,
    createList,
    createListItem,
    createOrganisationList,
    deleteList,
    deleteListItem,
    updateList,
    updateListItem,
} from './thunk';

export type ListsState = {
    data: Array<any>;
    error: any;
    status: 'idle' | 'loading' | 'failed' | 'succeeded';
};

export const listsSlice = createSlice({
    name: 'lists',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        ListsFetcher.reducers(builder);
        builder.addCase(createList.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(createList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = action.payload;
        });
        builder.addCase(createList.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(createOrganisationList.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(createOrganisationList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = [...state.data, action.payload];
        });
        builder.addCase(createOrganisationList.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(createListItem.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(createListItem.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = action.payload;
        });
        builder.addCase(createListItem.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(deleteList.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(deleteList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = action.payload;
        });
        builder.addCase(deleteList.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(updateList.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(updateList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = action.payload;
        });
        builder.addCase(updateList.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(deleteListItem.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            const { listId, listItemId } = action.payload;
            const listIndex = state.data.findIndex((list) => list.id === listId);
            if (listIndex !== -1) {
                state.data[listIndex].list_items = state.data[listIndex].list_items.filter(
                    (item) => item.id !== listItemId,
                );
            }
        });
        builder.addCase(updateListItem.pending, (state, action) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(updateListItem.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.data = action.payload;
        });
        builder.addCase(updateListItem.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
    },
});

// Action creators are generated for each case reducer function
// export const {} = listsSlice.actions;

export default listsSlice.reducer;
