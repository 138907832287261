import { FC } from 'react';

import { Chip, Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import useRfqStatus from 'src/translations/hooks/useRfqStatus';

interface Props {
    status: string;
    projectType: string;
    userType?: string;
}

const StyledRoleChip = styled(Chip)({
    color: 'white',
    fontWeight: 700,
    borderRadius: '4px',
    minWidth: '112px',
    '&.edit': {
        backgroundColor: '#ff9800',
    },
    '&.accepted': {
        backgroundColor: '#5bc0de',
    },
    '&.new': {
        backgroundColor: '#6f6f6f',
    },
    '&.rejected': {
        backgroundColor: '#d9534f',
    },
    '&.dismissed': {
        backgroundColor: '#d9534f',
    },
    '&.terms_rejected': {
        backgroundColor: '#d9534f',
    },
    '&.rejected_by_client': {
        backgroundColor: '#fd0700',
    },
    '&.confirmed_by_client': {
        backgroundColor: '#4caf50',
    },
    '&.sent': {
        backgroundColor: '#337ab7',
    },
    '&.paused': {
        backgroundColor: '#61738f',
    },
    '&.cancelled': {
        backgroundColor: '#750000',
    },
    '&.opened': {
        backgroundColor: '#87d8f1',
    },
    '&.completed': {
        backgroundColor: '#4caf50',
    },
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 14,
    },
}));

export const StatusChip: FC<Props> = ({ status, projectType, userType }: Props) => {
    // projectType: ratecards, proposals
    // userType: client, consultant
    const { label, tooltip } = useRfqStatus(status, projectType, userType);

    return (
        <CustomTooltip title={tooltip} placement="top">
            <StyledRoleChip label={label} size="small" className={status} />
        </CustomTooltip>
    );
};
