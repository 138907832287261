import { FC, useState } from 'react';

import { useLingui } from '@lingui/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';

import { EditModeLeaveMessageDialog } from './EditModeLeaveMessageDialog';

interface Props {
    continueEditing: () => void;
    editModeNextUrl?: string;
    noSave?: boolean;
}

export const ExitEditModeDialog: FC<Props> = ({
    continueEditing,
    editModeNextUrl,
    noSave,
}: Props) => {
    const { i18n } = useLingui();

    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    const openMessageDialog = () => {
        setMessageDialogOpen(true);
    };

    return (
        <>
            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        width: '80%',
                        maxHeight: 435,
                    },
                }}
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open
            >
                <DialogTitle id="confirmation-dialog-title">
                    {noSave
                        ? i18n._('Do you want to leave the edit mode?')
                        : i18n._('Your changes have been saved')}
                </DialogTitle>
                <DialogContent>
                    {!noSave && (
                        <Typography variant="subtitle2">
                            {i18n._('Do you now want to leave the edit mode?')}
                        </Typography>
                    )}
                    <Typography variant="subtitle2">
                        {i18n._(
                            'After exiting edit mode, suppliers will be notified and may access the tender again.',
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box m={1}>
                        <Button onClick={continueEditing} data-testid="continueEditing">
                            {i18n._('Continue editing')}
                        </Button>
                    </Box>
                    <Button
                        autoFocus
                        color="primary"
                        variant="contained"
                        onClick={openMessageDialog}
                        data-testid="exitEditMode"
                    >
                        {i18n._('Leave edit mode')}
                    </Button>
                </DialogActions>
            </Dialog>
            {messageDialogOpen && <EditModeLeaveMessageDialog editModeNextUrl={editModeNextUrl} />}
        </>
    );
};
