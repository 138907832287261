import { FC } from 'react';

import { useLingui } from '@lingui/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, AlertTitle } from '@mui/material';

interface Props {
    module: string;
}

export const LicenseWarning: FC<Props> = ({ module }: Props) => {
    const { i18n } = useLingui();

    return (
        <Alert
            iconMapping={{
                error: <WarningAmberIcon fontSize="inherit" />,
            }}
            severity="error"
            sx={{ my: 2 }}
        >
            <AlertTitle>
                {module === 'brief' &&
                    i18n._(
                        'You or your company have no active' +
                            ' license to the Brief module. To get access and more details, please contact ',
                    )}
                {module === 'rfp' &&
                    i18n._(
                        'You or your company have no active license to the Source module. To get access and more details, please contact ',
                    )}
                {module === 'rfq' &&
                    i18n._(
                        'You or your company have no active license to the Rate Card module. To get access and more details, please contact ',
                    )}
                {module === 'explore' &&
                    i18n._(
                        'You or your company have no active license to the Explore module. To get access and more details, please contact ',
                    )}
                {module === 'apalytics' &&
                    i18n._(
                        'You or your company have no active license to the Apalytics module. To get access and more details, please contact ',
                    )}
                <a href="mailto:support@apadua.com">support@apadua.com</a>
                {i18n._(' or your internal administrator.')}
            </AlertTitle>
        </Alert>
    );
};
