import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';

export const NotificationHeaderBox = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'top',
});

export const NotificationFlexBoxColumn = styled('div')({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
});

export const NotificationHeadingBox = styled('div')({
    width: '100%',
    display: 'flex',
});

export const NotificationFlexGrowBox = styled('div')({
    flexGrow: 1,
});

export const NotificationUnreadBox = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        marginTop: '1px',
    },
}));

export const NotificationDateBox = styled('div')(({ theme }) => ({
    flexShrink: 0,

    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
    },
}));

export const StyledCircleIcon = styled(CircleIcon)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontSize: '8px',
}));
