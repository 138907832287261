import { CompanySizeTurnoverAPI } from 'src/apis/companySizeTurnoverAPI';

import Fetcher from '../fetcher';
import { sanitizeCompanySizeTurnover } from './sanitizers';

const api = new CompanySizeTurnoverAPI();

export const CompanySizeTurnoverFetcher = new Fetcher(
    'companySizeTurnover/list',
    async () => api.getCompanySizeTurnover(),
    sanitizeCompanySizeTurnover,
);
