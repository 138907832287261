import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TextField } from 'src/components/formik-material-ui/TextField';
import ErrorMessage from 'src/components/typography/errorMessage';
import Note from 'src/components/typography/note';
import { useLoginError, useLoginLoading } from 'src/login/store/hook';
import { submitOTPAction } from 'src/sagas/actions';

const initialValues = Object.freeze({
    otp: '',
});

const validateScema = Yup.object().shape({
    otp: Yup.number()
        .typeError(() => t`OTP code must be a 6-digit number`)
        .required(() => t`OTP code is required`),
});

const useStyles = makeStyles({
    input: {
        '& input[type=number]::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0,
        },
    },
});

const LoginOTP = () => {
    const { i18n } = useLingui();
    const classes = useStyles();
    const dispatch = useDispatch();
    const submitHandler = async (values) => {
        const { otp } = values;
        dispatch(submitOTPAction(otp));
    };

    const error = useLoginError();
    const loading = useLoginLoading();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateScema}
            onSubmit={submitHandler}
        >
            {({ errors, touched }): JSX.Element => (
                <Form>
                    <Field
                        component={TextField}
                        helperText={touched.otp && errors.otp}
                        className={classes.input}
                        label={i18n._('OTP Code')}
                        name="otp"
                        type="text"
                        variant="outlined"
                        data-testid="loginOtp"
                        fullWidth
                    />

                    {(errors.submit || error) && (
                        <Box sx={{ mt: 1 }}>
                            <ErrorMessage errorMessage={errors.submit || error} />
                        </Box>
                    )}

                    <Box>
                        <Note
                            value={<Trans>A one-time password has been sent to your email.</Trans>}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <LoadingButton
                            color="primary"
                            disabled={loading}
                            loading={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            data-testid="loginButton"
                        >
                            <Trans>Submit</Trans>
                        </LoadingButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default LoginOTP;
