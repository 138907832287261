import { dictionaryCamelCaseConverter } from 'src/utils/dictionaryCamelCaseConverter';

export const sanitizeDocumentTypes = (response) => {
    const { data } = response;
    const mappedData = (data || []).map((item) => ({
        ...item.attributes,
        id: parseInt(item.id, 10),
    }));
    return dictionaryCamelCaseConverter(mappedData);
};
