import { FC, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { useLingui } from '@lingui/react';

import { QuoteUserManagementComponent } from 'src/components/users/QuoteUserManagementComponent';
import { RatecardUserManagmentSkeleton } from 'src/skeletons/ratecards/users/RatecardUserManagmentSkeleton';

const QuoteUserManagement: FC = () => {
    const { i18n } = useLingui();

    return (
        <>
            <Helmet>
                <title>{i18n._('User Management')}</title>
            </Helmet>
            <Suspense fallback={<RatecardUserManagmentSkeleton />}>
                <QuoteUserManagementComponent />
            </Suspense>
        </>
    );
};

export default QuoteUserManagement;
