import { ReactNodeLike } from 'prop-types';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';

import { notificationOptions } from 'src/components/notifications/notificationTypeProps';
import useAuth from 'src/hooks/useAuth';

const StyledRouterLink = styled(RouterLink)({
    textDecoration: 'none',
});

interface Props {
    notification: any;
    children: ReactNodeLike;
}

export const NotificationLink: FC<Props> = ({ notification, children }: Props) => {
    const { user } = useAuth();

    const notificationProps = notificationOptions[notification.notificationType];

    if (!notificationProps?.link) {
        return <>{children}</>;
    }

    const { external, getUrl } = notificationProps.link;

    return external ? (
        <MuiLink underline="none" href={getUrl(notification.context, user)}>
            {children}
        </MuiLink>
    ) : (
        <StyledRouterLink to={getUrl(notification.context, user)}>{children}</StyledRouterLink>
    );
};
