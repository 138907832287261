import union from 'lodash/union';
import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { SummaryCardItemProps } from 'src/components/common/lists/SummaryCardItem';

export const useCompanyTargetIndustries = (company) => {
    const theme = useTheme();

    const legacyIndustries = useMemo(
        () =>
            company?.industries?.edges?.map(
                (industry) =>
                    ({
                        header: industry.node.industryEn,
                        content: null,
                        onFlyTranslate: false,
                        key: industry.node.industryEn,

                        sx: {
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'auto',
                            backgroundColor: theme.palette.background.paper,
                        },
                    }) as SummaryCardItemProps & { key?: string },
            ) || [],
        [],
    );

    const targetIndustries = useMemo(
        () =>
            company?.targetIndustries?.edges?.map(
                (industry) =>
                    ({
                        header: industry.node.industryEn,
                        content: null,
                        onFlyTranslate: false,
                        key: industry.node.industryEn,

                        sx: {
                            flexGrow: 1,
                            height: '100%',
                            pointerEvents: 'auto',
                            backgroundColor: theme.palette.background.paper,
                        },
                    }) as SummaryCardItemProps & { key?: string },
            ) || [],
        [],
    );

    return union(legacyIndustries, targetIndustries);
};
