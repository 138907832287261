import { Field, useFormikContext } from 'formik';
import React from 'react';

import { FormControl, FormControlLabel, Radio as MuiRadio } from '@mui/material';
import { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';

import { RadioGroup } from 'src/components/formik-material-ui/RadioGroup';

interface Radio {
    value: boolean | string | number;
    label: React.ReactNode;
}

interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'value'> {
    radios: Radio[];
    required?: boolean;
}

const RadioGroupField: React.FC<RadioGroupProps> = ({
    radios,
    name,
    required,
    ...rest
}: RadioGroupProps) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const handleChange = (e, value) => {
        if (['false', 'true'].includes(value)) {
            value = value === 'true';
        }
        setFieldTouched(name);
        setFieldValue(name, value, true);
    };

    return (
        <Field
            component={RadioGroup}
            name={name}
            data-testid={name}
            onChange={handleChange}
            required={required}
            {...rest}
        >
            {radios.map((radio, index) => (
                <FormControl key={index}>
                    <FormControlLabel
                        value={radio.value}
                        control={<MuiRadio />}
                        label={radio.label}
                    />
                </FormControl>
            ))}
        </Field>
    );
};

export default RadioGroupField;
