import { FC, useContext, useEffect } from 'react';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { useCompanyExploreQuery } from 'src/explore/providers/CompanyExploreContextProvider';
import useAuth from 'src/hooks/useAuth';

interface Props {
    children: any;
}

export const CompanyExploreContextInitializer: FC<Props> = ({ children }: Props) => {
    const { setSearch, setResults, setTotalCount } = useContext(CompanyExploreContext);
    const { companyResultsQuery } = useCompanyExploreQuery();

    const { user } = useAuth();

    useEffect(() => {
        const payload = JSON.parse(companyResultsQuery?.companyEsResults);
        /* eslint no-underscore-dangle: ["error", { "allow": ["_source"] }] */
        const companies = payload?.hits?.map((result) => ({
            ...result._source,
            ...result.highlight,
        }));
        const totalCount = payload?.total?.value;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const search = urlParams.get('search');
        setSearch(search);
        setResults(companies);
        setTotalCount(totalCount);
    }, [companyResultsQuery, user]);

    return <>{children}</>;
};
