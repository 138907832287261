import { useConfirm } from 'material-ui-confirm';
import { FC, ReactNode, useState } from 'react';

import { useLingui } from '@lingui/react';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import { Box, Button, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';

import Drawer from 'src/components/common/drawer';
import ErrorMessage from 'src/components/typography/errorMessage';
import Calendar from 'src/icons/Calendar';
import PencilAltIcon from 'src/icons/PencilAlt';
import PlusIcon from 'src/icons/Plus';
import TrashIcon from 'src/icons/Trash';
import useTimelineEventType from 'src/translations/hooks/useTimelineEventType';
import { DateWithTime } from 'src/utils/time';

import TimelineItemForm from './timelineItemForm';

interface TimelineEditorProps {
    editable: boolean;
    timelineEvents: any[];
    setTimelineEvents: CallableFunction;
    timezone: string;
    sx?: SxProps;
    errorMessage?: ReactNode;
}

const StyledRoleChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
    borderRadius: '4px',
    overflow: 'ellipsis',
    marginRight: '4px',
    backgroundColor: theme.palette.secondary2.main,
}));

const StyledTimelineContentDiv = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 160,
    paddingLeft: 16,
    paddingRight: 16,
});

const StyledPaperWithBackground = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

export const ProjectTimeline: FC<TimelineEditorProps> = ({
    timelineEvents,
    setTimelineEvents,
    editable,
    timezone,
    sx,
    errorMessage,
}: TimelineEditorProps) => {
    const { i18n } = useLingui();
    const confirm = useConfirm();
    const getTimelineEventType = useTimelineEventType();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentEditableEvent, setCurrentEditableEvent] = useState(null);

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const addNewEvent = () => {
        setCurrentEditableEvent({
            key: `new-${timelineEvents.length}`,
            name: '',
            eventType: 'GENERAL',
            date: null,
        });
        setDrawerOpen(true);
    };

    const editEvent = (timelineEvent) => {
        setCurrentEditableEvent(timelineEvent);
        setDrawerOpen(true);
    };

    const deleteEvent = (tlEvent) => {
        confirm({
            title: i18n._('Are you sure you want to delete this event?'),
            confirmationText: i18n._('Yes'),
            cancellationText: i18n._('Cancel'),
        }).then(() => {
            setTimelineEvents(
                timelineEvents.filter((timelineEvent) => {
                    if (tlEvent.id) {
                        return timelineEvent.id !== tlEvent.id;
                    }
                    return timelineEvent.key !== tlEvent.key;
                }),
            );
        });
    };

    if (!editable && timelineEvents.length === 0) {
        return (
            <Typography variant="subtitle2" color="error">
                {i18n._('No timeline events scheduled')}
            </Typography>
        );
    }

    return (
        <>
            <ErrorMessage errorMessage={errorMessage} />
            <Timeline sx={sx}>
                {timelineEvents?.map((timelineEvent) => (
                    <span key={timelineEvent.key}>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{
                                    minWidth: 80,
                                }}
                            >
                                <DateWithTime date={timelineEvent.date} timezone={timezone} />
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <Calendar />
                                </TimelineDot>
                                {(editable ||
                                    timelineEvents.indexOf(timelineEvent) !==
                                        timelineEvents.length - 1) && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <StyledPaperWithBackground elevation={3}>
                                    <StyledTimelineContentDiv>
                                        <Box mb={0}>
                                            <Typography variant="h6" color="textPrimary">
                                                {timelineEvent.name}
                                            </Typography>
                                        </Box>
                                        {editable && (
                                            <Box>
                                                <Tooltip title={i18n._('Edit')} placement="top">
                                                    <IconButton
                                                        data-testid="timelineEventEditButton"
                                                        onClick={() => {
                                                            editEvent(timelineEvent);
                                                        }}
                                                    >
                                                        <PencilAltIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {timelineEvent.eventType === 'GENERAL' && (
                                                    <Tooltip
                                                        title={i18n._('Delete')}
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            data-testid="timelineEventDeleteButton"
                                                            onClick={() => {
                                                                deleteEvent(timelineEvent);
                                                            }}
                                                        >
                                                            <TrashIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        )}
                                    </StyledTimelineContentDiv>
                                    <Box sx={{ px: 2, pb: 1 }}>
                                        <StyledRoleChip
                                            label={getTimelineEventType(timelineEvent?.eventType)}
                                            size="small"
                                            className={timelineEvent?.eventType}
                                        />
                                    </Box>
                                </StyledPaperWithBackground>
                            </TimelineContent>
                        </TimelineItem>
                    </span>
                ))}
                {editable && (
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{
                                minWidth: 80,
                                p: 1,
                                mx: 2,
                                my: 1,
                            }}
                        />
                        <TimelineSeparator>
                            <TimelineDot>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    data-testid="addTimelineEventButton"
                                    onClick={addNewEvent}
                                >
                                    <PlusIcon />
                                </IconButton>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Button
                                sx={{
                                    mt: 1,
                                    minWidth: 160,
                                }}
                                onClick={addNewEvent}
                            >
                                <Typography variant="h6">{i18n._('New timeline event')}</Typography>
                            </Button>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
            {editable && drawerOpen && (
                <Drawer anchor="right" open>
                    <TimelineItemForm
                        closeDrawer={closeDrawer}
                        timelineEvent={currentEditableEvent}
                        setTimelineEvents={setTimelineEvents}
                        timelineEvents={timelineEvents}
                        timezone={timezone}
                    />
                </Drawer>
            )}
        </>
    );
};
