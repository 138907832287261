import { FC } from 'react';

import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';

type ListProps = Omit<GridProps, 'item' & 'container'>;

const List: FC<ListProps> = ({ children, ...gridProps }: ListProps) => (
    <Grid container {...gridProps}>
        {children}
    </Grid>
);

export default List;
