import * as Yup from 'yup';

import { t } from '@lingui/macro';

export const ContactInformationValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required(() => t`Please provide a first name`)
        .test(
            'Test if the content contains text',
            () => t`Please provide a first name`,
            (value) => value?.replace(/<(.|\n)*?>/g, '').trim().length !== 0,
        ),
    surname: Yup.string()
        .required(t`Please provide a last name`)
        .test(
            'Test if the content contains text',
            () => t`Please provide a last name`,
            (value) => value?.replace(/<(.|\n)*?>/g, '').trim().length !== 0,
        ),
    email: Yup.string()
        .email(() => t`Must be a valid email`)
        .max(255, () => t`Email must be at most 255 characters`)
        .required(() => t`Email is required`),
    phone: Yup.string()
        .matches(/^[+]?([-\s.]?[(]?\d{1,5}[)]?)*[\s]?$/, () => t`Enter a valid phone number`)
        .max(30, () => t`Phone must be at most 30 characters`)
        .nullable(),
});
