/**
 * @generated SignedSource<<84d6fcb2731d337d831239c343150018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteUserManagementComponent_sourceQuote$data = {
  readonly id: string;
  readonly userRoles: ReadonlyArray<{
    readonly email: string | null;
    readonly isActive: boolean | null;
    readonly role: string | null;
    readonly user: {
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "QuoteUserManagementComponent_sourceQuote";
};
export type QuoteUserManagementComponent_sourceQuote$key = {
  readonly " $data"?: QuoteUserManagementComponent_sourceQuote$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteUserManagementComponent_sourceQuote">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteUserManagementComponent_sourceQuote",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceUserRoleNode",
      "kind": "LinkedField",
      "name": "userRoles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuoteNode",
  "abstractKey": null
};
})();

(node as any).hash = "cc78b5a29e55c607bd9de6c6f65282e1";

export default node;
