import React from 'react';

import { useLingui } from '@lingui/react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';

interface CheckListItemTextFieldProps {
    value: string;
    done: boolean;
    name: string;
    onBlur: (name: string, value: string) => void;
    aiGenerated: boolean;
}

const CheckListItemTextField = ({
    value,
    done,
    name,
    onBlur,
    aiGenerated,
    ...rest
}: CheckListItemTextFieldProps) => {
    const { i18n } = useLingui();
    const theme = useTheme();
    const [text, setText] = React.useState(value);
    const handleChange = (e) => {
        if (!done) {
            setText(e.target.value);
        }
    };

    const handleBlur = React.useCallback(() => {
        onBlur(name, text);
    }, [name, text]);

    return (
        <Grid flex={1} display="flex" justifyContent="space-between" alignItems="center">
            <InputBase
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={text}
                placeholder={i18n._('Describe task')}
                sx={{ textDecoration: done ? 'line-through' : 'none', fontWeight: 600 }}
                {...rest}
            />
            {aiGenerated && (
                <Chip
                    size="small"
                    sx={{
                        ml: 0.5,
                        mr: 0.5,
                        p: 0.5,
                        borderRadius: 0.5,
                        borderColor: theme.palette.secondary2.main,
                        background: theme.palette.secondary2.main,
                        color: theme.palette.secondary.dark,
                    }}
                    icon={<AutoFixHighIcon color="secondary" />}
                    label="AI"
                    variant="outlined"
                />
            )}
        </Grid>
    );
};

export default CheckListItemTextField;
