import clone from 'lodash/clone';
import React from 'react';

import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';

import { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import SpacingContainer from 'src/components/sections/spacingContainer';

export interface DynamicListProps {
    items: any[];
    renderItem: (item: any, index: number) => React.ReactNode;
    canRemoveItem?: (index: number, item) => boolean;
    onRemove?: (index: number) => void;
    onAdd?: () => void;
    onChange: (items: any[]) => void;
    itemContainerProps?: any;
    emptyItem?: any;
}
const DynamicList = ({
    items = [],
    renderItem,
    canRemoveItem,
    onRemove,
    onAdd,
    onChange,
    itemContainerProps = {},
    emptyItem = '',
    ...rest
}: DynamicListProps) => {
    const { i18n } = useLingui();

    const addItem = () => {
        const updatedValues = [...items, clone(emptyItem)];
        onChange?.(updatedValues);
        onAdd?.();
    };

    const removeItem = (index: number) => {
        if (items.length === 1) {
            return;
        }

        const updatedValues = [...items];
        updatedValues.splice(index, 1);
        onChange?.(updatedValues);
        onRemove?.(index);
    };

    return (
        <BucketContainer {...rest} isVertical>
            <Bucket xs={12}>
                <SpacingContainer flexDirection="column">
                    <BucketContainer isVertical>
                        {items.map((value, index) => (
                            <Bucket key={index} xs={12} {...itemContainerProps}>
                                <Grid flexDirection="column" gap={1}>
                                    {renderItem(value, index)}
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            variant="outlined"
                                            data-testid={`remove-item-${index}`}
                                            onClick={() => removeItem(index)}
                                            disabled={
                                                canRemoveItem
                                                    ? !canRemoveItem(index, value)
                                                    : !(items.length > 1)
                                            }
                                            color="error"
                                        >
                                            {i18n._('Remove')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Bucket>
                        ))}
                    </BucketContainer>
                </SpacingContainer>
                <Bucket item xs={12} justifyContent="flex-start" withDivider={false}>
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        color="primary"
                        data-testid="add-list-item"
                        size="small"
                        onClick={addItem}
                        startIcon={<AddIcon />}
                    >
                        {i18n._('Add more items')}
                    </Button>
                </Bucket>
            </Bucket>
        </BucketContainer>
    );
};

export default DynamicList;
