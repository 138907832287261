import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import CachedIcon from '@mui/icons-material/Cached';
import ChecklistIcon from '@mui/icons-material/Checklist';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

import ConfirmationModal from 'src/components/common/confirmationModal';
import { useProjectChecklist, useProjectChecklistLoading } from 'src/project/state/hook';

const STARTING_DISTANCE = 2;
const INTERNAL_HEADER_PADDING = 2;

const buttonSx = {
    width: '100%',
    height: '100%',
    borderRadius: 0,
};

interface ChecklistHeaderProps {
    onClose: () => void;
    reloadAI: () => void;
    initialState: boolean;
}

const ChecklistHeader = ({ onClose, reloadAI, initialState }: ChecklistHeaderProps) => {
    const isLoading = useProjectChecklistLoading();
    const theme = useTheme();
    const { i18n } = useLingui();
    const checklist = useProjectChecklist() || {};
    const doesChecklistContainAiItems = checklist.checkListItems.some((item) => item.aiGenerated);

    const triggerButtonComponent = React.useCallback(
        ({ handleOpen }) => (
            <Button
                disabled={isLoading}
                onClick={doesChecklistContainAiItems ? handleOpen : reloadAI}
                sx={buttonSx}
                variant="text"
                data-testid="checklist-reload-ai"
            >
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <CachedIcon />
                        <Typography ml={1} color="textPrimary" variant="h6">
                            {doesChecklistContainAiItems ? (
                                <Trans>Reload AI</Trans>
                            ) : (
                                <Trans>Ask AI</Trans>
                            )}
                        </Typography>
                    </>
                )}
            </Button>
        ),
        [doesChecklistContainAiItems, isLoading, reloadAI],
    );

    return (
        <Grid container sx={{ background: theme.palette.secondary2.main }}>
            <Grid
                pt={INTERNAL_HEADER_PADDING}
                pl={STARTING_DISTANCE}
                pb={INTERNAL_HEADER_PADDING}
                xs={initialState ? 11 : 8}
                sx={{ borderRight: `1px solid ${theme.palette.primary.main}` }}
                display="flex"
                alignItems="center"
            >
                <ChecklistIcon />
                <Grid ml={1}>
                    <Typography color="textPrimary" variant="h6">
                        <Trans>Project checklist</Trans>
                    </Typography>
                </Grid>
            </Grid>
            {!initialState && (
                <Grid xs={3} sx={{ borderRight: `1px solid ${theme.palette.primary.main}` }}>
                    <ConfirmationModal
                        triggerButtonComponent={triggerButtonComponent}
                        onConfirm={reloadAI}
                        description={i18n._(
                            'By performing this action you will lose your existing AI generated items. Are you want to proceed?',
                        )}
                        confirmButtonText={i18n._('Confirm')}
                        cancelButtonText={i18n._('Cancel')}
                    />
                </Grid>
            )}
            <Grid xs={1}>
                <IconButton
                    onClick={onClose}
                    sx={buttonSx}
                    color="primary"
                    size="small"
                    data-testid="checklist-close-arrow"
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default ChecklistHeader;
