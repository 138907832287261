import { useCallback } from 'react';

export const useFormFieldOnChange = (name, setFieldTouched, setFieldValue) =>
    useCallback(
        (e) => {
            setFieldTouched(name);
            setFieldValue(name, e.target.value, true);
        },
        [setFieldTouched, name, setFieldValue],
    );
