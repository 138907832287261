import { Trans } from '@lingui/macro';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import { Information } from 'src/components/typography';
import EditAction from 'src/project/components/editAction';
import { useProjectCompanyInformation, useProjectDetails } from 'src/project/state/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

const ClientInformation = () => {
    const projectDetails = useProjectDetails();
    const companyData = useProjectCompanyInformation();

    return (
        <BucketSection
            title={<Trans>Client Information</Trans>}
            actions={<EditAction editRoute="rfp-details#project-setup" />}
        >
            <Bucket xs={12} sm={4}>
                <Information
                    title={<Trans>Company or Organization name</Trans>}
                    value={companyData.name || '-'}
                />
                <Information
                    title={<Trans>Company address line 1</Trans>}
                    value={companyData.addressLine1 || '-'}
                />
                <Information
                    title={<Trans>Company address line 2</Trans>}
                    value={companyData.addressLine2 || '-'}
                />
                <Information
                    title={<Trans>Company address line 3</Trans>}
                    value={companyData.addressLine3 || '-'}
                />
                <Information
                    title={<Trans>Company postal code</Trans>}
                    value={companyData.postalCode || '-'}
                />
                <Information title={<Trans>Company city</Trans>} value={companyData.city || '-'} />
                <Information
                    title={<Trans>Company country</Trans>}
                    value={getOptionKey(companyData.country, 'commonName') || '-'}
                />
                <Information
                    title={<Trans>Company size (Employees)</Trans>}
                    value={getOptionKey(companyData.employees, 'employeesRange') || '-'}
                />
                <Information
                    title={<Trans>Company size (Revenue)</Trans>}
                    value={getOptionKey(companyData.turnover, 'turnoverRange') || '-'}
                />
            </Bucket>
            <Bucket xs={12} sm={8}>
                <Information
                    title={<Trans>Client Profile</Trans>}
                    value={projectDetails?.clientIntroduction}
                />
            </Bucket>
        </BucketSection>
    );
};

export default ClientInformation;
