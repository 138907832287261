import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import FeedIcon from '@mui/icons-material/Feed';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoteIcon from '@mui/icons-material/Note';
import PollIcon from '@mui/icons-material/Poll';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

import NavSection from 'src/components/NavSection';

export const QuoteSidebarSkeleton: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();

    const sections = [
        {
            title: '',
            items: [
                {
                    title: i18n._('Project info'),
                    path: '',
                    external: true,
                    icon: <FeedIcon fontSize="small" />,
                },
                {
                    title: i18n._('Consulting firms'),
                    path: '',
                    external: true,
                    icon: <WorkIcon fontSize="small" />,
                },
                {
                    title: i18n._('RfP details'),
                    path: '',
                    external: true,
                    icon: <FindInPageIcon fontSize="small" />,
                },
                {
                    title: i18n._('Criteria evaluation'),
                    path: '',
                    external: true,
                    icon: <PollIcon fontSize="small" />,
                },
                {
                    title: i18n._('Cover letter'),
                    path: '',
                    external: true,
                    icon: <NoteIcon fontSize="small" />,
                },
                {
                    title: i18n._('Review & submit'),
                    path: '',
                    external: true,
                    icon: <RateReviewIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: '',
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: '',
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <Box sx={{ my: 2, px: 2, pb: 2 }}>
            <Typography color="textPrimary" variant="h6" align="center" noWrap>
                <Skeleton />
            </Typography>
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box>
                {sections.map((section) => (
                    <NavSection
                        key={section.title}
                        pathname={location.pathname}
                        sx={{
                            '& + &': {
                                mt: 3,
                            },
                        }}
                        {...section}
                    />
                ))}
            </Box>
        </Box>
    );
};
