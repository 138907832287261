/**
 * @generated SignedSource<<e0f07ae959affbcd2e68b8d947a8d9d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardLayoutQuery$variables = {};
export type DashboardLayoutQuery$data = {
  readonly allAcademicTitles: ReadonlyArray<{
    readonly id: string;
    readonly titleDe: string;
    readonly titleEn: string;
  } | null> | null;
  readonly allCountries: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly commonNameDe: string;
        readonly commonNameEn: string;
        readonly id: string;
        readonly iso2letter: string;
      } | null;
    } | null>;
  } | null;
  readonly allCurrencies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isoCurrencyCode: string;
      } | null;
    } | null>;
  } | null;
  readonly allEmployeesRanges: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employeesRange: string;
        readonly id: string;
      } | null;
    } | null>;
  } | null;
  readonly allLanguages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly iso6391: string;
        readonly iso6392: string;
        readonly languageDe: string;
        readonly languageEn: string;
      } | null;
    } | null>;
  } | null;
  readonly allPublishedIndustryStandards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly industryDe: string;
        readonly industryEn: string;
        readonly isLeaf: string | null;
        readonly parentIndustryDe: string | null;
        readonly parentIndustryEn: string | null;
      } | null;
    } | null>;
  } | null;
  readonly allPublishedServiceStandards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isLeaf: string | null;
        readonly parentServiceDe: string | null;
        readonly parentServiceEn: string | null;
        readonly serviceDe: string;
        readonly serviceEn: string;
      } | null;
    } | null>;
  } | null;
  readonly allRevenueRanges: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly revenueRange: string;
      } | null;
    } | null>;
  } | null;
  readonly allSalutations: ReadonlyArray<{
    readonly id: string;
    readonly salutationDe: string;
    readonly salutationEn: string;
  } | null> | null;
};
export type DashboardLayoutQuery = {
  response: DashboardLayoutQuery$data;
  variables: DashboardLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLeaf",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CountryNodeConnection",
    "kind": "LinkedField",
    "name": "allCountries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CountryNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iso2letter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commonNameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commonNameDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrencyNodeConnection",
    "kind": "LinkedField",
    "name": "allCurrencies",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrencyNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrencyNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isoCurrencyCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "IndustryStandardNodeConnection",
    "kind": "LinkedField",
    "name": "allPublishedIndustryStandards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IndustryStandardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndustryStandardNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentIndustryEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentIndustryDe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industryEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industryDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ServiceStandardNodeConnection",
    "kind": "LinkedField",
    "name": "allPublishedServiceStandards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceStandardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceStandardNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentServiceEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentServiceDe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LanguageNodeConnection",
    "kind": "LinkedField",
    "name": "allLanguages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LanguageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LanguageNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iso6391",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iso6392",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "languageEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "languageDe",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SalutationNode",
    "kind": "LinkedField",
    "name": "allSalutations",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salutationEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salutationDe",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AcademicTitleNode",
    "kind": "LinkedField",
    "name": "allAcademicTitles",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleDe",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "EmployeesRangeNodeConnection",
    "kind": "LinkedField",
    "name": "allEmployeesRanges",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmployeesRangeNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmployeesRangeNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employeesRange",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RevenueRangeNodeConnection",
    "kind": "LinkedField",
    "name": "allRevenueRanges",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RevenueRangeNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RevenueRangeNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueRange",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardLayoutQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardLayoutQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "566cb9aa7c15f42ba932dd697a570598",
    "id": null,
    "metadata": {},
    "name": "DashboardLayoutQuery",
    "operationKind": "query",
    "text": "query DashboardLayoutQuery {\n  allCountries {\n    edges {\n      node {\n        id\n        iso2letter\n        commonNameEn\n        commonNameDe\n      }\n    }\n  }\n  allCurrencies {\n    edges {\n      node {\n        id\n        isoCurrencyCode\n      }\n    }\n  }\n  allPublishedIndustryStandards {\n    edges {\n      node {\n        id\n        isLeaf\n        parentIndustryEn\n        parentIndustryDe\n        industryEn\n        industryDe\n      }\n    }\n  }\n  allPublishedServiceStandards {\n    edges {\n      node {\n        id\n        isLeaf\n        parentServiceEn\n        parentServiceDe\n        serviceEn\n        serviceDe\n      }\n    }\n  }\n  allLanguages {\n    edges {\n      node {\n        id\n        iso6391\n        iso6392\n        languageEn\n        languageDe\n      }\n    }\n  }\n  allSalutations {\n    id\n    salutationEn\n    salutationDe\n  }\n  allAcademicTitles {\n    id\n    titleEn\n    titleDe\n  }\n  allEmployeesRanges {\n    edges {\n      node {\n        id\n        employeesRange\n      }\n    }\n  }\n  allRevenueRanges {\n    edges {\n      node {\n        id\n        revenueRange\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cc8482c70199df363d8570cedbffcc5";

export default node;
