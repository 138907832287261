import { Trans } from '@lingui/macro';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Grid, Typography } from '@mui/material';
import Link from '@mui/material/Link';

import BucketSection, { Bucket } from 'src/components/sections/bucketSection';
import EditAction from 'src/project/components/editAction';
import { useProject, useProjectCoverLetter } from 'src/project/state/hook';

const Documents = () => {
    const project = useProject();
    const projectCoverLetter = useProjectCoverLetter();

    const { coverLetterTypeWasChosen, coverLetterDocument } = projectCoverLetter || {};
    const coverLetterUploaded = coverLetterTypeWasChosen;

    const handleCoverLetterDownload = () => {
        if (coverLetterUploaded) {
            window.open(coverLetterDocument, '_blank');
        }
    };

    return (
        <BucketSection
            title={<Trans>Cover Letter</Trans>}
            actions={
                <EditAction
                    editRoute={`${window.APADUA_SOURCE}app/rfp/${project.id}/cover_letter_choices`}
                />
            }
        >
            <Bucket xs={12}>
                {!coverLetterUploaded ? (
                    <Typography color="error">
                        <Trans>Cover letter has not been uploaded</Trans>
                    </Typography>
                ) : (
                    <Grid display="flex">
                        <InsertDriveFileIcon />
                        <Typography sx={{ ml: 1 }}>
                            <Link onClick={handleCoverLetterDownload} sx={{ cursor: 'pointer' }}>
                                <Trans>Cover Letter - click to download</Trans>
                            </Link>
                        </Typography>
                    </Grid>
                )}
            </Bucket>
        </BucketSection>
    );
};

export default Documents;
