import { QuoteDocumentLegacyAPI } from 'src/apis/quoteDocumentLegacyAPI';

import Fetcher from '../fetcher';
import { sanitizeDocumentTypes } from './sanitizers';

const api = new QuoteDocumentLegacyAPI();

export const DocumentTypesFetcher = new Fetcher(
    'documentTypes/list',
    async () => api.getDocumentTypes(),
    sanitizeDocumentTypes,
);
