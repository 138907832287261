import { Trans } from '@lingui/macro';
import { Alert } from '@mui/material';

const ProjectNoVendorsSelectedWarning = () => (
    <Alert severity="error">
        <Trans>
            No vendors have been selected for this project yet. Please add vendors manually or use
            the EXPLORE feature below to identify vendors for your project.
        </Trans>
    </Alert>
);

export default ProjectNoVendorsSelectedWarning;
