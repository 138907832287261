import React from 'react';

import AdditionalInformation from './additionalInformation';
import Attachments from './attachments';
import ClientInformation from './clientInformation';
import ContactInformation from './contactInformation';
import Documents from './documents';
import ProjectInformation from './projectInformation';
import RequestAdditionalInformation from './requestAdditionalInformation';
import RFPDetails from './rfpDetails';

const ProjectReviewAndSubmitContainer = () => (
    <>
        <RequestAdditionalInformation />
        <ClientInformation />
        <ProjectInformation />
        <RFPDetails />
        <ContactInformation />
        <AdditionalInformation />
        <Attachments />
        <Documents />
    </>
);

export default ProjectReviewAndSubmitContainer;
