/**
 * @generated SignedSource<<2753f041b9db4cd43c01c5273124a5a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendValidationEmailInput = {
  clientMutationId?: string | null;
  email: string;
};
export type DashboardLayoutBannerSendValidationEmailMutation$variables = {
  input: SendValidationEmailInput;
};
export type DashboardLayoutBannerSendValidationEmailMutation$data = {
  readonly sendValidationEmail: {
    readonly user: {
      readonly id: string;
      readonly trackingConsent: boolean | null;
    } | null;
  } | null;
};
export type DashboardLayoutBannerSendValidationEmailMutation = {
  response: DashboardLayoutBannerSendValidationEmailMutation$data;
  variables: DashboardLayoutBannerSendValidationEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendValidationEmailPayload",
    "kind": "LinkedField",
    "name": "sendValidationEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trackingConsent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardLayoutBannerSendValidationEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardLayoutBannerSendValidationEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "18c99187bedad5d8157c157176fb1716",
    "id": null,
    "metadata": {},
    "name": "DashboardLayoutBannerSendValidationEmailMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardLayoutBannerSendValidationEmailMutation(\n  $input: SendValidationEmailInput!\n) {\n  sendValidationEmail(input: $input) {\n    user {\n      trackingConsent\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "503e98fc6e5da9e75f7379db322ac5b0";

export default node;
