import { FC, ReactNode, useState } from 'react';

import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { useLingui } from '@lingui/react';
import { Button, Grid, TextField, Typography } from '@mui/material';

import 'src/explore/styles/searchUi.css';

import FilterOption from './filterOption';

type FilterProps = {
    label: string;
    icon?: ReactNode;
    onRemove: (value: any) => void;
    onSelect: (value: any) => void;
    options: any[];
    selectedOptions: number[];
    showSearch: boolean;
};

const Filter: FC<FilterProps> = ({
    label,
    icon,
    onRemove,
    onSelect,
    options,
    selectedOptions,
    showSearch,
}: FilterProps) => {
    const { i18n } = useLingui();
    const { locale: language } = i18n;

    const [visibleOptionsSize, setVisibleOptionsSize] = useState(5);
    const [searchTerm, setSearchTerm] = useState(null);

    const handleMoreClick = () => {
        setVisibleOptionsSize(
            visibleOptionsSize + 10 > options.length ? options.length : visibleOptionsSize + 10,
        );
    };

    const handleSearch = (value: string) => {
        setSearchTerm(value);
    };

    const searchedOptions = searchTerm
        ? options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
        : options;
    const visibleOptions = searchedOptions.slice(0, visibleOptionsSize);
    const showMore = visibleOptionsSize < searchedOptions.length;

    return (
        <fieldset className="sui-facet">
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography>
                    <legend className="sui-facet__title">{label}</legend>
                </Typography>
                {icon}
            </Grid>
            {showSearch && (
                <div className="sui-facet-search">
                    <TextField
                        placeholder={
                            language === 'en'
                                ? `${i18n._('Filter')} ${label}`
                                : `${label} ${i18n._('Filter')}`
                        }
                        variant="outlined"
                        style={{
                            backgroundColor: 'white',
                        }}
                        inputProps={{
                            style: {
                                padding: '4px 8px',
                            },
                        }}
                        onChange={(e) => {
                            handleSearch(e.target.value);
                        }}
                    />
                </div>
            )}
            <div className="sui-multi-checkbox-facet">
                {visibleOptions.map((option, index) => (
                    <FilterOption
                        key={option.id || index}
                        option={option}
                        checked={selectedOptions.includes(option.id)}
                        onRemove={onRemove}
                        onSelect={onSelect}
                    />
                ))}
            </div>
            {showMore && (
                <Button
                    variant="text"
                    onClick={handleMoreClick}
                    sx={{
                        p: 0,
                    }}
                >
                    {`+ ${i18n._('More')}`}
                </Button>
            )}
        </fieldset>
    );
};

export default Filter;
