import graphql from 'babel-plugin-relay/macro';
import { FC, SyntheticEvent, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';
import FlagIcon from '@mui/icons-material/Flag';
import { IconButton, Tooltip, useTheme } from '@mui/material';

import { ModalProps } from 'src/components/common/modal/modal';
import { Reason, ReportModal } from 'src/components/common/modal/reportModal';
import { useGenericQuery } from 'src/selectors/useGenericQuery';
import DismissibleToast from 'src/utils/DismissibleToast';

interface CompanyProfileReportModalProps extends ModalProps {
    company: any;
}

const CompanyProfileReportModalCompanyQuery = graphql`
    query CompanyProfileReportModalCompanyQuery {
        allReportReasons {
            id
            text
        }
    }
`;

const CompanyProfileReportModalReportCompanyMutation = graphql`
    mutation CompanyProfileReportModalReportCompanyMutation($input: ReportCompanyInput!) {
        reportCompany(input: $input) {
            company {
                id
                reported
            }
        }
    }
`;

const CompanyProfileReportModal: FC<CompanyProfileReportModalProps> = ({
    company,
    ...modalProps
}: CompanyProfileReportModalProps) => {
    const { i18n } = useLingui();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [note, setNote] = useState('');
    const [hasError, setHasError] = useState(false);
    const theme = useTheme();
    const { allReportReasons } = useGenericQuery(CompanyProfileReportModalCompanyQuery);
    const [reportCompanyProfile] = useMutation(CompanyProfileReportModalReportCompanyMutation);

    const handleReportButton = useCallback(() => {
        reportCompanyProfile({
            variables: {
                input: {
                    companyId: company.id,
                    reasonsIds: selectedReasons.map((reason) => reason.id).join(','),
                    note,
                },
            },
            onCompleted(completedData, errors) {
                if (errors == null) {
                    toast.success(
                        DismissibleToast(
                            company?.reported
                                ? i18n._('Successfully withdrawn from dataset report')
                                : i18n._('Dataset reported successfully'),
                        ),
                    );
                    setIsOpen(false);
                } else {
                    toast.error(
                        DismissibleToast(
                            company?.reported
                                ? i18n._(
                                      'Error while withdrawing from dataset report. Try again later',
                                  )
                                : i18n._('Error while reporting dataset. Try again later'),
                        ),
                    );
                }
            },
            onError() {
                toast.error(
                    DismissibleToast(
                        company?.reported
                            ? i18n._('Error while withdrawing from dataset report. Try again later')
                            : i18n._('Error while reporting dataset. Try again later'),
                    ),
                );
            },
        });
    }, [company?.reported, note, selectedReasons]);

    const triggerButtonComponent = ({ handleOpen }) => (
        <Tooltip
            title={
                company?.reported
                    ? i18n._(
                          'This dataset was already reported. By clicking, you will delete the reporting flag and the dataset can no longer be checked by our team. Please only perform this action if you are sure all data of this dataset is correct and consistent.',
                      )
                    : i18n._('Report dataset')
            }
        >
            <IconButton
                size="small"
                color="primary"
                style={{ backgroundColor: 'transparent' }}
                sx={{ mt: -1.2, mr: 1 }}
                onClick={company?.reported ? handleReportButton : handleOpen}
            >
                {i18n._('Report')}
                <FlagIcon
                    fontSize="large"
                    sx={{
                        color: company?.reported ? theme.palette.error.main : '#c7c7c7',
                        mx: 1,
                    }}
                />
            </IconButton>
        </Tooltip>
    );

    const onReasonSelect = (event: SyntheticEvent, newValue: Reason[]) => {
        setHasError(newValue.length === 0);
        setSelectedReasons(newValue);
    };

    const onSubmit = () => {
        if (selectedReasons.length === 0) {
            setHasError(true);
            return;
        }
        handleReportButton();
    };

    return (
        <ReportModal
            reasons={allReportReasons?.map((reason) => ({
                id: reason.id,
                text: JSON.parse(reason.text),
            }))}
            selectedReasons={selectedReasons}
            onReasonSelect={onReasonSelect}
            note={note}
            onNoteChange={setNote}
            onSubmit={onSubmit}
            triggerButtonComponent={triggerButtonComponent}
            dropdownLabel={i18n._('Reasons')}
            hasError={hasError}
            errorMessage={i18n._('Select at least one reason')}
            buttonLabel={i18n._('Report dataset')}
            modalTitle={i18n._('Please select a reporting reason for this dataset')}
            externalControl
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            {...modalProps}
        />
    );
};

export default CompanyProfileReportModal;
