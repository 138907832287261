import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';

import { ProjectBriefAPI } from 'src/apis/projectBriefAPI';
import ConfirmationModal from 'src/components/common/confirmationModal';
import Tooltip from 'src/components/common/tooltip';
import Dropdown from 'src/components/dropdown';
import { useCreateProjectisLoading } from 'src/project/state/hook';
import type { ProjectBriefFormData } from 'src/projectBrief/types';
import DismissibleToast from 'src/utils/DismissibleToast';

import DisclaimerAIModal from './disclaimerAIModal';
import EmailDrawer from './emailDrawer';
import { useFindVendors } from './hooks';
import {
    useBriefData,
    useBriefStatusIsLoading,
    useCreateProjectBrief,
    usePatchProjectBrief,
} from './state/hooks';
import type { ProjectBriefData } from './types';

interface ProjectBriefFormActionsProps {
    componentProps: ProjectBriefData | null;
    isEditMode?: boolean;
    values: ProjectBriefFormData;
    isFormValid: boolean;
    clearAll: () => void;
    createProjectAction: () => void;
    validateProject: () => Promise<boolean>;
    handleSelectBrief: (values: any) => void;
}

const api = new ProjectBriefAPI();

const ProjectBriefFormActions = ({
    values,
    isEditMode,
    componentProps,
    isFormValid,
    validateProject,
    clearAll,
    createProjectAction,
    handleSelectBrief,
}: ProjectBriefFormActionsProps) => {
    const { i18n } = useLingui();
    const briefLoading = useBriefStatusIsLoading();
    const projectLoading = useCreateProjectisLoading();
    const createProjectBrief = useCreateProjectBrief();
    const patchProjectBrief = usePatchProjectBrief();
    const briefData = useBriefData();
    const { isVendorsLoading, findVendors } = useFindVendors();
    const [emailDrawerOpen, setEmailDrawerOpen] = React.useState<boolean>(false);
    const pdfRef = React.useRef<Blob | null>(null);
    const [emailLoader, setEmailLoader] = React.useState<boolean>(false);
    const [isPDFReady, setPDFReady] = React.useState<boolean>(false);
    const pdfGenerationWorker = React.useMemo(
        () => new Worker(new URL('./pdfGenerationWorker.ts', import.meta.url)),
        // the below property is needed due to worker
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [emailDrawerOpen],
    );
    const params = useParams();

    React.useEffect(
        () => () => {
            setEmailLoader(false);
            setEmailDrawerOpen(false);
            setPDFReady(false);
        },
        [],
    );

    const sendEmail = async (recipients, personalMessage, includePersonalEmail) => {
        try {
            setEmailLoader(true);
            const payload = {
                file: pdfRef.current,
                startDate: values.startDate,
                endDate: values.endDate,
                projectTitle: values.projectTitle,
                recipients,
                personalMessage,
                includePersonalEmail,
            };
            await api.sendEmail(payload);

            toast.success(
                DismissibleToast(i18n._('Project brief overview has been sent succesfully!')),
                { duration: 3000 },
            );
            setEmailLoader(false);
            setEmailDrawerOpen(false);
            setPDFReady(false);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const { errors } = error.response.data;
                const invalidEmails = errors.blacklisted_recipients;
                toast.error(
                    DismissibleToast(
                        `${i18n._('Invalid email address')}: ${invalidEmails.join(', ')}`,
                    ),
                    { duration: 4000 },
                );
            } else {
                toast.error(
                    DismissibleToast(
                        i18n._('Something went wrong during the sending of your email'),
                    ),
                    { duration: 3000 },
                );
            }
            setEmailDrawerOpen(false);
            setEmailLoader(false);
            setPDFReady(false);
        }
    };

    const onSharePdf = React.useCallback(async () => {
        const isProjectValid = await validateProject();
        if (!isProjectValid) {
            toast.error(
                DismissibleToast(
                    i18n._('In order to share a pdf you need to fill in the missing fields below.'),
                ),
                { duration: 4000 },
            );
            return;
        }
        setEmailDrawerOpen(true);
        const translations = {
            'Project title': i18n._('Project title'),
            'Selected Service': i18n._('Selected Service'),
            'Selected Industry': i18n._('Selected Industry'),
            'Planned Project Start': i18n._('Planned Project Start'),
            'Planned Project End': i18n._('Planned Project End'),
            'Internal Description': i18n._('Internal Description'),
            'Initial Situation': i18n._('Initial Situation'),
            'Project Approach': i18n._('Project Approach'),
            'Project Objectives': i18n._('Project Objectives'),
            'Project Deliverables': i18n._('Project Deliverables'),
        };
        pdfGenerationWorker.postMessage({ componentProps, translations });
        pdfGenerationWorker.onmessage = (event) => {
            const pdfFile = event.data;
            pdfRef.current = pdfFile;
            setPDFReady(true);
        };
    }, [i18n, componentProps, pdfGenerationWorker, validateProject]);

    const handleEmailDrawerClose = () => {
        setEmailDrawerOpen(false);
        setPDFReady(false);
        pdfGenerationWorker.terminate();
    };

    const clearAllbutton = ({ handleOpen }) => (
        <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={handleOpen}
            style={{
                whiteSpace: 'nowrap',
            }}
            data-testid="clearAll"
        >
            {i18n._('Clear all')}
        </Button>
    );
    const findVendorsToolTipTextInactive = i18n._(
        'Please fill in all fields below to enable the vendor search.',
    );
    const findVendorsToolTipTextActive = i18n._(
        'if you activated the AI features below, the "find vendor" feature will automatically extract keywords from your text and use them for a first search. If AI is deactivated, you will simply be transitioned to the vendor search and can start your search from scratch.',
    );

    const onFindVendorsClick = React.useCallback(async () => {
        const isProjectValid = await validateProject();
        if (!isProjectValid) {
            toast.error(
                DismissibleToast(
                    i18n._(
                        'In order to search for vendors you need to fill in the missing fields below.',
                    ),
                ),
                { duration: 4000 },
            );
            return;
        }
        findVendors(values);
    }, [values, findVendors, validateProject, i18n]);

    const onValidate = React.useCallback(async () => {
        const isProjectValid = await validateProject();
        if (!isProjectValid) {
            toast.error(DismissibleToast(i18n._('Some of the fields above are not filled yet.')), {
                duration: 4000,
            });
        } else {
            toast.success(DismissibleToast(i18n._('Your input is valid.')), {
                duration: 4000,
            });
        }
        return isProjectValid;
    }, [validateProject, i18n]);

    const createBrief = React.useCallback(async () => {
        const isValid = await validateProject();
        const { id } = params;
        if (isValid) {
            if (id) {
                const payload = { ...values, id };
                patchProjectBrief(payload);
            } else {
                createProjectBrief(values);
            }
        } else {
            toast.error(
                DismissibleToast(
                    i18n._('Brief cannot be saved. Please complete all mandatory fields.'),
                ),
                {
                    duration: 4000,
                },
            );
        }
    }, [values, createProjectBrief, onValidate, params?.id, i18n, validateProject]);

    const triggerButtonComponent = React.useCallback(
        (handleOpenClick) => (
            <LoadingButton
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<AutoFixHighIcon />}
                onClick={handleOpenClick}
                style={{
                    whiteSpace: 'nowrap',
                }}
                data-testid="findVendors"
                loading={isVendorsLoading}
            >
                {i18n._('Find vendors')}
            </LoadingButton>
        ),
        [isVendorsLoading],
    );

    return (
        <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={4} lg>
                <ConfirmationModal
                    triggerButtonComponent={clearAllbutton}
                    onConfirm={clearAll}
                    description={i18n._(
                        'Are you sure you want delete all your changes? Your current changes will be lost!',
                    )}
                    confirmButtonText={i18n._('Confirm')}
                    cancelButtonText={i18n._('Cancel')}
                />
            </Grid>
            <Grid item display="flex" gap={1} data-testid="saveLoadBrief">
                {!isEditMode && (
                    <Grid item xs={12} sm={6} md={4} lg>
                        <LoadingButton
                            fullWidth
                            color="primary"
                            variant="outlined"
                            loading={briefLoading}
                            onClick={createBrief}
                            style={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {i18n._('Save Brief')}
                        </LoadingButton>
                    </Grid>
                )}
                {!isEditMode && (
                    <Grid item xs={12} sm={6} md={4} lg>
                        <Dropdown
                            items={briefData || []}
                            itemLabelField="projectTitle"
                            onSelect={handleSelectBrief}
                            menuButtonProps={{
                                variant: 'outlined',
                                color: 'primary',
                                content: i18n._('Load Brief'),
                                disabled: briefData.length === 0,
                                fullWidth: true,
                                style: {
                                    whiteSpace: 'nowrap',
                                },
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg>
                <LoadingButton
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={onSharePdf}
                    data-testid="pdfDownloadButton"
                    loading={emailLoader}
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {i18n._('Share PDF')}
                </LoadingButton>
            </Grid>
            {window.ENABLE_FIND_VENDORS && !isEditMode && (
                <Grid item xs={12} sm={6} md={4} lg>
                    <Box sx={{ width: '100%' }}>
                        <Tooltip
                            title={
                                !isFormValid
                                    ? findVendorsToolTipTextInactive
                                    : findVendorsToolTipTextActive
                            }
                        >
                            <DisclaimerAIModal
                                onConfirm={onFindVendorsClick}
                                triggerButtonComponent={triggerButtonComponent}
                            />
                        </Tooltip>
                    </Box>
                </Grid>
            )}
            {!isEditMode && (
                <Grid item xs={12} sm={6} md={4} lg>
                    <LoadingButton
                        data-testid="createProject"
                        fullWidth
                        loading={projectLoading}
                        disabled={projectLoading}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        color="primary"
                        onClick={createProjectAction}
                        variant="contained"
                    >
                        {i18n._('Create Project')}
                    </LoadingButton>
                </Grid>
            )}
            <Grid>
                <EmailDrawer
                    handleClose={handleEmailDrawerClose}
                    disabled={!isPDFReady}
                    isLoading={emailLoader}
                    handleSubmit={sendEmail}
                    open={emailDrawerOpen}
                />
            </Grid>
        </Grid>
    );
};

export default ProjectBriefFormActions;
