import React from 'react';

import { Menu } from '@mui/base';
import { Dropdown as MuiDropdown } from '@mui/base/Dropdown';

import { Z_INDEX } from 'src/theme/zIndex';

import Listbox from './listbox';
import MenuButton, { MenuButtonProps } from './menuButton';
import MenuItem from './menuItem';

interface DropdownProps {
    items: any[];
    onSelect: (item: string) => void;
    menuButtonProps?: MenuButtonProps;
    actionElement?: React.ReactNode;
    itemLabelField: string;
}
const Dropdown: React.FC<DropdownProps> = ({
    items,
    itemLabelField,
    onSelect,
    menuButtonProps,
    actionElement,
    ...rest
}) => (
    <MuiDropdown {...rest}>
        <MenuButton {...menuButtonProps} />
        <Menu style={{ zIndex: Z_INDEX.ONE }} slots={{ listbox: Listbox }}>
            {actionElement && <MenuItem>{actionElement}</MenuItem>}
            {items.map((item) => (
                <MenuItem key={item.id} onClick={() => onSelect?.(item)}>
                    {item[itemLabelField]}
                </MenuItem>
            ))}
        </Menu>
    </MuiDropdown>
);

export default Dropdown;
