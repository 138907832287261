import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchProvider, withSearch } from '@elastic/react-search-ui';
import { Layout } from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector';
import { Box, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import ExploreNoResults from 'src/explore/dashboard/resultsHeader/exploreNoResults';
import 'src/explore/styles/searchUi.css';
import { exploreQueryStringSafeguard } from 'src/router/exploreQueryStringSafeguard';
import { searchToQueryDSL } from 'src/utils/dslUtils';
import { getAccessToken } from 'src/utils/login';

import ExplorePagination from './ExplorePagination';
import ExploreSideFilters from './ExploreSideFilters';
import SearchBoxComponent from './SearchBoxComponent';
import ExploreCallToAction from './resultsHeader/exploreCallToAction';
import ExploreEmptyList from './resultsHeader/exploreEmptyList';
import ExploreResultsHeader from './resultsHeader/exploreResultsHeader';
import { useFetchTotalVendorsCount } from './state/hooks';
import ExploreTable from './table/exploreTable';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
}));

interface ExplorePageLayoutProps {
    widgets?: ReactNode;
}

const ExplorePageLayout: FC<ExplorePageLayoutProps> = ({ widgets }: ExplorePageLayoutProps) => {
    useFetchTotalVendorsCount();
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState(null);
    const [contentSearch, setContentSearch] = useState(
        !searchParams.toString().includes('nameSearch'),
    );
    const [currentSearchFields, setCurrentSearchFields] = useState({});
    const [currentAutocompleteSearchFields, setCurrentAutocompleteSearchFields] = useState({});
    const [currentAutocompleteResultFields, setCurrentAutocompleteResultFields] = useState({});
    const [disjunctiveFacets, setDisjunctiveFacets] = useState([
        '_id',
        'countries_en.keyword',
        'ln_company_industry_en.keyword',
        'consultant_type_en.keyword',
        'ln_company_size_employees_en.keyword',
        'ln_company_specialities_en.keyword',
        'ln_company_type_de.keyword',
        'zones_en.keyword',
    ]);

    const handleToggleChange = useCallback((e, newSearchType) => {
        if (newSearchType) {
            setContentSearch(newSearchType === 'contentSearch');
        }
    }, []);

    getAccessToken().then((tkn) => setToken(tkn));

    useEffect(() => {
        setCurrentSearchFields(
            contentSearch
                ? {
                      name: {
                          weight: 5,
                      },
                      content: {
                          weight: 2,
                      },
                      ln_company_specialities_en: {
                          weight: 5,
                      },
                      ln_company_specialities_de: {
                          weight: 5,
                      },
                      description_en: {
                          weight: 4,
                      },
                      description_de: {
                          weight: 4,
                      },
                      countries_en: {
                          weight: 1,
                      },
                  }
                : {
                      name: {
                          weight: 1,
                      },
                  },
        );

        setCurrentAutocompleteSearchFields(
            contentSearch
                ? {
                      ln_company_specialities_en: {
                          weight: 1,
                      },
                  }
                : {
                      name: {
                          weight: 1,
                      },
                  },
        );

        setCurrentAutocompleteResultFields(
            contentSearch
                ? {
                      ln_company_specialities_en: {
                          snippet: {
                              size: 100,
                              fallback: true,
                          },
                      },
                  }
                : {
                      name: {
                          snippet: {
                              size: 100,
                              fallback: true,
                          },
                      },
                  },
        );
    }, [contentSearch]);

    const connector = React.useMemo(
        () =>
            new ElasticsearchAPIConnector(
                {
                    host: `${window.APADUA_API}esproxy/default`,
                    index: window.ELASTICSEARCH_INDEX_NAME,
                    connectionOptions: {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                },
                (requestBody, requestState, queryConfig) => {
                    const searchFields = queryConfig.search_fields;
                    if (requestState.searchTerm) {
                        requestBody.query = {
                            simple_query_string: {
                                query: searchToQueryDSL(requestState.searchTerm, 10, 1),
                                fields: Object.keys(searchFields).map((fieldName) => {
                                    const { weight } = searchFields[fieldName];
                                    return `${fieldName}^${weight}`;
                                }),
                                default_operator: 'AND',
                            },
                        };
                    } else {
                        // we have artificial filter named `lists`, so we need to remove the
                        // query if there is no searchTerm. In any case we are overriding it with
                        // only the searchTerm.
                        delete requestBody.query;
                    }
                    requestBody.track_total_hits = true;

                    return requestBody;
                },
            ),
        [token],
    );

    const config = React.useMemo(
        () => ({
            initialState: {
                resultsPerPage: 25,
            },
            searchQuery: {
                search_fields: currentSearchFields,
                result_fields: {
                    name: {
                        snippet: {
                            size: 100,
                            fallback: true,
                        },
                    },
                    ln_company_type_de: {
                        raw: {},
                    },
                    ln_company_size_employees_en: {
                        raw: {},
                    },
                    ln_company_size_employees_de: {
                        raw: {},
                    },
                    ln_company_specialities_en: {
                        snippet: {
                            size: 100,
                            fallback: true,
                        },
                    },
                    ln_company_specialities_de: {
                        snippet: {
                            size: 100,
                            fallback: true,
                        },
                    },
                    description_en: {
                        snippet: {
                            size: 100,
                            fallback: true,
                        },
                    },
                    description_de: {
                        snippet: {
                            size: 100,
                            fallback: true,
                        },
                    },
                    logo: {
                        raw: {},
                    },
                    id: {
                        raw: {},
                    },
                },
                disjunctiveFacets,
                filters: [],
                facets: {
                    _id: { type: 'value' },
                    'countries_en.keyword': { type: 'value', size: 200 },
                    'consultant_type_en.keyword': { type: 'value' },
                    'target_industries_en.keyword': { type: 'value', size: 10000 },
                    'ln_company_industry_en.keyword': { type: 'value', size: 10000 },
                    'ln_company_size_employees_en.keyword': { type: 'value' },
                    'ln_company_specialities_en.keyword': { type: 'value', size: 10000 },
                    'ln_company_type_de.keyword': { type: 'value' },
                    'zones_en.keyword': { type: 'value' },
                },
            },
            autocompleteQuery: {
                results: {
                    resultsPerPage: 5,
                    search_fields: currentAutocompleteSearchFields,
                    result_fields: currentAutocompleteResultFields,
                },
            },
            onAutocomplete: (query, queryConfig, next) => {
                const newQuery = {
                    ...query,
                    searchTerm: query.searchTerm.split(',').pop(),
                };
                return next(newQuery, queryConfig);
            },
            routingOptions: {
                writeUrl: exploreQueryStringSafeguard,
            },
            apiConnector: connector,
            alwaysSearchOnInitialLoad: true,
            hasA11yNotifications: true,
        }),
        [
            connector,
            currentAutocompleteResultFields,
            currentAutocompleteSearchFields,
            currentSearchFields,
            disjunctiveFacets,
        ],
    );

    return token ? (
        <>
            <Root>
                <SearchProvider config={config}>
                    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                    <SearchProviderChildrenWithSearch
                        // @ts-ignore
                        contentSearch={contentSearch}
                        handleToggleChange={handleToggleChange}
                        disjunctiveFacets={disjunctiveFacets}
                        setDisjunctiveFacets={setDisjunctiveFacets}
                        widgets={widgets}
                    />
                </SearchProvider>
            </Root>
        </>
    ) : null;
};

export default ExplorePageLayout;

interface SearchProviderChildrenProps {
    isLoading: boolean;
    wasSearched: boolean;
    searchTerm: string;
    filters: any[];
    results: any[];
    contentSearch: boolean;
    handleToggleChange: (e: any, newSearchType: string) => void;
    disjunctiveFacets: string[];
    setDisjunctiveFacets: (disjunctiveFacets: string[]) => void;
    widgets: ReactNode;
}

const SearchProviderChildren = ({
    isLoading,
    wasSearched,
    searchTerm,
    filters,
    results,
    contentSearch,
    handleToggleChange,
    disjunctiveFacets,
    setDisjunctiveFacets,
    widgets,
}: SearchProviderChildrenProps) => {
    const showCallToAction = !wasSearched || isLoading || (!searchTerm && !filters.length);
    const showEmptyListWarning =
        wasSearched &&
        !isLoading &&
        filters.some((filter) => filter.field === '_id' && filter.values.length === 0);
    const showNoResultsWarning = wasSearched && !isLoading && !results.length;
    const showResults = !showCallToAction && !showEmptyListWarning && !showNoResultsWarning;
    return (
        <Layout
            header={
                <Box data-testid="projectExploreVendors">
                    <SearchBoxComponent
                        contentSearch={contentSearch}
                        onToggleChange={handleToggleChange}
                    />
                </Box>
            }
            sideContent={
                <ExploreSideFilters
                    disjunctiveFacets={disjunctiveFacets}
                    setDisjunctiveFacets={setDisjunctiveFacets}
                />
            }
            bodyContent={
                <Grid sx={{ mt: 2 }}>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress size={64} />
                        </Box>
                    )}
                    {!isLoading && showCallToAction && <ExploreCallToAction />}
                    {showEmptyListWarning && <ExploreEmptyList />}
                    {showResults && <ExploreTable />}
                    {showNoResultsWarning && <ExploreNoResults />}
                </Grid>
            }
            bodyHeader={showResults && <ExploreResultsHeader widgets={widgets} />}
            bodyFooter={showResults && <ExplorePagination />}
        />
    );
};

const SearchProviderChildrenWithSearch = withSearch(
    ({ isLoading, wasSearched, searchTerm, filters, results }) => ({
        isLoading,
        wasSearched,
        searchTerm,
        filters,
        results,
    }),
)(SearchProviderChildren);
