import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

import { proposalSelector, proposalStatusSelector } from './selectors';
import { serializeProposalCancellation } from './serializers';
import { CancelProposalParticipationFetcher } from './thunk';

export const useProposal = () => useAppSelector(proposalSelector).data;
export const useProposalStatus = () => useAppSelector(proposalStatusSelector);

export const usePatchProposalAsync = () => {
    const dispatch = useAppDispatch();
    const proposal = useProposal();
    const proposalStatus = useProposalStatus();

    return useCallback(
        async (values) => {
            if (proposalStatus !== 'loading') {
                const cancelPayload = serializeProposalCancellation(values);
                const payload = {
                    proposalId: proposal.id,
                    cancelPayload,
                };
                await dispatch(CancelProposalParticipationFetcher.action(payload));
            }
        },
        [dispatch, proposalStatus, proposal?.id],
    );
};
