import toast from 'react-hot-toast';

import { LoginAPI } from 'src/apis/loginAPI';
import store from 'src/redux/store';
import { navigateAction } from 'src/sagas/actions';
import DismissibleToast from 'src/utils/DismissibleToast';

const api = new LoginAPI();

export interface SSOResponse {
    loginURL: string;
}

export const SSO_COMPANY_LOGIN_PAGE_KEY = 'sso_login_page';

export const companyLoginLoader = async ({ params }) => {
    const { companyAlias } = params;
    try {
        window.localStorage.setItem(SSO_COMPANY_LOGIN_PAGE_KEY, companyAlias);
        const data: SSOResponse = await api.getSSOProvider(companyAlias);
        return data;
    } catch (e) {
        toast.error(DismissibleToast('Failed to retrieve company data. SSO has been disabled.'));
        return {};
    }
};

export const loginLoader = async (props) => {
    const companyAlias = window.localStorage.getItem(SSO_COMPANY_LOGIN_PAGE_KEY);

    if (companyAlias) {
        const url = new URL(props.request.url);
        url.pathname = `/login/company/${companyAlias}/`;
        store.dispatch(navigateAction({ url: url.toString(), options: { replace: true } }));
    }
    return null;
};
