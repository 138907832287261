import { ReactNodeLike } from 'prop-types';
import { FC } from 'react';

import { NoAccessDialog } from 'src/components/ratecardproposals/dialogs/NoAccessDialog';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';

interface Props {
    children: ReactNodeLike;
    dialog: boolean;
}

export const ProposalAccessChecker: FC<Props> = ({ children, dialog }: Props) => {
    const { ratecardProjectQuery } = useRatecardProjectQuery();

    if (!ratecardProjectQuery?.ratecardProjectProposals) {
        return dialog ? <NoAccessDialog /> : null;
    }

    const { editMode, state } = ratecardProjectQuery.ratecardProjectProposals;
    if (editMode) {
        return dialog ? <NoAccessDialog edit /> : null;
    }
    if (!['new', 'accepted', 'sent', 'paused', 'cancelled', 'completed'].includes(state)) {
        return dialog ? <NoAccessDialog /> : null;
    }

    return <>{children}</>;
};
