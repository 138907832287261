import { ApaduaAPI } from './ApaduaAPI';

export class ProposalAPI extends ApaduaAPI {
    resource: string = 'api/proposal/';

    getProposal(id: string): any {
        return this.get(`${id}/`);
    }

    cancelProposalParticipation(payload: any, id: any): any {
        return this.post(`${id}/cancel_participation/`, payload);
    }
}
