import { ReactNode } from 'react';

import { Typography } from '@mui/material';

interface Props {
    errorMessage: ReactNode;
}

const ErrorMessage = ({ errorMessage }: Props) => (
    <Typography variant="body1" color="error">
        {errorMessage}
    </Typography>
);

export default ErrorMessage;
