import React from 'react';

import { useLingui } from '@lingui/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, GridProps, IconButton, Typography } from '@mui/material';

import { useTheme } from 'src/theme';

import ConfirmationModal from '../confirmationModal';

export interface ViewWithSuggestionProps<T> extends GridProps {
    entry: T;
    suggestion: T;
    loading: boolean;
    suggestionView: React.ReactNode;
    showSuggestions?: boolean;
    onApply: (suggestion: T, item: T) => void;
    applyDirectly: boolean;
}

const ApplyButton = ({
    onApply,
    applyDirectly,
    disabled,
}: {
    onApply: () => void;
    applyDirectly: boolean;
    disabled?: boolean;
}) => {
    const { i18n } = useLingui();
    const theme = useTheme();

    const applyButton = ({ handleOpen }) => (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <IconButton
                onClick={applyDirectly ? onApply : handleOpen}
                sx={{
                    margin: 1,
                    height: 48,
                    borderRadius: 48,
                    color: theme.palette.info.contrastText,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.grey[200],
                        color: theme.palette.primary.dark,
                    },
                    '&:disabled': {
                        backgroundColor: theme.palette.grey[200],
                        color: theme.palette.primary.dark,
                        cursor: 'not-allowed',
                    },
                }}
                disabled={disabled}
            >
                <ArrowBackIosIcon
                    sx={{
                        marginLeft: 1,
                    }}
                />
            </IconButton>
            <Typography>{i18n._('Apply')}</Typography>
        </Grid>
    );

    if (applyDirectly) {
        return React.createElement(applyButton);
    }

    return (
        <Grid container alignItems="center" alignSelf="center" flexDirection="column" height="100%">
            <ConfirmationModal
                triggerButtonComponent={applyButton}
                onConfirm={onApply}
                description={i18n._(
                    'Are you sure you want to apply this suggestion? Your current changes will be lost!',
                )}
                confirmButtonText={i18n._('Confirm')}
                cancelButtonText={i18n._('Cancel')}
            />
        </Grid>
    );
};

const ViewWithSuggestion = <T,>({
    loading,
    entry,
    suggestion,
    suggestionView,
    onApply,
    applyDirectly,
    showSuggestions = true,
    children,
}: ViewWithSuggestionProps<T>) => {
    const { i18n } = useLingui();

    const handleApply = () => onApply(suggestion, entry);

    return (
        <Grid container height="100%" sx={{ mb: 1 }}>
            <Grid item xs={showSuggestions ? 6 : 12}>
                {children}
            </Grid>
            {showSuggestions && (
                <>
                    <Grid item xs={1} height="100%">
                        {!loading && (
                            <ApplyButton
                                disabled={!suggestion}
                                onApply={handleApply}
                                applyDirectly={applyDirectly}
                            />
                        )}
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container height="100%">
                            <Grid item xs minHeight="100%">
                                {suggestionView}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ViewWithSuggestion;
