import { FC } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';

interface Props {
    type: string;
    step: number;
}

export const StepIcon: FC<Props> = (props: Props) => {
    const { type, step } = props;

    const color = {
        info: 'primary.main',
        success: '#4caf50',
        error: '#f44336',
    };

    const special = {
        info: 'i',
        success: <DoneIcon fontSize="small" />,
        error: '!',
    };

    const size = {
        info: '14px',
        error: '14px',
    };

    return (
        <Box
            borderRadius="50%"
            width={24}
            height={24}
            fontWeight={900}
            fontSize={size[type] || '12px'}
            bgcolor={color[type] || 'primary.main'}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {special[type] || step}
        </Box>
    );
};
