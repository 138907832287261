export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    APADUA: 'APADUA',
    NATURE: 'NATURE',
};

export const mainSidebarWidth = 230;
export const stepperWidth = 260;

export const stepperHeightForTop = 155;

export const referenceSidebarWidth = 172;
export const referenceTopBarMinHeight = 64;
export const referenceTopBarMinHeightOnSmallScreen = 200;

export const supportedFileTypes = [
    '.doc',
    '.docx', // word
    '.xls',
    '.xlsx', // excel
    '.ppt',
    '.pptx', // power point
    '.pdf',
    '.odt',
    '.ods',
    '.odp', // open docs
    '.rtf',
    '.txt',
    '.csv',
    '.xps', // less common documents
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.tif',
    '.tiff',
    '.bmp',
    '.heic', // images
    '.zip',
    '.rar', // archives
];

// used for DelayedRender in skeletons
export const renderDelay = 500;

// interval used for refreshing the notifications query: 1 minute
export const refreshInterval = 60000;

// how many notifications we want to fetch initially
export const notificationsLimit = 15;

// number of notifications loaded with pagination
export const notificationsPaginationLimit = 10;
