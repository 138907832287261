// actions.js
import { createAction } from '@reduxjs/toolkit';

import { NavigatePayload } from 'src/types/navigation';

export const navigateAction = createAction<NavigatePayload>('NAVIGATE_ACTION');

export const submitOTPAction = createAction<any>('SUBMIT_OTP');

export const loginAction = createAction<any>('LOGIN_ACTION');
export const resendOTPAction = createAction('RESEND_OTP');

export const updateVendorsAction = createAction<any>('UPDATE_VENDORS');
