import React from 'react';

import { Box, useTheme } from '@mui/material';

interface Props {
    amount: string | number;
}

const HighlightedFeeAmount = ({ amount }: Props) => {
    const theme = useTheme();

    return (
        <Box
            component="span"
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                display: 'inline-block',
                px: 1,
                borderRadius: 1,
            }}
        >
            <b>{`${amount}`}</b>
        </Box>
    );
};

export default HighlightedFeeAmount;
