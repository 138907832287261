export const getNotificationHeading = (notification: any): string => {
    const type = notification?.notificationType;
    const context = notification?.context;

    switch (type) {
        case 'NEWQUESTIONS':
            return context?.projectTitle;

        case 'NEWANSWERSTOQUESTIONS':
            return `${context?.clientCompanyAlias} | ${context?.projectTitle}`;

        case 'NEWANNOUNCEMENT':
            return `${context?.clientCompanyAlias} | ${context?.projectTitle}`;

        case 'NEWCLIENTCALLBACKWASPOSTED':
            return `${context?.supplierCompanyAlias} | ${context?.projectTitle}`;

        case 'PARTICIPATIONCANCELLEDBYVENDOR':
            return `${context?.supplierCompanyAlias} | ${context?.projectName}`;

        case 'NEWSUPPORTQUESTION':
            return context?.userCompanyAlias;

        case 'PROPOSALINVITATION':
        case 'REMINDERFORINVITETORFP':
        case 'REMINDERFORCONFIRMATIONOFRRFP':
        case 'USERASSIGNEDTORFP':
        case 'INVITETOCONCEPTEVALUATION':
        case 'INVITETOPITCHEVALUATION':
        case 'EVALUATIONREMINDER':
        case 'PITCHEVALUATIONREMINDER':
        case 'PITCHINVITATION':
        case 'NEWPROJECTREQUEST':
        case 'NEWPROPOSALSUBMITTEDRFP':
        case 'PROPOSALAWARDINGTOSUPPLIER':
        case 'PROPOSALAWARDINGCLIENTINTERNAL':
        case 'PROPOSALCANCELLATIONBYSUPPLIER':
        case 'REQUESTNEWPROPOSAL':
        case 'NEWPROJECTSTATUS':
        case 'PROJECTAWARDINGDUE':
        case 'PROJECTAWARDINGDUEDATEREMINDER':
        case 'SUPPLIERDISMISSEDFROMRFP':
        case 'PROPOSALLOSTMESSAGETOSUPPLIER':
        case 'NEWPROJECTCREATEDRFP':
        case 'NEWPROJECTSUBMITTEDRFP':
        case 'SUPPLIERREJECTSTNCS':
        case 'SUPPLIERCONFIRMSTNCS':
        case 'PITCHINVITATIONACCEPTED':
        case 'PITCHINVITATIONDECLINED':
        case 'TIMELINECHANGE':
        case 'CONDITIONSACCEPTED':
        case 'REMINDERFORPROPOSALDUEDATE':
        case 'PROPOSALPARTICIPATIONCONFIRMATION':
        case 'SUCCESSFULPROPOSALSUBMISSION':
            return `${context?.clientCompanyAlias} | ${context?.projectId} ${
                context?.projectName || ''
            }`;

        case 'INVITETORFQ':
        case 'REMINDERFORINVITETORFQ':
        case 'REMINDERFORCONFIRMATIONOFRFQ':
        case 'USERASSIGNEDTORFQ':
        case 'NEWPROPOSALSUBMITTEDRFQ':
        case 'NEWRATESSUBMITTED':
        case 'RATESCONFIRMED':
        case 'RATESREJECTED':
        case 'RATEREVISIONREQUESTED':
        case 'PROPOSALREVISIONREQUESTED':
        case 'PROPOSALACCEPTED':
        case 'PROPOSALDELETED':
        case 'PROPOSALDISMISSED':
        case 'PROPOSALDUEDATEREMINDER':
        case 'PROPOSALAWARDINGDUE':
        case 'PROPOSALAWARDINGDUEDATEREMINDER':
        case 'NEWPROJECTCREATEDRFQ':
        case 'NEWPROJECTSUBMITTEDRFQ':
        case 'PROJECTINVITATIONACCEPTEDRFQ':
        case 'NEWPROJECTSTATUSRFQ':
        case 'EDITMODEREMINDER':
        case 'SUCCESSFULRATECARDPROPOSALSUBMISSION':
            return `${context?.clientCompanyAlias} | ${context?.projectName}`;

        case 'DEPRECATED':
        case 'DEPRECATED_72':
        case 'DEPRECATED_77':
        case 'DEPRECATED_80':
            return `${context?.verb}`;

        default:
            return 'Default heading';
    }
};
