import { createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface QuoteProposalContextType {
    quoteProposalQueryRef: PreloadedQuery | null;
    refreshQuoteProposalQuery: () => void;
}

const initialValues: QuoteProposalContextType = {
    quoteProposalQueryRef: null,
    refreshQuoteProposalQuery: () => {},
};

export const QuoteProposalContext = createContext(initialValues);
