import { createContext } from 'react';

interface SessionProps {
    dialogOpen: boolean;
    setDialogOpen: CallableFunction;
    setSessionExpired: CallableFunction;
}

const initialValues: SessionProps = {
    dialogOpen: false,
    setDialogOpen: () => {},
    setSessionExpired: () => {},
};

export const SessionContext = createContext(initialValues);
