import { AxiosError } from 'axios';

import { ProjectExtraVendorPayload } from 'src/project/types';

import { ApaduaAPI } from './ApaduaAPI';

export class ProjectExtraVendorAPI extends ApaduaAPI {
    resource: string = 'api/project/';

    createProjectExtraVendor(payload: ProjectExtraVendorPayload, projectId: string): any {
        return this.post(`${projectId}/extra-vendors/`, payload);
    }

    // eslint-disable-next-line
    handleError(error: AxiosError): any {
        const errors = error.response.data;
        let errorMessage = '';
        errors.forEach((err, index) => {
            const { detail, source } = err;
            const { pointer } = source;
            const field = pointer.split('/').pop();
            errorMessage += `Error ${index + 1}: ${detail} [${field}] \n`;
        });
        throw Error(errorMessage);
    }
}
