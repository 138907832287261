import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DelayedRender } from 'src/components/DelayedRender';

const StyledTypography = styled(Typography)({
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: 'textPrimary',
    align: 'center',
});

export const RatecardSuppliersSidebarSkeleton: FC = () => {
    const { i18n } = useLingui();

    return (
        <DelayedRender>
            <Box sx={{ mt: 2, px: 2 }}>
                <StyledTypography>{i18n._('Suppliers')}</StyledTypography>

                <Typography color="textPrimary" variant="h3" align="center" noWrap>
                    <Skeleton />
                </Typography>

                <Typography color="textPrimary" variant="h3" align="center" noWrap>
                    <Skeleton />
                </Typography>

                <Typography color="textPrimary" variant="h3" align="center" noWrap>
                    <Skeleton />
                </Typography>
            </Box>
        </DelayedRender>
    );
};
