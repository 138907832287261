/* eslint-disable */
import { FieldProps } from 'formik';
import * as React from 'react';
import invariant from 'tiny-warning';

import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';

export interface AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends FieldProps,
        Omit<
            MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
            'name' | 'value' | 'defaultValue'
        > {
    type?: string;
}

export function fieldToAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    disabled,
    field,
    form: { isSubmitting, setFieldValue, setFieldTouched },
    type,
    onChange,
    onBlur,
    freeSolo,
    ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): MuiAutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
> {
    if (process.env.NODE_ENV !== 'production') {
        if (props.multiple) {
            invariant(
                Array.isArray(field.value),
                `value for ${field.name} is not an array, this can caused unexpected behaviour`,
            );
        }
    }
    const {
        onChange: _onChange,
        onBlur: _onBlur,
        multiple: _multiple,
        ...fieldSubselection
    } = field;
    return {
        freeSolo,
        onBlur:
            onBlur ??
            function (event) {
                field.onBlur(event ?? field.name);
                setFieldTouched(field.name);
            },
        onChange:
            onChange ??
            function (_event, value) {
                setFieldTouched(field.name);
                setFieldValue(field.name, value, true);
            },
        disabled: disabled ?? isSubmitting,
        loading: isSubmitting,
        ...fieldSubselection,
        ...props,
    };
}

export function Autocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    return <MuiAutocomplete {...fieldToAutocomplete(props)} />;
}

Autocomplete.displayName = 'FormikMaterialUIAutocomplete';
