import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-relay/hooks';
import { Link } from 'react-router-dom';

import { Notifications } from '@atlaskit/atlassian-navigation';
import Badge from '@atlaskit/badge';
import Popup from '@atlaskit/popup';
import { useLingui } from '@lingui/react';
import { Box, Button, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { NotificationElement } from 'src/components/notifications/popup/NotificationElement';
import { NotificationContext } from 'src/contexts/NotificationContext';
import { useMobile } from 'src/hooks/useMobile';
import 'src/styles/notification/notificationPopup.css';
import useErrorMessage from 'src/translations/hooks/useErrorMessage';
import DismissibleToast from 'src/utils/DismissibleToast';
import { getNotificationCategories } from 'src/utils/notification';

const NotificationsFlexBox = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 500,
    height: '80vh',

    '&.mobile': {
        width: '90vw',
    },
}));

const PaddingTopBox = styled('div')(({ theme }) => ({
    paddingTop: '6px',

    // change default blue color of atlaskit
    '--ds-text-mediumEmphasis': theme.palette.text.primary,
    '--ds-text-selected': theme.palette.text.primary,
    '--ds-text-subtle': theme.palette.text.primary,
    '--ds-background-brand-bold': theme.palette.background.default,
    '--ds-background-brand-bold-pressed': theme.palette.background.default,
    '--ds-background-subtleNeutral-pressed': theme.palette.background.default,
    '--ds-background-subtleNeutral-hover': theme.palette.background.default,
}));

const TitleBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    borderBottom: '1px solid #ebecf0',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const ActionBox = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
});

const StyledLink = styled(Link)(() => ({
    textDecoration: 'none !important',
    '&.noPoiterEvents': {
        pointerEvents: 'none',
    },
}));

const StyledBadgeText = styled('span')(() => ({
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
}));

const TimeSectionsBox = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(-4.25),
    },
    width: '100%',
}));

const TimeSectionHeaderTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

export const NotificationPopupComponent: FC = () => {
    const { i18n } = useLingui();
    const isMobile = useMobile();
    const extractErrorMessage = useErrorMessage();

    const {
        notifications,
        unreadNotifications,
        unreadNotificationsCount,
        setUnreadNotificationsCount,
    } = useContext(NotificationContext);

    const [popupOpen, setPopupOpen] = useState(false);
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);
    const [allNotifications, setAllNotifications] = useState(notifications);

    useEffect(() => {
        setAllNotifications(showOnlyUnread ? unreadNotifications : notifications);
    }, [showOnlyUnread, notifications, unreadNotifications]);

    const noUnreadNotifications = !allNotifications?.some((notification) => notification.unread);

    const {
        todaysNotifications,
        yesterdaysNotifications,
        lastWeeksNotifications,
        olderNotifications,
    } = getNotificationCategories(allNotifications);

    const notificationsToShow =
        todaysNotifications.length > 0 ||
        yesterdaysNotifications.length > 0 ||
        lastWeeksNotifications.length > 0 ||
        olderNotifications.length > 0;

    const onOpen = () => {
        setPopupOpen(!popupOpen);
    };

    const onClose = () => {
        setPopupOpen(false);
    };

    const toggleUnreadSwitch = (event) => {
        setShowOnlyUnread(event.target.checked);
    };

    const [markAllNotificationsAsRead] = useMutation(graphql`
        mutation NotificationPopupComponentMarkAllAsReadMutation {
            markAllNotificationsAsRead(input: {}) {
                notifications {
                    id
                    notificationType
                    created
                    unread
                    context
                }
            }
        }
    `);

    const markAllAsRead = () => {
        markAllNotificationsAsRead({
            variables: {},
            onCompleted(completedData, errors) {
                if (errors) {
                    toast.error(
                        DismissibleToast(
                            extractErrorMessage(errors, 'cannotMarkAllNotificationsAsRead'),
                        ),
                    );
                } else {
                    setShowOnlyUnread(false);
                    toast.success(
                        DismissibleToast(i18n._('All notifications were marked as read')),
                    );
                    setUnreadNotificationsCount(0);
                }
            },
            onError() {
                toast.error(DismissibleToast(i18n._('Connection error')));
            },
        });
    };

    const NotificationsContent = () => (
        <NotificationsFlexBox className={isMobile && 'mobile'}>
            <TitleBox>
                <Typography variant="h6" color="primary">
                    {i18n._('Notifications')}
                </Typography>
                <Typography variant="caption" color="secondary">
                    {i18n._('Only show unread')}
                    <Switch
                        size="small"
                        checked={showOnlyUnread}
                        onChange={toggleUnreadSwitch}
                        disabled={noUnreadNotifications}
                    />
                </Typography>
            </TitleBox>
            {notificationsToShow ? (
                <>
                    <ActionBox>
                        <Button
                            size="small"
                            onClick={markAllAsRead}
                            disabled={noUnreadNotifications}
                        >
                            {i18n._('Mark all as read')}
                        </Button>
                    </ActionBox>

                    <TimeSectionsBox>
                        {/* today's notifications */}
                        {todaysNotifications.length > 0 && (
                            <>
                                <Box>
                                    <TimeSectionHeaderTypography
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        <b>{i18n._('Today').toUpperCase()}</b>
                                    </TimeSectionHeaderTypography>
                                </Box>
                                {todaysNotifications?.map((notification) => (
                                    <Box width="100%" key={notification?.id}>
                                        <NotificationElement
                                            notification={notification}
                                            closePopup={onClose}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}

                        {/* yesterday's notifications */}
                        {yesterdaysNotifications.length > 0 && (
                            <>
                                <TimeSectionHeaderTypography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    <b>{i18n._('Yesterday').toUpperCase()}</b>
                                </TimeSectionHeaderTypography>
                                {yesterdaysNotifications?.map((notification) => (
                                    <Box width="100%" key={notification?.id}>
                                        <NotificationElement
                                            notification={notification}
                                            closePopup={onClose}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}

                        {/* last week's notifications */}
                        {lastWeeksNotifications.length > 0 && (
                            <>
                                <TimeSectionHeaderTypography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    <b>{i18n._('Last week').toUpperCase()}</b>
                                </TimeSectionHeaderTypography>
                                {lastWeeksNotifications?.map((notification) => (
                                    <Box width="100%" key={notification?.id}>
                                        <NotificationElement
                                            notification={notification}
                                            closePopup={onClose}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}

                        {/* older notifications */}
                        {olderNotifications.length > 0 && (
                            <>
                                <TimeSectionHeaderTypography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    <b>{i18n._('Older').toUpperCase()}</b>
                                </TimeSectionHeaderTypography>
                                {olderNotifications?.map((notification) => (
                                    <Box width="100%" key={notification?.id}>
                                        <NotificationElement
                                            notification={notification}
                                            closePopup={onClose}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}
                    </TimeSectionsBox>
                </>
            ) : (
                <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    sx={{ mt: 1, fontStyle: 'italic' }}
                >
                    {i18n._('No notifications to display')}
                </Typography>
            )}
            <StyledLink to="/dashboard/notifications/all">
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ m: 2 }}
                    data-testid="seeAllNotifications"
                >
                    {i18n._('See all notifications')}
                </Button>
            </StyledLink>
        </NotificationsFlexBox>
    );

    const NotificationsBadge = () =>
        !!unreadNotificationsCount && (
            <Badge>
                <StyledBadgeText>{unreadNotificationsCount}</StyledBadgeText>
            </Badge>
        );

    return (
        <Popup
            placement="bottom-start"
            content={NotificationsContent}
            isOpen={popupOpen}
            onClose={onClose}
            trigger={(triggerProps) => (
                <PaddingTopBox>
                    <Notifications
                        badge={NotificationsBadge}
                        onClick={onOpen}
                        tooltip={null}
                        isSelected={popupOpen}
                        testId="notificationsIcon"
                        {...triggerProps}
                    />
                </PaddingTopBox>
            )}
        />
    );
};
