import { FC, useState } from 'react';

import { SearchContext } from 'src/explore/contexts/SearchContext';
import { SearchContextInitializer } from 'src/explore/providers/SearchContextInitializer';

interface Props {
    children: any;
}

export const SearchContextProvider: FC<Props> = ({ children }: Props) => {
    const [currentSearch, setCurrentSearch] = useState(null);

    return (
        <SearchContext.Provider
            value={{
                currentSearch,
                setCurrentSearch,
            }}
        >
            <SearchContextInitializer>{children}</SearchContextInitializer>
        </SearchContext.Provider>
    );
};
