import { useEffect, useState } from 'react';

export const getStorageValue = <T>(key: string, defaultValue: T): T => {
    try {
        const saved = localStorage.getItem(key);
        const initial = JSON.parse(saved) as T;
        return initial || defaultValue;
    } catch (e) {
        console.error(`Could not retrieve item with key:${key} from localstorage`);
        return defaultValue;
    }
};

const usePersistToLocalStorage = <T>(
    key: string,
    defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() => getStorageValue<T>(key, defaultValue));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

export default usePersistToLocalStorage;
