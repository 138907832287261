import React from 'react';
import { useNavigate } from 'react-router-dom';

import { i18n } from '@lingui/core';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Box,
    Checkbox,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    tableRowClasses,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import { SearchContext } from 'src/explore/contexts/SearchContext';
import { getOptionKey } from 'src/explore/dashboard/CustomResultView';
import { toRelayId } from 'src/relay/utils';
import { emToB, mergeSnippetArray } from 'src/utils/emUtils';

import { descriptionDisplayStyle } from './exploreTableHead';
import type { RowId, RowInterface } from './types';

interface ExploreTableRowProps {
    row: RowInterface;
    actions?: React.ReactNode;
    chips?: React.ReactNode;
    onSelect: (rowId: RowId) => void;
    isSelected?: boolean;
    queryParam: string | null;
}

export const style = {
    textOverflow: 'ellipsis',
    width: '100%',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
    overflow: 'hidden',
};

const ROW_HEIGHT = 96;
const MIN_WIDTH_NAME_CELL = '180px';
const MIN_WIDTH_COMPANY_SIZE = '100px';
const MIN_WIDTH_FOCUS_TOPICS_MD = '180px';
const MIN_WIDTH_FOCUS_TOPICS_LG = '260px';

const StyledTableRow = styled(TableRow)(() => ({
    [`&.${tableRowClasses.root}`]: {
        height: `${ROW_HEIGHT}px`,
        maxHeight: `${ROW_HEIGHT}px`,
        overflow: 'auto',
    },

    '& .table-row-name-column': {
        display: 'flex',
        position: 'relative',
        height: 'inherit',
        width: 200,
    },
    '& .table-row-actions': {
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'none',
    },
    '&:hover .table-row-actions': {
        display: 'block',
    },
    '& .table-row-chips': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '24px',
        width: '320px',
        overflow: 'hidden',
        textAlign: 'right',
    },
    height: 1,
}));

const ExploreTableRow = ({
    row,
    actions,
    chips,
    onSelect,
    isSelected,
    queryParam,
}: ExploreTableRowProps) => {
    const { setCurrentSearch } = React.useContext(SearchContext);
    const navigate = useNavigate();
    const { id, logo, name } = row;
    const focusTopics = mergeSnippetArray(row?.ln_company_specialities_en).join(', ');

    const computeCompanyProfileUrl = (companyId: string, search: string): string =>
        search ? `${companyId}/about?search=${search}` : `${companyId}/about`;

    const onCheckboxClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        onSelect(id);
    };
    const handleRowClick = () => {
        setCurrentSearch(`${window.location.pathname}${window.location.search}`);
        const redirectURL = computeCompanyProfileUrl(toRelayId('CompanyNode', id?.raw), queryParam);
        navigate(redirectURL);
    };
    const handleRowClickNewTab = (event) => {
        setCurrentSearch(`${window.location.pathname}${window.location.search}`);
        const redirectURL = computeCompanyProfileUrl(toRelayId('CompanyNode', id?.raw), queryParam);
        window.open(`${window.location.pathname}/${redirectURL}`);
        event.stopPropagation();
    };

    const computeDescription = () => {
        const { locale: language } = i18n;
        const descriptionKey = language === 'de' ? 'description_de' : 'description_en';
        const { snippet } = row[descriptionKey];

        if (!snippet) return row[descriptionKey]?.raw;
        return snippet.reduce(
            (accumulator, currentString) => accumulator + emToB(currentString),
            '',
        );
    };

    return (
        <StyledTableRow
            onClick={handleRowClick}
            sx={(theme) => ({
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': {
                    cursor: 'pointer',
                    boxShadow: `0 0 10px ${theme.palette.grey[500]}`,
                },
            })}
        >
            <TableCell width="48px" padding="checkbox">
                <Checkbox onClick={onCheckboxClick} checked={isSelected} />
            </TableCell>
            <TableCell>
                <CustomCompanyIcon
                    company={{
                        companyId: id.raw,
                        companyName: name?.raw,
                        logo: logo?.raw,
                    }}
                />
            </TableCell>
            <TableCell
                style={{
                    borderRight: `1px solid ${grey[300]}`,
                    height: 'inherit',
                    padding: 8,
                }}
            >
                <Box className="table-row-name-column">
                    <Box className="table-row-actions">
                        {actions}
                        <IconButton onClick={handleRowClickNewTab} size="small">
                            <LaunchIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Grid
                        container
                        flexDirection="column"
                        justifyContent="center"
                        sx={{ height: '100%' }}
                    >
                        <Typography
                            sx={{
                                ...style,
                                '-webkit-line-clamp': '2',
                                minWidth: MIN_WIDTH_NAME_CELL,
                            }}
                        >
                            {name?.raw}
                        </Typography>
                    </Grid>
                    <Box className="table-row-chips">{chips}</Box>
                </Box>
            </TableCell>
            <TableCell>
                <Typography
                    sx={{
                        minWidth: MIN_WIDTH_COMPANY_SIZE,
                    }}
                >
                    {getOptionKey(row, 'ln_company_size_employees')}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography
                    sx={{
                        ...style,
                        minWidth: {
                            md: MIN_WIDTH_FOCUS_TOPICS_MD,
                            lg: MIN_WIDTH_FOCUS_TOPICS_LG,
                        },
                    }}
                    dangerouslySetInnerHTML={{
                        __html: focusTopics,
                    }}
                />
            </TableCell>
            <TableCell sx={{ display: descriptionDisplayStyle }}>
                <Typography
                    sx={style}
                    dangerouslySetInnerHTML={{
                        __html: computeDescription(),
                    }}
                />
            </TableCell>
        </StyledTableRow>
    );
};

export default ExploreTableRow;
