import toast from 'react-hot-toast';
import { call, put, takeEvery } from 'redux-saga/effects';

import { i18n } from '@lingui/core';

import { LoginAPI, sanitizeResendOTPResponse, serializeResendOTPPayload } from 'src/apis/loginAPI';
import { resendOTPSuccess, setLoginError, setLoginLoading } from 'src/login/store/reducer';
import DismissibleToast from 'src/utils/DismissibleToast';

import { navigateAction, resendOTPAction } from './actions';

function* resendOTPSaga() {
    try {
        yield put({
            type: setLoginLoading.type,
            payload: true,
        });

        const api = new LoginAPI();
        const searchParams = new URLSearchParams(window.location.search);
        const otpToken = searchParams.get('jwtOtpToken');

        const requestPayload = serializeResendOTPPayload({ otpToken });
        const resendOTPResponse = yield call(api.resendOTP, requestPayload);

        if (resendOTPResponse) {
            const sanitizedResponse = sanitizeResendOTPResponse(resendOTPResponse);

            const queryParams = new URLSearchParams({ jwtOtpToken: sanitizedResponse.otpToken });
            const path = `/login/otp-validation?${queryParams.toString()}`;

            yield put({
                type: navigateAction.type,
                payload: { path },
            });

            yield put({
                type: resendOTPSuccess.type,
            });
            toast.success(DismissibleToast(i18n.t('OTP has been resent!')));
        }
    } catch (e) {
        toast.error(DismissibleToast(i18n.t('Something went wrong. Please contact an admin.')));
        if (e.status === 400) {
            yield put({
                type: setLoginError.type,
                payload: i18n.t('Something went wrong, please try again.'),
            });
        }
    } finally {
        yield put({
            type: setLoginLoading.type,
            payload: false,
        });
    }
}

export function* watchResendOTPAction() {
    yield takeEvery(resendOTPAction.type, resendOTPSaga);
}
