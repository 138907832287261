import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Box } from '@mui/material';

import NavSection from 'src/components/NavSection';
import CustomCompanyIcon from 'src/components/dashboard/CustomCompanyIcon';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';

export const RatecardSuppliersSidebar: FC = () => {
    const { i18n } = useLingui();
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const location = useLocation();

    const sections = [
        {
            title: i18n._('Suppliers'),
            items: ratecardProjectQuery.ratecardProject.proposals.edges.map(({ node }) => ({
                title: node.company.companyName,
                path: `/dashboard/ratecards/${ratecardProjectQuery.ratecardProject.id}/evaluation/suppliers/${node.id}/`,
                icon: <CustomCompanyIcon company={node.company} />,
            })),
        },
    ];

    return (
        <Box sx={{ mt: 2, px: 2 }}>
            {sections.map((section) => (
                <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                        '& + &': {
                            mt: 3,
                        },
                    }}
                    {...section}
                />
            ))}
        </Box>
    );
};
