import axios, { AxiosResponse } from 'axios';

import * as Sentry from '@sentry/react';

import { logoutUserSessionExpired } from 'src/utils/login';

export interface APIHeaders {
    [key: string]: string;
}

export default class HttpAPI {
    api: any;

    resource: string = '';

    constructor(BASE_URL: string) {
        const api = axios.create({
            responseEncoding: 'utf8',
            baseURL: BASE_URL,
        });

        api.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error) => {
                if (error?.response?.status === 429) {
                    // Handle rate limiting
                } else if (error?.response?.status === 401) {
                    return logoutUserSessionExpired();
                }
                Sentry.captureException(error);
                return Promise.reject(error);
            },
        );

        this.api = api;
    }

    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        return {};
    }

    // eslint-disable-next-line
    handleResponse(response: AxiosResponse) {
        if (response.status >= 200 && response.status <= 299) {
            return response.data;
        }
    }

    // eslint-disable-next-line
    handleError(error: Error): void {
        throw new Error(error.message);
    }

    async request<T>(method: string, url: string, data: any, config?: any): Promise<T> {
        const requestURL = this.resource + url;
        const requestConfig = {
            ...config,
            method,
            url: requestURL,
            data,
            headers: {
                ...this.getHeaders(),
                ...(config?.headers ?? {}),
            },
        };
        const responsePromise = this.api.request(requestConfig);
        return responsePromise
            .then((response) => this.handleResponse(response))
            .catch((err) => this.handleError(err));
    }

    get<T>(url: string, config?: any): Promise<T> {
        return this.request('GET', url, null, config);
    }

    post(url: string, data: any, config?: any): Promise<any> {
        return this.request('POST', url, data, config);
    }

    put(url: string, data: any, config?: any): Promise<Response> {
        return this.request('PUT', url, data, config);
    }

    patch(url: string, data: any, config?: any): Promise<Response> {
        return this.request('PATCH', url, data, config);
    }

    delete(url: string, config?: any): Promise<Response> {
        return this.request('DELETE', url, null, config);
    }
}
