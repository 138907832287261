import { useEffect } from 'react';

import { useTour } from '@reactour/tour';

const useCancelTourOnUnmount = () => {
    const { setSteps } = useTour();
    useEffect(
        () => () => {
            setSteps([]);
        },
        [],
    );
};

export default useCancelTourOnUnmount;
