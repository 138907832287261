import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { ProjectVendorsFormData, RawContact } from '../types';
import {
    sanitizeContact,
    sanitizeProjectVendors,
    sanitizeValidatedVendorContactInformation,
} from './sanitizers';
import {
    ProjectExtraVendorFetcher,
    ProjectVendorsFetcher,
    createProjectVendorContactAsync,
    patchProjectVendorContactAsync,
    updateProjectVendorContactAsync,
    updateProjectVendorsAsync,
    validateAndAutocompleteProjectVendorContactAsync,
} from './thunk';

const getUpdatedVendorsState = (
    vendors: ProjectVendorsFormData,
    newContact: RawContact,
): ProjectVendorsFormData => {
    const updatedVendors = vendors;
    updatedVendors.companies = updatedVendors.companies.map((v) =>
        v.id !== newContact.company
            ? v
            : {
                  ...v,
                  contact: sanitizeContact(newContact),
              },
    );
    updatedVendors.isComplete = vendors.isComplete;

    return updatedVendors;
};

const InitialState = {
    ...fetcherInitialState,
    contactInformationUser: null,
};

export const projectVendorsSlice = createSlice({
    name: 'projectVendors',
    initialState: InitialState,
    reducers: {
        clearContactInformationUser: (state) => {
            state.contactInformationUser = null;
        },
    },
    extraReducers(builder) {
        ProjectVendorsFetcher.reducers(builder);
        ProjectExtraVendorFetcher.reducers(builder);
        builder.addCase(updateProjectVendorsAsync.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(updateProjectVendorsAsync.fulfilled, (state, action) => {
            state.data = sanitizeProjectVendors(action.payload);
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(updateProjectVendorsAsync.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(createProjectVendorContactAsync.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(createProjectVendorContactAsync.fulfilled, (state, action) => {
            state.data = getUpdatedVendorsState(state.data, action.payload);
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(createProjectVendorContactAsync.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(updateProjectVendorContactAsync.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(updateProjectVendorContactAsync.fulfilled, (state, action) => {
            state.data = getUpdatedVendorsState(state.data, action.payload);
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(updateProjectVendorContactAsync.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(patchProjectVendorContactAsync.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(patchProjectVendorContactAsync.fulfilled, (state, action) => {
            state.data = getUpdatedVendorsState(state.data, action.payload);
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(patchProjectVendorContactAsync.rejected, (state, action) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.error;
        });
        builder.addCase(validateAndAutocompleteProjectVendorContactAsync.pending, (state) => {
            state.loading = true;
            state.status = 'loading';
        });
        builder.addCase(
            validateAndAutocompleteProjectVendorContactAsync.fulfilled,
            (state, action) => {
                state.contactInformationUser = sanitizeValidatedVendorContactInformation(
                    action.payload,
                );
                state.loading = false;
                state.status = 'succeeded';
            },
        );
        builder.addCase(
            validateAndAutocompleteProjectVendorContactAsync.rejected,
            (state, action) => {
                state.loading = false;
                state.status = 'failed';
                state.error = action.error;
            },
        );
    },
});

export const { clearContactInformationUser } = projectVendorsSlice.actions;
