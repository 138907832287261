import { createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface RatecardProjectContextType {
    ratecardProjectQueryRef: PreloadedQuery | null;
    refreshRatecardProjectQuery: () => void;
}

const initialValues: RatecardProjectContextType = {
    ratecardProjectQueryRef: null,
    refreshRatecardProjectQuery: () => {},
};

export const RatecardProjectContext = createContext(initialValues);
