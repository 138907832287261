import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from 'src/redux/fetcher';

import { ProjectTimelineFormData } from '../types';
import { PatchProjectTimelineFetcher, ProjectTimelineFetcher } from './thunk';

export const ProjectTimelineDefaultvalue: Omit<ProjectTimelineFormData, 'id'> = Object.freeze({
    startDate: null,
    endDate: null,
    presentationEndDate: null,
    presentationStartDate: null,
    projectAwardDate: null,
    proposalDueDate: null,
    rfpReleaseDueDate: null,
    timelineEntries: [],
});

const initialState = { ...fetcherInitialState, data: ProjectTimelineDefaultvalue };

export const projectTimelineSlice = createSlice({
    name: 'projectTimeline',
    initialState,
    reducers: {},
    extraReducers(builder) {
        ProjectTimelineFetcher.reducers(builder);
        PatchProjectTimelineFetcher.reducers(builder);
    },
});
