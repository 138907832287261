import toast from 'react-hot-toast';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';

import Section from 'src/components/sections/section';
import ExplorePageLayout from 'src/explore/dashboard/ExplorePageLayout';
import { useSelectedVendors } from 'src/explore/dashboard/state/hooks';
import ExploreSelectionHeader from 'src/project/projectVendors/exploreSelectionHeader';
import RequestedVendors from 'src/project/projectVendors/requestedVendors';
import VendorsTable from 'src/project/projectVendors/vendorsTable';
import { useProjectVendorsSelector, useUpdateProjectVendorsAsync } from 'src/project/state/hook';
import useProjectActions from 'src/project/useProjectActions';
import ProjectFooter from 'src/projectBrief/projectFooter';
import { useProjectVendorsTour } from 'src/tours/projectVendorsTour';
import DismissibleToast from 'src/utils/DismissibleToast';

const AddToProjectWidget = () => {
    const { i18n } = useLingui();
    const projectVendors = useProjectVendorsSelector();
    const selectedVendors = useSelectedVendors();
    const updateProjectVendors = useUpdateProjectVendorsAsync();
    const addVendorToProject = () => {
        if (selectedVendors.length === 0) {
            toast.error(DismissibleToast(i18n._('No vendors were selected')));
            return;
        }
        updateProjectVendors([
            ...(projectVendors?.companies?.map((v) => v.id) ?? []),
            ...selectedVendors,
        ]);
    };

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={selectedVendors.length === 0}
            startIcon={<AddIcon fontSize="small" />}
            style={{ fontWeight: 400 }}
            onClick={addVendorToProject}
        >
            <Trans>Add to project</Trans>
        </Button>
    );
};

const ProjectSupplierSelectionPage = () => {
    useProjectVendorsTour();
    const actions = useProjectActions({ withChecklist: true });

    return (
        <Grid container display="flex" flexDirection="column">
            <Section
                title={<Trans>Selected project vendors</Trans>}
                actions={actions}
                divider
                data-testid="projectSelectedVendors"
            >
                <RequestedVendors />
                <VendorsTable />
            </Section>
            <Section title={<ExploreSelectionHeader />}>
                <ExplorePageLayout widgets={<AddToProjectWidget />} />
            </Section>
            <ProjectFooter />
        </Grid>
    );
};

export default ProjectSupplierSelectionPage;
