const addSlopToKeyword = (keyword: string, slopAmount: number) => `${keyword}~${slopAmount}`;
const addFuzzinessToKeyword = (keyword: string, fuzzinessAmount: number, minLength: number = 5) => {
    if (keyword.length < minLength) {
        return `${keyword}`;
    }

    return `${keyword}~${fuzzinessAmount}`;
};

const searchToQueryDSL = (searchText: string, slop: number = 0, fuzziness: number = 0): string => {
    const searchTerms = searchText.split(',').filter((st) => st !== '');
    searchTerms.forEach((st) => {
        if (st.trim() !== '') {
            if (st.trim().includes(' ')) {
                searchText = searchText.replace(
                    st,
                    slop ? addSlopToKeyword(`"${st.trim()}"`, slop) : `"${st.trim()}"`,
                );
            } else {
                searchText = searchText.replace(
                    st,
                    fuzziness ? addFuzzinessToKeyword(`${st.trim()}`, fuzziness) : `${st.trim()}`,
                );
            }
        }
    });

    return searchText.replaceAll(',', ' ').trim();
};

export { searchToQueryDSL };
