import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

interface RoleBasedGuardProps {
    client?: boolean;
    consultant?: boolean;
    noNavigate?: boolean;
    children: ReactNode;
}

const RoleBasedGuard: FC<RoleBasedGuardProps> = ({
    client,
    consultant,
    noNavigate,
    children,
}: RoleBasedGuardProps) => {
    const { user } = useAuth();

    // no role specified
    if (client === undefined && consultant === undefined) {
        if (user.isClient || user.isConsultant || user.isAgent) {
            return <Navigate to="/dashboard/rfp" replace />;
        }
    }

    const allowed = (client && user.isClient) || (consultant && user.isConsultant) || user.isAgent;

    if (!allowed && !noNavigate) {
        return <Navigate to="/" replace />;
    }

    return <>{allowed && children}</>;
};

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
};

export default RoleBasedGuard;
