/**
 * @generated SignedSource<<f6e41c784605f409b5417eda87f4bbf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuoteProposalProviderClientQuery$variables = {
  quoteProposalId: string;
};
export type QuoteProposalProviderClientQuery$data = {
  readonly sourceProposal: {
    readonly id: string;
    readonly quote: {
      readonly id: string;
      readonly projectName: string | null;
    };
  } | null;
};
export type QuoteProposalProviderClientQuery = {
  response: QuoteProposalProviderClientQuery$data;
  variables: QuoteProposalProviderClientQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "quoteProposalId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "quoteProposalId",
        "variableName": "quoteProposalId"
      }
    ],
    "concreteType": "QuoteProposalNode",
    "kind": "LinkedField",
    "name": "sourceProposal",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "QuoteNode",
        "kind": "LinkedField",
        "name": "quote",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuoteProposalProviderClientQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuoteProposalProviderClientQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "59e83df4a8154ddf2c077250055b6580",
    "id": null,
    "metadata": {},
    "name": "QuoteProposalProviderClientQuery",
    "operationKind": "query",
    "text": "query QuoteProposalProviderClientQuery(\n  $quoteProposalId: ID!\n) {\n  sourceProposal(quoteProposalId: $quoteProposalId) {\n    id\n    quote {\n      id\n      projectName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e745e5beb615c8b067fd87db1e8868d";

export default node;
