import { FC } from 'react';

import { useLingui } from '@lingui/react';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';

import VideoModal from 'src/components/common/modal/videoModal';
import Tooltip from 'src/components/common/tooltip';

const SidebarTutorialModal: FC = () => {
    const { i18n } = useLingui();

    const triggerButtonComponent = ({ handleOpen }) => (
        <Tooltip
            title={i18n._(
                'The left Navigation bar allows you to access all applications and general account settings.',
            )}
        >
            <IconButton
                size="small"
                color="primary"
                onClick={handleOpen}
                aria-label="modal-info"
                data-testid="dashboardVideoTutorialsButton"
            >
                <InfoIcon fontSize="small" color="secondary" />
            </IconButton>
        </Tooltip>
    );

    return (
        <VideoModal
            videoUrl="https://www.youtube.com/watch?v=KOJy5Ma30TY"
            triggerButtonComponent={triggerButtonComponent}
        />
    );
};

export default SidebarTutorialModal;
