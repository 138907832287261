import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, CardContent, Container, Divider, Grid } from '@mui/material';

import Logo from 'src/components/Logo';
import { Information } from 'src/components/typography';
import Note from 'src/components/typography/note';
import { LoginOTP } from 'src/login';
import { useLoginLoading } from 'src/login/store/hook';
import { resendOTPAction } from 'src/sagas/actions';

const LoginOtpPage = () => {
    const dispatch = useDispatch();

    const handleResendClick = () => dispatch(resendOTPAction());
    const loading = useLoginLoading();

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="sm" sx={{ py: 16 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 8,
                    }}
                >
                    <RouterLink to="/">
                        <Logo />
                    </RouterLink>
                </Box>
                <Card>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 4,
                        }}
                    >
                        <Information
                            title={<Trans>Log in</Trans>}
                            value={<Trans>OTP verification</Trans>}
                        />
                        <LoginOTP />
                        <Divider sx={{ my: 3 }} />
                        <Grid display="flex" alignItems="center">
                            <LoadingButton
                                disabled={loading}
                                component="button"
                                variant="text"
                                sx={{ textDecoration: 'underline' }}
                                onClick={handleResendClick}
                            >
                                <Trans>Resend OTP</Trans>
                            </LoadingButton>
                        </Grid>
                        <Box sx={{ mt: 1 }}>
                            <Alert severity="info">
                                <Note
                                    value={
                                        <Trans>
                                            One of the organizations whose data you access via the
                                            Apadua Platform requires the use of two-factor
                                            authentication.
                                        </Trans>
                                    }
                                />
                            </Alert>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};
export default LoginOtpPage;
