import { camelCase } from 'lodash';

export const dictionaryCamelCaseConverter = (data: Array<any>): any =>
    data.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
            const camelKey = camelCase(key);
            newItem[camelKey] = item[key];
        });
        return newItem;
    });
