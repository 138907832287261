import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface InformationProps {
    title: React.ReactNode;
    value: React.ReactNode;
    defaultValue?: string;
}

const Information = ({ title, value, defaultValue = '-' }: InformationProps) => (
    <Grid sx={{ mb: 2 }}>
        {title && (
            <Typography variant="h6" textAlign="left">
                {title}
            </Typography>
        )}
        <Typography variant="body1">{value || defaultValue}</Typography>
    </Grid>
);

export default Information;
