import inRange from 'lodash/inRange';

const priceFormatter = (translationKey, formatError, locale, params) => {
    if (params.data) {
        let val = translationKey;
        if (params.value !== undefined && params.value !== '') {
            val = params.value;
        }
        if (val === translationKey) {
            return `${val}`;
        }
        if (Number.isNaN(+val)) {
            return formatError;
        }
        return new Intl.NumberFormat(locale).format(val);
    }

    return null;
};

const consultantPriceFormatter = (
    proposalState,
    editKey,
    noBidKey,
    formatError,
    maxIntError,
    locale,
    params,
) => {
    if (params.data) {
        let val = editKey;
        if (params.value !== undefined && params.value !== '') {
            val = params.value;
        }
        if (val === editKey) {
            if (proposalState !== 'opened' && proposalState !== 'accepted') {
                return noBidKey;
            }
            return `${val}`;
        }
        if (Number.isNaN(+val)) {
            return formatError;
        }
        if (!inRange(+val, Number.MAX_SAFE_INTEGER)) {
            return maxIntError;
        }
        return new Intl.NumberFormat(locale).format(val);
    }

    return null;
};

class NumberParser {
    group = null;

    decimal = null;

    numeral = null;

    index = null;

    locale = null;

    constructor(locale) {
        const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        const numerals = [
            ...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210),
        ].reverse();
        const index = new Map(numerals.map((d, i) => [d, i]));
        this.group = new RegExp(`[${parts.find((d) => d.type === 'group').value}]`, 'g');
        this.decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal').value}]`);
        this.numeral = new RegExp(`[${numerals.join('')}]`, 'g');
        this.index = (d) => index.get(d);
        this.locale = locale;
    }

    parse(string) {
        if (!Number.isNaN(+string) && string) {
            return +string;
        }
        if (string) {
            const parsedString = string
                .trim()
                .replace(this.group, '')
                .replace(this.decimal, '.')
                .replace(this.numeral, this.index);
            const newString = +parsedString;
            return Number.isNaN(newString) ? string : newString;
        }

        return '';
    }

    format(number, currencySymbol, minimumFractionDigits = 0) {
        if (currencySymbol === null) {
            return new Intl.NumberFormat(this.locale, { minimumFractionDigits }).format(number);
        }
        return currencySymbol !== '%'
            ? new Intl.NumberFormat(this.locale, {
                  style: 'currency',
                  currency: currencySymbol,
              }).format(number)
            : `${new Intl.NumberFormat(this.locale).format(number)}%`;
    }
}

/**
 * Method for formatting a date with time in a specified locale + displaying timezone
 *
 * @param {string} locale - String representing a locale (eg. en-US)
 * @param {number} date - Number representing a date in epoch time
 * @param {string} timeZone - String representing a timezone (eg. Europe/Rome)
 * @returns {string} A string representing the formatted input date
 */
const dateFormatterWithTimezone = (locale: string, date: number, timeZone: string): string => {
    if (!locale) {
        locale = navigator.language;
    }

    if (Number.isNaN(date)) {
        return null;
    }

    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timeZone || 'UTC',
        timeZoneName: 'short',
    }).format(date);
};

export const timeFormatterWithTimezone = (
    locale: string,
    date: number,
    timeZone: string,
): string => {
    if (!locale) {
        locale = navigator.language;
    }

    if (Number.isNaN(date)) {
        return null;
    }

    return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timeZone || 'UTC',
        timeZoneName: 'short',
    }).format(date);
};

/**
 * Method for formatting a date without time in a specified locale + displaying timezone
 *
 * @param {string} locale - String representing a locale (eg. en-US)
 * @param {number} date - Number representing a date in epoch time
 * @param {string} timeZone - String representing a timezone (eg. Europe/Rome)
 * @returns {string} A string representing the formatted input date
 */
const dateFormatterShortWithTimezone = (locale: string, date: number, timeZone: string): string => {
    if (!locale) {
        locale = navigator.language;
    }

    if (Number.isNaN(date)) {
        return null;
    }

    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: timeZone || 'UTC',
        timeZoneName: 'short',
    }).format(date);
};

/**
 * Method for formatting a date with time in a specified locale
 *
 * @param {string} locale - String representing a locale (eg. en-US)
 * @param {number} date - Number representing a date in epoch time
 * @returns {string} A string representing the formatted input date
 */
const dateFormatter = (locale: string, date: number): string => {
    if (!locale) {
        locale = navigator.language;
    }

    if (Number.isNaN(date)) {
        return null;
    }

    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(date);
};

/**
 * Method for formatting a date without time in a specified locale
 *
 * @param {string} locale - String representing a locale (eg. en-US)
 * @param {number} date - Number representing a date in epoch time
 * @returns {string} A string representing the formatted input date
 */
const dateFormatterShort = (locale: string, date: number): string => {
    if (!locale) {
        locale = navigator.language;
    }

    if (Number.isNaN(date)) {
        return null;
    }

    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(date);
};

/**
 * Method used to determine the date/datetime fromat from a locale (eg. MM/dd/yyyy for en-US)
 *
 * @param {string} locale - String representing a locale (eg. en-US)
 * @param {boolean} short - Flag indicating if the format should include the time or not
 * @returns {string} The format determined from the locale
 */
const getDateFormatFromLocale = (locale: string, short: boolean): string => {
    if (!locale) {
        return null;
    }

    let parts = null;
    if (!short) {
        parts = new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).formatToParts(new Date(Date.UTC(2012, 11, 20, 17, 0, 0, 200)));
    } else {
        parts = new Intl.DateTimeFormat(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).formatToParts(new Date(Date.UTC(2012, 11, 20, 17, 0, 0, 200)));
    }
    let format = '';
    parts?.forEach((part) => {
        if (part.type === 'day') {
            format += 'dd';
        }
        if (part.type === 'month') {
            format += 'MM';
        }
        if (part.type === 'year') {
            format += 'yyyy';
        }
        if (part.type === 'hour') {
            format += 'HH';
        }
        if (part.type === 'minute') {
            format += 'mm';
        }
        if (part.type === 'dayPeriod') {
            format += 'a';
        }
        if (part.type === 'literal') {
            format += part.value;
        }
    });
    return format;
};

const supportedLocales = Intl.NumberFormat.supportedLocalesOf([
    'af-ZA',
    'am-ET',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LB',
    'ar-LY',
    'ar-MA',
    'arn-CL',
    'ar-OM',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SY',
    'ar-TN',
    'ar-YE',
    'as-IN',
    'az-az',
    'az-Cyrl-AZ',
    'az-Latn-AZ',
    'ba-RU',
    'be-BY',
    'bg-BG',
    'bn-BD',
    'bn-IN',
    'bo-CN',
    'br-FR',
    'bs-Cyrl-BA',
    'bs-Latn-BA',
    'ca-ES',
    'co-FR',
    'cs-CZ',
    'cy-GB',
    'da-DK',
    'de-AT',
    'de-CH',
    'de-DE',
    'de-LI',
    'de-LU',
    'dsb-DE',
    'dv-MV',
    'el-CY',
    'el-GR',
    'en-029',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-cb',
    'en-GB',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MT',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-TT',
    'en-US',
    'en-ZA',
    'en-ZW',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-ES',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-PY',
    'es-SV',
    'es-US',
    'es-UY',
    'es-VE',
    'et-EE',
    'eu-ES',
    'fa-IR',
    'fi-FI',
    'fil-PH',
    'fo-FO',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-FR',
    'fr-LU',
    'fr-MC',
    'fy-NL',
    'ga-IE',
    'gd-GB',
    'gd-ie',
    'gl-ES',
    'gsw-FR',
    'gu-IN',
    'ha-Latn-NG',
    'he-IL',
    'hi-IN',
    'hr-BA',
    'hr-HR',
    'hsb-DE',
    'hu-HU',
    'hy-AM',
    'id-ID',
    'ig-NG',
    'ii-CN',
    'in-ID',
    'is-IS',
    'it-CH',
    'it-IT',
    'iu-Cans-CA',
    'iu-Latn-CA',
    'iw-IL',
    'ja-JP',
    'ka-GE',
    'kk-KZ',
    'kl-GL',
    'km-KH',
    'kn-IN',
    'kok-IN',
    'ko-KR',
    'ky-KG',
    'lb-LU',
    'lo-LA',
    'lt-LT',
    'lv-LV',
    'mi-NZ',
    'mk-MK',
    'ml-IN',
    'mn-MN',
    'mn-Mong-CN',
    'moh-CA',
    'mr-IN',
    'ms-BN',
    'ms-MY',
    'mt-MT',
    'nb-NO',
    'ne-NP',
    'nl-BE',
    'nl-NL',
    'nn-NO',
    'no-no',
    'nso-ZA',
    'oc-FR',
    'or-IN',
    'pa-IN',
    'pl-PL',
    'prs-AF',
    'ps-AF',
    'pt-BR',
    'pt-PT',
    'qut-GT',
    'quz-BO',
    'quz-EC',
    'quz-PE',
    'rm-CH',
    'ro-mo',
    'ro-RO',
    'ru-mo',
    'ru-RU',
    'rw-RW',
    'sah-RU',
    'sa-IN',
    'se-FI',
    'se-NO',
    'se-SE',
    'si-LK',
    'sk-SK',
    'sl-SI',
    'sma-NO',
    'sma-SE',
    'smj-NO',
    'smj-SE',
    'smn-FI',
    'sms-FI',
    'sq-AL',
    'sr-BA',
    'sr-CS',
    'sr-Cyrl-BA',
    'sr-Cyrl-CS',
    'sr-Cyrl-ME',
    'sr-Cyrl-RS',
    'sr-Latn-BA',
    'sr-Latn-CS',
    'sr-Latn-ME',
    'sr-Latn-RS',
    'sr-ME',
    'sr-RS',
    'sr-sp',
    'sv-FI',
    'sv-SE',
    'sw-KE',
    'syr-SY',
    'ta-IN',
    'te-IN',
    'tg-Cyrl-TJ',
    'th-TH',
    'tk-TM',
    'tlh-QS',
    'tn-ZA',
    'tr-TR',
    'tt-RU',
    'tzm-Latn-DZ',
    'ug-CN',
    'uk-UA',
    'ur-PK',
    'uz-Cyrl-UZ',
    'uz-Latn-UZ',
    'uz-uz',
    'vi-VN',
    'wo-SN',
    'xh-ZA',
    'yo-NG',
    'zh-CN',
    'zh-HK',
    'zh-MO',
    'zh-SG',
    'zh-TW',
    'zu-ZA',
    'zh-Hans-CN-u-nu-hanidec',
]);

const convertToInternationalCurrencySystem = (labelValue) => {
    if (Math.abs(Number(labelValue)) >= 1.0e9) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2),
            unit: 'billion',
        };
    }
    if (Math.abs(Number(labelValue)) >= 1.0e6) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2),
            unit: 'million',
        };
    }
    if (Math.abs(Number(labelValue)) >= 1.0e3) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2),
            unit: 'thousand',
        };
    }
    return {
        value: Math.abs(Number(labelValue)),
        unit: null,
    };
};

export {
    priceFormatter,
    consultantPriceFormatter,
    NumberParser,
    getDateFormatFromLocale,
    dateFormatter,
    dateFormatterShort,
    dateFormatterWithTimezone,
    dateFormatterShortWithTimezone,
    supportedLocales,
    convertToInternationalCurrencySystem,
};
