import { AxiosError } from 'axios';

import { ProjectVendorContactPayload } from 'src/project/types';

import { ApaduaAPI } from './ApaduaAPI';

export class ProjectVendorContactAPI extends ApaduaAPI {
    resource: string = 'api/project/';

    createProjectVendorContact(payload: ProjectVendorContactPayload, projectId: string): any {
        return this.post(`${projectId}/vendorcontacts/`, payload);
    }

    updateProjectVendorContact(
        payload: ProjectVendorContactPayload,
        projectId: string,
        contactId: string,
    ): any {
        return this.put(`${projectId}/vendorcontacts/${contactId}/`, payload);
    }

    patchProjectVendorContact(
        payload: ProjectVendorContactPayload,
        projectId: string,
        contactId: string,
    ): any {
        return this.patch(`${projectId}/vendorcontacts/${contactId}/`, payload);
    }

    validateAndAutocompleteProjectVendorContact(payload: any, projectId: string): any {
        return this.post(`${projectId}/vendorcontacts/validatecontact/`, payload);
    }

    deleteProjectVendorContact(projectId: string, contactId: string): any {
        return this.delete(`${projectId}/vendorcontacts/${contactId}/`);
    }

    // eslint-disable-next-line
    handleError(error: AxiosError): any {
        const errors = error.response.data;
        let errorMessage = '';
        errors.forEach((err, index) => {
            const { detail, source } = err;
            const { pointer } = source;
            const field = pointer.split('/').pop();
            errorMessage += `Error ${index + 1}: ${detail} [${field}] \n`;
        });
        throw Error(errorMessage);
    }
}
