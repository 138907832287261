import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        submitOTPSuccess: (state) => {
            state.error = null;
        },
        resendOTPSuccess: (state) => {
            state.error = null;
        },
        setLoginLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLoginError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { submitOTPSuccess, resendOTPSuccess, setLoginLoading, setLoginError } =
    loginSlice.actions;
export default loginSlice.reducer;
