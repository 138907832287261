import snakeCase from 'lodash/snakeCase';

import { CancelPayload, ProposalCancellationData } from '../types';

export const serializeProposalCancellation = (values: ProposalCancellationData): CancelPayload => {
    const snakeCaseValues = {};
    Object.keys(values).forEach((key) => {
        const snakeKey = snakeCase(key);
        snakeCaseValues[snakeKey] = values[key];
    });
    return snakeCaseValues as CancelPayload;
};
