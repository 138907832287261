import { Form, Formik } from 'formik';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import { Box, FormHelperText, TextField } from '@mui/material';

import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useLoginError, useLoginLoading } from 'src/login/store/hook';
import { useAppDispatch } from 'src/redux/hooks';
import { loginAction } from 'src/sagas/actions';

interface LoginJWTProps {
    afterLogin?: any;
}

const LoginJWT: FC<LoginJWTProps> = ({ afterLogin }: LoginJWTProps) => {
    const { i18n } = useLingui();
    const isMountedRef = useIsMountedRef();
    const dispatch = useAppDispatch();
    const loading = useLoginLoading();
    const error = useLoginError();

    const [queryParams] = useSearchParams();
    const nextURL = queryParams.get('next');
    const submit = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            const payload = { email: values.email, password: values.password, next: nextURL };
            dispatch(loginAction(payload));
            if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                if (afterLogin) {
                    afterLogin();
                }
            }
        } catch (err) {
            if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(i18n._('Must be a valid email'))
                    .max(255, i18n._('Email must be at most 255 characters'))
                    .required(i18n._('Email is required')),
                password: Yup.string()
                    .max(255, i18n._('Password must be at most 255 characters'))
                    .required(i18n._('Password is required')),
            })}
            onSubmit={submit}
        >
            {({ errors, handleBlur, handleChange, touched, values }): JSX.Element => (
                <Form noValidate>
                    <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={i18n._('Email Address')}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                        data-testid="loginEmailField"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={i18n._('Password')}
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                        data-testid="loginPasswordField"
                    />
                    {(errors.submit || error) && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit || error}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <LoadingButton
                            color="primary"
                            disabled={loading}
                            loading={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            data-testid="loginButton"
                        >
                            {i18n._('Log In')}
                        </LoadingButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default LoginJWT;
