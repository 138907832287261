import { useMemo } from 'react';

import { useLingui } from '@lingui/react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useLanguages } from 'src/redux/languages/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

export interface LanguageProps {
    title: React.ReactNode;
    value: number;
    defaultValue?: string;
}

const Language = ({ title, value, defaultValue = '-' }: LanguageProps) => {
    const languages = useLanguages();
    const { i18n } = useLingui();
    const { locale: language } = i18n;

    const languageString = useMemo(() => {
        const lang = languages.find((l) => l.id === value);
        if (lang) {
            return getOptionKey(lang, 'language');
        }
        return defaultValue;
    }, [languages, language, value]);

    return (
        <Grid sx={{ mb: 2 }}>
            {title && (
                <Typography variant="h6" textAlign="left">
                    {title}
                </Typography>
            )}
            <Typography variant="body1">{languageString}</Typography>
        </Grid>
    );
};

export default Language;
