import { FC } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/styles';

import { StepIcon } from 'src/components/StepIcon';
import { StepWithLink } from 'src/components/StepWithLink';
import { useRatecardProjectQuery } from 'src/components/ratecards/RatecardProjectProvider';

interface Props {
    orientation: 'horizontal' | 'vertical';
}

const StyledStep = styled(Step)({
    '& a': {
        textDecoration: 'none !important',
    },
});

export const RatecardEvaluationStepper: FC<Props> = ({ orientation }: Props) => {
    const { i18n } = useLingui();
    const { ratecardProjectQuery } = useRatecardProjectQuery();
    const location = useLocation();

    const project = ratecardProjectQuery?.ratecardProject;

    const steps = [
        {
            step: 1,
            label: i18n._('Overview'),
            status: 'intact',
            path: `/dashboard/ratecards/${project?.id}/evaluation/overview`,
        },
        {
            step: 2,
            label: i18n._('Suppliers'),
            status: 'intact',
            path: `/dashboard/ratecards/${project?.id}/evaluation/suppliers`,
        },
        {
            step: 3,
            label: i18n._('Bids'),
            status: 'intact',
            path: `/dashboard/ratecards/${project?.id}/evaluation/bids`,
        },
        {
            step: 4,
            label: i18n._('Analytics'),
            status: 'intact',
            path: `/dashboard/ratecards/${project?.id}/evaluation/analytics`,
        },
        {
            step: 5,
            label: i18n._('Proposals'),
            status: 'intact',
            path: `/dashboard/ratecards/${project?.id}/evaluation/proposals`,
        },
    ];

    const activeStep = steps.findIndex((step) => matchPath(step.path, location.pathname));

    return (
        <Stepper nonLinear activeStep={activeStep} orientation={orientation}>
            {steps.map((step, i) => (
                <StyledStep key={step.step}>
                    <StepWithLink to={step.path} isCurrent={i === activeStep}>
                        <StepLabel
                            style={{ cursor: 'pointer' }}
                            error={step.status === 'error'}
                            icon={<StepIcon type={step.status} step={step.step} />}
                        >
                            {step.label}
                        </StepLabel>
                    </StepWithLink>
                </StyledStep>
            ))}
        </Stepper>
    );
};
