import { useLingui } from '@lingui/react';
import { Box } from '@mui/material';

import OtherTutorialPlaylistModal from 'src/videoModals/OtherTutorialPlaylistModal';

const SidebarOthers = () => {
    const { i18n } = useLingui();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
            }}
        >
            {i18n._('Other')}
            <OtherTutorialPlaylistModal />
        </Box>
    );
};

export default SidebarOthers;
