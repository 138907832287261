import { FC, ReactNode, createContext, useEffect, useState } from 'react';

import useAuth from 'src/hooks/useAuth';
import {
    dateFormatter,
    dateFormatterShort,
    dateFormatterShortWithTimezone,
    dateFormatterWithTimezone,
    getDateFormatFromLocale,
} from 'src/utils/formattingUtils';

interface FormattingContextValue {
    formattingLocale: string;
    dateFormatterByUserLocale: (date: number) => string;
    dateFormatterShortByUserLocale: (date: number) => string;
    dateFormatterWithTimezoneByUserLocale: (date: number, timezone: string) => string;
    dateFormatterShortWithTimezoneByUserLocale: (date: number, timezone: string) => string;
    getDateFormatFromUserLocale: (short: boolean) => string;
}

export const FormattingContext = createContext<FormattingContextValue>({
    formattingLocale: null,
    dateFormatterByUserLocale: () => '',
    dateFormatterShortByUserLocale: () => '',
    dateFormatterWithTimezoneByUserLocale: () => '',
    dateFormatterShortWithTimezoneByUserLocale: () => '',
    getDateFormatFromUserLocale: () => '',
});

interface FormattingContextProviderProps {
    children: ReactNode;
}

export const FormattingContextProvider: FC<FormattingContextProviderProps> = ({
    children,
}: FormattingContextProviderProps) => {
    const { user } = useAuth();
    const [formattingLocale, setFormattingLocale] = useState(navigator.language);

    useEffect(() => {
        if (user?.formattingLocale) {
            setFormattingLocale(user.formattingLocale);
        }
    }, [user?.formattingLocale]);

    const dateFormatterByUserLocale = (date: number): string =>
        dateFormatter(formattingLocale, date);

    const dateFormatterShortByUserLocale = (date: number): string =>
        dateFormatterShort(formattingLocale, date);

    const dateFormatterWithTimezoneByUserLocale = (date: number, timezone: string): string =>
        dateFormatterWithTimezone(formattingLocale, date, timezone);

    const dateFormatterShortWithTimezoneByUserLocale = (date: number, timezone: string): string =>
        dateFormatterShortWithTimezone(formattingLocale, date, timezone);

    const getDateFormatFromUserLocale = (short: boolean): string =>
        getDateFormatFromLocale(formattingLocale, short);

    return (
        <FormattingContext.Provider
            value={{
                formattingLocale,
                dateFormatterByUserLocale,
                dateFormatterShortByUserLocale,
                dateFormatterWithTimezoneByUserLocale,
                dateFormatterShortWithTimezoneByUserLocale,
                getDateFormatFromUserLocale,
            }}
        >
            {children}
        </FormattingContext.Provider>
    );
};
