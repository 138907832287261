import React from 'react';

import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';

const ExploreNoResults: React.FC = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingY: 4,
        }}
    >
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '75%',
            }}
        >
            <Typography variant="h5" color="primary">
                <Trans>
                    There are no results for your search terms. Try refining them or split into
                    multiple keywords.
                </Trans>
            </Typography>
        </Box>
    </Box>
);

export default ExploreNoResults;
