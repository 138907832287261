import React from 'react';

import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';

const ExploreEmptyList: React.FC = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingY: 4,
        }}
    >
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '75%',
            }}
        >
            <Typography variant="h5" color="primary">
                <Trans>The list that you selected is empty</Trans>
            </Typography>
        </Box>
    </Box>
);

export default ExploreEmptyList;
