import { LanguagesAPI } from 'src/apis/languagesAPI';

import Fetcher from '../fetcher';
import { sanitizeLanguages } from './sanitizers';

const api = new LanguagesAPI();

export const LanguagesFetcher = new Fetcher(
    'languages/list',
    async () => api.getLanguages(),
    sanitizeLanguages,
);
