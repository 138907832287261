import { FC } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Avatar, Box } from '@mui/material';

import { notificationOptions } from 'src/components/notifications/notificationTypeProps';

interface Props {
    notificationType: any;
}

export const NotificationIcon: FC<Props> = ({ notificationType }: Props) => (
    <Box sx={{ mr: 1, mt: 1 }}>
        <Avatar
            src={notificationOptions[notificationType]?.src}
            sx={{
                height: '30px',
                width: '30px',
                backgroundColor: 'transparent',
            }}
        >
            {/* fallback */}
            <InfoIcon color="primary" />
        </Avatar>
    </Box>
);
