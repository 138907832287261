import { ReactNodeLike } from 'prop-types';
import { FC, Suspense } from 'react';

import { QuoteProposalProvider } from 'src/components/rfp/QuoteProposalProvider';

interface Props {
    children: ReactNodeLike;
}

const QuoteProposalRoute: FC<Props> = ({ children }: Props) => (
    <Suspense fallback="">
        <QuoteProposalProvider>{children}</QuoteProposalProvider>
    </Suspense>
);

export default QuoteProposalRoute;
