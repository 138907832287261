import { ReactNode } from 'react';

import App from './App';
import { FormattingContextProvider } from './contexts/FormattingContext';
import { AuthProvider } from './contexts/JWTContext';

const WrappedApp = ({ children }: { children?: ReactNode }) => (
    <AuthProvider>
        <FormattingContextProvider>
            <App>{children}</App>
        </FormattingContextProvider>
    </AuthProvider>
);

export default WrappedApp;
