import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base';
import { blue, grey } from '@mui/material/colors';
import { styled } from '@mui/system';

const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
    listStyle: 'none',
    padding: '8px',
    borderRadius: '8px',
    cursor: 'default',
    userSelect: 'none',

    '&:last-of-type': {
        borderBottom: 'none',
    },
    [`&.${menuItemClasses.focusVisible}`]: {
        outline: `3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]}`,
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[100],
        color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
    },

    [`&.${menuItemClasses.disabled}`]: {
        color: theme.palette.mode === 'dark' ? grey[700] : grey[400],
    },

    [`&:hover:not(.${menuItemClasses.disabled})`]: {
        backgroundColor: theme.palette.mode === 'dark' ? blue[900] : blue[50],
        color: theme.palette.mode === 'dark' ? blue[100] : blue[900],
    },
}));

export default MenuItem;
