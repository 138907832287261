import { FC } from 'react';

import { useLingui } from '@lingui/react';
import { MenuItem } from '@mui/material';

import { TextField } from 'src/components/formik-material-ui/TextField';

interface Option {
    value: string;
    label: string;
}

export const RatecardProjectRoleSelect: FC<any> = (props: any) => {
    const { i18n } = useLingui();

    const items: Option[] = [
        { value: 'user', label: i18n._('User') },
        { value: 'admin', label: i18n._('Admin') },
    ];

    const { form, field } = props;

    return (
        <TextField fullWidth variant="outlined" {...props} select error={form.errors[field.name]}>
            {items.map((option: Option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
