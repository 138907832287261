import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { useLingui } from '@lingui/react';
import { Button, Grid, TextField, Typography } from '@mui/material';

import { Modal } from 'src/components/common/modal';
import type { TriggerButtonProps } from 'src/components/common/modal';

import { ModalProps } from '../modal/modal';

export interface FieldProps {
    name: string;
    label: string;
    component?: React.ComponentType<any>;
    variant?: 'outlined' | 'filled';
    [key: string]: any;
}

export interface FormModalProps<T> extends ModalProps {
    title: string;
    fields: Array<FieldProps>;
    validationSchema: Yup.ObjectSchema<any>;
    initialValues?: T;
    onConfirm: (values: T) => void;
    triggerButtonText?: string;
    triggerButtonComponent: React.ComponentType<TriggerButtonProps>;
    cancelButtonText?: string;
    confirmButtonText?: string;
}

const BUTTON_MIN_WIDTH = '96px';
const MODAL_MIN_WIDTH = '460px';

const FormModal = <T,>({
    title,
    fields,
    validationSchema,
    onConfirm,
    triggerButtonText,
    triggerButtonComponent,
    cancelButtonText,
    confirmButtonText,
    initialValues,
    ...rest
}: FormModalProps<T>) => {
    const { i18n } = useLingui();
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const handleSubmit = (values: T, { resetForm }) => {
        onConfirm(values);
        resetForm();
        handleClose();
    };

    return (
        <Modal
            triggerButtonComponent={triggerButtonComponent}
            showTriggerButton
            externalControl
            onClose={handleClose}
            onOpen={handleOpen}
            isOpen={isOpen}
            style={{ minWidth: MODAL_MIN_WIDTH }}
            {...rest}
        >
            <Grid>
                <Formik
                    validateOnMount
                    onSubmit={handleSubmit}
                    initialValues={initialValues ?? {}}
                    validationSchema={validationSchema}
                >
                    {({ isValid }) => (
                        <Form>
                            <Grid
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                justifyContent="center"
                            >
                                <Grid sx={{ mb: 2 }} item>
                                    <Typography variant="h6">{title}</Typography>
                                </Grid>
                                {fields.map(
                                    ({ name, label, component = TextField, ...restFieldProps }) => (
                                        <Grid key={name} sx={{ mb: 2, width: '100%' }} item>
                                            <Field
                                                name={name}
                                                label={label}
                                                as={component}
                                                {...restFieldProps}
                                            />
                                        </Grid>
                                    ),
                                )}
                            </Grid>
                            <Grid display="flex" justifyContent="center" alignContent="center">
                                <Button
                                    variant="outlined"
                                    sx={{ minWidth: BUTTON_MIN_WIDTH }}
                                    color="secondary"
                                    onClick={handleClose}
                                >
                                    {cancelButtonText || i18n._('Cancel')}
                                </Button>
                                <Button
                                    sx={{ ml: 2, minWidth: BUTTON_MIN_WIDTH }}
                                    variant="contained"
                                    type="submit"
                                    disabled={!isValid}
                                >
                                    {confirmButtonText || i18n._('Confirm')}
                                </Button>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Modal>
    );
};

export default FormModal;
