import { graphql } from 'babel-plugin-relay/macro';
import React, { useMemo } from 'react';

import { t } from '@lingui/macro';
import { Typography } from '@mui/material';

import Section from 'src/components/sections/section';
import useAuth from 'src/hooks/useAuth';
import { useDeleteListItem } from 'src/pages/listManager/state/hooks';
import { mapEdgesToList, useGenericQuery } from 'src/selectors/useGenericQuery';

import ListItem from './listItem';
import { useUpdateListItem } from './state/hooks';

const getVendorsQuery = graphql`
    query listItemsAllCompaniesQuery($companyIds: [String!]) {
        allCompanies(companyIds: $companyIds) {
            edges {
                node {
                    id
                    logo
                    companyName
                    generalInfo {
                        descriptionEn
                        descriptionDe
                    }
                    entryId
                }
            }
        }
    }
`;

interface ListItemsProps {
    list: any;
    canAlterList: boolean;
}
function ListItems({ list, canAlterList }: ListItemsProps) {
    const { user } = useAuth();
    const updateListItem = useUpdateListItem();
    const deleteListItem = useDeleteListItem();

    const queryParams = useMemo(
        () => ({
            companyIds: list.list_items.map((item) => parseInt(item.object_id, 10)),
        }),
        [list.list_items],
    );
    const queryResults = useGenericQuery(getVendorsQuery, queryParams);
    const vendors = mapEdgesToList(queryResults.allCompanies);
    const vendorsWithListItem = vendors.map((vendor) => {
        const listItem = list.list_items.find((item) => item.object_id === vendor.id);
        return {
            ...vendor,
            listItem,
        };
    });

    const handleUpdateItem = (item, values: { note: string }) => {
        updateListItem({
            listId: list.id,
            itemId: item.listItem.id,
            item: {
                note: values.note,
            },
        });
    };

    const handleDeleteItem = (item) => {
        deleteListItem(list.id, item.listItem.id);
    };

    return (
        <Section title={t`Vendors`} sx={{ p: 0, py: 1 }}>
            {vendorsWithListItem.map((item) => (
                <ListItem
                    onItemUpdate={handleUpdateItem}
                    onDelete={handleDeleteItem}
                    item={item}
                    key={item.id}
                    canAlterList={canAlterList}
                />
            ))}
            {vendors.length === 0 && <Typography>{t`No vendors in this list`}</Typography>}
        </Section>
    );
}

export default ListItems;
