import { FC } from 'react';
import { useLocation } from 'react-router';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Box, Divider, Skeleton, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { DelayedRender } from 'src/components/DelayedRender';
import NavSection from 'src/components/NavSection';
import { StepIcon } from 'src/components/StepIcon';
import CalendarIcon from 'src/icons/Calendar';

export const RatecardEvaluationSidebarSkeleton: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();

    const steps = [
        {
            step: 1,
            label: i18n._('Overview'),
            path: '',
        },
        {
            step: 2,
            label: i18n._('Suppliers'),
            path: '',
        },
        {
            step: 3,
            label: i18n._('Bids'),
            path: '',
        },
        {
            step: 4,
            label: i18n._('Analytics'),
            path: '',
        },
        {
            step: 5,
            label: i18n._('Proposals'),
            path: '',
        },
    ];

    const sections = [
        {
            title: i18n._('Other'),
            items: [
                {
                    title: i18n._('Announcements'),
                    path: '',
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: '',
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: '',
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: '',
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Edit project'),
                    path: '',
                    icon: <NoteAltIcon fontSize="small" />,
                },
                {
                    title: i18n._('Calendar'),
                    path: '',
                    icon: <CalendarIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <DelayedRender>
            <Box sx={{ my: 2, px: 2, pb: 2 }}>
                <Typography color="textPrimary" variant="h6" align="center" noWrap>
                    <Skeleton />
                </Typography>
                <Divider sx={{ mb: 2, mt: 1 }} />
                <Stepper nonLinear activeStep={-1} orientation="vertical">
                    {steps.map((step) => (
                        <Step key={step.step}>
                            <StepLabel
                                style={{ cursor: 'pointer' }}
                                icon={<StepIcon type="warning" step={step.step} />}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                        {sections.map((section) => (
                            <NavSection
                                key={section.title}
                                pathname={location.pathname}
                                sx={{
                                    '& + &': {
                                        mt: 3,
                                    },
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </DelayedRender>
    );
};
