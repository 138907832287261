import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';

import { ListData } from 'src/apis/ListsAPI';
import ConfirmationModal from 'src/components/common/confirmationModal';
import type { TriggerButtonProps } from 'src/components/common/modal';
import Section from 'src/components/sections/section';
import useAuth from 'src/hooks/useAuth';
import { ApaduaRole } from 'src/types/user';
import { ElapsedTimeWithTooltip } from 'src/utils/time';

import ListFormModal from './listFormModal';
import ListItems from './listItems';
import { ListUpdateData } from './types';

const DeleteButton = ({ handleOpen }: TriggerButtonProps) => {
    const { i18n } = useLingui();

    return (
        <IconButton color="error" onClick={handleOpen}>
            <DeleteIcon />
        </IconButton>
    );
};

const EditIconButton = ({ handleOpen }: TriggerButtonProps) => {
    const { i18n } = useLingui();

    return (
        <IconButton color="primary" onClick={handleOpen}>
            <EditIcon />
        </IconButton>
    );
};

interface ListProps {
    list: any;
    isOrganisation: boolean;
    onUpdate: (values: ListUpdateData) => void;
    onDelete: (list: ListData) => void;
}

const List = ({ list, isOrganisation, onUpdate, onDelete }: ListProps) => {
    const { i18n } = useLingui();
    const { user } = useAuth();
    const userId = atob(user.id || '').split(':')[1];
    const navigate = useNavigate();

    const canAlterList =
        (isOrganisation && user?.apaduaRole === ApaduaRole.ADMIN) || !isOrganisation;

    const userTagRelation = list.tags_relations.find((tagsRelation) =>
        tagsRelation.tag.key.includes('user_'),
    );

    const userTagId = userTagRelation?.tag?.key?.split('_')[1];

    const handleDelete = () => {
        onDelete(list);
    };

    const handleUpdate = (values) => {
        onUpdate({
            id: list.id,
            ...values,
        });
    };

    const redirectToExplore = () => {
        navigate(`/explore?listId=${list.id}`);
    };

    return (
        <Grid sx={{ p: 1 }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container gap={1} alignItems="center">
                        <Typography variant="h6">{list.name}</Typography>
                        <Chip
                            label={
                                <Grid container gap={0.5}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {i18n._('Created')}
                                    </Typography>
                                    <ElapsedTimeWithTooltip start={new Date(list.created)} />
                                </Grid>
                            }
                            variant="filled"
                            size="small"
                        />
                        {userTagRelation && isOrganisation && (
                            <Chip
                                label={
                                    <Grid container gap={0.5}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {i18n._('Created by')}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {userTagId === userId
                                                ? i18n._('you')
                                                : userTagRelation.tag.label}
                                        </Typography>
                                    </Grid>
                                }
                                variant="filled"
                                size="small"
                            />
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {list.note && (
                        <Grid display="flex" alignItems="center">
                            <Grid xs={10} item>
                                <Section title={i18n._('Note')} sx={{ p: 0 }}>
                                    <Typography>{list.note}</Typography>
                                </Section>
                            </Grid>
                        </Grid>
                    )}
                    <ListItems list={list} canAlterList={canAlterList} />
                    {canAlterList && (
                        <Grid container>
                            <Button onClick={redirectToExplore}>{i18n._('+ Add vendors')}</Button>
                            <ListFormModal
                                initialValues={{ name: list.name, note: list.note }}
                                onConfirm={handleUpdate}
                                triggerButtonComponent={EditIconButton}
                            />
                            {list.locked || (
                                <ConfirmationModal
                                    triggerButtonComponent={DeleteButton}
                                    onConfirm={handleDelete}
                                    description={i18n._(
                                        'Are you sure you want to delete this list?',
                                    )}
                                    confirmButtonText={i18n._('Confirm')}
                                    cancelButtonText={i18n._('Cancel')}
                                />
                            )}
                        </Grid>
                    )}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default List;
