import { FC } from 'react';

import { Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface FilterOptions {
    option: any;
    checked: boolean;
    onRemove: (value: any) => void;
    onSelect: (value: any) => void;
}

const WhiteCheckbox = styled(Checkbox)((theme) => ({
    '&': {
        position: 'relative',
        zIndex: 0,
    },
    '&:not(.Mui-checked):after': {
        content: '""',
        left: 4,
        top: 4,
        height: 13,
        width: 13,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1,
    },
}));

const FilterOption: FC<FilterOptions> = ({
    option,
    checked,
    onRemove,
    onSelect,
}: FilterOptions) => (
    <label
        key={option.name}
        htmlFor={`example_facet_${option.name}`}
        className="sui-multi-checkbox-facet__option-label"
    >
        <div className="sui-multi-checkbox-facet__option-input-wrapper">
            <WhiteCheckbox
                data-transaction-name={`facet - ${option.name}`}
                id={`example_facet_${option.name}`}
                checked={checked}
                onChange={() => (checked ? onRemove(option) : onSelect(option))}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
                sx={{
                    p: 0,
                }}
            />
            <span className="sui-multi-checkbox-facet__input-text">
                <Typography variant="subtitle2">{option.name}</Typography>
            </span>
        </div>
    </label>
);

export default FilterOption;
