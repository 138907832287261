import { Trans } from '@lingui/macro';
import { Button, ButtonProps } from '@mui/material';
import { useTour } from '@reactour/tour';

const TourAvailableButton = ({ ...rest }: ButtonProps) => {
    const { steps, setIsOpen } = useTour();

    if (steps.length === 0) {
        return null;
    }

    return (
        <Button onClick={() => setIsOpen(true)} variant="contained" color="secondary2" {...rest}>
            <Trans>Guided tour</Trans>
        </Button>
    );
};

export default TourAvailableButton;
