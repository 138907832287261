import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ProjectInfoFormData } from 'src/project/types';
import type { ProjectBriefFormData } from 'src/projectBrief/types';
import type { StatusValueType } from 'src/redux/fetcher';

import { sanitizeProjectBrief } from './sanitizers';
import {
    creatProjectBrief,
    fetchAllProjectBrief,
    fetchProjectBrief,
    fetchSuggestions,
    patchProjectBrief,
} from './thunk';

export const ProjectInfoDefaultvalue: ProjectInfoFormData = Object.freeze({
    projectTitle: '',
    startDate: null,
    endDate: null,
    internalDescription: '',
    projectApproach: '',
    initialSituation: '',
    internalOrganisationReference: '',
    engagementMode: 'on_site',
    projectObjectives: [
        {
            title: '',
            description: '',
        },
    ],
    projectDeliverables: [
        {
            title: '',
            description: '',
        },
    ],
    secondaryProjectLocations: [],
    selectedServiceID: null,
    industryStandardID: null,
    primaryProjectLocation: null,
    isComplete: false,
    budgetEstimate: null,
    budgetEstimateCurrency: null,
    budgetApprovalStatus: null,
});

export const LOCAL_STORAGE_KEY = 'projectBriefValues';

interface SuggestionItemWithTitle {
    title: string;
    description: string;
}

interface SuggestionsValues {
    initialSituation?: string;
    projectApproach?: string;
    projectObjectives?: Array<SuggestionItemWithTitle>;
    projectDeliverables?: Array<SuggestionItemWithTitle>;
}

const initialSuggestions: SuggestionsValues = {
    initialSituation: undefined,
    projectApproach: undefined,
    projectObjectives: [],
    projectDeliverables: [],
};

export interface ProjectBriefState extends ProjectBriefFormData {
    suggestions: SuggestionsValues;
    isSuggestionsLoading: boolean;
    suggestionsLoadingStatus: StatusValueType;
    data: any;
    loading: StatusValueType;
    error: any;
}

const initialState: ProjectBriefState = {
    ...ProjectInfoDefaultvalue,
    suggestions: {},
    isSuggestionsLoading: false,
    suggestionsLoadingStatus: 'idle',
    error: null,
    data: [],
    loading: 'idle',
};

export const projectBriefSlice = createSlice({
    name: 'projectBrief',
    initialState,
    reducers: {
        resetSuggestions: (state) => {
            state.suggestions = initialSuggestions;
        },
        removeSuggestion: (
            state,
            action: PayloadAction<{ suggestionType: string; index: number }>,
        ) => {
            const { suggestionType, index } = action.payload;

            if (
                state.suggestions[suggestionType] &&
                Array.isArray(state.suggestions[suggestionType])
            ) {
                state.suggestions[suggestionType].splice(index, 1);
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchSuggestions.pending, (state) => {
            state.suggestionsLoadingStatus = 'loading';
        });
        builder.addCase(fetchSuggestions.fulfilled, (state, action) => {
            state.suggestionsLoadingStatus = 'succeeded';
            state.suggestions = action.payload;
        });
        builder.addCase(fetchSuggestions.rejected, (state, action) => {
            state.suggestionsLoadingStatus = 'failed';
            state.error = action.error;
        });

        builder.addCase(fetchProjectBrief.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchProjectBrief.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.data = [sanitizeProjectBrief(action.payload[0])];
        });
        builder.addCase(fetchProjectBrief.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error;
        });
        builder.addCase(creatProjectBrief.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(creatProjectBrief.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.data = [...state.data, sanitizeProjectBrief(action.payload)];
        });
        builder.addCase(creatProjectBrief.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error;
        });
        builder.addCase(patchProjectBrief.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(patchProjectBrief.fulfilled, (state, action) => {
            const updatedItemIndex = state.data.find((item) => item.id === action.payload.id);
            const updatedStsate = [...state.data];
            updatedStsate[updatedItemIndex] = action.payload;
            state.loading = 'succeeded';
            state.data = updatedStsate;
        });
        builder.addCase(patchProjectBrief.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error;
        });
        builder.addCase(fetchAllProjectBrief.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchAllProjectBrief.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.data = action.payload.map((item) => sanitizeProjectBrief(item));
        });
        builder.addCase(fetchAllProjectBrief.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.error;
        });
    },
});

export default projectBriefSlice.reducer;
export const { resetSuggestions, removeSuggestion } = projectBriefSlice.actions;
