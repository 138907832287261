import React from 'react';

import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';

import { BUTTON_WIDTH } from './style';

interface Props {
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
}

const SaveDraftAction = ({ disabled, onClick, loading }: Props) => (
    <LoadingButton
        sx={{ width: BUTTON_WIDTH }}
        color="primary"
        variant="outlined"
        disabled={disabled}
        onClick={onClick}
        data-testid="saveAction"
        loading={loading}
    >
        <Trans>Save draft</Trans>
    </LoadingButton>
);

export default SaveDraftAction;
