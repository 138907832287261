import React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface ListInformationProps extends Omit<GridProps, 'title'> {
    number?: string | number;
    title?: React.ReactNode;
    value?: React.ReactNode;
    defaultValue?: string;
}

const ListInformation = ({
    number,
    title,
    value,
    defaultValue = '-',
    sx,
    ...rest
}: ListInformationProps) => (
    <Grid sx={{ mb: 2, ...sx }} {...rest}>
        {title && (
            <Typography variant="h6">
                {number && `${number}. `}
                {title}
            </Typography>
        )}
        <Typography variant="body1">{value || defaultValue}</Typography>
    </Grid>
);

export default React.memo(ListInformation);
