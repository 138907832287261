import { useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

export const loginErrorSelector = (state: GlobalState) => state.login.error;

export const loginLoadingSelector = (state: GlobalState) => state.login.loading;

export const useLoginError = () => useAppSelector(loginErrorSelector);

export const useLoginLoading = () => useAppSelector(loginLoadingSelector);
