import { useMenuButton } from '@mui/base';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export interface MenuButtonProps extends LoadingButtonProps {
    content: string;
}

const MenuButton = ({ content, ...rest }: MenuButtonProps) => {
    const { getRootProps, rootRef } = useMenuButton();
    return (
        <LoadingButton ref={rootRef} {...getRootProps()} {...rest}>
            {content}
        </LoadingButton>
    );
};

export default MenuButton;
