import graphql from 'babel-plugin-relay/macro';
import { useConfirm } from 'material-ui-confirm';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFragment, useMutation } from 'react-relay/hooks';

import { useLingui } from '@lingui/react';

import { useQuoteQuery } from 'src/components/rfp/QuoteProvider';
import { useCompanyUsersQuery } from 'src/components/users/CompanyUsersProvider';
import { UserManagmentTable } from 'src/components/users/UserManagmentTable';
import useAuth from 'src/hooks/useAuth';
import { fetchProjectData } from 'src/project/state/thunk';
import store from 'src/redux/store';
import { fromRelayId } from 'src/relay/utils';
import useErrorMessage from 'src/translations/hooks/useErrorMessage';
import DismissibleToast from 'src/utils/DismissibleToast';
import { fieldValidationErros } from 'src/utils/backendErrors';
import useWindowSize from 'src/utils/useWindowSize';

export const QuoteUserManagementComponent: FC = () => {
    const { i18n } = useLingui();
    const { user: loggedInUser } = useAuth();
    const confirm = useConfirm();
    const extractErrorMessage = useErrorMessage();

    const windowSize = useWindowSize();
    const [tableHeight, setTableHeight] = useState(windowSize?.height - 220 || 700);
    useEffect(() => {
        setTableHeight(windowSize?.height - 220);
    }, [windowSize.height]);

    const [actionsAllowed, setActionsAllowed] = useState(false);
    const rolesWithActionsAllowed = ['admin', 'company_admin'];

    const { quoteQuery } = useQuoteQuery();
    const { companyUsersQuery, refreshCompanyUserQuery } = useCompanyUsersQuery();

    const data = useFragment(
        graphql`
            fragment QuoteUserManagementComponent_sourceQuote on QuoteNode {
                id
                userRoles {
                    user {
                        id
                        firstName
                        lastName
                    }
                    email
                    role
                    isActive
                }
            }
        `,
        quoteQuery.sourceQuote,
    );

    const quoteId = data?.id;

    const users = data?.userRoles?.map((role) => ({
        email: role.email,
        firstName: role.user.firstName,
        lastName: role.user.lastName,
        role: role.isActive ? role.role.toLowerCase() : 'disabled',
        user: role.user,
        isActive: role.isActive,
    }));

    const userOptions = companyUsersQuery?.allUserCompanyRoles
        ?.filter((node) => !users.some((row) => row.email === node.email))
        .map((node) => ({
            id: node.user.id,
            firstName: node.user.firstName,
            lastName: node.user.lastName,
            email: node.email,
            isActive: node.isActive,
        }));

    useEffect(() => {
        setActionsAllowed(
            rolesWithActionsAllowed.includes(
                users.find((row) => row.email === loggedInUser.email).role,
            ),
        );
    });

    const [createUser] = useMutation(graphql`
        mutation QuoteUserManagementComponentCreateUserMutation(
            $input: CreateUserWithQuotePermissionInput!
        ) {
            createUserWithQuotePermission(input: $input) {
                quote {
                    id
                    projectName
                    companyName
                    userRoles {
                        user {
                            id
                            firstName
                            lastName
                        }
                        email
                        role
                        isActive
                    }
                }
            }
        }
    `);

    const [setUserPermission] = useMutation(graphql`
        mutation QuoteUserManagementComponentSetUserPermissionMutation(
            $input: SetQuotePermissionInput!
        ) {
            setQuotePermission(input: $input) {
                quote {
                    id
                    projectName
                    companyName
                    userRoles {
                        user {
                            id
                            firstName
                            lastName
                        }
                        email
                        role
                        isActive
                    }
                }
            }
        }
    `);

    const inviteNewUser = (values, actions) => {
        createUser({
            variables: {
                input: {
                    quoteId,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    role: values.role,
                },
            },
            onCompleted(completedData, errors) {
                if (errors === null) {
                    actions?.closeDrawer?.();
                    actions?.setSubmitting?.(false);
                    actions?.afterSuccessAction?.();
                    toast.success(DismissibleToast(i18n._('User successfully added')));
                    store.dispatch(fetchProjectData(fromRelayId(quoteId)));
                } else {
                    actions?.setStatus?.({ errors });
                    actions?.setErrors?.(fieldValidationErros(errors));
                    toast.error(
                        DismissibleToast(extractErrorMessage(errors, 'userManagementAddError')),
                    );
                    actions?.setSubmitting?.(false);
                }
            },
            onError() {
                actions?.setSubmitting?.(false);
                toast.error(DismissibleToast(i18n._('Connection error')));
            },
        });
    };

    const addOrEditExistingUser = (values, edit, actions) => {
        setUserPermission({
            variables: {
                input: {
                    quoteId,
                    userId: values.user.id,
                    role: values.role,
                },
            },
            onCompleted(completedData, errors) {
                if (errors === null) {
                    actions?.closeDrawer?.();
                    actions?.setSubmitting?.(false);
                    actions?.afterSuccessAction?.();
                    toast.success(
                        DismissibleToast(
                            edit
                                ? i18n._('User successfully edited')
                                : i18n._('User successfully added'),
                        ),
                    );
                    store.dispatch(fetchProjectData(fromRelayId(quoteId)));
                } else {
                    actions?.setStatus?.({ errors });
                    actions?.setErrors?.(fieldValidationErros(errors));
                    toast.error(
                        DismissibleToast(
                            extractErrorMessage(
                                errors,
                                `userManagement${edit ? 'Edit' : 'Add'}Error`,
                            ),
                        ),
                    );
                    actions?.setSubmitting?.(false);
                }
            },
            onError() {
                actions?.setSubmitting?.(false);
                toast.error(DismissibleToast(i18n._('Connection error')));
            },
        });
    };

    const removeUser = (userId, actions) => {
        confirm({
            title: i18n._('Are you sure you want to remove this user from the project?'),
            confirmationText: i18n._('Yes'),
            cancellationText: i18n._('Cancel'),
        })
            .then(() => {
                setUserPermission({
                    variables: {
                        input: {
                            quoteId,
                            userId,
                            role: 'none',
                        },
                    },
                    onCompleted(completedData, errors) {
                        if (errors === null) {
                            actions?.afterSuccessAction?.();
                            toast.success(DismissibleToast(i18n._('User successfully removed')));
                            store.dispatch(fetchProjectData(fromRelayId(quoteId)));
                        } else {
                            toast.error(
                                DismissibleToast(
                                    extractErrorMessage(errors, 'userManagementDeleteError'),
                                ),
                            );
                        }
                    },
                    onError() {
                        toast.error(DismissibleToast(i18n._('Connection error')));
                    },
                });
            })
            .catch(() => {});
    };

    return (
        <UserManagmentTable
            users={users}
            unassignedUsers={userOptions}
            actionsAllowed={actionsAllowed}
            tableHeight={tableHeight}
            inviteNewUserAction={inviteNewUser}
            addOrEditExistingUserAction={addOrEditExistingUser}
            removeUserAction={removeUser}
            afterSuccessAction={refreshCompanyUserQuery}
        />
    );
};
