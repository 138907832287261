import { useNavigate, useParams } from 'react-router';

import { Trans } from '@lingui/macro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CallIcon from '@mui/icons-material/Call';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';

import RequestCallbackModal from 'src/components/common/requestCallbackModal';
import { ProjectFormData } from 'src/project/state/sanitizers';
import { useProposal } from 'src/proposal/state/hook';

import { ProposalStatus } from '../types';
import CancelParticipationModal from './cancelParticipationModal';

interface Props {
    project: ProjectFormData;
}

const SupplierActions = ({ project }: Props) => {
    const params = useParams();
    const navigate = useNavigate();
    const proposal = useProposal();

    const onContinue = () => {
        const { quoteId, quoteProposalId } = params;
        navigate(`/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/questions`);
    };

    return (
        <Grid sx={{ mb: 2 }} container justifyContent="flex-end">
            {[ProposalStatus.invited, ProposalStatus.accepted].includes(proposal.status) && (
                <Grid sx={{ ml: 1 }}>
                    <CancelParticipationModal
                        style={{
                            width: 'auto',
                        }}
                    />
                </Grid>
            )}
            <Grid sx={{ ml: 1 }}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onContinue}
                    endIcon={<ArrowForwardIcon />}
                >
                    <Typography>
                        <Trans>Continue</Trans>
                    </Typography>
                </LoadingButton>
            </Grid>
            <Grid sx={{ ml: 1 }}>
                <RequestCallbackModal
                    projectID={project?.id}
                    projectTitle={project?.projectTitle}
                    triggerButtonComponent={({ handleOpen }) => (
                        <LoadingButton
                            variant="contained"
                            startIcon={<CallIcon />}
                            color="primary"
                            onClick={handleOpen}
                        >
                            <Typography>
                                <Trans>Request a call back from client</Trans>
                            </Typography>
                        </LoadingButton>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default SupplierActions;
