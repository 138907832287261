import React from 'react';

import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

const AiDescription = () => {
    const [visible, setVisible] = React.useState(false);
    const toggleExpanded = () => {
        setVisible(!visible);
    };

    return (
        <Grid mt={2} mb={2} pr={2} pl={2}>
            <Typography variant="body2" color="textSecondary">
                <Trans>Apadua’s AI may auto-generate a checklist for this RFP.</Trans>{' '}
                {visible && (
                    <Trans>
                        The checklist is an optional aide that you or your stakeholders can use to
                        prepare a high-quality RFP. Its main intention is to suggest data that
                        should be provided to the service providers for them to be able to submit
                        highly relevant and competitive proposals.
                    </Trans>
                )}
                <Grid
                    onClick={toggleExpanded}
                    role="button"
                    sx={{
                        display: 'inline-block',
                        marginLeft: 0.5,
                        color: 'inherit',
                        fontWeight: 600,
                        '&:hover': {
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        },
                    }}
                >
                    {visible ? <Trans>Hide</Trans> : <Trans>Read More</Trans>}
                </Grid>
            </Typography>
        </Grid>
    );
};

export default AiDescription;
