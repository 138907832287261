import React from 'react';

import { Grid } from '@mui/material';

import StyledTextarea from '../styledTextArea';

interface TextAreaProps {
    value: string;
    onChange: (newValue: string) => void;
    name?: string;
    minRows?: number;
    error?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
    value,
    onChange,
    name,
    minRows = 5,
    error,
}: TextAreaProps) => (
    <Grid container>
        <Grid item xs={12}>
            <StyledTextarea
                error={error}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                name={name}
                minRows={minRows}
            />
        </Grid>
    </Grid>
);

export default TextArea;
