import { FC } from 'react';

// import { CompanyExploreContextProvider } from 'src/explore/providers/CompanyExploreContextProvider';
import { Box } from '@mui/material';

import CompanyResultsPaginationControls from 'src/explore/pagination/CompanyResultsPaginationControls';

import CompanyProfileResultsList from './company/CompanyProfileResultsList';
import CompanyProfileSearchInput from './company/CompanyProfileSearchInput';

const CompanyExplore: FC = () => (
    <>
        <Box
            sx={{
                p: 2,
            }}
        >
            <CompanyProfileSearchInput />
        </Box>
        <Box
            sx={{
                p: 2,
            }}
        >
            <CompanyProfileResultsList />
            <CompanyResultsPaginationControls />
        </Box>
    </>
);

export default CompanyExplore;
