import graphql from 'babel-plugin-relay/macro';
import { FC, Suspense, useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';

import { NotificationContextProvider } from 'src/components/notifications/providers/NotificationContextProvider';
import { refreshInterval } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import useInterval from 'src/hooks/useInterval';

const NotificationChangesQuery = graphql`
    query NotificationProviderNotificationChangesQuery {
        lastNotificationCreatedAt
    }
`;

interface Props {
    children: any;
}

export const NotificationProvider: FC<Props> = ({ children }: Props) => {
    const { user } = useAuth();

    const [changesQueryRef, loadChangesQuery, disposeChangesQuery] =
        useQueryLoader(NotificationChangesQuery);
    const refreshChangesQuery = () => {
        loadChangesQuery({}, { fetchPolicy: 'store-and-network' });
    };

    useEffect(() => {
        if (user) {
            refreshChangesQuery();
        }
        return () => {
            disposeChangesQuery();
        };
    }, [user]);

    useInterval(() => {
        if (user) {
            refreshChangesQuery();
        }
    }, refreshInterval);

    if (changesQueryRef === null) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            <NotificationContextProvider
                changesQueryRef={changesQueryRef}
                refreshChangesQuery={refreshChangesQuery}
            >
                {children}
            </NotificationContextProvider>
        </Suspense>
    );
};
