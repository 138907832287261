import { createContext } from 'react';
import { PreloadedQuery } from 'react-relay';

interface RatecardProposalContextType {
    ratecardProposalQueryRef: PreloadedQuery | null;
    refreshRatecardProposalQuery: () => void;
}

const initialValues: RatecardProposalContextType = {
    ratecardProposalQueryRef: null,
    refreshRatecardProposalQuery: () => {},
};

export const RatecardProposalContext = createContext(initialValues);
