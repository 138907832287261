import React from 'react';

import { Trans } from '@lingui/macro';
import { Button, Grid } from '@mui/material';
import { useTour } from '@reactour/tour';

const TourContentWithDecline = ({
    children,
    onTourDeclined,
}: {
    children?: React.ReactNode;
    onTourDeclined: () => void;
}) => {
    const { setIsOpen, setCurrentStep } = useTour();

    const handleDeclineTour = () => {
        onTourDeclined();
        setIsOpen(false);
        setCurrentStep(0);
    };

    return (
        <>
            {children}
            <Grid container justifyContent="flex-end">
                <Button data-testid="hideTourButton" color="primary" onClick={handleDeclineTour}>
                    <Trans>Don&apos;t show this tour again</Trans>
                </Button>
            </Grid>
        </>
    );
};

export default TourContentWithDecline;
