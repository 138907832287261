import { useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

export const companySizeEmployeesSelector = (state: GlobalState) => state.companySizeEmployees;

export const companySizeEmployeesSelectorData = (state: GlobalState) =>
    companySizeEmployeesSelector(state).data;

export const companySizeEmployeesStatusSelector = (state: GlobalState) =>
    companySizeEmployeesSelector(state).status;
export const useCompanySizeEmployees = () => useAppSelector(companySizeEmployeesSelectorData);
