import { useCallback } from 'react';

import { ConsultingCompanyAutocompleteConsultingCompanySearchQuery } from 'src/components/common/autocomplete/ConsultingCompanyAutocomplete';
import useQueryRefetch from 'src/selectors/useQueryRefetch';

const useCompaniesRefetch = () => {
    const {
        refetch: queryRefetch,
        data,
        isRefreshing,
    } = useQueryRefetch(ConsultingCompanyAutocompleteConsultingCompanySearchQuery);

    const companies = data?.consultingCompaniesSearch?.edges?.map((item) => item.node) || [];

    const refetch = useCallback(
        (value) => {
            if (value.length < 3) {
                return;
            }
            queryRefetch({ text: value });
        },
        [queryRefetch],
    );

    return {
        refetch,
        companies,
        isRefreshing,
    };
};

export default useCompaniesRefetch;
