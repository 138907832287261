import React from 'react';

import { withSearch } from '@elastic/react-search-ui';
import { Paper, TableBody } from '@mui/material';

import Table from 'src/components/common/table';
import { searchToQueryDSL } from 'src/utils/dslUtils';

import { useSelectVendor, useSelectedVendors } from '../state/hooks';
import ExploreTableHead from './exploreTableHead';
import ExploreTableRow from './exploreTableRow';
import ExploreTableRowChips from './exploreTableRowChips';
import type { RowId } from './types';

interface ExploreTableProps {
    results?: any;
    searchTerm?: string;
}

const ExploreTable = ({ results, searchTerm }: ExploreTableProps) => {
    const selectedVendors = useSelectedVendors();
    const { selectVendors, deselectVendors, clearSelectedVendors } = useSelectVendor();

    React.useEffect(() => {
        clearSelectedVendors();
    }, [results]);

    const toggleRowSelection = (rowId: string) => {
        if (selectedVendors.includes(rowId)) {
            deselectVendors([rowId]);
        } else {
            selectVendors([rowId]);
        }
    };

    const isAllSelected = selectedVendors.length === results.length;
    const toggleSelectAll = () => {
        if (isAllSelected) {
            clearSelectedVendors();
        } else {
            selectVendors(results.map((row) => row?.id?.raw));
        }
    };

    const onRowSelected = (rowId: RowId) => toggleRowSelection(rowId?.raw);

    const queryParam = searchToQueryDSL(searchTerm);
    const tableRows = results.map((row) => (
        <ExploreTableRow
            key={row?.id?.raw}
            row={row}
            chips={<ExploreTableRowChips row={row} />}
            queryParam={queryParam}
            onSelect={onRowSelected}
            isSelected={selectedVendors.includes(row?.id?.raw)}
        />
    ));

    return (
        <Table component={Paper}>
            <ExploreTableHead isChecked={isAllSelected} onSelectAll={toggleSelectAll} />
            <TableBody>{tableRows}</TableBody>
        </Table>
    );
};

export default withSearch(({ results, searchTerm }) => ({
    results,
    searchTerm,
}))(ExploreTable) as React.FC<ExploreTableProps>;
