import { Box } from '@mui/material';

import ApalyticsFrame from 'src/apalytics/apalyticsFrame';
import { LicenseWarning } from 'src/components/utils/LicenseWarning';
import useAuth from 'src/hooks/useAuth';

const ApalyticsPage = () => {
    const { user } = useAuth();

    return (
        <>
            {user.apps.includes('apalytics') ? (
                <ApalyticsFrame />
            ) : (
                <Box
                    sx={{
                        backgroundColor: 'background.default',
                        minHeight: '100%',
                        p: 3,
                    }}
                >
                    <LicenseWarning module="apalytics" />
                </Box>
            )}
        </>
    );
};

export default ApalyticsPage;
