import graphql from 'babel-plugin-relay/macro';
import { FC, useContext, useEffect } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';

import { CompanyUserContext } from 'src/contexts/CompanyUserContext';

const CompanyUsersProviderQuery = graphql`
    query CompanyUsersProviderQuery {
        allUserCompanyRoles {
            user {
                id
                firstName
                lastName
            }
            email
            registrationCompleted
            profileCompleted
            briefRole
            apaduaRole
            exploreRole
            apalyticsRole
            monitorRole
            isActive
            isUserManager
        }
    }
`;

export const useCompanyUsersQuery = () => {
    const { companyUserQueryRef, refreshCompanyUserQuery } = useContext(CompanyUserContext);
    const companyUsersQuery = usePreloadedQuery(CompanyUsersProviderQuery, companyUserQueryRef);
    return {
        companyUsersQuery,
        refreshCompanyUserQuery,
    };
};

interface Props {
    children: any;
}

export const CompanyUsersProvider: FC<Props> = ({ children }: Props) => {
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(CompanyUsersProviderQuery);

    const refresh = () => {
        loadQuery({}, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refresh();
        return () => {
            disposeQuery();
        };
    }, []);

    if (queryRef === null) {
        return null;
    }

    return (
        <CompanyUserContext.Provider
            value={{
                companyUserQueryRef: queryRef,
                refreshCompanyUserQuery: refresh,
            }}
        >
            {children}
        </CompanyUserContext.Provider>
    );
};
