import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';

interface CompanyProfileResultEntryPreviewProps {
    content: any;
}

const StyledLink = styled(Link)({
    textDecoration: 'none !important',
    '&.noPoiterEvents': {
        pointerEvents: 'none',
    },
});

const CompanyProfileResultEntryPreview: FC<CompanyProfileResultEntryPreviewProps> = ({
    content,
}: CompanyProfileResultEntryPreviewProps) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('search');
    const { company } = useContext(CompanyProfileContext);

    return (
        <>
            <StyledLink to={`/explore/${company.id}/explore?search=${searchQuery}`}>
                <Button
                    sx={{
                        px: 0,
                    }}
                    style={{
                        textAlign: 'left',
                    }}
                >
                    <Box
                        sx={{
                            pb: 1,
                        }}
                    >
                        <Typography
                            variant="h6"
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                                __html: content.title,
                            }}
                        />
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                                __html: content.content,
                            }}
                        />
                    </Box>
                </Button>
            </StyledLink>
        </>
    );
};

export default CompanyProfileResultEntryPreview;
