import { FC, useMemo } from 'react';

import { useLingui } from '@lingui/react';
import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

import 'src/styles/utils.css';
import { ProjectAnnouncement } from 'src/types';
import AnnouncementTutorialModal from 'src/videoModals/AnnouncementTurorialModal';

import { AnnouncementHistoryItem } from './AnnouncementHistoryItem';

interface Props {
    announcements: Array<ProjectAnnouncement>;
    timezone: string;
    openFile: any;
    title: string;
    announcementNumber?: number;
}

export const AnnouncementHistory: FC<Props> = ({
    announcements,
    timezone,
    openFile,
    title,
    announcementNumber,
}: Props) => {
    const { i18n } = useLingui();

    const titleComponent = useMemo(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '11px',
                    }}
                >
                    {title}
                </Typography>
                <AnnouncementTutorialModal />
            </Box>
        ),
        [title],
    );

    return (
        <>
            {/* Announcements history */}
            {/* do not display the Latest Announcements section in the rate card Overview if no announcement was posted */}
            {announcementNumber && announcements?.length === 0 ? null : (
                <Card variant="outlined" sx={{ mb: 4 }}>
                    <CardHeader title={titleComponent} />
                    <Divider />
                    <CardContent>
                        {(!announcements || announcements?.length === 0) && (
                            <Typography color="textPrimary" variant="subtitle2">
                                {i18n._('No announcements')}
                            </Typography>
                        )}
                        {announcements?.map((announcementNode: ProjectAnnouncement) => (
                            <AnnouncementHistoryItem
                                announcementNode={announcementNode}
                                timezone={timezone}
                                key={announcementNode.id}
                                openFile={openFile}
                            />
                        ))}
                    </CardContent>
                </Card>
            )}
        </>
    );
};
