import { createSlice } from '@reduxjs/toolkit';

import { fetcherInitialState } from '../fetcher';
import { TenantsFetcher } from './thunk';

export const tenantsSlice = createSlice({
    name: 'tenants',
    initialState: fetcherInitialState,
    reducers: {},
    extraReducers(builder) {
        TenantsFetcher.reducers(builder);
    },
});

export default tenantsSlice.reducer;
