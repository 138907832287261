import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField } from '@mui/material';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';

interface Props {
    preview?: boolean;
}

const CompanyProfileSearchInput: FC<Props> = ({ preview }: Props) => {
    const { i18n } = useLingui();
    const { refreshCompanyResultsQuery, setSearch, search } = useContext(CompanyExploreContext);
    const { company } = useContext(CompanyProfileContext);
    const [currentKeyword, setCurrentKeyword] = useState(search || '');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const keyword = searchParams.get('search');
        if (keyword) {
            setCurrentKeyword(search || keyword || '');
        }
    }, [search]);

    const seek = () => {
        const newFilters = {
            keyword: currentKeyword,
            domain: company?.generalInfo?.website,
            companyId: company?.id,
        };
        searchParams.set('search', currentKeyword);
        const newUrl = `${window.location.href.split('?')[0]}?${searchParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        refreshCompanyResultsQuery(newFilters);
        setSearch(currentKeyword);
        if (preview) {
            navigate({
                pathname: `/explore/${company.id}/explore`,
                search: window.location.search,
            });
        }
    };

    const keyPress = (event) => {
        if (event.keyCode === 13) {
            seek();
        }
    };

    return (
        <Box
            sx={{
                pt: 2,
            }}
        >
            <TextField
                id="input-with-icon-textfield"
                label={i18n._('Keyword search')}
                size="small"
                value={currentKeyword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentKeyword(event.target.value);
                }}
                onKeyDown={(event) => keyPress(event)}
                InputProps={{
                    endAdornment: (
                        <IconButton size="small" color="primary" onClick={() => seek()}>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                style={{
                    backgroundColor: 'white',
                }}
                variant="outlined"
                fullWidth
            />
        </Box>
    );
};

export default CompanyProfileSearchInput;
