import graphql from 'babel-plugin-relay/macro';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
    fetchQuery,
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
} from 'react-relay/hooks';
import { useSearchParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { CompanyExploreContext } from 'src/explore/contexts/CompanyExploreContext';
import { CompanyProfileContext } from 'src/explore/contexts/CompanyProfileContext';
import { CompanyExploreContextInitializer } from 'src/explore/providers/CompanyExploreContextInitializer';
import useAuth from 'src/hooks/useAuth';

const CompanyExploreQuery = graphql`
    query CompanyExploreContextProviderQuery(
        $first: Int
        $last: Int
        $keyword: String
        $domain: String
        $companyId: ID
    ) {
        companyEsResults(
            first: $first
            last: $last
            keyword: $keyword
            domain: $domain
            companyId: $companyId
        )
    }
`;

export const useCompanyExploreQuery = () => {
    const { companyResultsQueryRef, refreshCompanyResultsQuery } =
        useContext(CompanyExploreContext);
    const companyResultsQuery = usePreloadedQuery(CompanyExploreQuery, companyResultsQueryRef);

    return {
        companyResultsQuery,
        refreshCompanyResultsQuery,
    };
};

interface Props {
    children: any;
}

export const CompanyExploreContextProvider: FC<Props> = ({ children }: Props) => {
    const { user } = useAuth();
    const { company } = useContext(CompanyProfileContext);
    const [searchParams] = useSearchParams();

    const [results, setResults] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState(searchParams.get('search') || '');

    const [filters, setFilters] = useState({});

    const mergeFilters = (newFilters) => {
        const mergedFilters = {
            ...filters,
            ...newFilters,
        };
        setFilters(mergedFilters);
        return mergedFilters;
    };

    const [companyResultsQueryRef, loadCompanyResultsQuery, disposeCompanyResultsQuery] =
        useQueryLoader(CompanyExploreQuery);

    const [activeSubscription, setActiveSubscription] = useState(null);

    const environment = useRelayEnvironment();

    const refetch = useCallback(
        (appliedfilters?) => {
            const finalFilters = mergeFilters(appliedfilters ?? {});

            if (activeSubscription) {
                // if there is an active subscription, we drop it;
                activeSubscription.unsubscribe();
            }

            const keyword = finalFilters?.keyword
                ? finalFilters?.keyword
                : searchParams.get('search') || '';
            const first = finalFilters?.first ? finalFilters?.first : 5;
            const last = finalFilters?.last ? finalFilters?.last : 5;
            const domain = finalFilters?.domain
                ? finalFilters?.domain
                : company?.generalInfo?.website;

            const variables = {
                keyword,
                first,
                last,
                domain: domain.replace(/^https?:\/\/(www\.)?/, ''),
                companyId: company.id,
            };

            // fetchQuery will fetch the query and write
            // the data to the Relay store. This will ensure
            // that when we re-render, the data is already
            // cached and we don't suspend
            // get the subscription object and set it as state
            setActiveSubscription(
                fetchQuery(environment, CompanyExploreQuery, variables).subscribe({
                    complete: () => {
                        setActiveSubscription(null);

                        // *After* the query has been fetched, we call
                        // loadQuery again to re-render with a new
                        // queryRef.
                        loadCompanyResultsQuery(
                            {
                                keyword,
                                first,
                                last,
                                domain: domain.replace(/^https?:\/\/(www\.)?/, ''),
                                companyId: company.id,
                            },
                            { fetchPolicy: 'store-and-network' },
                        );
                    },
                    error: () => {
                        setActiveSubscription(null);
                    },
                }),
            );
        },
        [filters],
    );

    useEffect(() => {
        refetch();
        return () => {
            disposeCompanyResultsQuery();
        };
    }, [user]);

    if (!companyResultsQueryRef) {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={64} />
                </Box>
            </>
        );
    }

    return (
        <CompanyExploreContext.Provider
            value={{
                results,
                setResults,
                filters,
                setFilters: mergeFilters,
                companyResultsQueryRef,
                refreshCompanyResultsQuery: refetch,
                totalCount,
                setTotalCount,
                search,
                setSearch,
            }}
        >
            <CompanyExploreContextInitializer>{children}</CompanyExploreContextInitializer>
        </CompanyExploreContext.Provider>
    );
};
