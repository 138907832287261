import graphql from 'babel-plugin-relay/macro';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';

import CompanyProfile from 'src/explore/company/CompanyProfile';

const CompanyProfilePageCompanyQuery = graphql`
    query CompanyProfilePageCompanyQuery($id: ID!) {
        company(id: $id) {
            id
            companyName
            reported

            allSocialAccounts {
                totalCount
                edges {
                    node {
                        network
                        profileUrl
                        followers
                    }
                }
            }

            serviceLine {
                serviceLineEn
                serviceLineDe
            }

            serviceIndividuals {
                totalCount
                edges {
                    node {
                        allServices
                    }
                }
            }

            countries {
                totalCount
                edges {
                    node {
                        commonNameEn
                        iso2letter
                    }
                }
            }

            generalInfo {
                descriptionEn
                descriptionDe
                foundationYear
                website
                email
                numberOfEmployees
                turnover
                turnoverCurrency {
                    isoCurrencyCode
                }
            }
            services {
                totalCount
                edges {
                    node {
                        id
                        serviceEn
                    }
                }
            }
            industries {
                totalCount
                edges {
                    node {
                        id
                        industryEn
                    }
                }
            }
            lnCompanySizeEmployees {
                employeesRangeEn
                employeesRangeDe
            }
            consultantType {
                consultantTypeEn
                consultantTypeDe
            }
            lnCompanyType {
                companyTypeEn
                companyTypeDe
            }
            lnCompanySpecialities {
                totalCount
                edges {
                    node {
                        id
                        keywordEn
                        keywordDe
                    }
                }
            }
            targetIndustries {
                totalCount
                edges {
                    node {
                        id
                        industryEn
                        industryDe
                    }
                }
            }
            logo
            allOffices {
                totalCount
                edges {
                    node {
                        id
                        country
                    }
                }
            }
            studies {
                totalCount
                edges {
                    node {
                        title
                        descriptionEn
                        descriptionDe
                        year
                        logo
                    }
                }
            }
            allKeywordSummaries {
                id
                keyword {
                    id
                    keywordEn
                    keywordDe
                }
                summary
            }
            importYear
        }
    }
`;

const CompanyProfilePage: FC = () => {
    const { i18n } = useLingui();
    const { companyId } = useParams();

    const initialQueryRef = null;

    const [companyQueryRef, loadQuery, disposeQuery] = useQueryLoader(
        CompanyProfilePageCompanyQuery,
        initialQueryRef,
    );

    const refreshCompanyQuery = () => {
        loadQuery({ id: companyId }, { fetchPolicy: 'network-only' });
    };

    useEffect(() => {
        refreshCompanyQuery();
        return () => {
            disposeQuery();
        };
    }, [companyId]);

    if (companyQueryRef === null) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{i18n._('Explore')}</title>
            </Helmet>
            <CompanyProfile companyQueryRef={companyQueryRef} />
        </>
    );
};

export default CompanyProfilePage;
