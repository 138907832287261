import { useCallback } from 'react';
import { createSelector } from 'reselect';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { GlobalState } from 'src/redux/store';

import { sanitizeList } from './sanitizers';
import { serialiseVendorListItem } from './serializers';
import {
    ListsFetcher,
    createList,
    createListItem,
    createOrganisationList,
    deleteList,
    deleteListItem,
    updateList,
    updateListItem,
} from './thunk';

const isOrganisationList = (list) =>
    sanitizeList(list).tagsRelations.some((tagsRelation) =>
        tagsRelation.tag.key.includes('company_'),
    )
        ? 1
        : 0;

const listsSelector = (state: GlobalState) => state.lists;

const listsDataSelector = createSelector(
    [(state: GlobalState) => listsSelector(state).data],
    (lists) =>
        [...lists].sort((list1, list2) => {
            if (list1.locked || list2.locked) {
                return list2.locked - list1.locked;
            }

            return isOrganisationList(list2) - isOrganisationList(list1);
        }),
);

const listDataUserSelector = createSelector(
    [(state: GlobalState) => listsSelector(state).data],
    (lists) => lists.filter((list) => !isOrganisationList(list)),
);

const listDataOrganizationSelector = createSelector(
    [(state: GlobalState) => listsSelector(state).data],
    (lists) =>
        lists
            .filter((list) => isOrganisationList(list))
            .sort((list1, list2) => list2.locked - list1.locked),
);

const listsStatusSelector = (state: GlobalState) => listsSelector(state).status;

const listsErrorSelector = (state: GlobalState) => listsSelector(state).error;

export const useLists = () => useAppSelector(listsSelector);

export const useListsData = () => useAppSelector(listsDataSelector);

export const useUserListsData = () => useAppSelector(listDataUserSelector);

export const useOrganizationListsData = () => useAppSelector(listDataOrganizationSelector);

export const useListsIdle = () => useAppSelector(listsStatusSelector) === 'idle';

export const useListsLoading = () => useAppSelector(listsStatusSelector) === 'loading';

export const useListsFailed = () => useAppSelector(listsStatusSelector) === 'failed';

export const useListsError = () => useAppSelector(listsErrorSelector);

export const useFetchLists = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(() => {
        if (listStatus !== 'loading') {
            dispatch(ListsFetcher.action());
        }
    }, [listStatus, dispatch, listStatus]);
};

export const useCreateList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (data) => {
            if (listStatus !== 'loading') {
                dispatch(createList(data));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useCreateOrganisationList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (data) => {
            if (listStatus !== 'loading') {
                dispatch(createOrganisationList(data));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useCreateVendorList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (data) => {
            data.selectedVendors = data.selectedVendors?.map((v) => serialiseVendorListItem(v));
            if (listStatus !== 'loading') {
                dispatch(createList(data));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useCreateOrganisationVendorList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (data) => {
            data.selectedVendors = data.selectedVendors?.map((v) => serialiseVendorListItem(v));
            if (listStatus !== 'loading') {
                dispatch(createOrganisationList(data));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useCreateListItems = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (listId, listItems) => {
            if (listStatus !== 'loading') {
                dispatch(createListItem({ listId, listItems }));
            }
        },
        [dispatch, listStatus],
    );
};

export const useCreateVendorListItems = () => {
    const createListItems = useCreateListItems();
    return useCallback(
        (listId, vendorIds) => {
            createListItems(
                listId,
                vendorIds.map((v) => serialiseVendorListItem(v)),
            );
        },
        [createListItems],
    );
};

export const useDeleteList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (listId) => {
            if (listStatus !== 'loading') {
                dispatch(deleteList(listId));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useUpdateList = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (list) => {
            if (listStatus !== 'loading') {
                dispatch(updateList(list));
            }
        },
        [listStatus, dispatch, listStatus],
    );
};

export const useUpdateListItem = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (listItemData) => {
            if (listStatus !== 'loading') {
                dispatch(updateListItem(listItemData));
            }
        },
        [dispatch, listStatus],
    );
};

export const useDeleteListItem = () => {
    const dispatch = useAppDispatch();
    const listStatus = useAppSelector(listsStatusSelector);

    return useCallback(
        (listId, listItemId) => {
            if (listStatus !== 'loading') {
                dispatch(deleteListItem({ listId, listItemId }));
            }
        },
        [listStatus, dispatch],
    );
};
