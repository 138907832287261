import { Trans } from '@lingui/macro';

import Section from 'src/components/sections/section';
import ProjectReviewAndSubmitContainer from 'src/project/projectSummary/projectReviewAndSubmitContainer';

const ProjectClientSummaryPage = () => (
    <Section title={<Trans>RFP summary</Trans>} data-testid="ProjectReviewSubmit">
        <ProjectReviewAndSubmitContainer />
    </Section>
);

export default ProjectClientSummaryPage;
