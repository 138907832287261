import { useFormikContext } from 'formik';

import { Section } from 'src/components/sections';
import useConfirmUnsavedChangesModal from 'src/hooks/useConfirmUnsavedChangesModal';
import useProjectActions from 'src/project/useProjectActions';

interface ProjectFooterProps {
    onValidate?: () => Promise<any>;
    onNext?: () => void;
    onSave?: () => Promise<void>;
    submitProject?: boolean;
}

const ProjectFooter = ({
    onValidate,
    onNext,
    onSave,
    submitProject = false,
}: ProjectFooterProps) => {
    const formikContext = useFormikContext();

    const modalContent = useConfirmUnsavedChangesModal({
        condition: formikContext?.dirty,
        onDiscard: onNext,
        onSave,
    });
    const actions = useProjectActions({ onValidate, onSave, submitProject });

    return (
        <>
            <Section actions={actions} />
            {modalContent}
        </>
    );
};

export default ProjectFooter;
