import snakeCase from 'lodash/snakeCase';

export const dictionarySnakeCaseConverter = (data: Array<any>) =>
    data.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
            const snakeKey = snakeCase(key);
            newItem[snakeKey] = item[key];
        });
        return newItem;
    });
