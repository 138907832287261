import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';

type SuggestionChipProps = {
    onClick: (label: string) => void;
    label: string;
};

const SuggestionChip: FC<SuggestionChipProps> = ({
    onClick: handleClick,
    label,
}: SuggestionChipProps) => (
    <Chip
        icon={<AddIcon />}
        key={label}
        tabIndex={-1}
        label={label}
        sx={{
            mr: 0.5,
            mb: 0.5,
        }}
        onClick={() => handleClick(label)}
    />
);

export default SuggestionChip;
