import { FC, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Box, Button, Typography } from '@mui/material';

import { Bucket, BucketContainer } from 'src/components/sections/bucketSection';
import { useApaduaAnnouncements } from 'src/redux/apaduaAnnouncements/hook';
import getOptionKey from 'src/utils/language/getOptionKey';

import Modal from './modal';

const defaultTriggerButtonComponent = (props) => null;

const ApaduaAnnouncementModal: FC = () => {
    const { announcement, handleDoNotShow } = useApaduaAnnouncements();
    const [isOpen, setIsOpen] = useState(!!announcement.id);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleDontShowAgain = () => {
        setIsOpen(false);
        handleDoNotShow(announcement.id);
    };

    return (
        <Modal
            externalControl
            onClose={handleClose}
            onOpen={handleOpen}
            isOpen={isOpen}
            triggerButtonComponent={defaultTriggerButtonComponent}
            style={{
                width: 'auto',
                padding: 0,
            }}
        >
            <Box sx={{ p: 4 }}>
                <BucketContainer isVertical>
                    <Bucket xs={12}>
                        <Typography variant="h5" sx={{ pb: 4, textAlign: 'center' }}>
                            {getOptionKey(announcement, 'title')}
                        </Typography>
                        <Typography>{getOptionKey(announcement, 'text')}</Typography>
                    </Bucket>
                    <Bucket xs={12}>
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            <Trans>Close</Trans>
                        </Button>
                        <Button color="primary" onClick={handleDontShowAgain}>
                            <Trans>Don&apos;t show this again</Trans>
                        </Button>
                    </Bucket>
                </BucketContainer>
            </Box>
        </Modal>
    );
};

export default ApaduaAnnouncementModal;
